import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DisplayModeProvider } from "./context/DisplayModeContext";
import { OrderDataProvider } from "./context/OrderDataContext";
import HomePage from "./pages/HomePage";
import DeliveryPage from "./pages/DeliveryPage";
import EditPage from "./pages/Editorder";
import ReportsPage from "./pages/ReportsPage";
import DriverReportsPage from "./pages/DriverReportPage";
import RoutePage from "./pages/RoutePage";
import OpenRoute from "./pages/RouteSubPages/OpenRoute";
import Layout from "./components/layout/Layout";
import AddDriverPage from "./pages/AddDriverPage";
import AddPharmacyEmployeePage from "./pages/AddPharmacyEmployeePage";
import AddInfo from "./pages/AddDriversSubPages/AddInfo";
import AddPharmacyEmployee from "./pages/AddPharmacyEmployeeSubPages/AddPharmacyEmployee";
import AddPharmacyInfo from "./pages/AddPharmacySubPages/AddPharmacyInfo";
import AddPharmacy from "./pages/AddPharmacy";
import NotificationsPage from "./pages/NotificationsPage";
import ZonePage from "./pages/ZonePage";
import ZoneDetailPage from "./pages/ZoneSubPages/ZoneDetailPage";
import TicketsPage from "./pages/TicketsPage";
import ChatPage from "./pages/ChatPage";
import Dashboard from "./pages/Dashboard";
import PharmacyOrders from "./pages/DailyOrderPage";
import RoutesStatus from "./pages/RoutesStatus";
import Calls from "./pages/Calls";
import ResponsiveComponent from "./components/ResposiveComponent";
import HomeSmall from "./smallPages/HomeSmall";
import DeliverySmall from "./smallPages/DeliverySmall";
import RouteSmall from "./smallPages/RouteSmall";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import AddFacility from "./pages/AddFacility";
import ExpenseList from "./pages/ExpenseList";
import FacilityUser from "./pages/FacilityUser";
import AdminNotifications from "./pages/AdminNotificationsPage";
import AdminExpense from "./pages/ExpenseForm";
import AnnoucmentNotification from "./pages/AnnoucmentNotification";
import SMSList from "./pages/SMSList";
import RouteHereDetailMobile from "./components/mobileScreens/RouteHereDetailMobile";
import RouteReturnHereDetailMobile from "./components/mobileScreens/RouteReturnHereDetailMobile";
function App() {
  const userType = localStorage.getItem("userType");
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("userType") === "PharmacyEmployee" ||
    localStorage.getItem("userType") === "PharmacyOwner"
  );
  return (
    <>
      <ToastContainer />
      <DisplayModeProvider>
        <OrderDataProvider>
          <Router>
            <Routes>
              <Route path="/order-list" element={<Layout />}>
                <Route
                  index
                  element={
                    <ResponsiveComponent large={HomePage} small={HomeSmall} />
                  }
                />
              </Route>
              <Route path="/dashboard" element={<Layout />}>
                <Route index element={<Dashboard />} />
              </Route>
              <Route path="/add-order" element={<Layout hasSidebar={false} hasNavbar={false} />}>
                <Route
                  index
                  element={
                    <ResponsiveComponent
                      large={DeliveryPage}
                      small={DeliverySmall}
                    />
                  }
                />
              </Route>
              <Route
                path="/add-manual-order"
                element={<Layout hasSidebar={false} hasNavbar={false} />}
              >
                <Route
                  index
                  element={
                    <ResponsiveComponent
                      large={DeliveryPage}
                      small={DeliverySmall}
                    />
                  }
                />
              </Route>
              <Route
                path="/detail/order/:oid"
                element={<Layout hasSidebar={false} hasNavbar={false} />}
              >
                <Route
                  index
                  element={
                    <ResponsiveComponent
                      large={EditPage}
                      small={EditPage}
                      isEditable={false}
                    />
                  }
                />
              </Route>
              <Route
                path="/update/order/:oid"
                element={<Layout hasSidebar={false} hasNavbar={false} />}
              >
                <Route
                  index
                  element={
                    <ResponsiveComponent
                      large={EditPage}
                      small={EditPage}
                      isEditable={true}
                    />
                  }
                />
              </Route>
              <Route
                path="/detail/order/:oid"
                element={<Layout hasSidebar={false} hasNavbar={false} />}
              >
                <Route
                  index
                  element={
                    <ResponsiveComponent
                      large={DeliveryPage}
                      small={DeliverySmall}
                    />
                  }
                />
              </Route>
              {/* Reports Page Routes with Layout */}
              <Route path="/reports" element={<Layout />}>
                <Route index element={<ReportsPage />} />
                <Route path="report1" element={<ReportsPage />} />

                <Route path="driver" element={<DriverReportsPage />} />
                <Route path="report2" element={<ReportsPage />} />

                <Route path="pharmacyorders" element={<PharmacyOrders />} />
              </Route>
              {/* Reports Page Routes with Layout */}
              <Route path="/reports" element={<Layout />}>
                <Route index element={<ReportsPage />} />
                <Route path="report1" element={<ReportsPage />} />
                <Route
                  path="report2"
                  element={<ReportsPage extraContent={true} />}
                />
                <Route path="pharmacyorders" element={<PharmacyOrders />} />
              </Route>
              {/* Routes Page with Nested Routes */}
              <Route path="/routes" element={<Layout />}>
                <Route
                  index
                  element={
                    <ResponsiveComponent large={RoutePage} small={RouteSmall} />
                  }
                />
                <Route path="open-route/:rid" element={<OpenRoute />} />
                <Route path="open-route/:rid/:copay" element={<OpenRoute />} />
                <Route
                  path="edit-route/:rid"
                  element={<OpenRoute editable={true} />}
                />
              </Route>

              {/* Routes Page with Nested Routes */}
              <Route path="/adddrivers" element={<Layout />}>
                <Route index element={<AddDriverPage />} />
                <Route path="add-info" element={<AddInfo />} />
              </Route>
              <Route path="/addemployee" element={<Layout />}>
                <Route index element={<AddPharmacyEmployeePage />} />
                <Route path="add-info" element={<AddPharmacyEmployee />} />
              </Route>
              {/* Routes Page with Nested Routes */}
              <Route path="/addpharmacy" element={<Layout />}>
                <Route index element={<AddPharmacy />} />
                <Route path="add-info" element={<AddPharmacyInfo />} />
              </Route>

              <Route path="/facilityuser" element={<Layout />}>
                <Route index element={<AddFacility />} />
                <Route path="adduser" element={<FacilityUser />} />
              </Route>
              <Route path="/notifications" element={<Layout />}>
                <Route
                  index
                  element={<NotificationsPage cancelationReason={false} />}
                />
              </Route>

              <Route path="/cancelOrders" element={<Layout />}>
                <Route
                  index
                  element={<NotificationsPage cancelationReason={true} />}
                />
              </Route>
              <Route path="/updateOrders" element={<Layout />}>
                <Route index element={<NotificationsPage />} />
              </Route>
              <Route path="/zones" element={<Layout />}>
                <Route index element={<ZonePage />} />
                <Route path="/zones/zonedetail" element={<ZoneDetailPage />} />
              </Route>
              <Route path="/seealltickets" element={<Layout />}>
                <Route index element={<TicketsPage />} />
              </Route>
              <Route path="/chats" element={<Layout />}>
                <Route index element={<ChatPage />} />
              </Route>
              <Route path="/sms-list" element={<Layout />}>
                <Route index element={<SMSList />} />
              </Route>
              <Route path="/routes-status" element={<Layout />}>
                <Route index element={<RoutesStatus />} />
              </Route>
              <Route path="/notifications-form" element={<Layout />}>
                <Route index element={<AdminNotifications />} />
              </Route>
              <Route path="/announcment-notification" element={<Layout />}>
                <Route index element={<AnnoucmentNotification />} />
              </Route>
              <Route path="/list" element={<Layout />}>
                <Route index element={<ExpenseList />} />
                <Route path="expense-form" element={<AdminExpense />} />
              </Route>
              <Route path="/calls" element={<Layout />}>
                <Route index element={<Calls />} />
              </Route>
              <Route
                path="/"
                element={
                  <Login
                    userType={userType}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                  />
                }
              />

              <Route path="/mobile/route/:rid/:token" element={<RouteHereDetailMobile />} />
              <Route path="/mobile/routereturn/:rid/:token" element={<RouteReturnHereDetailMobile />} />

            </Routes>
          </Router>
        </OrderDataProvider>

      </DisplayModeProvider>
    </>
  );
}

export default App;
