import { Box } from "@mui/material";
import React from "react";
import Topbar from "../components/facilityUserPageCompo/Topbar";
import UserForm from "../components/facilityUserPageCompo/UserForm";
import { ContainedButton } from "../components/buttonCompo/Buttons";

const FacilityUser = () => {
  return (
    <Box sx={{ mx: 3, marginBottom: 8 }}>
      <UserForm />
    </Box>
  );
};

export default FacilityUser;
