import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import StatusCounter from "../components/dashboardCompo/StatusCounter";
import LineChartComponent from "../components/dashboardCompo/LineChartComponent";
import TrafficByWebsite from "../components/dashboardCompo/TrafficByWebsite";
import TrafficByLocation from "../components/dashboardCompo/TrafficByLocation";
import DeliveryChart from "../components/dashboardCompo/DeliveryChart";
import DeliveryCostChart from "../components/dashboardCompo/DeliveryCostChart";
import FinanceHistory from "../components/dashboardCompo/FinanceHistory";
import SingleFieldDateRangePicker from "../components/dateRangePicker/DateRangePicker";
import { GET_DASHBOARD, EXPENSE_LIST } from "../utils/apiUrl";
import moment from "moment";
import { getApiWithAuth, postApiWithAuth, putApiWithAuth } from "../utils/api";
import SupportButtonManager from "../components/SupportComponent/SupportButtonManager";
import Income from "../components/dashboardCompo/Income";
const Dashboard = () => {
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [orderNum, setOrderNum] = useState({});
  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const fetchOrderNum = async () => {
    try {
      setLoading(true);
      const validEndDate =
        dateRange[1] && !isNaN(dateRange[1].getTime())
          ? dateRange[1]
          : new Date(); // If invalid, set to today
      const response = await getApiWithAuth(
        GET_DASHBOARD +
          `?from=${moment(dateRange[0]).format("MM-DD-YYYY")}&to=${moment(
            validEndDate
          ).format("MM-DD-YYYY")}`
      );
      setLoading(false);
      if (response.data !== undefined) {
        setOrderNum(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchOrderNum();
  }, [dateRange]);
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        setLoading(true);
        const validEndDate =
          dateRange[1] && !isNaN(dateRange[1].getTime())
            ? dateRange[1]
            : new Date();
        const response = await getApiWithAuth(
          EXPENSE_LIST +
            `?from=${moment(dateRange[0]).format("MM-DD-YYYY")}&to=${moment(
              validEndDate
            ).format("MM-DD-YYYY")}`
        );
        if (response.data !== undefined) {
          const driversData = response.data.data;
          setExpenses(driversData);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchExpenses();
  }, [dateRange]);
  return (
    <Box
      sx={{
        mx: 3,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        overflow: "hidden",
      }}
    >
      {/* <SupportButtonManager /> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <SingleFieldDateRangePicker
          value={dateRange}
          onChange={handleDateChange}
        />
      </Box>
      <StatusCounter orderNum={orderNum} />
      <Box sx={{ display: "flex", gap: 3, marginBottom: 2 }}>
        <LineChartComponent orderNum={orderNum} />
        <TrafficByWebsite orderNum={orderNum} />
        <TrafficByLocation orderNum={orderNum} />
      </Box>
      <Box sx={{ display: "flex", gap: 3, marginBottom: 2 }}>
        <DeliveryChart orderNum={orderNum} />
        <DeliveryCostChart orderNum={orderNum} />
        <Income orderNum={orderNum} />
      </Box>
      <FinanceHistory expenses={expenses} />
    </Box>
  );
};

export default Dashboard;
