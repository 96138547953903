import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { CallIcon, MailIcon, PersonIconDriver, SettingsIcon } from "../Icons";
import { useNavigate } from "react-router-dom";
import {
  ContainedButton,
  ContainedButtonWithIcon,
} from "../buttonCompo/Buttons";

const PharmacyEmployeeOverview = ({ driver, showActive }) => {
  const navigate = useNavigate();
  console.log("pharmacy employee overview", driver)
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const handleSettingsClick = (driver) => {
    navigate(`add-info?id=${driver.id}`);
  };
  const currentDate = new Date().toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 20px",
        boxShadow: 1,
        borderRadius: "8px",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f0f0f0",
          boxShadow: 1,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: "700px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "auto", // Adjust height for two lines
            width: "207px",
            padding: "7px",
            border: "none",
            backgroundColor: "#f1f3f4",
            color: "#000",
            gap: 1,
          }}
        >
          <PersonIconDriver />
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 700, fontSize: "11.71px" }}
            >
              {driver.username}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "11.71px", fontWeight: 700 }}
            >
              {driver.first_name + " " + driver.last_name}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            minWidth: "129px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <CallIcon />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {driver.contact}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            maxWidth: "237px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <MailIcon />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {driver.email}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        {!showActive && (
          <ContainedButton
            label="Deactivated"
            bgColor="#fc1002"
            width="91px"
            height="31.23px"
            border="none"
          />
        )}
        {showActive && (
          <ContainedButton
            label="Active"
            bgColor="#05b320"
            width="60.51px"
            height="31.23px"
            border="none"
          />
        )}
        <ContainedButtonWithIcon
          label="Account Setting"
          icon={<SettingsIcon />}
          bgColor="#000"
          borderRadius="8px"
          width="124.94px"
          height="31.23px"
          padding="4.19px"
          onClick={() => handleSettingsClick(driver)}
        />
        {driver?.profile_pic ? (
          <img
            src={driver?.profile_pic}
            alt="Preview"
            style={{
              width: 40,
              height: 40,
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        ) : (
          <Avatar
            src="/icons/Profile.png"
            alt="Profile Image"
            sx={{ width: 40,
              height: 40,
              objectFit: "cover",
              borderRadius: "50%"
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default PharmacyEmployeeOverview;
