import React from "react";
import { Box, Typography } from "@mui/material";

const routeProgressData = [
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#FF5733", // Green line
    whiteSteps: [], // Steps with white background
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#0ea985", // Blue line
    whiteSteps: [],
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#3598dc", // Yellow line
    whiteSteps: [],
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#f4cf40", // Purple line
    whiteSteps: [1, 2, 3, 4, 5, 6, 7],
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#8a46ad", // Green line
    whiteSteps: [],
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#2dcc70", // Dark Blue line
    whiteSteps: [],
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#164360", // Light Red line
    whiteSteps: [1, 2, 3, 4, 5, 6, 7],
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#f29488", // Green line
    whiteSteps: [],
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#753d8f", // Green line
    whiteSteps: [],
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#164360", // Light Red line
    whiteSteps: [1, 2, 3, 4, 5, 6, 7],
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#f29488", // Green line
    whiteSteps: [],
  },
  {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 2,
    lineColor: "#753d8f", // Green line
    whiteSteps: [],
  },
];

const RouteProgress = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="body2" sx={{ marginBottom: 2, fontSize: "1.25rem" }}>
        Route Progress Time
      </Typography>
      {routeProgressData.map((route, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "47px",
            marginBottom: "10px",
            paddingBottom: "10px",
            position: "relative",
            transition: "box-shadow 0.3s ease",
            "&:hover": {
              cursor: "pointer",
              boxShadow: 1, // Increase shadow on hover
            },
          }}
        >
          {/* Line before progress steps */}
          <Box
            sx={{
              width: "100%",
              position: "absolute",
              zIndex: 1,
              height: "5px",
              backgroundColor: route.lineColor,
              borderRadius: "2px",
            }}
          ></Box>

          {/* Render steps */}
          {route.steps.map((step, stepIndex) => (
            <Box
              key={stepIndex}
              sx={{
                backgroundColor: route.whiteSteps.includes(step)
                  ? "#fff"
                  : step === route.activeStep
                  ? "#E53935"
                  : "#4CAF50", // Red for active step, Green for others, White for specified steps
                color: route.whiteSteps.includes(step) ? "#000" : "#fff", // Black text on white steps
                width: "28px",
                fontSize: "12px",
                zIndex: 2,
                height: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: stepIndex === 0 ? "12px" : "0", // Margin left for the first step
                marginRight:
                  stepIndex === route.steps.length - 1 ? "12px" : "8px", // Margin right for the last step, otherwise normal margin
                boxShadow: route.whiteSteps.includes(step) ? 1 : "none", // Border for white steps
              }}
            >
              {step}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default RouteProgress;
