/* eslint-disable no-console */
import axios from "axios";
import { toast } from 'react-toastify';
import { getToken, removeToken } from "./localStorage";
import { useHistory } from 'react-router-dom';

import { AGENTS } from "./apiUrl";
const BASE_URL = process.env.REACT_APP_BACKENDURL;
const baseInstance = axios.create({
  baseURL: BASE_URL,
});
baseInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      toast.error(`Session expired. Please log in again.`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      handleLogoutActive();
    }
    return Promise.reject(error);
  }
);
export const getApiWithoutAuth = async (url) => {
  try {
    const res = await baseInstance.get(url);
    return {
      data: res.data,
    };
  } catch (err) {
    return err.response;
  }
};

export const getApiWithAuth = async (url, params = {}) => {
  await setApiHeader();
  try {
    const res = await baseInstance.get(url, { params });

    return {
      data: res,
    };
  } catch (err) {
    return err.response;
  }
};

export const getApiWithLocalAuth = async (token, url, params = {}) => {
  await setApiLocalHeader(token);
  try {
    const res = await baseInstance.get(url, { params });

    return {
      data: res,
    };
  } catch (err) {
    return err.response;
  }
};

export const patchApiWithAuth = async (url, body) => {
  await setApiHeader();
  try {
    const res = await baseInstance.patch(url, body);
    return {
      data: res,
    };
  } catch (err) {
    return err.response;
  }
};
export const patchApiWithOutAuth = async (url, body) => {
  try {
    const res = await baseInstance.patch(url, body);
    return {
      data: res.data,
    };
  } catch (err) {
    return err.response;
  }
};

export const putApiWithAuth = async (url, body) => {
  await setApiHeader();
  try {
    const res = await baseInstance.put(url, body);

    return {
      data: res.data,
    };
  } catch (err) {
    return err.response;
  }
};

export const putApiWithoutAuth = async (url, body) => {
  try {
    const res = await baseInstance.put(url, body);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const deleteApiWithAuth = async (url) => {
  await setApiHeader();
  try {
    const res = await baseInstance.delete(url);

    return {
      data: res,
    };
  } catch (err) {
    return err.response;
  }
};

export const postApiWithAuth = async (url, body) => {
  await setApiHeader();
  try {
    const res = await baseInstance.post(url, body);

    return {
      data: res,
    };
  } catch (err) {
    return err.response;
  }
};
export const postApiWithoutAuth = async (url, body) => {
  try {
    const res = await baseInstance.post(url, body);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const postFormDataAPI = async (url, body) => {
  baseInstance.defaults.headers.common["Content-Type"] = "multipart/form-data";
  await setApiHeader();
  try {
    const res = await baseInstance.post(url, body);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export const postFormJsonAPI= async (url, body) => {
  baseInstance.defaults.headers.common["Content-Type"] = "application/json";
  await setApiHeader();
  try {
    const res = await baseInstance.post(url, body);
    return res.data;
  } catch (err) {
    return err.response;
  }
};


const setApiHeader = async () => {
  baseInstance.defaults.headers.common["Authorization"] =
    "Bearer " + getToken();
};

const setApiLocalHeader = async (token) => {
  baseInstance.defaults.headers.common["Authorization"] =
    "Bearer " + token;
};

const handleLogoutActive = async () => {
  removeToken();
  const set_agent = localStorage.getItem("sessionId");
  await patchApiWithOutAuth(
    AGENTS + `delete-by-session/?session_id=${set_agent}`
  );
  localStorage.clear();
  window.location.href = '/';
};