import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  CallIcon,
  LocationIconBlack,
  MailIcon,
  PersonIconDriver,
  SettingsIcon,
} from "../Icons";
import {
  ContainedButton,
  ContainedButtonWithIcon,
} from "../buttonCompo/Buttons";
import { useNavigate } from "react-router-dom";

const UserOverview = ({ data }) => {
  const navigate = useNavigate();

  const handleSettingsClick = (data) => {
    navigate(`adduser?id=${data.id}`);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 20px",
        boxShadow: 1,
        borderRadius: "8px",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f0f0f0",
          boxShadow: 1,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: "700px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            width: "207px",
            padding: "7px",
            border: "none",
            backgroundColor: "#f1f3f4",
            color: "#000",
            gap: 1,
          }}
        >
          <PersonIconDriver />
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, fontSize: "11.71px" }}
          >
            {data.firstName + " " + data.lastName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            minWidth: "129px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <CallIcon />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {data.phoneNumber}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            maxWidth: "237px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <MailIcon />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {data.email}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            maxWidth: "237px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <LocationIconBlack />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {data.address}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        {!data.isActive && (
          <ContainedButton
            label="Deactivated"
            bgColor="#fc1002"
            width="91px"
            height="31.23px"
            border="none"
          />
        )}
        {data.isActive && (
          <ContainedButton
            label="Active"
            bgColor="#05b320"
            width="60.51px"
            height="31.23px"
            border="none"
          />
        )}

        <ContainedButtonWithIcon
          label="Setting"
          icon={<SettingsIcon />}
          bgColor="#000"
          borderRadius="8px"
          width="87px"
          height="32px"
          padding="4.19px"
          onClick={() => handleSettingsClick(data)}
        />
        {data?.logo ? (
          <img
            src={data?.logo}
            alt="Preview"
            style={{
              width: 40,
              height: 40,
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        ) : (
          <Avatar
            src="/icons/Profile.png"
            alt="Profile Image"
            sx={{ width: 40,
              height: 40,
              objectFit: "cover",
              borderRadius: "50%"
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default UserOverview;
