import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
const colors = [
  "#95a4fc", // Monday
  "#baedbd", // Tuesday
  "#1c1c1c", // Wednesday
  "#b1e3ff", // Thursday
  "#a8c5da", // Friday
  "#a1e3cb", // Saturday
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: "#000",
          color: "#fff",
          padding: "6px 12px",
          borderRadius: "8px",
          fontSize: "12px",
        }}
      >
        {payload[0].value.toLocaleString()}
      </Box>
    );
  }
  return null;
};

const DeliveryCostChart = ({orderNum} ) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const daysMapping = {
    Monday: "Monday_price",
    Tuesday: "Tuesday_price",
    Wednesday: "Wednesday_price",
    Thursday: "Thursday_price",
    Friday: "Friday_price",
    Saturday: "Saturday_price",
  };
  const data = Object.entries(daysMapping).map(([day, key]) => ({
    name: day.slice(0, 3), // Shorten to Mon, Tue, etc.
    value: orderNum?.[key] || 0, // Get value from orderNum using key
  }));
  // Handle hover event
  const onMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const onMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <Box
      sx={{
        padding: "16px",
        flex: 1,
        borderRadius: "12px",
        backgroundColor: "#f7f9fb", // Background color
      }}
    >
      {/* Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h1" sx={{ fontSize: "14px", fontWeight: 600 }}>
        Deliveries Cost
        </Typography>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 600, color: "#0b6699" }}
        >
          ${orderNum !== undefined ? orderNum["totaldriverprice"] : 0}
        </Typography>
      </Box>

      {/* Bar Chart */}
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={data}>
          <XAxis
            dataKey="name"
            axisLine={false} // Remove axis line
            tickLine={false} // Remove tick lines
            tick={{ fontSize: 12, fill: "#9fa1a2", fontWeight: 200 }} // Styling for ticks
          />
          {/* <YAxis
            axisLine={false} // Remove Y-axis line
            tickLine={false} // Remove tick lines
            tick={{ fontSize: 12, fill: "#9fa1a2" }}
            interval={1}
            tickFormatter={(tick) => `${tick / 1000}K`} // Format tick values as 'K'
          /> */}
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Bar
            dataKey="value"
            barSize={28}
            radius={[8, 8, 8, 8]}
            onMouseLeave={onMouseLeave}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  index === activeIndex ? "rgba(0, 0, 0, 0.8)" : colors[index]
                } // Change fill on hover
                onMouseEnter={() => onMouseEnter(index)}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DeliveryCostChart;
