import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  CallIcon,
  ChatIcon,
  HomeIcon,
  MailIcon,
  PersonIconDriver,
  SettingsIcon,
  StopsIcon,
} from "../Icons";
import {
  ContainedButton,
  ContainedButtonWithIcon,
} from "../buttonCompo/Buttons";
import ProgressBar from "./ProgressBar";

const PharmaciesOverview = ({ pharmacy, index }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const totalOrders = pharmacy.total_orders || 0;
  const doneOrders = pharmacy.done_orders || 0;
  const pendingOrders = pharmacy.pending_orders || 0;

  const donePercentage =
    totalOrders > 0 ? ((doneOrders / totalOrders) * 100).toFixed(2) : 0;
  const pendingPercentage =
    totalOrders > 0 ? ((pendingOrders / totalOrders) * 100).toFixed(2) : 0;

  const handleSettingsClick = (pharmacy) => {
    navigate(`add-info?id=${pharmacy.id}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "10px 20px",
        gap: 1,
        boxShadow: 1,
        backgroundColor: "#fff",
        borderRadius: "8px",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f0f0f0", // Change to a light gray on hover
          boxShadow: 1, // Increase shadow on hover
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "789px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1.5,
              width: "308px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                borderRadius: "8px",
                height: "32px",
                width: "232px",
                // minWidth: "120px",
                padding: "7px",
                border: "none",
                backgroundColor: "#f1f3f4",
                color: "#000",
                gap: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  border: "1px solid #000",
                  borderRadius: "3px",
                  padding: "2px 4px",
                }}
              >
                {pharmacy.id < 10 ? `0${pharmacy.id}` : pharmacy.id}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "uppercase",
                }}
              >
                {pharmacy.name}
              </Typography>
            </Box>
            {pharmacy.isActive && (
              <ContainedButton
                label="Active"
                bgColor="#05b320"
                width="60.51px"
                height="31.23px"
                border="none"
              />
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 1.5 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                borderRadius: "8px",
                height: "31px",
                minWidth: "129px",
                padding: "7px",
                border: "1px solid #dce0e4", // Border styling like 'outlined'
                color: "#000",
                gap: 1,
              }}
            >
              <CallIcon />
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "10.74px" }}
              >
                {pharmacy.contact_no}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                borderRadius: "8px",
                height: "31px",
                minWidth: "129px",
                padding: "7px",
                border: "1px solid #dce0e4", // Border styling like 'outlined'
                color: "#000",
                gap: 1,
              }}
            >
              <ChatIcon />
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "10.74px" }}
              >
                Pharmacy Chats
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 1.5 }}>
          <ProgressBar done={donePercentage} pending={pendingPercentage} />
          <ContainedButtonWithIcon
            label="Setting"
            icon={<SettingsIcon />}
            bgColor="#000"
            borderRadius="8px"
            width="87px"
            height="32px"
            padding="4.19px"
            onClick={() => handleSettingsClick(pharmacy)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 1.5, width: "790px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "none",
              borderRadius: "8px",
              height: "32px",
              width: "421px",
              padding: "7px",
              border: "1px solid #dce0e4", // Border styling like 'outlined'
              color: "#000",
              gap: 1,
            }}
          >
            <HomeIcon />
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, fontSize: "11px" }}
            >
              {pharmacy.email}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "none",
              borderRadius: "8px",
              height: "32px",
              width: "148px",
              padding: "7px",
              border: "1px solid #dce0e4", // Border styling like 'outlined'
              color: "#000",
              gap: 1,
            }}
          >
            <CallIcon />
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, fontSize: "11px" }}
            >
              {pharmacy.contact_no}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "none",
              borderRadius: "8px",
              height: "32px",
              width: "197px",
              padding: "7px",
              border: "1px solid #dce0e4", // Border styling like 'outlined'
              color: "#000",
              gap: 1,
            }}
          >
            <MailIcon />
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, fontSize: "11px" }}
            >
              {pharmacy.email}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            border: "1px solid #dce0e4",
            alignItems: "center",
            height: "32px",
            width: "428px",
            borderRadius: "9px",
            padding: "0 4px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center", fontSize: "11px" }}
          >
            <StopsIcon />
            Total Orders {pharmacy.total_orders}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 4,
            }}
          >
            <Typography sx={{ fontSize: "11px", color: "#05b320" }}>
              Done: {pharmacy.done_orders}
            </Typography>
            <Typography sx={{ fontSize: "11px", color: "#fc1002" }}>
              Failed: {pharmacy.fail_orders}
            </Typography>
            <Typography sx={{ fontSize: "11px", color: "#fe9604" }}>
              Pending: {pharmacy.pending_orders}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PharmaciesOverview;
