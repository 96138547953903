import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { keyframes } from "@mui/system";
import { useTheme } from "@mui/styles";

// Keyframe animations
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const AnnouncementPopup = ({ open, title, description, onClose, onSubmit }) => {
  const [userInput, setUserInput] = React.useState("");

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleFormSubmit = () => {
    onSubmit(userInput);
    setUserInput("");
    onClose();
  };

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleFormSubmit}
      maxWidth="sm"
      fullWidth
      aria-labelledby="announcement-title"
      aria-describedby="announcement-description"
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f4f6f8", // Light gray theme background
        },
      }}
    >
      {/* Title Section with Icon */}
      <Box
        sx={{
          position: "relative",
          backgroundColor: theme.palette.custom.layoutBgColor,
          color: "#fff",
          textAlign: "center",
          padding: "40px 16px 16px",
        }}
      >
        {/* Speaker Icon */}
        <Box
          sx={{
            position: "absolute",
            top: "15%",
            left: "45%",
            transform: "translateX(-50%)",
            animation: `${pulse} 1.5s infinite ease-in-out`,
          }}
        >
          <Box
            component="img"
            src="https://png.pngtree.com/png-vector/20221125/ourmid/pngtree-important-announcement-megaphone-icon-banner-png-image_6480113.png"
            alt="Speaker Icon"
            sx={{
              width: "60px",
              height: "60px",
            }}
          />
        </Box>

        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "#fff",
            marginTop: "30px",
            paddingBottom: 0,
          }}
          id="announcement-title"
        >
          {title}
        </DialogTitle>
      </Box>

      {/* Description */}
      {/* <Box
        sx={{
          padding: "8px",
          textAlign: "center",
          borderBottom: "1px solid #ddd",
          backgroundColor: "#f4f6f8",
        }}
      >
        <Typography
          sx={{
            color: "#555",
            fontSize: "14px",
            marginBottom: "8px",
          }}
        >
          {description}
        </Typography>
      </Box> */}
      {/* User Input Section */}
      <DialogContent
        sx={{
          padding: "16px",
        }}
      >
        <TextField
          label="Your Response"
          placeholder="Type your response here..."
          variant="outlined"
          autoComplete="off"
          fullWidth
          value={description}
          onChange={handleInputChange}
          multiline
          rows={4}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              backgroundColor: "#fff", // White input field
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              color: "#555", // Light gray label
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
            },
          }}
        />
      </DialogContent>

      {/* Submit and Close Buttons */}
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          padding: "16px",
          backgroundColor: "#f4f6f8", // Match the dialog background
          borderTop: "1px solid #ddd",
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            textTransform: "none",
            color: theme.palette.custom.layoutFontColor,
            backgroundColor: theme.palette.custom.layoutBgColor,
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: theme.palette.custom.layoutHoverColor,
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnnouncementPopup;
