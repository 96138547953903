import React, { useState } from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { AttendCallIcon, DialpadIcons, HangUpIcon } from "../Icons";
import CloseIcon from "@mui/icons-material/Close";
import VoiceComponent from "../TwilioCalls/TwilioCalls";

const Dialpad = () => {
  const theme = useTheme();
  const [isCallDialogOpen, setIsCallDialogOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [initiateCall, setInitiateCall] = useState(false);
  const [callingOrder, setCallingOrder] = useState({});


  const userType = localStorage.getItem("userType");

  const handleOpenCallDialog = () => setIsCallDialogOpen(true);
  const handleCloseCallDialog = () => {
    setIsCallDialogOpen(false);
    setPhoneNumber("")
  };

  const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (!match) return input;
    return [match[1], match[2], match[3]].filter((x) => x).join("-");
  };

  const handleDial = (value) => {
    if (phoneNumber.replace(/\D/g, "").length >= 10) return;
    setPhoneNumber((prev) => formatPhoneNumber(prev + value));
  };

  const handleClear = () => setPhoneNumber("");

  const handleBackspace = () => {
    setPhoneNumber((prev) => formatPhoneNumber(prev.slice(0, -1)));
  };

  const handleEndCall = () => {
    setPhoneNumber("");
    setIsCallDialogOpen(false);
  };

  const makeCall = () => {
    setInitiateCall(true);
    setIsCallDialogOpen(false);
    setCallingOrder({
      number: phoneNumber,
      name: "Unknown",
    });
  };

  const handleKeyPress = (event) => {
    const validKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "*",
      "#",
    ];
    if (validKeys.includes(event.key)) {
      handleDial(event.key);
    } else if (event.key === "Backspace") {
      handleBackspace();
    }
  };

  return (
    <>
      {(userType === "Admin" || userType === "SubAdmin") &&
        <Box
          sx={{ position: "fixed", bottom: "60px", right: "5px", zIndex: 999 }}
          onKeyDown={handleKeyPress}
          tabIndex={0}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <IconButton
              onClick={handleOpenCallDialog}
              sx={{
                backgroundColor: theme.palette.custom.layoutBgColor,
                color: theme.palette.custom.layoutFontColor,
                borderRadius: "50px",
                padding: "10px 20px",
                display: "flex",
                alignItems: "center",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: theme.palette.custom.layoutHoverColor,
                },
              }}
            >
              <DialpadIcons fill={theme.palette.custom.layoutFontColor} />
            </IconButton>

            {/* Box Styled like Dialog */}
            {isCallDialogOpen && (
              <Box
                sx={{
                  position: "fixed",
                  bottom: "5px",
                  right: "5px",
                  zIndex: 1000,
                  width: "300px",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  // padding: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: theme.palette.custom.layoutBgColor,
                    color: theme.palette.custom.layoutFontColor,
                    padding: "8px 12px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography sx={{ color: theme.palette.custom.layoutFontColor }}>
                    Dialer
                  </Typography>
                  <IconButton onClick={handleCloseCallDialog}>
                    <CloseIcon />
                  </IconButton>
                </Box>

                <TextField
                  value={phoneNumber}
                  fullWidth
                  autoComplete="off"
                  placeholder="(000) 111-2222"
                  className="dalierNumber"
                  InputProps={{
                    inputProps: { maxLength: 12 },
                    style: {
                      textAlign: "center",
                    },
                  }}
                  sx={{
                    marginBottom: 2,
                    color: "black",
                    marginLeft: "8px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      fontSize: "18px",
                      width: "95%",
                    },
                  }}
                />


                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    mx: "auto",
                    gap: 2,
                  }}
                >
                  {Array.from({ length: 9 }, (_, i) => i + 1).map((num) => (
                    <Box key={num}>
                      <Button
                        variant="contained"
                        onClick={() => handleDial(num.toString())}
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {num}
                      </Button>
                    </Box>
                  ))}
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => handleDial("*")}
                      sx={{
                        width: "40px",
                        height: "40px",
                        paddingTop: "15px",
                        borderRadius: "50%",
                        fontSize: "20px",
                      }}
                    >
                      *
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => handleDial("0")}
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        fontSize: "20px",
                      }}
                    >
                      0
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => handleDial("#")}
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        fontSize: "20px",
                      }}
                    >
                      #
                    </Button>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    padding: "16px",
                  }}
                >
                  <IconButton onClick={makeCall}>
                    <AttendCallIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
          {initiateCall && (
            <VoiceComponent
              orderCall={callingOrder}
              setSelectedOrder={setCallingOrder}
            />
          )}
        </Box>
      }
    </>
  );
};

export default Dialpad;
