import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import DriverDateRange from "../components/reportPageCompo/DriverDateRange";
import DriverReport from "../components/reportPageCompo/DriverReport.jsx";
import { styled } from "@mui/system";
import SingleFieldDateRangePicker from "../components/dateRangePicker/DateRangePicker.jsx";
import moment from "moment";
import CustomDropdown from "../components/CustomDropdown.jsx";
import { DRIVERS_ROUTE, ALL_DRIVERS_REPORTS } from "../utils/apiUrl";
import { putApiWithAuth, getApiWithAuth } from "../utils/api";
const isCurrentWeek = (dateRange) => {
  const [startDate, endDate] = dateRange.split(" - ");
  const currentDate = new Date();
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  return false;
};
const DriverReportsPage = ({ extraContent }) => {
  const [dateRange, setDateRange] = useState(() => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 14);
    return [startDate, endDate];
  });
  const [activeDriverList, setActiveDriverList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [driverReports, setDriverReports] = useState([]);
  const [pharmacyOrderList, setPharmacyOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const userType = localStorage.getItem("userType");
  const [driver, setDriver] = useState("");
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };
  const formControlStyle = {
    minWidth: 120,
    height: 36,
    "& .MuiInputBase-root": {
      height: 36,
      display: "flex",
      alignItems: "center",
    },
  };
  const handleDriverChange = (value) => {
    if (value) {
      setDriver(value);
    } else {
      setDriver("");
    }
  };
  const renderMenuItem = (value, label, selectedValues) => (
    <MenuItem key={value} value={value} sx={{ fontSize: "11px" }}>
      <Checkbox size="small" checked={selectedValues.indexOf(value) > -1} />
      {label}
    </MenuItem>
  );
  const getDrivers = async () => {
    try {
      const Response = await getApiWithAuth(DRIVERS_ROUTE, { justList: true });
      setDriverList(Response.data.data);
      const filteredDrivers = Response.data.data.filter(
        (item) => item.isactive
      );
      setUniquePharmacy(
        Response.data.data.map((item) => ({ id: item.id, name: item.name }))
      );
      setActiveDriverList(filteredDrivers);
    } catch {
      toast.error(`Backend Error for get driver list!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    getDrivers();
  }, []);
  const getpharmacyOrderList = async () => {
    setLoading(true);
    const driverList = driver || [];
    const validEndDate =
      dateRange[1] && !isNaN(dateRange[1].getTime())
        ? dateRange[1]
        : new Date();
    let formData = {
      data: driverList.length === 0 ? "all" : "specific",
      from: moment(dateRange[0]).format("YYYY-MM-DD"),
      to: moment(validEndDate).format("YYYY-MM-DD"),
      driver: driverList,
    };

    const response = await putApiWithAuth(ALL_DRIVERS_REPORTS, formData);
    if (response.data !== undefined) {
      const { driverReports } = response.data;
      setDriverReports(driverReports);
      setPharmacyOrderList(response.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getpharmacyOrderList();
  }, [dateRange, driver]);

  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        paddingTop: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {userType !== "PharmacyOwner" && userType !== "PharmacyEmployee" && (
            <CustomDropdown
              customstyles={{
                height: "36px",
                width: "200px",
              }}
              label="Select Driver"
              value={driver}
              onChange={handleDriverChange}
              options={uniquePharmacy.map((pharm) => ({
                value: pharm.id,
                label: pharm.name,
              }))}
              isMultiSelect={true}
              search={true}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SingleFieldDateRangePicker
            value={dateRange}
            onChange={handleDateChange}
          />
        </Box>
      </Box>
      {driverReports?.length > 0 && (
        <>
          {driverReports
            .slice()
            .reverse()
            .map((pharmacyOrder, index) => (
              <div key={index}>
                {pharmacyOrder.driverReports && (
                  <Box key={index} sx={{ marginBottom: "24px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <DriverDateRange
                        pharmacyDetails={pharmacyOrder?.monthDates}
                      />
                    </Box>
                    {pharmacyOrder.driverReports?.map((report, reportIndex) => (
                      <DriverReport key={reportIndex} pharmaDetails={report} />
                    ))}
                  </Box>
                )}
              </div>
            ))}
        </>
      )}
    </Box>
  );
};

export default DriverReportsPage;
