import React, { useState } from "react";
import { Box, Typography, Popover, TextField, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const DeliveryNotes = ({
  isOpenRoutePage = false,
  orderDetail,
  handleUpdateDeliveryNote,
  isRoute = false,
  order
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const selectedOrder = isRoute ? orderDetail?.order : order; // Select based on isRoute
  const [adminNote, setAdminNote] = useState(selectedOrder?.notes || "");

  // Open Popover
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close Popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      marginLeft={1}
      paddingTop={0.5}
      borderRadius="8px"
      color="white"
      width={isOpenRoutePage ? "126px" : "217px"}
      height={isOpenRoutePage ? "97px" : "120px"}
      textAlign="center"
      justifyContent="center"
      onClick={() =>
        isRoute ? handleUpdateDeliveryNote(orderDetail?.order?.id, orderDetail?.order?.notes) : null
      }
      sx={{
        backgroundColor: theme.palette.custom.layoutBgColor,
        cursor: isRoute ? "pointer" : "default",
      }}
    >
      <Typography
        sx={{
          fontSize: isOpenRoutePage ? "10px" : "13px",
          fontWeight: 700,
          paddingTop: "2%",
          textDecoration: "underline",
          color: theme.palette.custom.layoutFontColor,
        }}
      >
        Delivery Notes
      </Typography>
      <br />

      {/* Truncated Text with "See More" */}
      <Typography
        sx={{
          fontSize: "11px",
          fontWeight: 500,
          marginTop: "-8%",
          color: theme.palette.custom.layoutFontColor,
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {selectedOrder?.notes?.length > 100 ? (
          <>
            {selectedOrder?.notes?.slice(0, 100)}...
            <Typography
              component="span"
              sx={{
                color: "white",
                fontWeight: 600,
                cursor: "pointer",
                marginLeft: "5px",
                fontSize: "11px"
              }}
              onClick={handleOpen}
            >
              See More
            </Typography>
          </>
        ) : (
          selectedOrder?.notes
        )}
      </Typography>

      {/* Popover for Full Notes */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            padding: 1,
            borderRadius: "10px",
            width: "254px",
            backgroundImage: "linear-gradient(45deg, #Fff5f3, #f1fbfd)",
          },
        }}
      >
        {/* Popover Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" sx={{ fontSize: "12px", color: "#0b6699" }}>
            Delivery Notes
          </Typography>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        {/* Editable Text Area */}
        <TextField
          fullWidth
          variant="outlined"
          autoComplete="off"
          multiline
          rows={5}
          value={adminNote}
          onChange={(e) => setAdminNote(e.target.value)}
          sx={{ mb: 2 }}
          InputProps={{
            sx: {
              padding: 0,
              "& .MuiInputBase-input": {
                padding: 0,
                fontSize: "11px",
                fontWeight: 500,
                lineHeight: "1.2",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          }}
        />
      </Popover>
    </Box>
  );
};

export default DeliveryNotes;

// import React, { useState } from "react";
// import { Box, Typography, Popover, TextField, IconButton } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";

// const DeliveryNotes = ({
//   isOpenRoutePage = false,
//   orderDetail,
//   handleUpdateDeliveryNote,
//   isRoute = false,
// }) => {
//   const theme = useTheme();

//   const [anchorEl, setAnchorEl] = useState(null);
//   const [adminNote, setAdminNote] = useState(orderDetail?.order?.delivered_note || "");
//   const [showSeeMore, setShowSeeMore] = useState(false);

//   // Open Popover
//   const handleOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   // Close Popover
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   console.log(orderDetail, "safsas")

//   return (
//     <Box
//       marginLeft={1}
//       paddingTop={0.5}
//       borderRadius="8px"
//       color="white"
//       width={isOpenRoutePage ? "126px" : "217px"}
//       height={isOpenRoutePage ? "97px" : "120px"}
//       textAlign="center"
//       justifyContent="center"
//       onClick={() =>
//         isRoute ? handleUpdateDeliveryNote(orderDetail?.order?.id, orderDetail?.order?.delivered_note) : null
//       }
//       sx={{
//         backgroundColor: theme.palette.custom.layoutBgColor,
//         cursor: isRoute ? "pointer" : "default",
//       }}
//     >
//       <Typography
//         sx={{
//           fontSize: isOpenRoutePage ? "10px" : "13px",
//           fontWeight: 700,
//           paddingTop: "2%",
//           textDecoration: "underline",
//           color: theme.palette.custom.layoutFontColor,
//         }}
//       >
//         Delivery Notes
//       </Typography>
//       <br />

//       {/* Truncated Text with "See More" */}
//       <Typography
//         sx={{
//           fontSize: "11px",
//           fontWeight: 500,
//           marginTop: "-8%",
//           color: "black",
//           display: "-webkit-box",
//           WebkitLineClamp: 3,
//           WebkitBoxOrient: "vertical",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//         }}
//       >
//         {orderDetail?.notes?.length > 100 ? (
//           <>
//             {orderDetail?.notes?.slice(0, 100)}...
//             <Typography
//               component="span"
//               sx={{
//                 color: "#0b6699",
//                 fontWeight: 600,
//                 cursor: "pointer",
//                 marginLeft: "5px",
//                 fontSize: "11px"
//               }}
//               onClick={handleOpen}
//             >
//               See More
//             </Typography>
//           </>
//         ) : (
//           orderDetail?.order?.delivered_note
//         )}
//       </Typography>

//       {/* Popover for Full Notes */}
//       <Popover
//         open={Boolean(anchorEl)}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "right",
//         }}
//         transformOrigin={{
//           vertical: "top",
//           horizontal: "left",
//         }}
//         PaperProps={{
//           sx: {
//             padding: 1,
//             borderRadius: "10px",
//             width: "254px",
//             backgroundImage: "linear-gradient(45deg, #Fff5f3, #f1fbfd)",
//           },
//         }}
//       >
//         {/* Popover Header */}
//         <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//           <Typography variant="h6" sx={{ fontSize: "12px", color: "#0b6699" }}>
//             Delivery Notes
//           </Typography>
//           <IconButton size="small" onClick={handleClose}>
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         </Box>

//         {/* Editable Text Area */}
//         <TextField
//           fullWidth
//           variant="outlined"
//           autoComplete="off"
//           multiline
//           rows={5}
//           value={adminNote}
//           onChange={(e) => setAdminNote(e.target.value)}
//           sx={{ mb: 2 }}
//           InputProps={{
//             sx: {
//               padding: 0,
//               "& .MuiInputBase-input": {
//                 padding: 0,
//                 fontSize: "11px",
//                 fontWeight: 500,
//                 lineHeight: "1.2",
//               },
//               "& .MuiOutlinedInput-notchedOutline": {
//                 border: "none",
//               },
//             },
//           }}
//         />
//       </Popover>
//     </Box>
//   );
// };

// export default DeliveryNotes;
