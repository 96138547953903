import React from "react";
import { Box, Typography, Avatar, Divider } from "@mui/material";
import moment from "moment";


const TimelineItem = ({
  label,
  time,
  icon,
  user,
  extra,
  reason,
  user2,
  time2,
}) => (
  <Box display="flex" alignItems="center" position="relative">
    <Box
      width="100%"
      py="8px"
      display="flex"
      alignItems="center"
      flexDirection="row"
    >
      <Box sx={{ width: icon ? "120%" : "50%" }}>
        <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "10px" }}>
          {label}
        </Typography>
        {extra && (
          <Typography variant="body2" fontSize="10px">
            {extra}
          </Typography>
        )}
        {reason && (
          <Typography variant="body2" fontSize="10px">
            {reason}
          </Typography>
        )}
      </Box>
      {/* Icon Section */}
      {icon && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ width: "45%" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0, // Adjust this to control the starting point of the line
              bottom: "50%",
              width: "2px",
              backgroundColor: "#e0e0e0", // Light gray color for the line
            }}
          />

          <Avatar
            src={icon}
            alt={label}
            sx={{ width: 26, height: 26, zIndex: 2 }}
          />

          <Box
            sx={{
              position: "absolute",
              top: "50%", // Adjust this to control the starting point of the line
              bottom: 0,
              width: "2px",
              backgroundColor: "#e0e0e0", // Light gray color for the line
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          width: icon ? "45%" : "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        {time && (
          <Typography variant="body2" fontSize="10px">
            At: {time}
          </Typography>
        )}
        {user && (
          <Typography variant="body2" fontSize="10px">
            By: {user}
          </Typography>
        )}
        {time2 && (
          <Typography variant="body2" fontSize="10px">
            At: {time2}
          </Typography>
        )}
        {user2 && (
          <Typography variant="body2" fontSize="10px">
            By: {user2}
          </Typography>
        )}
      </Box>
    </Box>
  </Box>
);
const RouteTimelineItem = ({ orderHistory }) => {




  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Divider sx={{ borderBottom: "2px solid #d9d9d9", width: "100%" }} />

      {orderHistory?.routeHistory?.map((historyItem, index) => {

        return (
          <React.Fragment key={`history-item-${index}`}>
            <TimelineItem
              label={
                historyItem.display_name
              }                            
              time={
                historyItem.date
                  ? `${moment(historyItem.date).format("MM-DD-YY")} ${moment(
                      historyItem.date
                    ).format("hh:mm:ss a")}`
                  : "Unknown Time"
              }
              user={historyItem.updatedBy || "Unknown"}
              icon={`/icons/historymodalicons/${historyItem.name}.svg` || null}
            />
            <Divider
              sx={{ borderBottom: "2px solid #d9d9d9", width: "100%" }}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default RouteTimelineItem;
