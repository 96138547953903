import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  Avatar,
  Typography,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Badge,
} from "@mui/material";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/styles";
import { USER_CHAT_ROOMS, GET_MESSAGES } from "../../utils/apiUrl";
import { getApiWithAuth } from "../../utils/api";
import {
  AttendCallIcon,
  CallIcon,
  HangUpIcon,
  IncomingIcons,
  SeenIcon,
  TelephoneIcon,
} from "../Icons";
const BASE_URL = process.env.REACT_APP_BACKENDURL;
const SupportButtonManager = () => {
  const theme = useTheme();
  const [view, setView] = useState("button");
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [chatRooms, setChatRooms] = useState([]);
  const [roomId, setroomId] = useState(null);
  const [loadingChats, setLoadingChats] = useState(false);
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
  const [files, setFiles] = useState([]);
  const [typingIndicator, setTypingIndicator] = useState(false);
  const userId = parseInt(localStorage.getItem("UID"), 10);
  const [client, setClient] = useState(null);
  const [member, setMember] = useState(null);
  const [isCallDialogOpen, setIsCallDialogOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const handleOpenCallDialog = () => setIsCallDialogOpen(true);
  const handleCloseCallDialog = () => setIsCallDialogOpen(false);

  const handleDial = (value) => {
    setPhoneNumber((prev) => prev + value); // Append the pressed number
  };

  const handleClear = () => {
    setPhoneNumber(""); // Clear the text bar
  };

  const handleEndCall = () => {
    console.log("Call ended");
    setPhoneNumber(""); // Clear the number when the call ends
    setIsCallDialogOpen(false);
  };

  const handleBackspace = () => {
    setPhoneNumber((prev) => prev.slice(0, -1)); // Remove the last digit
  };

  const getOtherMember = (members, userId) => {
    if (!members || members.length < 2) return null;
    const smember = members.find(
      (member) => member.id !== userId && member.type === "PharmacyOwner"
    );
    setMember(smember);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    if (view === "chat") {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }, [messages, view]);
  // Checks if a given date is today
  const isToday = (dateString) => {
    const today = new Date();
    const givenDate = new Date(dateString);

    return (
      today.getDate() === givenDate.getDate() &&
      today.getMonth() === givenDate.getMonth() &&
      today.getFullYear() === givenDate.getFullYear()
    );
  };

  useEffect(() => {
    // const newClient = new W3CWebSocket(`ws://127.0.0.1:8000/ws/users/${userId}/chat/`);
    const newClient = new W3CWebSocket(
      `wss://testadmin.pilldrop.ai/ws/users/${userId}/chat/`
    );

    newClient.onopen = () => {
      console.log("WebSocket connection established");
    };

    newClient.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      const chatData = dataFromServer.chat;
      console.log("dataaa", dataFromServer);
      if (dataFromServer.action === "message" && chatData) {
        const receivedMessage = chatData.message;
        const receivedfile = chatData.media;
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            message: receivedMessage,
            user: chatData.user,
            userName: chatData.userName,
            profile_pic: chatData.profile_pic,
            timestamp: chatData.timestamp,
            media: receivedfile,
          },
        ]);
        if (chatData.user !== userId && chatData.roomId === roomId) {
          const data = {
            action: "message_read",
            messageId: dataFromServer.chat.messageid,
            userId: userId,
            roomId: roomId,
          };
          const json = JSON.stringify(data);
          client.send(json);
        }
      } else if (dataFromServer.action === "typing" && chatData) {
        if (chatData.userId !== userId && chatData.userId == member.id) {
          if (chatData.isTyping === true) {
            setTypingIndicator(true);
          } else if (chatData.isTyping === false) {
            setTypingIndicator(false);
          }
        } else {
        }
      }
    };

    newClient.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    newClient.onclose = () => {
      console.log("WebSocket connection closed");
    };

    setClient(newClient);

    return () => {
      newClient.close();
    };
  }, [userId]);
  const handleFileChange = (e) => {
    const fileArray = Array.from(e.target.files);
    const filesWithBase64 = fileArray.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => {
          resolve({
            name: file.name,
            data: reader.result, // Base64 representation of the file
          });
        };
      });
    });

    Promise.all(filesWithBase64).then((fileData) => {
      setFiles((prevFiles) => [...prevFiles, ...fileData]);
    });
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const fetchChatRooms = async () => {
    setLoadingChats(true);
    try {
      const response = await getApiWithAuth(USER_CHAT_ROOMS);
      setChatRooms(response.data.data);
      const totalUnread = response.data.data.reduce((sum, room) => {
        const unread = Number(room.unread_messages_count) || 0;
        return sum + unread;
      }, 0);
      setTotalUnreadMessages(totalUnread);
    } catch (error) {
      console.error("Failed to fetch chat rooms:", error);
    } finally {
      setLoadingChats(false);
    }
  };

  const fetchMessages = async (roomId, userId) => {
    try {
      const response = await getApiWithAuth(
        GET_MESSAGES + `?status=all&roomId=${roomId}&userId=${userId}`
      );

      console.log("Messages API Response:", response.data);
      setMessages(response.data.data);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      setMessages([]);
    }
  };

  useEffect(() => {
    // if (view === "popup") {
    fetchChatRooms();
    // }
  }, []);
  useEffect(() => {
    // if (view === "popup") {
    fetchChatRooms();
    // }
  }, [roomId]);
  const handleOpenPopup = () => {
    setView("popup");
  };

  const handleClosePopup = () => {
    setView("button");
    setSelectedChat(null);
  };
  const handleChatSelect = async (chat) => {
    setSelectedChat(chat);
    setView("chat");
    setroomId(chat.roomId);
    getOtherMember(chat.member, userId);
    await fetchMessages(chat.roomId, userId);
  };

  const handleCloseChat = () => {
    setSelectedChat(null);
    setView("popup");
  };
  const handleSendMessage = () => {
    if (messageInput.trim() !== "" || files.length > 0) {
      const fileData = files.map((file) => ({
        name: file.name,
        file: file.data,
      }));

      client.send(
        JSON.stringify({
          action: "message",
          message: messageInput,
          roomId: roomId,
          userId: userId,
          files: fileData,
        })
      );
      setMessageInput("");
      setFiles([]);
    } else {
    }
  };
  const handleTyping = () => {
    if (!typingIndicator) {
      client.send(
        JSON.stringify({
          action: "typing",
          roomId: roomId,
          userId: userId,
          isTyping: true,
        })
      );
    }
  };

  const handleInputBlur = () => {
    setTypingIndicator(false);
    client.send(
      JSON.stringify({
        action: "typing",
        roomId: roomId,
        userId: userId,
        isTyping: false,
      })
    );
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };
  return (
    <Box sx={{display: "none"}}>
      {view === "button" && (
        <Box
          sx={{
            position: "fixed",
            bottom: "5px",
            right: "5px",
            zIndex: 999,
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpenPopup}
            sx={{
              textTransform: "none",
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
              fontWeight: "bold",
              borderRadius: "50px",
              padding: "10px 20px",
              display: "flex",
              alignItems: "center",
              position: "relative",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: theme.palette.custom.layoutHoverColor,
              },
            }}
          >
            <Badge
              badgeContent={totalUnreadMessages}
              color="error"
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: "10px",
                  height: "22px",
                  minWidth: "22px",
                  borderRadius: "50%",
                  top: 0,
                  left: 14,
                },
              }}
            >
              <ChatBubbleOutlineIcon />
            </Badge>
          </Button>
        </Box>
      )}

      {/* Support Popup */}
      {view === "popup" && (
        <Box
          sx={{
            position: "fixed",
            bottom: "5px",
            right: "5px",
            width: "350px",
            minHeight: "500px",
            maxHeight: "500px",
            borderRadius: "8px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
              padding: "8px 12px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                color: theme.palette.custom.layoutFontColor,
              }}
            >
              Chats
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton onClick={handleClosePopup} sx={{ color: "#fff" }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Chat List */}
          <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
            {loadingChats ? (
              <Typography
                sx={{
                  textAlign: "center",
                  marginTop: "16px",
                  color: "#757575",
                }}
              >
                Loading...
              </Typography>
            ) : chatRooms.length > 0 ? (
              <List>
                {chatRooms.map((chat) => (
                  <ListItem
                    key={chat.roomId}
                    onClick={() => handleChatSelect(chat)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 12px",
                      borderBottom: "1px solid #f0f0f0",
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>{chat.name.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#333",
                          }}
                        >
                          {chat.name}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#757575",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {/* Last Message */}
                            <span
                              style={{
                                maxWidth: "60%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {chat.last_message?.message ||
                                "No recent messages"}
                            </span>

                            {/* Unread Messages Count */}
                            {chat.unread_messages_count > 0 && (
                              <span
                                style={{
                                  backgroundColor: "#FF5722",
                                  color: "#fff",
                                  borderRadius: "50%",
                                  padding: "2px 8px",
                                  fontSize: "10px",
                                  marginLeft: "10px",
                                }}
                              >
                                {chat.unread_messages_count}
                              </span>
                            )}

                            {/* Last Updated Time */}
                            <span
                              style={{
                                fontSize: "10px",
                                color: "#999",
                                marginLeft: "auto",
                              }}
                            >
                              {isToday(chat.updated_at)
                                ? new Date(chat.updated_at).toLocaleTimeString(
                                  [],
                                  { hour: "2-digit", minute: "2-digit" }
                                )
                                : new Date(chat.updated_at).toLocaleDateString(
                                  [],
                                  { month: "short", day: "numeric" }
                                )}
                            </span>
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography
                sx={{
                  textAlign: "center",
                  marginTop: "16px",
                  color: "#757575",
                }}
              >
                No Chat Available
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {/* Support Chat */}
      {view === "chat" && (
        <Box
          sx={{
            position: "fixed",
            bottom: "5px",
            right: "5px",
            width: "350px",
            minHeight: "500px",
            maxHeight: "500px",
            borderRadius: "8px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
              padding: "8px 12px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={handleCloseChat} sx={{ color: "#fff" }}>
                <ArrowBackIcon />
              </IconButton>
              {/* <Avatar sx={{ marginLeft: 1, marginRight: 1 }}>
                {selectedChat?.name.charAt(0)}
                {typingIndicator && <div>Typing...</div>}
              </Avatar> */}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: theme.palette.custom.layoutFontColor,
                  }}
                >
                  {selectedChat?.name}
                </Typography>
                {typingIndicator && (
                  <Typography
                    sx={{
                      color: theme.palette.custom.layoutFontColor,
                      margin: 0,
                      padding: 0,
                      fontSize: "10px",
                    }}
                  >
                    typing...
                  </Typography>
                )}
              </Box>
            </Box>
            <IconButton onClick={handleClosePopup} sx={{ color: "#fff" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Messages */}
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              padding: "8px",
              backgroundColor: "#f9f9f9",
            }}
          >
            {messages.map((msg, index) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent:
                    msg.user === userId ? "flex-end" : "flex-start",
                  marginBottom: "8px",
                }}
              >
                <Box sx={{ flexDirection: "column", maxWidth: "70%" }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color:
                        msg.user === userId
                          ? theme.palette.custom.layoutBgColor
                          : "#000",
                    }}
                  >
                    {msg.userName}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor:
                        msg.user === userId
                          ? theme.palette.custom.layoutBgColor
                          : "#e0e0e0",
                      padding: "8px 12px",
                      borderRadius: "12px",
                      minWidth: "70px",
                      maxWidth: "100%",
                    }}
                  >
                    {/* Media Attachments */}
                    {msg.media && msg.media.length > 0 && (
                      <div className="msg-media">
                        {msg.media.map((file, fileIndex) => (
                          <div key={fileIndex} className="message-item-files">
                            {file.file.toLowerCase().endsWith(".pdf") ? (
                              <a
                                href={file.file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            ) : file.file
                              .toLowerCase()
                              .match(/\.(jpeg|jpg|png|gif)$/) ? (
                              <a
                                href={file.file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={file.file}
                                  alt={file.name}
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                    margin: "4px 0",
                                  }}
                                />
                              </a>
                            ) : (
                              <a
                                href={file.file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            )}
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Message Content */}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color:
                          msg.user === userId
                            ? theme.palette.custom.layoutFontColor
                            : "#000",
                      }}
                    >
                      {msg.message}
                    </Typography>
                    {/* Timestamp and Seen Icon */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 1, // Add space between time and seen icon
                        maxWidth: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          marginTop: "4px",
                          textAlign: "right",
                          color:
                            msg.user === userId
                              ? theme.palette.custom.layoutFontColor
                              : "#000",
                        }}
                      >
                        {new Date(msg.timestamp).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </Typography>

                      {msg.user === userId && (
                        <SeenIcon color={msg.is_read ? "#2757FF" : "#808080"} />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
            <div ref={messagesEndRef} />
          </Box>

          {/* Message Input */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px",
              borderTop: "1px solid #e0e0e0",
              backgroundColor: "#fff",
              flexDirection: "column", // Added to align input and attachments
            }}
          >
            {/* Render Attached Files */}
            {files.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  flexWrap: "wrap",
                  padding: "8px 0",
                  justifyContent: "flex-start",
                }}
              >
                {files.map((file, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      width: "60px",
                      height: "60px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {file.data.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/) ? (
                      <img
                        src={file.data}
                        alt={file.name}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          textAlign: "center",
                          color: "#555",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {file.name}
                      </Typography>
                    )}
                    {/* Deselect Icon */}
                    <IconButton
                      onClick={() => handleRemoveFile(index)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        background: "#fff",
                        padding: "2px",
                        borderRadius: "50%",
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}

            {/* Input Box */}
            <TextField
              placeholder="Type a message..."
              fullWidth
              autoComplete="off"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              onFocus={handleTyping}
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleSendMessage}
                      color="primary"
                      disabled={
                        messageInput.trim() === "" && files.length === 0
                      } // Disable when input is empty and no files
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <input
                        id="file-input"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <label htmlFor="file-input">
                        <AttachFileIcon />
                      </label>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                  backgroundColor: "#f4f4f4",
                },
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SupportButtonManager;
