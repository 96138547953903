import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { styled } from "@mui/system";
import {
  AddressIcon,
  CalendarReportIcon,
  CopayStopsIcon,
  DeleteRouteIcon,
  DriverNoteIcon,
  DropDownArrowIcon,
  EditableIcon,
  EditRouteIcon,
  MergeRouteIcon,
  MilesIcon,
  OpenRouteIcon,
  PersonIcon,
  PersonIconBlack,
  PrintRouteIcon,
  RescheduleIcon,
  RouteHistoryIcon,
  SplitRouteIcon,
  StopsIcon,
  TruckIcon,
} from "../components/Icons";

const menuItems = [
  { label: "Open Route", icon: <OpenRouteIcon />, route: "/routes/open-route" },
  // {
  //   label: "Open Google Route",
  //   icon: <AddressIcon />,
  //   route: "/open-google-route",
  // },
  { label: "Edit route", icon: <EditRouteIcon />, route: "/routes/edit-route" },
  {
    label: "Split route",
    icon: <SplitRouteIcon />,
    route: "/routes/split-route",
  },
  {
    label: "Merge Route",
    icon: <MergeRouteIcon />,
    route: "/routes/merge-route",
  },
  {
    label: "Reschedule",
    icon: <RescheduleIcon />,
    route: "/routes/reschedule",
  },
  {
    label: "Copay Stops",
    icon: <CopayStopsIcon />,
    route: "/routes/copay-stops",
  },
  {
    label: "Driver notes",
    icon: <DriverNoteIcon />,
    route: "/routes/driver-notes",
  },
  {
    label: "Print Route Slip",
    icon: <PrintRouteIcon />,
    route: "/routes/print-route-slip",
  },
  {
    label: "Delete Route",
    icon: <DeleteRouteIcon />,
    route: "/routes/delete-route",
  },
  {
    label: "Route History",
    icon: <RouteHistoryIcon />,
    route: "/routes/route-history",
  },
];
const RouteDetailsSmall = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [driver, setDriver] = useState([]);

  const renderMenuItem = (value, label, selectedValues, setter) => (
    <MenuItem
      key={value}
      value={value}
      sx={{
        fontSize: "11px",
        color: '#000000',
      }}
    >
      {label}
    </MenuItem>
  );

  const CustomDropdownIcon = styled("img")({
    marginRight: "7px",
    opacity: "0.6",
  });
  const navigate = useNavigate();

  const handleMenuItemClick = (route) => {
    navigate(route); // Navigate to the selected route
    handleActionsClose(); // Close the menu after navigating
  };

  const handleChange = (setter) => (event) => {
    const {
      target: { value },
    } = event;
    setter(typeof value === "string" ? value.split(",") : value);
  };

  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const formControlStyle = {
    minWidth: 120,
    height: 38,
    "& .MuiInputBase-root": {
      height: 38,
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 1,
        gap: 1,
        p: 1,
        borderRadius: "10px",
        backgroundColor: "#fff",
        boxShadow: 1,
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f0f0f0", // Change to a light gray on hover
          boxShadow: 1, // Increase shadow on hover
        },
      }}
    >
      {/* Left Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            size="small"
            sx={{
              textTransform: "none",
              backgroundColor: "#f2f3f5",
              borderRadius: "10px",
              color: "#000",
              height: "36px",
              fontSize: "13px",
              fontWeight: 500,
              boxShadow: "none",
            }}
          >
            Zone 1
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              textTransform: "none",
              backgroundColor: "#00b8d9",
              borderRadius: "10px",
              color: "#fff",
              height: "36px",
              fontSize: "13px",
              fontWeight: 500,
              boxShadow: "none",
            }}
          >
            Ready
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 1.5,
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "none",
              width: "88px",
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #919eab",
              color: "#000",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, fontSize: "11px" }}
            >
              Copay:$1.50
            </Typography>
          </Box>
          {/* <Box
            sx={{
              border: "1px solid #919eab",
              borderRadius: "10px",
              padding: 0.5,
              width: "106px",
              height: "36px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", color: "#000", fontWeight: 700 }}
            >
              <span style={{ color: "#0094ff" }}>ETC:</span> 1H 20 Min
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", color: "#000", fontWeight: 700 }}
            >
              <span style={{ color: "#0d4df2" }}>ATC:</span> 1H 20 Min
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid #919eab",
              borderRadius: "10px",
              padding: "0 0px",
              gap: 0.5,
              height: "32px",
              width: "157px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center", fontSize: "11px" }}
            >
              <StopsIcon />
              Stops 1:Distance 5.87 ml
            </Typography>
          </Box>

          <Box>
            {/* Actions Button */}
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "none",
                backgroundColor: "#424242",
                color: "#fff",
                borderRadius: "8px",
                width: "82px",
                height: "32px",
                fontSize: "11px",
                padding: "5px 5px",
              }}
              endIcon={
                <Box
                  sx={{ display: "flex", alignItems: "center", opacity: 0.8 }}
                >
                  <DropDownArrowIcon />
                </Box>
              }
              onClick={handleActionsClick}
            >
              Actions
            </Button>

            {/* Actions Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleActionsClose}
              MenuListProps={{ disablePadding: true }}
            >
              <Box sx={{ backgroundColor: "rgba(0,0,0,0.1)" }}>
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleMenuItemClick(item.route)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        backgroundColor: "#eeeee5",
                        px: 0,
                        borderRadius: "5px",
                      }}
                    >
                      {item.icon}
                      <Typography variant="body2" sx={{ padding: "2px 4px" }}>
                        {" "}
                        {item.label}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            gap: 1,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              border: "1px solid #919eab",
              borderRadius: "10px",
              height: "38px",
              width: "280px",
              paddingX: "5px",
            }}
          >
            <PersonIcon />
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", fontWeight: 700 }}
            >
              Queens / Zone 1
            </Typography>
            <IconButton size="small" sx={{ paddingLeft: 1 }}>
              <EditableIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              minWidth: "200px",
            }}
          >
            <FormControl
              sx={{ ...formControlStyle, mx: 1, width: "208px" }}
              size="small"
            >
              <InputLabel
                sx={{
                  fontSize: "11px",
                  fontWeight: 700,
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 0.3,
                }}
              >
                <TruckIcon />{" "}
                <Typography variant="body2" sx={{ fontSize: "10px" }}>
                  Select Driver
                </Typography>
              </InputLabel>
              <Select
                value={driver}
                onChange={handleChange(setDriver)}
                sx={{
                  fontSize: "11PX",
                  borderRadius: "7px",
                  backgroundColor: "transparent",
                  width: 208,
                  "& .MuiSelect-outlined": {
                    padding: 2,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#919eab",
                  },
                }}
                IconComponent={() => (
                  <CustomDropdownIcon
                    src="/icons/Dropdown.svg"
                    alt="custom arrow icon"
                  />
                )}
              >
                {renderMenuItem(10, "Zone 1", driver, setDriver)}
                {renderMenuItem(20, "Zone 2", driver, setDriver)}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}
        >
          <Box
            sx={{
              backgroundColor: "#ff9800",
              color: "#fff",
              padding: "4px 8px",
              borderRadius: "8px",
              width: "350px",
              height: "38px",

              display: "flex",

              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
              Pending: 70%
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            gap: 2,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "38px",
              width: "308px",
              alignItems: "center",
              textTransform: "none",
              borderRadius: "8px",
              padding: "0px 10px",
              border: "1px solid #919eab", // Border styling like 'outlined'
              color: "#000",
              gap: 2,
            }}
          >
            <CalendarReportIcon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 700, fontSize: "11px" }}
              >
                Created At:
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "11px", fontWeight: 500 }}
                >
                  08-25-2023
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "11px", fontWeight: 500 }}
                >
                  08-25-2023
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "38px",
              width: "208px",
              alignItems: "center",
              textTransform: "none",
              borderRadius: "8px",
              padding: "0px 10px",
              border: "1px solid #919eab", // Border styling like 'outlined'
              color: "#000",
              gap: 1,
            }}
          >
            <EditableIcon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 700, fontSize: "11px" }}
              >
                Created By:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "11px" }}
              >
                Admin_User
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "38px",
              width: "208px",
              alignItems: "center",
              textTransform: "none",
              borderRadius: "8px",
              padding: "0px 10px",
              border: "1px solid #919eab", // Border styling like 'outlined'
              color: "#000",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 700, fontSize: "11px", color: "#00b8D9" }}
              >
                ETC
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "11px" }}
              >
                1H 20Min
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "38px",
              width: "208px",
              alignItems: "center",
              textTransform: "none",
              borderRadius: "8px",
              padding: "0px 10px",
              border: "1px solid #919eab", // Border styling like 'outlined'
              color: "#000",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 700, fontSize: "11px", color: "#00b8D9" }}
              >
                ATC
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "11px" }}
              >
                1H 10Min
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RouteDetailsSmall;
