import React, {useState} from "react";

import moment from "moment";

import "./times-new-roman-reg.ttf";

const Printdoc = ({ orderDetail, edit, view, upload, pharmacy, printt }) => {
  console.log("orderdetaill", orderDetail);
  const [imageError, setImageError] = useState(false);

  const styles = {
    page: {
      width: printt ? "7.7in" : "6.15in", // Dynamically set width based on view
      height: "9.25in",
      margin: "10px",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      fontFamily: "Times New Roman, sans-serif",
      color: "black",
      backgroundColor: "white",
    },
    pageHead: {
      display: "flex",
      justifyContent: "space-between",
      fontFamily: "Times New Roman, sans-serif",
      fontSize: "9px",
    },
    pageMainText: {
      textAlign: "center",
      fontSize: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontFamily: "Times New Roman, sans-serif",
    },
    bar: {
      height: "3px",
      widht: "100%",
      backgroundColor: "black",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
    },
    bar2: {
      height: "1px",
      widht: "100%",
      backgroundColor: "black",
      marginTop: "15px",
    },
    orderDetail: {
      fontWeight: "400",
      fontSize: "10px",
      width: "50%",
      marginLeft: "20px",
      fontFamily: "Times New Roman, sans-serif",
    },
    orderList: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "15px",
      marginRight: "50px",
    },
    orderResult: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "20px",
      marginRight: "80px",
      fontWeight: "800",
      fontSize: "8px",
    },
    orderBottom: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "20px",
      marginTop: "20px",
      marginRight: "30px",
      fontSize: "8px",
    },
  };

  console.log("orderDetailorderDetailorderDetail",orderDetail)

  return (
    <>
      <div style={styles.page}>
        <div style={styles.pageHead}>
          <p>Date: {moment(orderDetail.createdAt).format("MM/DD/YYYY")}</p>
          <p>Page 1/1</p>
        </div>
        <div style={styles.pageMainText}>
          <h1 style={{ fontWeight: "700" }}>{pharmacy?.name}</h1>
          <h1 style={{ fontWeight: "500" }}>{pharmacy?.location}</h1>
          <h1 style={{ fontWeight: "500" }}>
            Phone: {pharmacy?.contact_no}
            <span style={{ marginLeft: "10px" }}>
              Fax: {pharmacy?.fax_number}
            </span>
          </h1>
          <h2
            style={{
              fontWeight: "800",
              fontSize: "8px",
              border: "3px solid black",
              marginBottom: "10px",
              width: "200px",
            }}
          >
            Delivery Slip
          </h2>
        </div>
        <div style={styles.bar} />
        <div style={styles.orderDetail}>
          <h1 style={{ fontWeight: "700", fontSize: "8px", textAlign: "left" }}>
            Client:
          </h1>
          <h1 style={{ fontWeight: "800", fontSize: "8px", textAlign: "left" }}>
            {orderDetail.name}
          </h1>
          <p style={{ textAlign: "left" }}>{orderDetail.address}</p>
          <p style={{ textAlign: "left" }}>Phone# : {orderDetail.contact}</p>
          <p style={{ textAlign: "left" }}>Cell# : {orderDetail.cellNumber}</p>
          <p style={{ textAlign: "left" }}>
            Work# :{" "}
            {orderDetail.workNumber === undefined ||
              orderDetail.workNumber === "undefined"
              ? ""
              : orderDetail.workNumber}
          </p>
        </div>
        <div style={styles.orderList}>
          <table
            style={{
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: "5px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid black",
                    fontSize: "8px",
                    width: "60px",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    borderBottom: "1px solid black",
                    fontSize: "8px",
                    width: "120px",
                  }}
                >
                  Rx. Barcode
                </th>
                <th
                  style={{
                    borderBottom: "1px solid black",
                    fontSize: "8px",
                    width: "100px",
                  }}
                >
                  Rx. #
                </th>
                <th
                  style={{
                    borderBottom: "1px solid black",
                    fontSize: "8px",
                    width: "40px",
                  }}
                >
                  Rf. #
                </th>
                <th />
                <th style={{ width: "70px" }} />
                <th />
                <th
                  style={{
                    borderBottom: "1px solid black",
                    fontSize: "8px",
                    width: "50px",
                  }}
                >
                  Qty
                </th>
                <th
                  style={{
                    borderBottom: "1px solid black",
                    fontSize: "8px",
                    width: "80px",
                  }}
                >
                  Copay
                </th>
              </tr>
            </thead>
            <tbody>
              {edit
                ? orderDetail.orderdetail.map((rxOrder, i) => (
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td>{rxOrder.date}</td>
                    <td style={{ width: "120px" }}>
                      {rxOrder.barcode_image && !imageError && (
                        <img
                          style={{ height: "12px", maxWidth: "120px" }}
                          src={rxOrder.barcode_image}
                          alt="bar-code"
                          onError={() => setImageError(true)}
                        />
                      )}
                    </td>{" "}
                    <td>{rxOrder.rxnumber}</td>
                    <td>{rxOrder.rfnumber}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{rxOrder.quantity}</td>
                    <td>{rxOrder.copay}</td>
                  </tr>
                ))
                : view
                  ? orderDetail.orderdetail.map((rxOrder, i) => (
                    <tr style={{ textAlign: "center" }}>
                      <td>{rxOrder.date}</td>
                      <td style={{ width: "120px" }}>
                        {rxOrder.barcode_image && !imageError && (
                        <img
                          style={{ height: "12px", maxWidth: "120px" }}
                          src={rxOrder.barcode_image}
                          alt="bar-code"
                          onError={() => setImageError(true)}
                        />
                      )}
                      </td>{" "}
                      <td>{rxOrder.rx_number}</td>
                      {/* <td style={{ borderBottom: '1px solid black', maxWidth: '200px' }}><Barcode value={extractNumberAfterHyphen(rxOrder.rx_number)} displayValue="false" width="2" format="CODE128" height="20px" /></td> */}
                      <td>{rxOrder.rf_number}</td>
                      {/* <td style={{ borderBottom: '1px solid black', maxWidth: '70px' }}></td> */}
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{rxOrder.quantity}</td>
                      <td>{rxOrder.pat_pay_amount}</td>
                    </tr>
                  ))
                  : orderDetail.orderdetail.map((rxOrder, i) => (
                    <tr style={{ textAlign: "center" }}>
                      <td>{rxOrder.date}</td>
                      <td style={{ maxWidth: "70px" }}>
                        {rxOrder.barcode_image && !imageError && (
                        <img
                          style={{ height: "18px" }}
                          src={rxOrder.barcode_image}
                          alt="bar-code"
                          onError={() => setImageError(true)}
                        />
                      )}
                      </td>
                      <td>{rxOrder.rx_number}</td>
                      {/* <td style={{ borderBottom: '1px solid black', maxWidth: '200px' }}><Barcode value={extractNumberAfterHyphen(rxOrder.rx_number)} displayValue="false" width="2" format="CODE128" height="20px" /></td> */}
                      <td>{rxOrder.rf_number}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{rxOrder.quantity}</td>
                      <td>{rxOrder.pat_pay_amount}</td>
                    </tr>
                  ))}
              {/* Add more <tr> elements for additional rows */}
            </tbody>
          </table>
        </div>
        <div style={styles.bar2} />
        <div style={styles.orderResult}>
          <p>
            Total Rx Count :{" "}
            {view
              ? orderDetail.orderdetail.length
              : orderDetail.orderdetail.length}
          </p>
          <p>
            Total Pat. Amt: $
            {edit
              ? orderDetail.orderdetail
                .reduce((total, rx) => total + parseFloat(rx.copay), 0)
                .toFixed(2)
              : view
                ? orderDetail.orderdetail
                  .reduce(
                    (total, rx) => total + parseFloat(rx.pat_pay_amount),
                    0
                  )
                  .toFixed(2)
                : orderDetail.orderdetail
                  .reduce(
                    (total, rx) => total + parseFloat(rx.pat_pay_amount),
                    0
                  )
                  .toFixed(2)}
          </p>
        </div>
        {!upload && edit && orderDetail.status === "5" && (
          <>
            <p
              style={{
                marginLeft: "20px",
                marginTop: "10px",
                marginBottom: "5px",
                fontSize: "8px",
                textAlign: "left",
              }}
            >
              Patient is requesting Counseling:
              <span style={{ marginLeft: "10px" }}>
                Yes:{" "}
                <span style={{ borderBottom: "1px solid black" }}>
                  {orderDetail.requestCouncelling ? "✓" : " "}
                </span>
              </span>
              <span style={{ marginLeft: "10px" }}>
                No:{" "}
                <span style={{ borderBottom: "1px solid black" }}>
                  {!orderDetail.requestCouncelling ? "✓" : " "}
                </span>
              </span>
            </p>
            <p
              style={{
                marginLeft: "20px",
                marginRight: "30px",
                fontSize: "8px",
                textAlign: "left",
              }}
            >
              {pharmacy?.hippa_notes === null
                ? `I certify that I requested and received my medication listed above from ${pharmacy.name} Pharmacy (${pharmacy.location} - the "Pharmacy"). I further certify that I have a patient relationship with the ordering medical provider indicated on the prescription label and that I requested that the prescriber send this prescription to the Pharmacy. The foregoing is certified as true and accurate under the penalty of perjury.`
                : pharmacy?.hippa_notes}
            </p>
            <div style={styles.orderBottom}>
              <p>
                Receiver Name{" "}
                <span
                  style={{
                    paddingLeft: "30px",
                    fontWeight: "800",
                    paddingRight: "30px",
                    borderBottom: "1px solid black",
                  }}
                >
                  {orderDetail.name}
                </span>
              </p>
              <div style={{ display: "flex" }}>
                <p>
                  Date{" "}
                  <span
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "800",
                      paddingRight: "10px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {moment(orderDetail.deliverAt).format("MM/DD/YYYY")}
                  </span>
                </p>
                <p>
                  Time{" "}
                  <span
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "800",
                      paddingRight: "10px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {moment(orderDetail.deliverAt).format("hh:mm:ss a")}
                  </span>
                </p>
              </div>
            </div>
            <div style={styles.orderBottom}>
              <p style={{ display: "flex" }}>
                Receiver Signature
                <span
                  style={{
                    paddingLeft: "15px",
                    fontWeight: "800",
                    paddingRight: "30px",
                  }}
                >
                  {/* Handle decoding logic for the signature image */}
                  {(() => {
                    let signatureImage =
                      orderDetail?.ordermaster_route4me !== null
                        ? orderDetail?.ordermaster_route4me[
                          orderDetail?.ordermaster_route4me?.length - 1
                        ]?.signature_image
                        : undefined;
                    console.log("signatureimg", signatureImage);
                    if (
                      signatureImage &&
                      signatureImage.startsWith(
                        "https://pilldrop-s3-bucket.s3.amazonaws.com/https%3A"
                      )
                    ) {
                      signatureImage = decodeURIComponent(
                        signatureImage.replace(
                          "https://pilldrop-s3-bucket.s3.amazonaws.com/",
                          ""
                        )
                      );
                      console.log("decoded signature_img", signatureImage);
                    }

                    // Render the signature image if it exists
                    return signatureImage ? (
                      <img
                        style={{ height: "35px", width: "90px" }}
                        src={signatureImage}
                        alt="signature"
                      />
                    ) : null;
                  })()}
                </span>
              </p>

              <p>
                Delivered by
                <span
                  style={{
                    paddingLeft: "30px",
                    fontWeight: "800",
                    paddingRight: "30px",
                    borderBottom: "1px solid black",
                  }}
                >
                  {pharmacy?.delivered_by === "PD"
                    ? "PD"
                    : pharmacy?.delivered_by === "Driver"
                      ? orderDetail?.driver?.name
                      : ""}
                </span>
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Printdoc;
