import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import {
  BluePencilIcon,
  DeleteRedIcon,
  DeleteZoneIcon,
  UIcon,
  USimpleIcon,
  ZonePencilIcon,
} from "../Icons";
import {
  ContainedButton,
  ContainedButtonWithIcon,
} from "../buttonCompo/Buttons";
import {
  putApiWithAuth,
  deleteApiWithAuth,
  postApiWithAuth,
} from "../../utils/api";
import { ALL_ZONES_LIST } from "../../utils/apiUrl";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
const ZoneCard = ({ zone, borough, getBorough }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: zone.name,
    code: zone.code,
    id: zone.id,
    comment: "Comment will be here!",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };
  const handleSaveBorough = async () => {
    const bid = formState.id;
    const response = await putApiWithAuth(
      ALL_ZONES_LIST + `borros/${bid}/`,
      formState
    );
    if (response.status !== 400) {
      toast.success("Borough Updated Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setOpen(false);
    setFormState({
      name: "",
      id: "",
      code: "",
      comment: "Comment will be here!",
    });
    getBorough();
  };
  const handleDeleteCity = async () => {
    const response = await deleteApiWithAuth(
      ALL_ZONES_LIST + `borros/${zone.id}/`
    );
    if (response.status !== 400) {
      toast.success("City Delete Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setFormState({
      name: "",
      id: "",
      code: "",
      comment: "Comment will be here!",
    });
    getBorough();
    // setId('')
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: theme.palette.custom.layoutBgColor,
          padding: "10px",
          borderRadius: "10px",
          boxShadow: 1,
          width: "198px",
          height: "66px",
          marginRight: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              fontSize: "12px",
              color: theme.palette.custom.layoutFontColor,
              border: "1.25px solid #fff",
              borderRadius: "4px",
              padding: "1px 3px",
            }}
          >
            {zone.code}
          </Typography>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                fontSize: "12px",
                color: theme.palette.custom.layoutFontColor,
              }}
            >
              {zone.name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <ZonePencilIcon onClick={handleOpen} sx={{ cursor: "pointer" }} />
            <DeleteZoneIcon
              onClick={handleDeleteCity}
              sx={{ cursor: "pointer" }}
            />
          </Box>
        </Box>
      </Box>
      {/* Modal for Editing Zone */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Edit City</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="City Name"
            autoComplete="off"
            value={formState.name}
            name="name"
            fullWidth
            variant="outlined"
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="City Code"
            autoComplete="off"
            value={formState.code}
            name="code"
            fullWidth
            variant="outlined"
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <ContainedButton
            label="Save"
            onClick={handleSaveBorough}
            borderRadius="4px"
            variant="contained"
            color="primary"
          />
          <ContainedButton
            label="Cancel"
            textColor="#000"
            bgColor="transparent"
            borderRadius="4px"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const ZoneEdit = ({ zone, borough, getBorough }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: zone.name,
    code: zone.code,
    id: zone.id,
    comment: "Comment will be here!",
    borro: zone.borro,
  });
  const handleZoneEditClick = () => {
    navigate("/zones/zonedetail", { state: { zoneId: zone.id } });
  };
  const handleDeleteZone = async () => {
    const response = await deleteApiWithAuth(
      ALL_ZONES_LIST + `ZonemdList/${formState.id}/delete`
    );
    if (response.status !== 400) {
      toast.success("Zone Delete Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setFormState({
      name: "",
      borro: borough.id,
    });
    getBorough();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };
  const handleSaveZone = async () => {
    const boro = borough.find((b) => b.id === formState.borro);
    if (!boro) {
      toast.error("Borough not found!", {
        position: "top-right",
        autoClose: 1000,
      });
      return;
    }
    const existingObject = boro.zones.find((item) => item.id === formState.id);
    if (
      existingObject.name !== formState.name ||
      existingObject.code !== formState.code
    ) {
      const response = await putApiWithAuth(
        ALL_ZONES_LIST + `ZonemdList/${formState.id}/update`,
        formState
      );
      if (response.status !== 400) {
        toast.success("Zone Edited Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Backend Error!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setOpen(false);
      setFormState({
        name: "",
        borro: borough.id,
      });
      getBorough();
    } else {
      toast.error("Name and/or Zipcode Already Present!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setOpen(false);
      setFormState({
        name: "",
        borro: borough.id,
      });
      getBorough();
    }
  };
  return (
    <>
      <Box
        sx={{
          cursor: "pointer",
          marginRight: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              fontSize: "12px",
              color: "#000",
            }}
          >
            Zone
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <BluePencilIcon onClick={handleOpen} sx={{ cursor: "pointer" }} />
            <DeleteRedIcon
              onClick={handleDeleteZone}
              sx={{ cursor: "pointer" }}
            />
          </Box>
        </Box>
        <Box
          onClick={handleZoneEditClick}
          sx={{
            display: "flex",
            alignItems: "center",

            gap: 5,
            border: "1px solid #dce0e4",
            borderRadius: "8px",
            padding: "6px 12px",
            width: "172px",
          }}
        >
          {/* <USimpleIcon /> */}
          {zone.code && (
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "11px",
                fontWeight: 500,
                border: "0.8px solid gray",
                borderRadius: "4px",
                padding: "1px 3px",
              }}
            >
              {zone.code}
            </Typography>
          )}
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "11px",
              fontWeight: 500,
            }}
          >
            {zone.name}
          </Typography>
        </Box>
      </Box>
      {/* Modal for Editing Zone */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Edit Zone</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Zone Name"
            autoComplete="off"
            value={formState.name}
            name="name"
            fullWidth
            variant="outlined"
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="Zone Code"
            autoComplete="off"
            autoFocus
            value={formState.code}
            name="code"
            fullWidth
            variant="outlined"
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <ContainedButton
            label="Save"
            onClick={handleSaveZone}
            borderRadius="4px"
            variant="contained"
            color="primary"
          />
          <ContainedButton
            label="Cancel"
            textColor="#000"
            bgColor="transparent"
            borderRadius="4px"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const ZoneOverview = ({ borough, getBorough }) => {
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    code: "",
    borro: borough.id,
  });
  const handleOpen = (boroughId) => {
    setFormState({
      name: "",
      code: "",
      borro: boroughId, // Set borough ID dynamically
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [edit, setEdit] = useState(false);
  const handleSaveZone = async () => {
    const boro = borough.find((b) => b.id === formState.borro);
    if (!boro) {
      toast.error("Borough not found!", {
        position: "top-right",
        autoClose: 1000,
      });
      return;
    }
    if (!edit) {
      if (!boro.zones.some((item) => item.name === formState.name)) {
        const response = await postApiWithAuth(ALL_ZONES_LIST, formState);
        if (response.status !== 400) {
          toast.success("Zone Created Successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Backend Error!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setOpen(false);
        setFormState({
          name: "",
          borro: borough.id,
        });
        getBorough();
      } else {
        toast.error("Name already present!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOpen(false);
        setFormState({
          name: "",
          borro: borough.id,
        });
        getBorough();
      }
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      {borough?.map((item) => (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            paddingX: "5px",
            borderRadius: "8px",
            backgroundColor: "white",
            boxShadow: 1,
            marginTop: "10px",
            transition: "background-color 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#f0f0f0", // Change to a light gray on hover
              boxShadow: 1, // Increase shadow on hover
            },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <ZoneCard zone={item} borough={borough} getBorough={getBorough} />
            {item.zones.map((zone) => (
              <ZoneEdit
                key={zone.id}
                zone={zone}
                borough={borough}
                getBorough={getBorough}
              />
            ))}
          </Box>
          <Box>
            <ContainedButtonWithIcon
              label="Add Zone"
              onClick={() => handleOpen(item.id)}
            />
          </Box>
        </Box>
      ))}
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Add Zone</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Zone Name"
            autoComplete="off"
            autoFocus
            fullWidth
            name="name"
            value={formState.name}
            onChange={handleInputChange}
            variant="outlined"
          />
          <TextField
            autoFocus
            margin="dense"
            autoComplete="off"
            label="Unique ID"
            fullWidth
            name="code"
            value={formState.code}
            onChange={handleInputChange}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <ContainedButton
            label="Save"
            onClick={handleSaveZone}
            borderRadius="4px"
            variant="contained"
            color="primary"
          />
          <ContainedButton
            label="Cancel"
            textColor="#000"
            bgColor="transparent"
            onClick={handleClose}
            borderRadius="4px"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ZoneOverview;
