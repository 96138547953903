import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  TextField,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import { OrderDataContext } from "../../context/OrderDataContext";
import { AddIcon } from "../Icons";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


const getFormattedDate = (date) => {
  if (date) {
    const dateObj = new Date(date);
    return dateObj.toISOString().split('T')[0]; // Format to "yyyy-MM-dd"
  }
  return '';
};

const PatientOrderDetails = ({ onOrderDetailChange, isEditable }) => {
  const { orderData, setOrderData } = useContext(OrderDataContext);
  const [localData, setLocalData] = useState(orderData?.order);
  useEffect(() => {
    setLocalData(orderData?.order);
  }, [orderData]);
  const handleInputChange = (field, value) => {
    setLocalData((prev) => ({ ...prev, [field]: value }));
  };
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      onOrderDetailChange(localData);
    }, 300);
    return () => clearTimeout(debounceTimer);
  }, [localData, onOrderDetailChange]);

  const handleOrderChange = (
    event,
    index,
    fieldName
  ) => {
    const { name, value, type, checked } = event.target;

    const updatedRxList = [...localData.orderdetail];
    updatedRxList[index][fieldName] = value;
    setLocalData({ ...localData, orderdetail: updatedRxList });
  };

  const handleAddRow = () => {
    setLocalData((prev) => ({
      ...prev,
      orderdetail: [
        ...prev.orderdetail,
        {
          quantity: "1",
          rfnumber: "0",
          date: "",
          copay: "0.00",
          rxnumber: "M - ",
          select: false,
        },
      ],
    }));
  };

  const handleDeleteRow = (index) => {
    const updatedRxList = [...localData?.orderdetail];
    updatedRxList.splice(index, 1);
    setLocalData((prev) => ({
      ...prev,
      orderdetail: updatedRxList,
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mb: 1 }}>
        <Box
          sx={{
            width: "full",
            height: "38px",
            marginBottom: "3px",
            backgroundColor: "#f4f6f8",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              opacity: "0.7",
              paddingLeft: "15px",
            }}
          >
            Order Details
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "750px" }}>
          <TableContainer>
            <TableContainer
              sx={{ borderCollapse: "collapse" }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="start"
                    sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                  >
                    Count
                  </TableCell>
                  <TableCell
                    align="start"
                    sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                  >
                    RX Number
                  </TableCell>
                  <TableCell
                    align="start"
                    sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="start"
                    sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                  >
                    Copay
                  </TableCell>
                  <TableCell
                    align="start"
                    sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                  >
                    Remove
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localData?.orderdetail?.map((rx, i) => (
                  <TableRow key={i}>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "11px", padding: 0, width: "100px" }}
                    >
                      <Box
                        name="i"
                        sx={{
                          fontSize: "11px",
                          border: "1px solid #d2d5da",
                          height: "33px",
                          width: "50%",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {i + 1}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="start"
                      sx={{ fontSize: "11px", padding: 0, width: "200px" }}
                    >
                      <TextField
                        name={`orderdetail.${i}.rxnumber`}
                        value={rx.rxnumber}
                        autoComplete="off"
                        onChange={(e) => handleOrderChange(e, i, "rxnumber")}
                        disabled={!isEditable}
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "80%",
                          "& .MuiOutlinedInput-root": {
                            fontSize: "11px",
                            borderRadius: "6px",
                          },
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="start"
                      sx={{ fontSize: "11px", padding: 0, width: "200px" }}
                    >
                      <TextField
                        name={`orderdetail.${i}.date`}
                        value={getFormattedDate(rx.date)}
                        onChange={(e) => handleOrderChange(e, i, "date")}
                        type="date"
                        autoComplete="off"
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "85%",
                          "& .MuiOutlinedInput-root": {
                            fontSize: "11px",
                            borderRadius: "6px",
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </TableCell>

                    <TableCell
                      align="start"
                      sx={{ fontSize: "11px", padding: 0, width: "100px" }}
                    >
                      <TextField
                        value={rx.copay}
                        name={`orderdetail.${i}.copay`}
                        autoComplete="off"
                        onChange={(e) => handleOrderChange(e, i, "copay")}
                        disabled={!isEditable}
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "80%",
                          "& .MuiOutlinedInput-root": {
                            fontSize: "11px",
                            borderRadius: "6px",
                          },
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "0", width: "100px" }}>
                      <Checkbox
                        name={`orderdetail.${i}.select`}
                        checked={rx.select}
                        onChange={(e) => handleOrderChange(e, i, "select")}
                        disabled={!isEditable}
                        sx={{ transform: "scale(0.8)" }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "11px", padding: "0", width: "20px" }}
                    >
                      <IconButton
                        onClick={() => handleDeleteRow(i)}
                        disabled={!isEditable}
                        sx={{ padding: 0 }}
                      >
                        <img
                          src="/icons/deliveryIcons/Delete.svg"
                          alt="Delete-Icon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          </TableContainer>
          <Button
            variant="contained"
            onClick={handleAddRow}
            disabled={!isEditable}
            sx={{
              mt: 2,
              backgroundColor: "#000",
              color: "#fff",
              borderRadius: "10px",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <AddIcon /> Add Item
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
export default PatientOrderDetails;
