import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Checkbox,
  Typography,
  MenuItem,
  ListItemText,
  FormHelperText,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";

const CustomDropdownContainer = styled(Box)(({ customstyles, error, disabled }) => ({
  backgroundColor: customstyles?.backgroundColor || "#fff",
  borderRadius: customstyles?.borderRadius || "4px",
  width: customstyles?.width || "100%",
  height: customstyles?.height || "36px",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: disabled ? "not-allowed" : "pointer", // Update cursor if disabled
  border: error
    ? "1px solid red" // Highlight the error state
    : customstyles?.border || "1px solid #dce0e4",
  color: customstyles?.color || "#000",
  // Prevent clicks if disabled, also reduce opacity
  pointerEvents: disabled ? "none" : "auto",
  opacity: disabled ? 0.6 : 1,
}));

const DropdownMenu = styled(Box)(({ theme, customstyles }) => ({
  position: "absolute",
  zIndex: 1000,
  backgroundColor: customstyles?.backgroundColor || "#fff",
  borderRadius: customstyles?.menuBorderRadius || "8px",
  marginTop: "8px",
  boxShadow: theme.shadows[3],
  minWidth: customstyles?.menuMinWidth || "100%",
  maxWidth: customstyles?.menuMaxWidth || "auto",
  whiteSpace: "nowrap",
}));

const DropdownMenuItem = styled(MenuItem)(() => ({
  fontSize: "10px",
  color: "#000",
  fontWeight: 400,
}));

const DropdownIcon = styled("img")(({ open }) => ({
  marginLeft: "8px",
  transition: "transform 0.3s ease",
  transform: open ? "rotate(180deg)" : "rotate(0deg)",
}));

const CustomDropdown = ({
  label,
  options,
  value,
  onChange,
  customstyles = {},
  iconSrc = "/icons/Dropdown.svg",
  isMultiSelect = false,
  error = false,
  helperText = "",
  disabled = false, // <-- Make sure it defaults to false
  search = false,
  isShowCross = true,
}) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    // If the dropdown is disabled after being opened, close it
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (selectedValue) => {
    // Do nothing if disabled
    if (disabled) return;

    let newSelected;

    if (isMultiSelect) {
      newSelected = Array.isArray(value) ? [...value] : [];
      const index = newSelected.indexOf(selectedValue);

      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(selectedValue);
      }
    } else {
      newSelected = selectedValue;
    }

    onChange(newSelected);
    if (!isMultiSelect) {
      setOpen(false);
    }
  };

  const handleClearSelection = (e) => {
    e.stopPropagation();
    onChange(isMultiSelect ? [] : "");
  };

  const getSelectedLabels = () => {
    if (isMultiSelect) {
      const selectedLabels = options
        .filter(
          (option) => Array.isArray(value) && value.includes(option.value)
        )
        .map((option) => option.label)
        .join(", ");
      return selectedLabels || label || "Select an option";
    } else {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.label : label || "Select an option";
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option?.label?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box
      ref={dropdownRef}
      sx={{ position: "relative", width: customstyles.width || "100%" }}
    >
      <CustomDropdownContainer
        onClick={() => {
          // Only toggle if not disabled
          if (!disabled) {
            setOpen((prevOpen) => !prevOpen);
          }
        }}
        customstyles={customstyles}
        error={error ? "true" : undefined}
        disabled={disabled} // Pass 'disabled' to the styled component
      >
        <Typography
          variant="body2"
          sx={{ fontSize: "11px", fontWeight: 400, color: "#000" }}
        >
          {getSelectedLabels()}
        </Typography>
        {/** Hide cross icon if disabled or no value selected */}
        {(!disabled &&
          ((isMultiSelect && Array.isArray(value) && value.length > 0) ||
            (!isMultiSelect && value && isShowCross))) ? (
          <img
            src="/icons/Cross.svg"
            alt="Clear"
            onClick={handleClearSelection}
            style={{
              cursor: "pointer",
              backgroundColor: "#000",
              margin: "5px 5px",
              borderRadius: "6px",
            }}
          />
        ) : (
          <DropdownIcon src={iconSrc} alt="Dropdown Icon" open={open} />
        )}
      </CustomDropdownContainer>

      {open && !disabled && (
        <DropdownMenu
          customstyles={customstyles}
          sx={{
            maxHeight: "500px",
            overflowY: "auto",
          //   "&::-webkit-scrollbar": {
          //     width: "6px",
          //   },
          //   "&::-webkit-scrollbar-track": {
          //     background: "#f1f1f1",
          //   },
          //   "&::-webkit-scrollbar-thumb": {
          //     background: "#888",
          //     borderRadius: "8px",
          //   },
          //   "&::-webkit-scrollbar-thumb:hover": {
          //     background: "#555",
          //   },
          //   scrollbarWidth: "thin",
          //   scrollbarColor: "#888 #f1f1f1",
          }}
        >
          {search && open && (
            <TextField
              fullWidth
              variant="outlined"
              autoComplete="off"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{
                marginBottom: "8px",
                fontSize: "10px",
                "& .MuiInputBase-root": {
                  fontSize: "10px",
                },
              }}
              placeholder="Search..."
            />
          )}
          {filteredOptions.map((option) => (
            <DropdownMenuItem
              key={option.value}
              onClick={() => handleSelect(option.value)}
              sx={{
                maxWidth: "600px",
                whiteSpace: "normal",
                wordBreak: "break-word",
                paddingX: "5px",
              }}
            >
              {isMultiSelect && (
                <Checkbox
                  size="small"
                  checked={Array.isArray(value) && value.includes(option.value)}
                  sx={{ width: "10px" }}
                />
              )}
              <ListItemText
                primary={option.label}
                primaryTypographyProps={{
                  fontSize: "11px",
                  color: "black",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  alignItems: "left",
                  paddingLeft: "10px",
                }}
              />
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}

      {helperText && (
        <FormHelperText error={error} sx={{ marginLeft: "12px" }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default CustomDropdown;
