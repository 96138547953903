import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  // Autocomplete,
} from "@mui/material";
import InputMask from "react-input-mask";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { PhoneNumberField, ZipCodeField } from "../Ui/PhoneNumberField";
import { useLocation } from "react-router-dom";
import { useDisplayMode } from "../../context/DisplayModeContext";
import { OrderDataContext } from "../../context/OrderDataContext";
import axios from "axios";
import {
  CallIcon,
  HomeIcon,
  MobileIcon,
  PersonIconBlack,
  TelephoneIcon,
} from "../Icons";
import Autocomplete from "react-google-autocomplete";

const PatientDetails = ({
  pharmacyDetail,
  onOrderDetailChange,
  isEditable,
  addressError,
  setAddressError
}) => {
  const { orderData, setOrderData } = useContext(OrderDataContext);
  const [checked, setChecked] = useState(false);
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("17:00");
  const { displayMode } = useDisplayMode();
  const [address, setAddress] = useState("");
  const [results, setResults] = useState([]);
  const [streetViewUrl, setStreetViewUrl] = useState("");
  const [mapError, setMapError] = useState(false);
  const GOOGLE_MAPS_API_KEY = "AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ";
  const options = {
    types: ["address"],
    componentRestrictions: { country: "us" },
    bounds: {
      north: 42.0505, // Northernmost point roughly in CT
      south: 38.9285, // Southernmost point roughly in NJ
      west: -79.7624, // Westernmost point roughly in NY
      east: -71.1851, // Easternmost point roughly in CT
    },
    strictBounds: true,
  };
  const handleApartmentChange = (event) => {
    const apartmentNumber = event.target.value;
    setLocalData({
      ...localData,
      apart: apartmentNumber,
    });
  };
  const [localData, setLocalData] = useState(orderData?.order);
  useEffect(() => {
    setLocalData(orderData?.order);
  }, [orderData]);
  const handleInputChange = (field, value) => {
    setLocalData((prev) => ({ ...prev, [field]: value }));
  };
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      onOrderDetailChange(localData);
    }, 300);
    return () => clearTimeout(debounceTimer);
  }, [localData, onOrderDetailChange]);

  const handleSearch = async (event) => {
    const { value } = event.target;
    setAddress(value);
    if (value.length > 2) {
      // To limit requests, only search if query length > 2
      const apikey = "6CgVwXbgNzlj3uCnA38M0Du16b2o_0DKBOLGYyc1SH8";
      const endpoint = `https://autosuggest.search.hereapi.com/v1/autosuggest?at=40.8301933,-73.8521865&in=countryCode:USA&lang=en-US&limit=5&q=${value}&apikey=${apikey}`;

      try {
        const response = await axios.get(endpoint);
        setResults(response.data.items || []);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setResults([]);
      }
    } else {
      setResults([]);
    }
  };
  const handlePlaceSelect = (place) => {
    if (pharmacyDetail.geocode_handling === "GOOGLE") {
      setLocalData((localData) => ({
        ...localData,
        address: place.formatted_address,
      }));
      setAddress(place.formatted_address);
    } else {
      setLocalData((localData) => ({
        ...localData,
        address: place.title,
      }));
      setAddress(place.title);
    }

    setResults([]);
  };

  useEffect(() => {
    if (
      (orderData?.order?.address, orderData?.order?.lat, orderData?.order?.lng)
    ) {
      const streetViewUrlApi = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${orderData?.order?.lat},${orderData?.order?.lng}&key=AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ`;
      setStreetViewUrl(streetViewUrlApi);
      setLocalData({
        ...localData,
        streetViewUrl: streetViewUrl,
      });
    }
  }, [orderData?.order?.address, orderData?.order?.lat, orderData?.order?.lng]);

  useEffect(() => {
    if (orderData?.order?.address !== "") {
      const fetchLocation = async () => {
        try {
          if (pharmacyDetail.geocode_handling === "GOOGLE") {
            const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
              orderData?.order?.address
            )}&key=AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ`;
            const response = await axios.get(geocodingUrl);

            const { results } = response.data;

            if (results.length > 0) {
              console.log(results);
              const { lat, lng } = results[0].geometry.location;
              const formattedLat = lat.toFixed(5); // Now formattedLat is a string with 5 decimals, even if trailing zeros
              const formattedLng = lng.toFixed(5);
              const type = results[0].geometry.location_type;
              const postalComponent = results[0]?.address_components.find(
                (component) => component.types.includes("postal_code")
              );

              if (
                lat.toString().split(".")[1]?.length < 2 ||
                lng.toString().split(".")[1]?.length < 4
              ) {
                setAddressError(
                  "Latitude or longitude has less than 2 decimal places"
                );
              } else if (type === "APPROXIMATE") {
                setAddressError(
                  "Address is invalid. Please select a valid address"
                );
              } else {
                setAddressError("");
                setAddress(results[0]?.formatted_address);
                setLocalData((localData) => ({
                  ...localData,
                  lat: formattedLat,
                  lng: formattedLng,
                  zip: postalComponent?.long_name,
                }));
                setMapError(false);
              }
              // we will use this if needed for testing remove it!
              // else if (results[0].partial_match) {
              //   setAddressError("Address match is partial and might be inaccurate. Please confirm the address.");
              // }
            } else {
              setMapError(true);
              setLocalData((localData) => ({
                ...localData,
                lat: "",
                lng: "",
                zip: "",
              }));
            }
          } else {
            const hereURL = `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(
              orderData?.order?.address
            )}&apiKey=6CgVwXbgNzlj3uCnA38M0Du16b2o_0DKBOLGYyc1SH8`;
            const responseHere = await axios.get(hereURL);
            const results = responseHere.data.items;
            if (results.length > 0) {
              console.log(results);
              // Find the first item where resultType is 'houseNumber'
              const houseNumberResult = results.find(
                (result) => result.resultType === "houseNumber"
              );

              if (houseNumberResult) {
                const { lat, lng } = houseNumberResult.position;
                const postalCode =
                  houseNumberResult.address.postalCode.substring(0, 5); // Get first 5 characters
                const title = houseNumberResult.title;

                setAddressError("");
                setAddress(title);
                setLocalData((localData) => ({
                  ...localData,
                  lat: lat,
                  lng: lng,
                  zip: postalCode,
                  address: title,
                }));
                setMapError(false);
              } else {
                // Handle the case where no 'houseNumber' result is found
                setAddressError(
                  "Address is invalid. Please select a valid address"
                );
                setMapError(true);
                setLocalData((localData) => ({
                  ...localData,
                  lat: "",
                  lng: "",
                  zip: "",
                  title: "",
                }));
              }
            } else {
              setMapError(true);
              console.log("No results found");
              setLocalData((localData) => ({
                ...localData,
                lat: "",
                lng: "",
                zip: "",
              }));
            }
          }
        } catch (error) {
          console.log("Google map give error yet please try again later!");
        }
      };

      fetchLocation();
    }
  }, [orderData]);

  const handleCheckboxChange = (event, field) => {
    setChecked(event.target.checked);
    setLocalData((prev) => ({ ...prev, [field]: checked }));
  };
  return (
    <Box sx={{ marginTop: "18px" }}>
      <style>
        {`
           .react-time-picker__inputGroup__input,
          .react-time-picker__inputGroup__divider,
          .react-time-picker__inputGroup__leadingZero {
            color: black !important;
            font-size: 12px !important;
        `}
      </style>
      <Box
        sx={{
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          gap: 3,
          backgroundColor: "#f6f6f6",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            paddingTop: 1,
            paddingLeft: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1.3,
            height: "147px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            <PersonIconBlack />
            {localData?.name}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            <HomeIcon />
            {address}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            <CallIcon />
            {localData?.contact}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            <MobileIcon />
            {localData?.cell}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            <TelephoneIcon />
            {localData?.workNumber}
          </Typography>
        </Box>

        {displayMode === "large" && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {orderData?.order?.lat && orderData?.order?.lng && (
              <img
                component="img"
                src={streetViewUrl}
                alt="Google Maps Street View"
                style={{
                  width: "60%",
                  height: "100%",
                  objectFit: "cover",
                  backgroundColor: "red",
                }}
              />
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          <Box sx={{ flex: 3 }}>
            <Typography
              variant="h1"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Name
            </Typography>
            <TextField
              name="name"
              autoComplete="off"
              placeholder="Name"
              value={localData?.name}
              disabled={!isEditable}
              // onChange={handleOrderChange}
              onChange={(e) => handleInputChange("name", e.target.value)}
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "36px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                marginBottom: "3px",
              }}
            >
              Phone
            </Typography>
            <InputMask
              mask="(999) 999-9999"
              value={localData?.contact}
              disabled={!isEditable}
              onChange={(e) => handleInputChange("contact", e.target.value)}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  name="contact"
                  autoComplete="off"
                  placeholder="(___) ___-____"
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-input": {
                      fontSize: "11px",
                      padding: "8px 12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "36px",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "11px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#4b5563",
                    },
                  }}
                />
              )}
            </InputMask>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                marginBottom: "3px",
              }}
            >
              Cell
            </Typography>
            <InputMask
              mask="(999) 999-9999"
              value={localData?.cell}
              disabled={!isEditable}
              onChange={(e) => handleInputChange("cell", e.target.value)}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  name="cell"
                  autoComplete="off"
                  placeholder="(___) ___-____"
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-input": {
                      fontSize: "11px",
                      padding: "8px 12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "36px",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "11px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#4b5563",
                    },
                  }}
                />
              )}
            </InputMask>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                marginBottom: "3px",
              }}
            >
              Work
            </Typography>
            <InputMask
              mask="(999) 999-9999"
              value={localData?.workNumber}
              disabled={!isEditable}
              onChange={(e) => handleInputChange("workNumber", e.target.value)}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  name="workNumber"
                  autoComplete="off"
                  placeholder="(___) ___-____"
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-input": {
                      fontSize: "11px",
                      padding: "8px 12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "36px",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "11px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#4b5563",
                    },
                  }}
                />
              )}
            </InputMask>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2.8 }}>
          <Box sx={{ flex: 4 }}>
            <Typography
              variant="h1"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Address
            </Typography>

            {pharmacyDetail.geocode_handling === "GOOGLE" ? (
              <Autocomplete
                apiKey={GOOGLE_MAPS_API_KEY}
                onPlaceSelected={handlePlaceSelect}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    type="text"
                    name="address"
                    id="address"
                    className="order-field"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value)
                    }}
                  />
                )}
                className="order-field"
                inputProps={{
                  style: {
                    '&:hover': {
                      borderColor: '#69c0ff'
                    },
                    '&:active': {
                      borderColor: '#69c0ff'
                    },
                    '&:focus': {
                      borderColor: '#69c0ff'
                    }
                  }
                }}
                sx={{
                  width: "155px",
                  "& .MuiOutlinedInput-root": {
                    height: "36px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
                options={options}
              />
            ) : (
              <Box sx={{ position: "relative" }}>
                <TextField
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Address"
                  disabled={!isEditable}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      height: "36px",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "11px",
                      padding: "8px 12px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#4b5563",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#69c0ff",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  value={address}
                  onChange={handleSearch}
                />
                <Box
                  sx={{
                    overflowY: "auto",
                    position: "absolute",
                    backgroundColor: "white",
                    zIndex: 1,
                    width: "100%",
                  }}
                >
                  {results.length > 0 && (
                    <ul
                      className="addressList"
                      style={{ listStyle: "none", margin: 0, padding: 0 }}
                    >
                      {results.map((item, index) => (
                        <li
                          key={index}
                          style={{
                            border: "1px solid grey",
                            borderRadius: "4px",
                            cursor: "pointer",
                            color: "black",
                            fontSize: "10px",
                            padding: "6px",
                            transition: "background-color 0.3s, color 0.3s",
                          }}
                          onClick={() => handlePlaceSelect(item)}
                          onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = "black";
                            e.currentTarget.style.color = "white";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = "white";
                            e.currentTarget.style.color = "black";
                          }}
                        >
                          {item.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </Box>
              </Box>
            )}
            {addressError && <p style={{ color: "red" }}>{addressError}</p>}
          </Box>
          <ZipCodeField
            label="Zip code"
            value={localData?.zip}
            disabled={!isEditable}
            onChange={(e) => handleInputChange("zip", e.target.value)}
            placeholder="00000"
          />
          <Box>
            <Typography
              variant="h1"
              inputProps={{ maxLength: 3 }}
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              APT #
            </Typography>
            <TextField
              name="apart"
              autoComplete="off"
              disabled={!isEditable}
              placeholder="Apt Number"
              value={localData?.apart}
              onChange={(e) => handleApartmentChange(e)}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  height: "36px",
                },
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 3,
            maxWidth: "100%",
            marginTop: "-15px",
          }}
        >
          <Typography variant="body3" sx={{ fontSize: "10px", color: "gray" }}>
            Please Confirm with the patient for apartment number if they don't
            live in a private house
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ transform: "scale(0.8)" }}
                  checked={localData?.addresstype}
                  disabled={!isEditable}
                  onChange={(e) =>
                    handleInputChange("addresstype", e.target.checked)
                  }
                />
              }
              label="Business Address"
              labelPlacement="start"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "10px",
                  color: "gray",
                },
              }}
            />
            {localData?.addresstype && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                <TimePicker
                  value={localData?.business_start_time}
                  onChange={(time) =>
                    handleInputChange("business_start_time", time)
                  }
                  disableClock
                  format="hh:mm a"
                  className="custom-time-picker"
                />

                <TimePicker
                  value={localData?.business_end_time}
                  onChange={(time) =>
                    handleInputChange("business_end_time", time)
                  }
                  disableClock
                  format="hh:mm a"
                  className="custom-time-picker"
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientDetails;
