import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useLocation } from "react-router";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import { CREATE_ORDER, ALL_ORDERS } from "../../utils/apiUrl";
import { getApiWithAuth, patchApiWithAuth } from "../../utils/api";
import moment from "moment";
import { styled } from "@mui/system";
import SingleFieldDateRangePicker from "../dateRangePicker/DateRangePicker";
import CustomDropdown from "../CustomDropdown";
import { SearchIcon } from "../Icons";
import { useDisplayMode } from "../../context/DisplayModeContext";

// Redux Imports
import { useSelector, useDispatch } from "react-redux";
import { fetchPharmacies } from "../../slices/pharmacySlice";
import { fetchZones, fetchBoroughs } from "../../slices/zoneSlice";

const CustomDropdownIcon = styled("img")({
  marginRight: "7px",
  opacity: "0.6",
});

const FILTER_FONT_SIZE = "11.5px";
const FILTER_DROPDOWN_WIDTH = 120;
const FILTER_FONT_WEIGHT = 600;

const FilterComponent = ({
  onFilterData,
  onOrderData,
  buttonData,
  markReadOrderChat,
  fetchOrdersRef,
  statuschild,
  resetTrigger,
  triggerFetch
}) => {

  const formControlStyle = {
    minWidth: 120,
    height: 36,
    "& .MuiInputBase-root": {
      height: 36,
      display: "flex",
      alignItems: "center",
    },
  };
  const { displayMode } = useDisplayMode();

  // Local States
  const [timer, setTimer] = useState("");
  const [rx, setrx] = useState("");
  const [boroughs, setBoroughs] = useState("");
  const [filter, setFilter] = useState("");
  const [callToNotify, setCallToNotify] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
  ]);

  const orderObjRef = useRef(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [status, setStatus] = useState("all");
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(null);
  const [selectedTab, setSelectedTab] = useState("allOrders");
  const [pharmacy, setPharmacy] = useState("");
  const [zip, setZip] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);

  const handleChange = (setter) => (value) => {
    setter(value);
  };

  // Function to map button labels to statuses
  const handleStatusChange = (label) => {
    let newStatus = "all";
    switch (label) {
      case "All":
        newStatus = "all";
        break;
      case "Ready to pick up":
        newStatus = "1";
        break;
      case "Pickup Occurred":
        newStatus = "2";
        break;
      case "Received at Facility":
        newStatus = "3";
        break;
      case "In Transit":
        newStatus = "7all";
        break;
      case "Route Created":
        newStatus = "7";
        break;
      case "Failed":
        newStatus = "8";
        break;
      case "Dispatching":
        newStatus = "4";
        break;
      case "Return Created":
        newStatus = "10";
        break;
      case "Return Route Created":
        newStatus = "11";
        break;
      case "Return to Pharmacy":
        newStatus = "12";
        break;
      case "Out for Delivery":
        newStatus = "9";
        break;
      case "Delivered":
        newStatus = "5";
        break;
      case "Canceled":
        newStatus = "6all";
        break;
      case "Cancelled":
        newStatus = "6";
        break;
      default:
        newStatus = "all";
    }
    return newStatus;
  };
  useEffect(() => {
    if (buttonData?.label) {
      const updatedStatus = handleStatusChange(buttonData.label);
      setStatus(updatedStatus);
      getOrders(updatedStatus);
      fetchFilteredData(dateRange);
    }
  }, [buttonData]);

  useEffect(() => {
    if (statuschild !== null) {
      setStatus(statuschild);
      getOrders(statuschild);
      onOrderData([]);
      setTotalItems(0);
      setTotalPages(0);
      setPageIndex(1);
      setPageSize(20);
      fetchFilteredData(dateRange);
    }
  }, [statuschild]);

  useEffect(() => {
    getOrders(status);
    onOrderData([]);
    setTotalItems(0);
    setTotalPages(0);
    setPageIndex(1);
    setPageSize(20);
    fetchFilteredData(dateRange);
  }, [status]);

  useEffect(() => {
    if (triggerFetch) {
      getOrders(status);
      onOrderData([]);
      setTotalItems(0);
      setTotalPages(0);
      setPageIndex(1);
      setPageSize(20);
      fetchFilteredData(dateRange);
    }
  }, [triggerFetch]);

  const dispatch = useDispatch();

  // Redux State Selectors
  const pharmacyList = useSelector((state) => state.pharmacy.list);
  const pharmacyStatus = useSelector((state) => state.pharmacy.status);
  const pharmacyError = useSelector((state) => state.pharmacy.error);

  const zoneList = useSelector((state) => state.zone.zones);
  const boroughList = useSelector((state) => state.zone.boroughs);
  const zoneStatus = useSelector((state) => state.zone.status);
  const zoneError = useSelector((state) => state.zone.error);

  const userType = localStorage.getItem("userType");
  const pid = localStorage.getItem("pharmacyId");

  useEffect(() => {
    if (userType !== "PharmacyOwner" && userType !== "PharmacyEmployee") {
      if (pharmacyStatus === "idle") {
        dispatch(fetchPharmacies());
      }
      if (zoneStatus === "idle") {
        dispatch(fetchBoroughs());
      }
    }
  }, [userType, dispatch, pharmacyStatus, zoneStatus]);

  const handleBoroughChange = async (value) => {
    if (value !== null) {
      const selectedBoroughId = value;
      setBoroughs(value);
      try {
        const result = await dispatch(fetchZones(selectedBoroughId));
        if (fetchZones.fulfilled.match(result)) {
          const zones = result.payload;
          setSelectedZone(null);
          const zipcodes = zones
            .filter((zone) => zone.zipcode && zone.zipcode.length > 0)
            .flatMap((zone) => zone.zipcode.map((zipcode) => zipcode.zipcode));
          setZip(zipcodes);
        } else {
          console.error("Failed to fetch zones:", result.error.message);
        }
      } catch (error) {
        console.error("Error in handleBoroughChange:", error);
      }
    } else {
      setZip([]);
      setPharmacy("");
      setSelectedZone(null);
    }
  };

  const handleZipChange = (selectedZoneId) => {
    if (selectedZoneId) {
      const selectedZoneObj = zoneList.find(
        (zone) => zone.id === selectedZoneId
      );

      if (selectedZoneObj) {
        const zipcodes = selectedZoneObj.zipcode.map((item) => item.zipcode);
        setZip(zipcodes);
        setSelectedZone(selectedZoneObj);
      } else {
        console.error("Selected zone not found in zoneList.");
        setZip([]);
        setSelectedZone(null);
      }
    } else {
      setZip([]);
      setSelectedZone(null);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      const searchTerm = inputValue.toLowerCase();
      setSearch(inputValue);
    }
  };

  const fetchFilteredData = async (newDateRange) => {
    try {
      const [startDate, endDate] = newDateRange;
      if (!startDate || !endDate) return;

      const formattedStartDate = moment(startDate).format("MM-DD-YYYY");
      const formattedEndDate = moment(endDate).format("MM-DD-YYYY");

      const response = await getApiWithAuth(
        `${CREATE_ORDER}?from=${formattedStartDate}&to=${formattedEndDate}&callToNotify=${callToNotify}`
      );

      if (response?.data?.data) {
        onFilterData(response.data.data);
        resetTrigger();
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
      resetTrigger();
    }
  };

  const location = useLocation();
  const [Urgent, setUrgent] = useState(false);

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  let isFetching = false;
  const getOrders = async (
    statusId = status,
    pageIndexData = pageIndex,
    loadMore = false
  ) => {
    const [startDate, endDate] = dateRange;
    if (!startDate || !endDate) return;
    const formattedStartDate = moment(startDate).format("MM-DD-YYYY");
    const formattedEndDate = moment(endDate).format("MM-DD-YYYY");
    setLoadingOrder(true);
    if (loadingOrder) return;
    if (isFetching) return;

    isFetching = true;
    const pharmacyParam =
      userType === "PharmacyOwner" || userType === "PharmacyEmployee"
        ? pid
        : `${pharmacy}`;
    if (!loadMore) {
      setPageIndex(1);
    }
    if (callToNotify) {
      setSelectedTab("allOrders");
      const response = await getApiWithAuth(
        ALL_ORDERS +
          `?status=all&pharmacy=${pharmacyParam}&filter=${
            filter === "0" ? "" : filter
          }&timer=${
            timer === "0" ? "" : timer
          }&zip=${zip}&from=${formattedStartDate}&to=${formattedEndDate}&callToNotify=${callToNotify}&pageIndex=${
            !loadMore ? 1 : pageIndexData
          }&pageSize=${pageSize}&search=${search}&rx=${rx}`
      );
      if (response.data !== undefined) {
        if (response.data.data !== undefined) {
          if (loadMore) {
            onOrderData((prevOrders) => [
              ...prevOrders,
              ...response.data.data.data,
            ]);
          } else {
            onOrderData(response.data.data.data);
          }
          setPageIndex((prevPageIndex) => prevPageIndex + 1);
          setHasMore(response.data.data.hasMore);
          setPageSize(response.data.data.pagination.pageSize);
          setTotalPages(response.data.data.pagination.totalPages);
          setTotalItems(response.data.data.pagination.totalItems);
          setLoading(false);
          setLoadingOrder(false);
          isFetching = false;
        } else {
          setLoadingOrder(false);
          setLoading(false);
          isFetching = false;
        }
      } else {
        setLoadingOrder(false);
        setLoading(false);
      }
    } else {
      const pharmacyParam =
        userType === "PharmacyOwner" || userType === "PharmacyEmployee"
          ? pid
          : `${pharmacy}`;
      const response = await getApiWithAuth(
        ALL_ORDERS +
          `?status=${statusId}&pharmacy=${pharmacyParam}&filter=${
            filter === "0" ? "" : filter
          }&timer=${
            timer === "0" ? "" : timer
          }&zip=${zip}&from=${formattedStartDate}&to=${formattedEndDate}&pageIndex=${
            !loadMore ? 1 : pageIndexData
          }&pageSize=${pageSize}&search=${search}&rx=${rx}`
      );
      if (response.data !== undefined) {
        if (response.data.data !== undefined) {
          if (loadMore) {
            onOrderData((prevOrders) => [
              ...prevOrders,
              ...response.data.data.data,
            ]);
          } else {
            onOrderData(response.data.data.data);
          }
          setPageIndex((prevPageIndex) => prevPageIndex + 1);
          setHasMore(response.data.data.hasMore);
          setPageSize(response.data.data.pagination.pageSize);
          setTotalPages(response.data.data.pagination.totalPages);
          setTotalItems(response.data.data.pagination.totalItems);
          setLoadingOrder(false);
          setLoading(false);
        } else {
          setLoadingOrder(false);
          setLoading(false);
        }
      } else {
        setLoadingOrder(false);
        setLoading(false);
      }
      setLoadingOrder(false);
      setLoading(false);
    }
    resetTrigger();
  };


  // const markReadOrderChat = async (id) => {
  //   await patchApiWithAuth(
  //     ORDER_CHAT_LIST + `update_by_order/?order_id=${id}`, { is_read: true }
  //   );
  //   getOrders(status);
  // };

  useImperativeHandle(fetchOrdersRef, () => async (loadMore = false) => {
    await getOrders(status, pageIndex, loadMore);
  });
  const getOrdersUrgent = async () => {
    const pharmacyParam =
      userType === "PharmacyOwner" || userType === "PharmacyEmployee"
        ? pid
        : `${pharmacy}`;
    const response = await getApiWithAuth(
      ALL_ORDERS +
        `?status=1&pharmacy=${pharmacyParam}&filter=${
          filter === "0" ? "" : filter
        }&timer=Urgent&zip=${zip}&pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}&rx=${rx}`
    );
    if (response.data !== undefined) {
      if (response.data.data !== undefined) {
        onOrderData(response.data.data.data);
        setPageIndex(response.data.data.pagination.nextPageIndex);
        setPageSize(response.data.data.pagination.pageSize);
        setTotalPages(response.data.data.pagination.totalPages);
        setTotalItems(response.data.data.pagination.totalItems);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("true")) {
      getOrdersUrgent();
      setSelectedTab("readyToPickup");
    } else {
      fetchFilteredData(dateRange);
      getOrders();
    }
  }, [search, rx, pharmacy, zip, dateRange, filter, timer, callToNotify]);

  const renderMenuItem = (value, label) => (
    <MenuItem key={value} value={value} sx={{ fontSize: FILTER_FONT_SIZE }}>
      {label}
    </MenuItem>
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {displayMode === "large" && (
          <TextField
            variant="outlined"
            autoComplete="off"
            placeholder="Search Order Id, Name, Phone, Address, RX#"
            InputProps={{
              sx: {
                fontSize: `${FILTER_FONT_SIZE}`,
                fontWeight: FILTER_FONT_WEIGHT,
              },
              onKeyDown: handleKeyPress,
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            sx={{
              mr: 2,
              minWidth: 250,
              height: 36,
              backgroundColor: "#fff",
              borderRadius: "15px",
              boxShadow: 1,
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            size="small"
          />
        )}

        {userType !== "PharmacyOwner" && userType !== "PharmacyEmployee" && (
          <div style={{ marginRight: "10px" }}>
            <CustomDropdown
              customstyles={{
                height: "36px",
                width: "137px",
              }}
              label="Filter Pharmacy"
              value={pharmacy}
              onChange={(selected) => setPharmacy(selected)}
              options={pharmacyList.map((pharm) => ({
                value: pharm.id,
                label: pharm.name,
              }))}
              isMultiSelect={true}
            />
          </div>
        )}
        {userType !== "PharmacyOwner" && userType !== "PharmacyEmployee" && (
          <div style={{ marginRight: "10px" }}>
            <CustomDropdown
              customstyles={{
                height: "36px",
                width: "137px",
              }}
              label="Select Borough"
              value={boroughs}
              onChange={handleBoroughChange}
              options={boroughList.map((boro) => ({
                value: boro.id,
                label: boro.name,
              }))}
              isMultiSelect={false}
            />
          </div>
        )}
        {userType !== "PharmacyOwner" && userType !== "PharmacyEmployee" && (
          <div style={{ marginRight: "10px" }}>
            <CustomDropdown
              customstyles={{
                height: "36px",
                width: "137px",
              }}
              label="Select Zone"
              value={selectedZone ? selectedZone.id : ""}
              onChange={handleZipChange}
              options={zoneList.map((zon) => ({
                value: zon.id,
                label: zon.name,
              }))}
              isMultiSelect={false}
            />
          </div>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <CustomDropdown
            customstyles={{
              height: "36px",
              width: FILTER_DROPDOWN_WIDTH,
            }}
            label="Select Filter"
            value={filter}
            onChange={handleChange(setFilter)}
            options={[
              { value: 1, label: "Refrigerator" },
              { value: 2, label: "Photo Id" },
              { value: 3, label: "Copay" },
            ]}
            isMultiSelect={false}
          />
          <CustomDropdown
            customstyles={{
              height: "36px",
              width: "120px",
            }}
            label="Time Window"
            value={timer}
            onChange={(value) => setTimer(value)}
            options={[
              { value: "Anytime", label: "Anytime" },
              { value: "9am-3pm", label: "9am-3pm" },
              { value: "2pm-6pm", label: "2pm-6pm" },
              { value: "5pm-9pm", label: "5pm-9pm" },
              { value: "Urgent", label: "Urgent" },
            ]}
            isMultiSelect={false}
          />
        </Box>

        {userType !== "PharmacyOwner" && userType !== "PharmacyEmployee" && (
          <Button
            variant="outlined"
            sx={{
              ml: 1,
              border: "none",
              backgroundColor: "#EDEFF1",
              borderRadius: "5px",
              color: "#000",
              textTransform: "none",
              fontSize: `${FILTER_FONT_SIZE}`,
              fontWeight: FILTER_FONT_WEIGHT,
              height: 36,
            }}
            onClick={() => setCallToNotify(!callToNotify)}
            endIcon={
              <img
                src="/icons/Info.svg"
                alt="Info icon"
                style={{ opacity: 0.6 }}
              />
            }
          >
            Call to Notify
          </Button>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <SingleFieldDateRangePicker
          value={dateRange}
          onChange={handleDateChange}
        />
      </Box>
    </Box>
  );
};

export default forwardRef(FilterComponent);
