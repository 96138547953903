import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";

const SubTabData = ({ filterData, canceled = false, onSubTabClick, labelShow }) => {
  const data = filterData;
  const [activeButton, setActiveButton] = useState();
  const tabData = [
    {
      label: "Pickup Occurred",
      bgColor: "#e4e6e7",
      num: data?.[2] || 0,
      color: "#637381",
    },
    {
      label: "Received at Facility",
      bgColor: "#0094ff",
      num: data?.[3] || 0,
      color: "#fff",
    },
    {
      label: "Dispatching",
      bgColor: "#637381",
      num: data?.[4] || 0,
      color: "#fff",
    },
    {
      label: "Route Created",
      bgColor: "#fe9901",
      num: data?.[7] || 0,
      color: "#fff",
    },
    {
      label: "Out for Delivery",
      bgColor: "#ff5630",
      num: data?.[9] || 0,
      color: "#fff",
    },
    {
      label: "Failed",
      bgColor: "#fb1001",
      num: data?.[8] || 0,
      color: "#fff",
    },
    {
      label: "Cancelled",
      bgColor: "#fb1001",
      num: data?.[6] || 0,
      color: "#fff",
    },
    {
      label: "Return Created",
      bgColor: "#fb1001",
      num: data?.[10] || 0,
      color: "#fff",
    },
    {
      label: "Return Route Created",
      bgColor: "#fe9901",
      num: data?.[11] || 0,
      color: "#fff",
    },
    {
      label: "Return to Pharmacy",
      bgColor: "#fb1001",
      num: data?.[12] || 0,
      color: "#fff",
    },
  ];

  useEffect(() => {
    console.log("label", labelShow)
    setActiveButton(labelShow);
  }, [labelShow])

  const filteredTabData = canceled
    ? tabData.slice(-4) // Show the last three tabs if `canceled` is true
    : tabData.slice(0, 6); // Show the first six tabs otherwise

  // Handle tab click
  const handleTabClick = (event, index) => {
    const selectedTab = filteredTabData[index];
    setActiveButton(selectedTab.label);
    onSubTabClick && onSubTabClick(selectedTab); // Notify parent with tab data
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mt: -2,
        mb: 0.5,
        position: "relative",
      }}
    >
      <Tabs
        aria-label="tabs"
        onChange={handleTabClick} 
        sx={{
          "& .MuiTabs-flexContainer": {
            gap: 2,
          },
          "& .MuiTab-root": {
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            minWidth: "auto",
            fontSize: "13px",
            lineHeight: "22.62px",
            padding: 0,
            paddingBottom: 1,
          },
        }}
      >
        {filteredTabData.map((tab, index) => (
          <Tab
            key={index}
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: activeButton === tab.label ? "#000" : "#434242",
                  backgroundColor: "transparent",

                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "3px",
                    backgroundColor:
                      activeButton === tab.label ? "black" : "#f2f3f5",
                    zIndex: activeButton === tab.label ? 3 : 2,
                  },
                }}
              >
                {tab.label}
                <Box
                  sx={{
                    ml: 1,
                    backgroundColor: tab.bgColor,
                    color: tab.color,
                    borderRadius: "25%",
                    padding: "2px 6px",
                    fontSize: "12.34px",
                    fontWeight: "bold",
                  }}
                >
                  {tab.num}
                </Box>
              </Box>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default SubTabData;
