import React, { useEffect, useState, useRef } from "react";
import H from "@here/maps-api-for-javascript";
import axios from "axios";
import { FaHome } from "react-icons/fa";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Popover,
  TextareaAutosize,
} from "@mui/material";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { useOutletContext } from "react-router-dom";
import { CSS } from "@dnd-kit/utilities";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import dayjs from "dayjs";

import RouteDetails from "../../components/routePageCompo/subPages/openPage/RouteDetails";
import {
  CrossIcon,
  HomeRouteIcon,
  PencilIcon,
  ReOptimizeIcon,
  SearchIcon,
  SettingsDarkIcon,
  TrashIcon,
} from "../../components/Icons";
import { ContainedButtonWithIcon } from "../../components/buttonCompo/Buttons";
import { useTheme } from "@mui/material";
import DefaultRouteSetting from "./DefaultRouteSetting";
import {
  deleteApiWithAuth,
  getApiWithAuth,
  patchApiWithAuth,
  postApiWithAuth,
  putApiWithAuth,
} from "../../utils/api";
import {
  ALL_ROUTES,
  AUTH_ME,
  CREATE_ORDER,
  ORDER_FAILED,
  REOPTIMIZED_ROUTE,
  ROUTE_DETAIL,
  SPLIT_ROUTE,
} from "../../utils/apiUrl";
import "./routeDetail.css";
import ConfirmationDialog from "../../components/Ui/ConfirmationDialog";
import CustomDropdown from "../../components/CustomDropdown";

function floatToTime(floatValue) {
  const hours = Math.floor(floatValue / 60);
  const remainingMinutes = floatValue % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes % 1) * 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
  }

  return parts.join(" ");
}

function modifyDateTime(dateTimeString, addedMinutes) {
  const dateTime = new Date(dateTimeString);
  dateTime.setMinutes(dateTime.getMinutes() + Math.floor(addedMinutes));
  return dateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

const DraggableItem = ({
  id,
  order,
  selectedOrder,
  handleOrderSelect,
  children,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 999 : "auto",
    backgroundColor: isDragging ? "#e0e0e0" : "white",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`routeOrderObject hover:bg-slate-300 ${
        selectedOrder === order.id ? "bg-slate-300" : ""
      }`}
      onClick={() => handleOrderSelect(order, order.number)}
    >
      {children}
    </div>
  );
};

const OpenRoute = ({ editable = false }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { rid, copay } = useParams();
  const defaultRouteCount = 10;
  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const ui = useRef(null);
  const apikey = process.env.REACT_APP_HERE_API;
  const { isSidebarCollapsed } = useOutletContext();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reOrdering, setReOrdering] = useState(false);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [mergeModal, setMergeModal] = useState(false);
  const [routeAddressModalOpen, setRouteAddressModalOpen] = useState(false);
  const [routeOrderId, setRouteOrderId] = useState(0);
  const [order, setOrder] = useState(0);
  const [orderId, setOrderId] = useState(0);

  const [modalDeliveryNote, setModalDeliveryNote] = useState(false);
  const [deliveryNote, setDeliveryNote] = useState("");
  const [orderType, setOrderType] = useState("");
  const [failReason, setFailReason] = useState("");
  const [address, setAddress] = useState("");

  const [route, setRoute] = useState(null);
  const [routes, setRoutes] = useState();
  const [results, setResults] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [routeOrders, setRouteOrders] = useState([]);
  const [userPosition, setUserPosition] = useState([]);
  const [allRouteOrders, setAllRouteOrders] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedRouteIds, setSelectedRouteIds] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentBubble, setCurrentBubble] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [items, setItems] = useState([]);

  const [estimatedTime, setEstimatedTime] = useState();
  const [distanceTotal, setDistanceTotal] = useState();

  const [mapType, setMapType] = useState("StorePolyline");
  const [optimizeRoute, setOptimizeRoute] = useState(false);
  const [optimizeRouteDone, setOptimizeRouteDone] = useState(false);
  const [isManualOptimize, setIsManualOptimize] = useState(false);
  const [routeSettings, setRouteSettings] = useState({
    id: "",
    optimizeFor: "DISTANCE",
    routeOption: "endAnywhere",
    clusterOption: "none",
    traffic: "enabled",
    driving_distance_value: 800,
    extraMinutes: 5,
    sortOrder: "ascending",
    departureDateTime: new dayjs(),
  });
  const [formState, setFormState] = useState({
    address: "",
  });

  useEffect(() => {
    if (map.current) {
      setTimeout(() => {
        map.current.getViewPort().resize();
      }, 300);
    }
  }, [isSidebarCollapsed]);

  // Popover handlers for settings
  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const isPopoverOpen = Boolean(anchorEl);

  const getRouteDetail = async () => {
    setLoading(true);

    const response = await getApiWithAuth(ROUTE_DETAIL + `?route=${rid}`);
    if (response.data !== undefined) {
      if (copay && copay === "copay") {
        const responseCopay = await getApiWithAuth(
          ALL_ROUTES + `getCopayOrders/?route_id=${rid}`
        );
        setAllRouteOrders(responseCopay.data.data);
      } else {
        setAllRouteOrders(response.data.data.orders);
      }
      setRouteOrders(response.data.data.orders);
      setRoutes(response.data.data.route);
      if (mapType !== "LiveMap") {
        setRouteSettings({
          id: response.data.data.routeSettings.id,
          optimizeFor: response.data.data.routeSettings.optimizeFor,
          routeOption: response.data.data.routeSettings.routeOption,
          clusterOption: response.data.data.routeSettings.clusterOption,
          traffic: response.data.data.routeSettings.traffic,
          driving_distance_value:
            response.data.data.routeSettings.driving_distance_value,
          extraMinutes: response.data.data.routeSettings.extraMinutes,
          departureDateTime: new dayjs(
            response.data.data.routeSettings.departureDateTime
          ),
        });
      }
      setEstimatedTime(response.data.data.route.estimatedTimeCal);
      setDistanceTotal(response.data.data.route.distanceCal);
      setUserPosition({
        lat: response.data.data.route.latitude,
        lng: response.data.data.route.longitude,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getRouteList = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ALL_ROUTES + `?routeStatus=Ready`);
    if (response.data !== undefined) {
      setRouteList(response.data.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchAuthUserDetail = async () => {
    const response = await getApiWithAuth(AUTH_ME);
    if (response && response.data) {
      setUserDetail(response.data.data.data);
    }
  };

  useEffect(() => {
    fetchAuthUserDetail();
  }, []);

  useEffect(() => {
    if (!map.current && routeOrders?.length > 0) {
      platform.current = new H.service.Platform({ apikey });
      const defaultLayers = platform.current.createDefaultLayers({
        pois: true,
      });

      map.current = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
        zoom: 14,
        center: userPosition,
      });

      ui.current = H.ui.UI.createDefault(map.current, defaultLayers);
      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(map.current)
      );
    }
    if (routeOrders?.length > 0) {
      if (mapType === "LiveMap" && !reOrdering && optimizeRoute) {
        setLoading(true);
        setOptimizeRoute(false);
        findAndDisplayRoute(
          platform.current,
          map.current,
          userPosition,
          routeOrders
        );
      } else if (mapType === "StorePolyline") {
        routeResponseHandler(map.current, routeOrders, null);
      }
    }

    if (routeOrders?.length < 1) {
      getRouteDetail();
    }
  }, [apikey, routeOrders, optimizeRoute]);

  useEffect(() => {
    setItems(allRouteOrders.filter((order) => order.isActive));
  }, [allRouteOrders]);

  useEffect(() => {
    getRouteList();
  }, []);

  const handleRouteSelection = (routeId) => {
    if (selectedRouteIds.includes(routeId)) {
      setSelectedRouteIds(selectedRouteIds.filter((id) => id !== routeId));
    } else {
      setSelectedRouteIds([...selectedRouteIds, routeId]);
    }
  };

  const handleDeleteRouteOrder = async () => {
    setLoading(true);
    try {
      for (const id of selectedRouteIds) {
        const response = await deleteApiWithAuth(ROUTE_DETAIL + `${id}/`);
        if (response.data !== undefined) {
          setRouteOrders([]);
          setOpenDeleteModal(false);
          toast.success("Route Order Deleted Successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } catch (error) {
      setOpenDeleteModal(false);
    } finally {
      setOpenDeleteModal(false);
      navigate(`/routes/open-route/${rid}`);
      setLoading(false);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      const newItems = arrayMove(items, oldIndex, newIndex).map(
        (item, index) => ({
          ...item,
        })
      );

      setItems(newItems);
      setAllRouteOrders(newItems);
      setRouteOrders(newItems);
    }
  };

  const handleSettingsChange = (field, value) => {
    setRouteSettings((prevSettings) => ({
      ...prevSettings,
      [field]: value,
    }));
    if (field === "optimizeFor" && value === "MANUAL") {
      setIsManualOptimize(true);
      setAnchorEl(null);
    } else {
      setIsManualOptimize(false);
    }
  };

  const handleOptimizeChanges = (e) => {
    if (routeSettings.optimizeFor === "MANUAL") {
      setOptimizeRoute(true);
      setOptimizeRouteDone(true);
      setMarkers([]);
      setReOrdering(false);
    } else {
      setOptimizeRoute(true);
      setOptimizeRouteDone(true);
      setMarkers([]);
      setRoute();
      setRouteOrders([]);
      setAllRouteOrders([]);
      setReOrdering(false);
    }
  };

  const handleOptimizedRoute = async () => {
    setLoading(true);
    let totalDistance = 0;
    let totalDuration = 0;
    for (const routeOrder of routeOrders) {
      const {
        id: routeOrderId,
        number,
        polyline,
        duration,
        distance,
      } = routeOrder;
      totalDuration += duration;
      // Accumulate total distance
      totalDistance += distance;
      // Prepare formData
      const formData = {
        number: number,
        polyline: polyline,
        distanceCal: distance,
        estimatedTimeCal: totalDuration,
        routeTrip: routeSettings?.routeOption,
      };

      // Make API call
      try {
        const response = await patchApiWithAuth(
          ROUTE_DETAIL + `${routeOrderId}/`,
          formData
        );
      } catch (error) {
        console.error("API call failed:", error);
      }
    }
    const modifiedTotalDuration = totalDuration;
    const formData = {
      distanceCal: totalDistance,
      estimatedTimeCal: modifiedTotalDuration,
    };
    try {
      const response = await patchApiWithAuth(
        ALL_ROUTES + `${routes.id}/`,
        formData
      );
      if (response.status !== 400) {
        console.log("SAve Route Info API call successful:");
      } else {
        toast.error("Backend Error!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("API call failed:", error);
    }
    const routeSpecificSettingsResponse = await patchApiWithAuth(
      `/api/route-settings/${routeSettings.id}/`,
      routeSettings
    );

    if (routeSpecificSettingsResponse.status !== 400) {
      toast.success("Route Specific Settings Updated Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Failed to Update Route Specific Settings!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setOptimizeRoute(false);
    setOptimizeRouteDone(false);
    setIsManualOptimize(false);
    setLoading(false);
  };

  const handleMapTypeChange = (e) => {
    setMapType(e.target.value);
    if (e.target.value === "StorePolyline") {
      setRoute();
      setRouteOrders([]);
      setMarkers([]);
      setAllRouteOrders([]);
      setReOrdering(false);
    }
  };

  const handleOrderSelect = (order, index) => {
    if (order.id === selectedOrder) {
      setSelectedOrder(null);
      ui.current.removeBubble(currentBubble);
      setCurrentBubble(null);
    } else {
      setSelectedOrder(order.id);

      const infoContent = `
        <div style="width: 400px; font-size: 12px;">
          <div>
            <p>
              <span style="font-weight: 700;">Order Id: </span>
              ${order.order.slug}
            </p>
            <p>
              <span style="font-weight: 700;">Name: </span>
              ${order.order.name}
            </p>
            <p>
              <span style="font-weight: 700;">Address: </span>
              ${order.order.address}
            </p>
            ${
              routes?.routeStatus === "In Progress"
                ? `<p>
                <span style="font-weight: 700;">
                  Planned Arrival Time: 
                </span>
                ${modifyDateTime(routes?.startTime, order.estimatedTimeCal)}
              </p>`
                : routes?.routeStatus === "Done"
                ? `<div>
                <p>
                  <span style="font-weight: 700;">
                    Planned Arrival Time: 
                  </span>
                  ${modifyDateTime(routes?.startTime, order.estimatedTimeCal)}
                </p>
                <p>
                  <span style="font-weight: 700;">
                    Delivery Time: 
                  </span>
                  ${moment(order.order.deliverAt).format("hh:mm A")}
                </p>
              </div>`
                : `<p>
                <span style="font-weight: 700;">
                  Planned Arrival Time: 
                </span>
                ${floatToTime(order.estimatedTimeCal)} (after start)
              </p>`
            }
          </div>
        </div>
      `;
      // Find the marker associated with the selected order
      const selectedMarker = markers.find((marker, i) => i === index);

      if (currentBubble) {
        ui.current.removeBubble(currentBubble);
        setCurrentBubble(null);
      }

      if (selectedMarker) {
        // map.current.setCenter({lat:parseFloat(order.order.latitude), lng:parseFloat(order.order.longitude)});
        // map.current.setZoom(14);
        showInfoBubble(selectedMarker, infoContent);
      }
    }
  };

  const handleSearch = (e) => {
    const filteredOrders = routeOrders.filter((order) =>
      order.order.slug.toLowerCase().includes(e.toLowerCase())
    );
    setAllRouteOrders(filteredOrders);
  };

  async function findAndDisplayRoute(platform, map, start, orderList) {
    let waypoints;

    if (routeSettings.optimizeFor === "MANUAL") {
      // In manual mode, construct waypoints manually
      waypoints = [
        { lat: parseFloat(start.lat), lng: parseFloat(start.lng) }, // Start location
        ...orderList.map((order) => ({
          lat: parseFloat(order.order.latitude),
          lng: parseFloat(order.order.longitude),
        })),
      ];

      // If routeSettings.routeOption is "startToEnd", we need to end at the start location
      if (routeSettings?.routeOption === "startToEnd") {
        waypoints.push({
          lat: parseFloat(start.lat),
          lng: parseFloat(start.lng),
        }); // Add start location at the end
      }
    } else {
      const destination = orderList[orderList.length - 1];
      const bestRoute = await findBestRouteSequence(
        apikey,
        start,
        orderList,
        destination
      );

      if (bestRoute) {
        waypoints = bestRoute[0].waypoints;

        // Apply sortOrder
        waypoints =
          routeSettings.sortOrder === "ascending"
            ? waypoints
            : [waypoints[0], ...waypoints.slice(1).reverse()];
      } else {
        // Handle case when bestRoute is not found
        console.error("No best route found");
        return;
      }
    }

    const allSections = [];

    const lastIndex = waypoints.length - 1;

    for (let i = 0; i < lastIndex; i++) {
      const startPoint = waypoints[i];
      const endPoint = waypoints[i + 1];

      const routeDetails = await fetchRouteDetails(
        apikey,
        startPoint,
        endPoint
      );

      if (
        routeDetails &&
        routeDetails.routes[0] &&
        routeDetails.routes[0].sections
      ) {
        allSections.push(...routeDetails.routes[0].sections);
      }
    }

    // Once all sections are collected, display them on the map
    const reorderList = await reorderOrderList(routeOrders, allSections);
    if (reorderList) {
      routeResponseHandler(map, allSections, reorderList);
    }
  }

  async function reorderOrderList(orderList, sections) {
    const locationToOrder = orderList.reduce((acc, order) => {
      const latLngKey = `${parseFloat(order.order.latitude).toFixed(
        5
      )},${parseFloat(order.order.longitude).toFixed(5)}`;
      if (!acc[latLngKey]) {
        acc[latLngKey] = [];
      }
      acc[latLngKey].push(order);
      return acc;
    }, {});

    const newOrderList = [];
    const processedOrders = new Set();
    let time = 0.0;
    let distance = 0.0;
    sections.forEach((section, index) => {
      const arrivalLocation = section.arrival.place.originalLocation;
      const duration = section.summary.baseDuration;
      const sectionDistance = section.summary.length;
      const durationMinutes = parseInt(duration) / 60 + 5;
      const distanceMiles = parseInt(sectionDistance) / 1609.34;
      const latLngKey = `${arrivalLocation.lat.toFixed(
        5
      )},${arrivalLocation.lng.toFixed(5)}`;
      time += durationMinutes;
      distance += distanceMiles;
      if (latLngKey in locationToOrder) {
        locationToOrder[latLngKey].forEach((order, i) => {
          if (!processedOrders.has(order)) {
            processedOrders.add(order);
            order.number = index + i + 1;
            order.duration = durationMinutes;
            order.distance = distanceMiles;
            order.polyline = section.polyline; // Placeholder for the polyline URL
            newOrderList.push(order);
          }
        });
      }
    });

    setEstimatedTime(time);
    setDistanceTotal(distance);
    setRouteOrders(newOrderList);
    setAllRouteOrders(newOrderList);
    setReOrdering(true);
    setLoading(false);
    toast.success("Route Optimized Successfully!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    return newOrderList;
  }

  async function findBestRouteSequence(apikey, start, waypoints, end) {
    const baseUrl = "https://wps.hereapi.com/v8/findsequence2";
    // const departureTime = encodeURIComponent("2021-10-15T10:30:00+05:00");
    const departureTime = encodeURIComponent(
      moment(routeSettings?.departureDateTime).format("YYYY-MM-DDTHH:mm:ssZ")
    );

    const mode = `fastest;car;traffic:${
      routeSettings?.optimizeFor !== "MANUAL"
        ? routeSettings?.traffic
        : "disabled"
    }`;

    let url = `${baseUrl}?mode=${mode}&start=${start.lat},${
      start.lng
    }&apikey=${apikey}&departure=${departureTime}${
      routeSettings?.optimizeFor !== "MANUAL"
        ? `&improveFor=${routeSettings?.optimizeFor}`
        : ""
    }`;

    // Append clustering parameter
    if (routeSettings?.clusterOption === "topologySegment") {
      url += "&clustering=topologySegment";
    } else if (routeSettings?.clusterOption === "drivingDistance") {
      const distanceValue =
        parseInt(routeSettings?.driving_distance_value, 10) || 800; // Default to 800 if invalid
      url += `&clustering=drivingDistance:${distanceValue}`;
    }

    if (routeSettings?.routeOption === "startToEnd") {
      url += `&end=${start.lat},${start.lng}`;
    }
    waypoints.forEach((point, index) => {
      url += `&destination${index + 1}=${point.order.latitude},${
        point.order.longitude
      }`;
    });

    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.results;
    } catch (error) {
      console.error("Error finding best route sequence:", error);
    }
  }

  async function fetchRouteDetails(apikey, origin, destination) {
    const baseUrl = "https://router.hereapi.com/v8/routes";
    const url = `${baseUrl}?apikey=${apikey}&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&routingMode=fast&transportMode=car&return=polyline,summary`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching route details:", error);
    }
  }

  async function routeResponseHandler(map, response, reorderList) {
    const sections = response;
    if (reorderList === null) {
      async function fetchPolyline(url) {
        const response = await fetch(url);
        if (!response.ok) throw new Error("Failed to fetch polyline");
        const data = await response.text();
        return H.geo.LineString.fromFlexiblePolyline(data);
      }

      // Map over sections and convert polylines
      const promises = sections.map((section) => {
        return fetchPolyline(section.polyline);
      });

      const lineStrings = await Promise.all(promises);
      const multiLineString = new H.geo.MultiLineString(lineStrings);
      const bounds = multiLineString.getBoundingBox();
      const routePolyline = new H.map.Polyline(multiLineString, {
        style: { lineWidth: 5 },
      });
      map.removeObjects(map.getObjects());
      map.addObject(routePolyline);

      const startMarker = createMarker(
        {
          order: {
            latitude: routes?.latitude,
            longitude: routes?.longitude,
            route: routes,
          },
        },
        "#0b6699",
        "S",
        true
      );
      // const endMarker = createMarker(restaurantList[restaurantList.length - 1], 'green');

      let lastWaypoint = null;
      let lastIndex = 0;
      let indices = [];

      response?.forEach((waypoint, i) => {
        const currentLatLng = `${parseFloat(waypoint.order.latitude).toFixed(
          5
        )},${parseFloat(waypoint.order.longitude).toFixed(5)}`;

        if (lastWaypoint && currentLatLng === lastWaypoint) {
          indices.push(i + 1);
        } else {
          if (indices.length) {
            const markerLabel = indices.join(",");
            const waypointMarker = createMarker(
              response[lastIndex],
              "#FF7733",
              markerLabel,
              false,
              indices.length
            );
            if (waypointMarker !== null) {
              map.addObject(waypointMarker);
            }
          }
          indices = [i + 1];
          lastIndex = i;
        }
        lastWaypoint = currentLatLng;
      });

      if (indices.length) {
        const markerLabel = indices.join(",");
        const waypointMarker = createMarker(
          response[lastIndex],
          "#FF7733",
          markerLabel,
          false,
          indices.length
        );
        if (waypointMarker !== null) {
          map.addObject(waypointMarker);
        }
      }
      map.addObjects([startMarker]);
      map.getViewModel().setLookAtData({ bounds });
    } else {
      const lineStrings = sections.map((section) =>
        H.geo.LineString.fromFlexiblePolyline(section.polyline)
      );
      const multiLineString = new H.geo.MultiLineString(lineStrings);
      const bounds = multiLineString.getBoundingBox();

      const routePolyline = new H.map.Polyline(multiLineString, {
        style: { lineWidth: 5 },
      });
      map.removeObjects(map.getObjects());
      map.addObject(routePolyline);

      const startMarker = createMarker(
        {
          order: { latitude: routes?.latitude, longitude: routes?.longitude },
          route: routes,
        },
        "#0b6699",
        "S",
        true
      );
      // const endMarker = createMarker(restaurantList[restaurantList.length - 1], 'green');
      let lastWaypoint = null;
      let lastIndex = 0;
      let indices = [];

      reorderList?.forEach((waypoint, i) => {
        const currentLatLng = `${parseFloat(waypoint.order.latitude).toFixed(
          5
        )},${parseFloat(waypoint.order.longitude).toFixed(5)}`;

        if (lastWaypoint && currentLatLng === lastWaypoint) {
          indices.push(i + 1);
        } else {
          if (indices.length) {
            const markerLabel = indices.join(",");
            const waypointMarker = createMarker(
              reorderList[lastIndex],
              "#FF7733",
              markerLabel,
              false,
              indices.length
            );
            if (waypointMarker !== null) {
              map.addObject(waypointMarker);
            }
          }
          indices = [i + 1];
          lastIndex = i;
        }
        lastWaypoint = currentLatLng;
      });

      if (indices.length) {
        const markerLabel = indices.join(",");
        const waypointMarker = createMarker(
          reorderList[lastIndex],
          "#FF7733",
          markerLabel,
          false,
          indices.length
        );
        if (waypointMarker !== null) {
          map.addObject(waypointMarker);
        }
      }
      map.addObjects([startMarker]);
      map.getViewModel().setLookAtData({ bounds });
    }
  }

  function createMarker(coords, color, number, home, count = 1) {
    if (!home) {
      if (coords?.isActive === false && coords?.status === "failed") {
        return null;
      } else if (coords?.status === "failed") {
        color = "red";
      } else if (coords?.status === "delivered") {
        color = "green";
      } else {
        color = "#FF7733";
      }
    }
    const icon = getMarkerIcon(color, number);
    const marker = new H.map.Marker(
      { lat: coords.order.latitude, lng: coords.order.longitude },
      { icon }
    );
    const infoContent = `
    <div style="width: 400px; font-size: 12px;">
      ${
        home
          ? `<div>
          <p>
            <span style="font-weight: 700;">Address: </span>
            ${routes?.address}
          </p>
          ${
            routes?.routeStatus === "In Progress"
              ? `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${modifyDateTime(routes?.startTime, 0.0)}
            </p>`
              : `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              Not Started Yet
            </p>`
          }
        </div>`
          : `<div>
          <p>
            <span style="font-weight: 700;">Order Id: </span>
            ${coords.order.slug}
          </p>
          <p>
            <span style="font-weight: 700;">Name: </span>
            ${coords.order.name}
          </p>
          <p>
            <span style="font-weight: 700;">Address: </span>
            ${coords.order.address}
          </p>
          ${
            routes?.routeStatus === "In Progress"
              ? `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${modifyDateTime(routes?.startTime, coords.estimatedTimeCal)}
            </p>`
              : routes?.routeStatus === "Done"
              ? `<div>
              <p>
                <span style="font-weight: 700;">
                  Planned Arrival Time: 
                </span>
                ${modifyDateTime(routes?.startTime, coords.estimatedTimeCal)}
              </p>
              <p>
                <span style="font-weight: 700;">
                  Delivery Time: 
                </span>
                ${moment(coords.order.deliverAt).format("hh:mm A")}
              </p>
            </div>`
              : `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${floatToTime(coords.estimatedTimeCal)} (after start)
            </p>`
          }
        </div>`
      }
    </div>
    `;

    if (currentBubble) {
      ui.current.removeBubble(currentBubble);
      setCurrentBubble(null);
    }

    marker.addEventListener("tap", () => showInfoBubble(marker, infoContent));

    const markers = [];
    for (let i = 0; i < count; i++) {
      markers.push(marker);
    }

    setMarkers((prevMarkers) => [...prevMarkers, ...markers]);
    return marker;
  }

  function getMarkerIcon(color, number) {
    return new H.map.Icon(
      `<svg width="30" height="20" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="30" height="20" fill="${color}" stroke="${color}" stroke-width="2" />
        <path d="M189.5 350L111.441 257.726L267.559 257.726L189.5 350Z" fill="${color}"/>
        <text x="50%" y="50%" font-family="Arial" font-size="10" font-weight="bold" text-anchor="middle" alignment-baseline="middle" fill="white">${number}</text>
      </svg>`,
      { anchor: { x: 15, y: 15 } }
    );
  }

  function showInfoBubble(marker, text) {
    const bubble = new H.ui.InfoBubble(marker.getGeometry(), { content: text });
    ui.current.addBubble(bubble);
    setCurrentBubble(bubble);
  }

  const handleRouteOrderingModalOpen = async (number, id, orderid) => {
    setOrderModalOpen(true);
    setOrder(number);
    setOrderId(orderid);
    setRouteOrderId(id);
  };

  const handleRouteOrderingModalClose = () => {
    setOrderModalOpen(false);
    setOrder(0);
    setRouteOrderId(0);
    getRouteDetail();
  };

  const handleFailOrder = async (orderId, reason) => {
    try {
      setLoading(true);
      const payload = {
        reason: reason,
      };

      const response = await postApiWithAuth(
        `${ORDER_FAILED}${orderId}`,
        payload
      );

      if (response.status === 200) {
        // Success response
        toast.success("RouteOrder updated successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (response.status === 400) {
        // Check specific message returned from the backend
        const errorMessage =
          response.data.message || "Error updating RouteOrder!";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // Generic backend error for other status codes
        toast.error("An unexpected error occurred. Please try again.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      // Network or unexpected error
      console.error("An error occurred:", error);
      toast.error("An error occurred while updating the order.", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
      setOrderModalOpen(false);
      getRouteDetail();
    }
  };

  const handleSaveDeliveryNote = async () => {
    setLoading(true);
    const data = { deliveryNote: deliveryNote };
    const response = await patchApiWithAuth(
      CREATE_ORDER + `/${routeOrderId}`,
      data
    );
    if (response.status !== 400) {
      toast.success("Delivery Note Updated Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setModalDeliveryNote(false);
    setDeliveryNote("");
    getRouteDetail();
  };

  const handleSaveRouteOrdering = async () => {
    if (orderType === "changeOrder") {
      setLoading(true);
      const formData = {
        number: order,
      };
      const response = await patchApiWithAuth(
        ROUTE_DETAIL + `${routeOrderId}/update_order_number/`,
        formData
      );
      if (response.status !== 400) {
        toast.success("Route Address Updated Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Backend Error!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setOrderModalOpen(false);
      getRouteDetail();
    } else if (orderType === "failOrder") {
      handleFailOrder(routeOrderId, failReason); // Call fail order function
    }
  };

  const handleOpenMenu = (event, order) => {
    setAnchorEl(event.currentTarget);
    setRoute(order);
  };

  const handleUpdateDeliveryNote = (id, note) => {
    setRouteOrderId(id);
    setDeliveryNote(note);
    setModalDeliveryNote(true);
  };

  const handleDeliveryNoteClose = () => {
    setModalDeliveryNote(false);
  };

  const hanldeOpenDeleteConfirmModel = () => {
    setOpenDeleteModal(true);
  };

  const hanldeCloseDeleteConfirmModel = () => {
    setOpenDeleteModal(false);
  };

  const handleMergeModalClose = () => {
    setSelectedRoute(null);
    setMergeModal(false);
  };

  const handleMergeModalOpen = () => {
    setMergeModal(true);
  };

  const handleCustomSplitRouteOrder = async () => {
    setLoading(true);
    try {
      const response = await putApiWithAuth(SPLIT_ROUTE + `custom/${rid}/`, {
        routeOrderIds: selectedRouteIds,
      });
      if (response.data !== undefined) {
        setRouteOrders([]);
        toast.success("Route Orders Splitted Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error deleting routes:", error);
    } finally {
      const response = await getApiWithAuth(REOPTIMIZED_ROUTE + `${rid}/`);
      if (response.data !== undefined) {
        navigate(`/routes/open-route/${rid}`);
      } else {
        console.log("Failed");
      }
      setLoading(false);
    }
  };

  const handleUpdateMergeRoute = async () => {
    const formState = {
      movingTo: selectedRoute,
      routeOrders: selectedRouteIds,
    };
    const response = await patchApiWithAuth(
      ROUTE_DETAIL + `${rid}/change_route_order/`,
      formState
    );
    if (response.status !== 400) {
      toast.success("Route Orders Transfer Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setMergeModal(false);
    setLoading(false);
    setSelectedRoute(null);
    getRouteDetail();
  };

  const handlePlaceSelect = (place) => {
    setFormState((prevOrderDetail) => ({
      ...prevOrderDetail,
      address: place.title,
    }));
    setAddress(place.title);
    setResults([]);
  };

  const handleHereSearch = async (event) => {
    const { value } = event.target;
    setAddress(value);
    if (value.length > 2) {
      // To limit requests, only search if query length > 2
      const endpoint = `https://autosuggest.search.hereapi.com/v1/autosuggest?at=40.8301933,-73.8521865&in=countryCode:USA&lang=en-US&limit=5&q=${value}&apikey=${apikey}`;

      try {
        const response = await axios.get(endpoint);
        setResults(response.data.items || []);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setResults([]);
      }
    } else {
      setResults([]);
    }
  };

  const handleRouteAddressModalOpen = async (id, address) => {
    setRouteAddressModalOpen(true);
    setFormState({
      ...formState,
      address: routes?.address,
    });
    setAddress(routes?.address);
  };

  const handleRouteAddressModalClose = () => {
    setFormState({
      ...formState,
      address: "",
    });
    setRouteAddressModalOpen(false);
  };

  const handleSaveRouteAddress = async () => {
    setLoading(true);
    const response = await patchApiWithAuth(
      ALL_ROUTES + `${routes?.id}/update_address/`,
      formState
    );
    if (response.status !== 400) {
      toast.success("Route Address Updated Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setRouteAddressModalOpen(false);
    setFormState({
      address: "",
    });
    getRouteDetail();
    setLoading(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: 2,
          height: "calc(100vh - 100px)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: 1,
          }}
        >
          {!editable ? (
            <Box sx={{ display: "flex", gap: 3 }}>
              <TextField
                variant="outlined"
                autoComplete="off"
                placeholder="Search Order Slug"
                InputProps={{
                  sx: { fontSize: "11px" },
                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                sx={{
                  width: 350,
                  flex: 1,
                  backgroundColor: "#fff",
                  borderRadius: "15px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    "& fieldset": { border: "none" },
                  },
                }}
                onChange={(e) => handleSearch(e.target.value)}
                size="small"
              />
              <ContainedButtonWithIcon
                label="Edit Route"
                icon={<PencilIcon />}
                width="109px"
                height="37px"
                onClick={() => navigate(`/routes/edit-route/${rid}`)}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "14px" }}
              >
                Route Stops
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: theme.palette.custom.layoutBgColor,
                    cursor: "pointer",
                  }}
                  onClick={handleCustomSplitRouteOrder}
                >
                  Create New Route
                </Typography>
                <IconButton onClick={hanldeOpenDeleteConfirmModel}>
                  <TrashIcon />
                </IconButton>
                <IconButton onClick={handleMergeModalOpen}>
                  <ReOptimizeIcon fill={theme.palette.custom.layoutBgColor} />
                </IconButton>
                <IconButton
                  onClick={() => navigate(`/routes/open-route/${rid}`)}
                >
                  <CrossIcon />
                </IconButton>
              </Box>
            </Box>
          )}
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: theme.palette.custom.layoutBgColor,
                borderRadius: "20px",
                padding: "10px 20px",
                marginX: "10px",
                maxWidth: "100%",
                color: "white",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "-10px",
                  left: "-10px",
                  backgroundColor: theme.palette.custom.layoutBgColor,
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                <FaHome
                  style={{
                    color: theme.palette.custom.layoutFontColor,
                    fontSize: "14px",
                  }}
                />
              </Box>
              {/* Main Content */}
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    color: theme.palette.custom.layoutFontColor,
                  }}
                >
                  {routes?.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "5px",
                    mt: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      mt: "5px",
                    }}
                  >
                    <HomeRouteIcon
                      fill={theme.palette.custom.layoutFontColor}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: theme.palette.custom.layoutFontColor,
                      }}
                    >
                      {routes?.address}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() =>
                      handleRouteAddressModalOpen(rid, routes?.address)
                    }
                  >
                    <PencilIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                maxHeight: "calc(100vh - 255px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {!isManualOptimize ? (
                allRouteOrders !== undefined &&
                allRouteOrders.length > 0 &&
                allRouteOrders
                  .filter((order) => order.isActive)
                  .map((orderDetail, index) => (
                    <RouteDetails
                      key={index}
                      editable={editable}
                      checked={selectedRouteIds}
                      onCheckboxChange={handleRouteSelection}
                      orderDetail={orderDetail}
                      index={index}
                      handleOrderSelect={handleOrderSelect}
                      selectedOrder={selectedOrder}
                      handleRouteOrderingModalOpen={
                        handleRouteOrderingModalOpen
                      }
                      handleUpdateDeliveryNote={handleUpdateDeliveryNote}
                      userDetail={userDetail}
                    />
                  ))
              ) : (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext items={items} strategy={rectSortingStrategy}>
                    {items.map((orderDetail, index) => (
                      <DraggableItem
                        key={orderDetail.id}
                        id={orderDetail.id}
                        order={orderDetail}
                        selectedOrder={selectedOrder}
                        handleOrderSelect={handleOrderSelect}
                      >
                        <RouteDetails
                          key={index}
                          editable={editable}
                          checked={selectedRouteIds}
                          onCheckboxChange={handleRouteSelection}
                          orderDetail={orderDetail}
                          index={index}
                          handleOrderSelect={handleOrderSelect}
                          selectedOrder={selectedOrder}
                          handleRouteOrderingModalOpen={
                            handleRouteOrderingModalOpen
                          }
                          handleUpdateDeliveryNote={handleUpdateDeliveryNote}
                          userDetail={userDetail}
                        />
                      </DraggableItem>
                    ))}
                  </SortableContext>
                </DndContext>
              )}
            </Box>
          </>
        </Box>

        <Box
          sx={{
            flex: 4,
            position: "relative",
            height: "calc(100vh - 100px)",
          }}
        >
          <Box sx={{ position: "absolute", top: 0, right: 0, zIndex: 100 }}>
            <IconButton onClick={handleSettingsClick}>
              <SettingsDarkIcon />
            </IconButton>
          </Box>
          <div
            style={{ width: "100%", height: "100%", position: "relative" }}
            ref={mapRef}
          />
        </Box>
      </Box>

      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box p={2}>
          <DefaultRouteSetting
            onClose={handleClosePopover}
            formData={routeSettings}
            onChange={handleSettingsChange}
            handleSaveSettings={handleOptimizedRoute}
            mapType={mapType}
            handleMapTypeChange={handleMapTypeChange}
            handleOptimizeChanges={handleOptimizeChanges}
            optimizeRouteDone={optimizeRouteDone}
          />
        </Box>
      </Popover>

      <Dialog
        open={orderModalOpen}
        onClose={handleRouteOrderingModalClose}
        sx={{ borderRadius: "8px" }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ fontSize: "18px" }}>
          Update Route Ordering
        </DialogTitle>
        <DialogContent>
          <Box sx={{ marginBottom: "16px" }}>
            <label
              className="pharmacy-field-label"
              style={{ display: "block", marginBottom: "8px" }}
            >
              Order Type
            </label>
            <select
              className="modal-field"
              name="orderType"
              value={orderType}
              onChange={(e) => {
                setOrderType(e.target.value);
                setOrder("");
                setFailReason(""); // Reset fail reason when changing type
              }}
              style={{
                width: "100%", // Increased width to span full width
                padding: "8px", // Added padding for better usability
                borderRadius: "4px",
              }}
            >
              <option value="">Select Order Type</option>
              <option value="changeOrder">Change Order</option>
              <option value="failOrder">Fail Order</option>
            </select>
          </Box>

          {/* Display fields based on the selected order type */}
          {orderType === "changeOrder" && (
            <Box sx={{ marginBottom: "16px" }}>
              <label
                className="pharmacy-field-label"
                style={{ display: "block", marginBottom: "8px" }}
              >
                Order
              </label>
              <select
                className="modal-field"
                name="number"
                placeholder="Order"
                value={order}
                onChange={(e) => setOrder(e.target.value)}
                style={{
                  width: "100%", // Increased width to match the style
                  padding: "8px", // Added padding for consistency
                  borderRadius: "4px",
                }}
              >
                <option value="">Select Order</option>
                {Array.from({ length: routeOrders.length }, (_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </Box>
          )}

          {orderType === "failOrder" && (
            <Box sx={{ marginBottom: "16px" }}>
              <label
                className="pharmacy-field-label"
                style={{ display: "block", marginBottom: "8px" }}
              >
                Fail Reason
              </label>
              <input
                type="text"
                autoComplete="off"
                className="modal-field"
                value={failReason}
                onChange={(e) => setFailReason(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "4px",
                }}
              />
            </Box>
          )}
          <DialogActions
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "10px",
              paddingX: "0px",
            }}
          >
            <Button
              onClick={handleRouteOrderingModalClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveRouteOrdering}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.custom.layoutBgColor,
                color: theme.palette.custom.layoutFontColor,
              }}
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDeliveryNote}
        onClose={handleDeliveryNoteClose}
        aria-labelledby="confirmation-dialog-title"
        PaperProps={{
          style: {
            width: "400px",
            height: "250px",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Typography variant="h6" sx={{ fontSize: "18px" }}>
            Delivery Notes
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextareaAutosize
            fullWidth
            variant="outlined"
            minRows={5}
            size="large"
            name="delivery_note"
            value={deliveryNote}
            onChange={(e) => setDeliveryNote(e.target.value)}
            style={{
              width: "100%",
              fontSize: "12px",
              padding: "5px",
              "& .MuiInputBase-root": {
                fontSize: "12px",
              },
            }}
            placeholder="Delivery Note"
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginX: "25px",
            marginY: "15px",
            paddingX: "0px",
          }}
        >
          <Button
            onClick={handleDeliveryNoteClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveDeliveryNote}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={mergeModal}
        onClose={handleMergeModalClose}
        aria-labelledby="confirmation-dialog-title"
        PaperProps={{
          style: {
            width: "400px",
            height: "350px",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Typography variant="h6">Move to Other Route</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">Route List</Typography>
          <CustomDropdown
            customstyles={{
              width: "100%",
            }}
            label="Select Route"
            value={selectedRoute}
            onChange={(selectedValue) => {
              setSelectedRoute(selectedValue);
            }}
            options={routeList.map((route) => ({
              value: route.id,
              label: route.name,
            }))}
            iconSrc="/icons/Dropdown.svg"
            isMultiSelect={false}
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginX: "30px",
            marginY: "15px",
          }}
        >
          <Button
            onClick={handleMergeModalClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateMergeRoute}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={routeAddressModalOpen}
        onClose={handleRouteAddressModalClose}
        aria-labelledby="confirmation-dialog-title"
        PaperProps={{
          style: {
            width: "400px",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Typography variant="h6" sx={{ fontSize: "18px" }}>
            Update Route Address
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextareaAutosize
            fullWidth
            variant="outlined"
            size="large"
            name="address"
            value={address}
            onChange={handleHereSearch}
            style={{
              width: "100%",
              fontSize: "12px",
              padding: "5px",
              "& .MuiInputBase-root": {
                fontSize: "12px",
              },
            }}
            placeholder="Address"
          />
          {results.length > 0 && (
            <ul
              style={{
                listStyle: "none",
                margin: 0,
                padding: 0,
                border: "1px solid black",
                borderRadius: "7px",
              }}
            >
              {results.map((item, index) => (
                <li
                  key={index}
                  style={{
                    borderBottom: "1px solid black",
                    cursor: "pointer",
                    listStyle: "none",
                  }}
                  onClick={() => handlePlaceSelect(item)}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginX: "15px",
            marginY: "15px",
          }}
        >
          <Button
            onClick={handleRouteAddressModalClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveRouteAddress}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Modal for Deleting Selected Routes */}
      <ConfirmationDialog
        open={openDeleteModal}
        title="Delete Route"
        description={`Are you sure you want to delete selected Route Orders from this route?`}
        onConfirm={handleDeleteRouteOrder}
        onCancel={hanldeCloseDeleteConfirmModel}
      />
    </Box>
  );
};

export default OpenRoute;
