import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Box,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  getApiWithAuth,
  patchApiWithAuth,
  putApiWithAuth,
} from "../../utils/api";
import {
  ORDER_Filter,
  ALL_PHARMACY_LIST,
  MARK_READ,
  ALL_ORDERS,
  ALL_USERS,
  MARK_READ_CANCEL,
} from "../../utils/apiUrl";
import { ContainedButtonWithIcon } from "../buttonCompo/Buttons";
import { AddIcon, SearchIcon } from "../Icons";
import CustomDropdown from "../CustomDropdown";
import SingleFieldDateRangePicker from "../dateRangePicker/DateRangePicker";

const Topbar = ({ cancelationReason, onOrdersUpdate, fetchOrdersRef }) => {
  const navigate = useNavigate();
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const [pharmacy, setPharmacy] = useState([]);
  const [search, setSearch] = useState("");
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000), // 2 days ago
    new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000), // 3 days ahead
  ]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5000);
  const [orders1, setOrders1] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };
  const userType = localStorage.getItem("userType");
  const fetchPharmacies = async () => {
    try {
      const response = await getApiWithAuth(ALL_PHARMACY_LIST, {
        justList: true,
      });
      if (response.data !== undefined) {
        setUniquePharmacy(
          response.data.data.map((item) => ({ id: item.id, name: item.name }))
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (userType !== "PharmacyOwner" || userType !== "PharmacyEmployee") {
      fetchPharmacies();
  }}, [userType]);
  useEffect(() => {
    if (userType === "PharmacyOwner" || userType === "PharmacyEmployee") {
    const pharmacyId = localStorage.getItem('pharmacyId')
    setPharmacy(pharmacyId)
  }}, [userType])
  const getOrders = async () => {
    setLoading(true);
    const validEndDate =
      dateRange[1] && !isNaN(dateRange[1].getTime())
        ? dateRange[1]
        : new Date();
    if (!cancelationReason) {
      const response1 = await getApiWithAuth(
        ORDER_Filter +
          `?pageIndex=${pageIndex}&pageSize=${pageSize}&pharmacy=${pharmacy}&search=${search}&from=${moment(
            dateRange[0]
          ).format("MM-DD-YYYY")}&to=${moment(validEndDate).format(
            "MM-DD-YYYY"
          )}`
      );
      console.log("response1", response1.data.data.data);
      onOrdersUpdate(response1.data.data.data);
    } else {
      // Fetch orders with cancellation reason
      console.log("work");
      const response = await getApiWithAuth(
        ALL_ORDERS +
          `?status=6&pageIndex=${pageIndex}&pageSize=${pageSize}&pharmacy=${pharmacy}&search=${search}&from=${moment(
            dateRange[0]
          ).format("MM-DD-YYYY")}&to=${moment(validEndDate).format(
            "MM-DD-YYYY"
          )}`
      );

      if (response.data?.data?.data) {
        onOrdersUpdate(response.data.data.data);
      }

      const UID = localStorage.getItem("UID");
      const updatedPersonalDetail = new FormData();
      updatedPersonalDetail.append("user_id", UID);
      updatedPersonalDetail.append("action", "last_view");

      await putApiWithAuth(ALL_USERS + `all`, updatedPersonalDetail);
    }

    setLoading(false);
  };

  useImperativeHandle(fetchOrdersRef, () => getOrders);
  useEffect(() => {
    getOrders();
  }, [search, pharmacy, dateRange]);

  // Handle dropdown change
  const handlePharmacyChange = (selectedOption) => {
    if (selectedOption) {
      setPharmacy([selectedOption]);
    } else {
      setPharmacy([]);
    }
  };
  const options = uniquePharmacy
    .filter((pharm) => pharm && pharm.id && pharm.name)
    .map((pharm) => ({
      value: pharm.id,
      label: pharm.name,
    }));
  const handleMarkAllAsRead = async () => {
    setLoading(true);
    let response;

    try {
      if (!cancelationReason) {
        // Mark all as read for regular orders
        if (userType === "PharmacyEmployee" || userType === "PharmacyOwner") {
          response = await patchApiWithAuth(MARK_READ, {
            isPharmacyRead: true,
          });
        } else {
          response = await patchApiWithAuth(MARK_READ, { isAdminRead: true });
        }
      } else {
        // Mark all as read for cancellation reasons
        if (userType === "PharmacyEmployee" || userType === "PharmacyOwner") {
          response = await patchApiWithAuth(MARK_READ_CANCEL, {
            cancelnotePharmacyRead: true,
          });
        } else {
          response = await patchApiWithAuth(MARK_READ_CANCEL, {
            cancelnoteAdminRead: true,
          });
        }
      }

      if (response?.data) {
        console.log(response);

        if (cancelationReason) {
          getOrders("6");
        } else {
          getOrders();
        }
      } else {
        console.error("Error in response:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      const searchTerm = inputValue ? inputValue.toLowerCase() : "";
      setSearch(searchTerm);
    }
  };
  const [isRead, setIsRead] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setIsRead(event.target.checked);
  };

  useEffect(() => {
    console.log("Pharmacy on load:", pharmacy);
  }, [pharmacy]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      {/* Top Section: Search, Dropdown */}
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <TextField
          variant="outlined"
          autoComplete="off"
          placeholder="Search"
          InputProps={{
            sx: {
              fontSize: "11px",
              border: "1px solid #dce0e4",
            },
            onKeyDown: handleKeyPress,
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={{
            width: 408,
            backgroundColor: "#fff",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              height: "36px",
              paddingLeft: 0,
              "& fieldset": {
                border: "none",
              },
            },
          }}
          size="small"
        />

        {/* CustomDropdown Component */}
        {userType !== "PharmacyOwner" && userType !== "PharmacyEmployee" && (
          <CustomDropdown
            customstyles={{
              height: "36px",
              width: "200px",
            }}
            label="Filter Pharmacy"
            value={pharmacy.length > 0 ? pharmacy[0] : null}
            onChange={handlePharmacyChange}
            options={options}
            isMultiSelect={false}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: 2,
        }}
      >
        <Box>
          <SingleFieldDateRangePicker
            value={dateRange}
            onChange={handleDateRangeChange}
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRead}
              onClick={() => handleMarkAllAsRead()}
              color="primary"
              sx={{
                width: "20px",
                height: "20px",
                "& .MuiSvgIcon-root": {
                  fontSize: "18px",
                  borderRadius: "16px",
                },
                "& .MuiCheckbox-root": {
                  borderRadius: "16px",
                },
              }}
            />
          }
          label="Mark all as Read"
          sx={{
            color: "#000",
            margin: 0,
            "& .MuiTypography-root": {
              fontSize: "12px",
              color: "#000",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default forwardRef(Topbar);
