import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Typography,
  Paper,
  IconButton,
  Modal,
  DialogActions,
  TextareaAutosize,
  useTheme,
  Checkbox,
} from "@mui/material";
import CallSharpIcon from "@mui/icons-material/CallSharp";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { getApiWithAuth, patchApiWithAuth } from "../../utils/api";
import {
  CREATE_CALL_NOTE,
  CREATE_ORDER,
  ORDER_MISSED_CALLS,
  UNKNOWN_CALL_ORDER_SET,
} from "../../utils/apiUrl";
import { toast } from "react-toastify";
import moment from "moment";
import VoiceComponent from "../TwilioCalls/TwilioCalls";
import ConfirmationDialog from "../Ui/ConfirmationDialog";
import { CallIcons, IncomingIcons, MissCallIcons, OutgoingIcons } from "../Icons";

const renderCallIcon = (call) => {
  if (call.type === "incoming") {
    return <IncomingIcons />;
  } else if (call.type === "outgoing") {
    return <OutgoingIcons />;
  }
  return <IncomingIcons />;
};

const getCallStatusColor = (status) => {
  if (status === "completed") {
    return "green"; // Green for completed
  } else if (status === "no-answer") {
    return "red"; // Red for no answer
  } else if (status === "busy") {
    return "#FFA500"; // Yellow for busy
  }
  return "inherit"; // Default color if no status matches
};

function formatDuration(durationInSeconds) {
  const totalSeconds = parseInt(durationInSeconds, 10);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(seconds).padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

const CallListView = ({ activeTab, dateRange }) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(null);
  const [callingOrder, setCallingOrder] = useState({});
  const [modalFacilityNoteOpen, setModalFacilityNoteOpen] = useState(false);

  const [callId, setCallId] = useState("");
  const [staffNote, setStaffNote] = useState("");
  const [orderSlug, setOrderSlug] = useState("");
  const [orderCheck, setOrderCheck] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");
  const [calls, setCalls] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [markConfirmModal, setmarkConfirmModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);

  const [hasMore, setHasMore] = useState(true);
  const [initiateCall, setInitiateCall] = useState(false);
  const [page, setPage] = useState(1);
  const callListRef = useRef();

  const handleModalFacilityNoteClose = () => {
    setModalFacilityNoteOpen(false);
    setStaffNote("");
    setCallId("");
  };

  const handleCreateFacilityNote = (call) => {
    setModalFacilityNoteOpen(true);
    setCallId(call.id);
    setStaffNote(call.staff_note);
  };

  const loadCalls = async (loadpage) => {
    if (loading) return;
    setLoading(true);
    const [startDate, endDate] = dateRange;
    if (!startDate || !endDate) return;

    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    try {
      const response = await getApiWithAuth(ORDER_MISSED_CALLS, {
        missed: activeTab === "missed" ? "true" : "false",
        list: "true",
        tab: activeTab,
        page: loadpage,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      });
      const newCalls = response.data.data.results;
      if (loadpage === 1) {
        setCalls(newCalls);
      } else {
        setCalls((prevCalls) => [...prevCalls, ...newCalls]);
      }
      setHasMore(response.data.next !== null);
      setLoading(false);
    } catch (error) {
      console.error("Error loading calls", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setCalls([]);
    setPage(1);
    loadCalls(1);
  }, [activeTab, dateRange]);

  const handleScroll = () => {
    if (callListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = callListRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
        loadCalls(page + 1);
      }
    }
  };

  const makeCall = (call) => {
    setInitiateCall(true);
    setCallingOrder({
      number: call.clientNumber,
      id: call?.order?.id,
      name: call?.order?.name,
    });
  };

  useEffect(() => {
    const currentRef = callListRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [callListRef, handleScroll]);

  const handleSaveCallNote = async () => {
    const formData = new FormData();
    if (staffNote !== "") {
      formData.append("staff_note", staffNote);
      const response = await patchApiWithAuth(
        CREATE_CALL_NOTE + `${callId}/update/`,
        formData
      );
      if (response.data !== undefined) {
        console.log(response.data);
        setModalFacilityNoteOpen(false);
        setCallId("");
        setStaffNote("");
        setError("");
      } else {
        setModalFacilityNoteOpen(false);
        setCallId("");
        setStaffNote("");
        setError("");
      }
    } else {
      setError("Add note for save");
    }
  };

  const handleSaveUnknownCall = async () => {
    setIsSaving(true);
    try {
      const response = await patchApiWithAuth(UNKNOWN_CALL_ORDER_SET, {
        twilioCallingId: orderCheck,
        orderSlug: orderSlug,
      });
      toast.success("Order saved against unknwon call successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setOrderCheck("");
      setIsSaving(false);
      setOrderSlug("");
      loadCalls(1);
    } catch (error) {
      toast.error("Failed to save the order. Please try again.", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setIsSaving(false);
      setIsModalOpen(false);
      loadCalls(1);
    }
  };

  const handlefetchOrderDetails = async () => {
    try {
      const response = await getApiWithAuth(CREATE_ORDER + `/${orderSlug}`);
      setOrderDetails(response.data.data);
      setIsModalOpen(true);
    } catch (error) {
      toast.error(
        "Failed to fetch order details. Please check the Order Slug.",
        {
          position: "top-right",
          autoClose: 1000,
          theme: "colored",
        }
      );
    }
  };

  const handleCancelSave = () => {
    setIsModalOpen(false);
    setOrderDetails(null);
  };

  const handleOpenMarkConfirmModel = (call) => {
    setmarkConfirmModal(true);
    setCallId(call.id);
  };

  const handleCloseMarkConfirmModel = () => {
    setmarkConfirmModal(false);
  };

  const handleMarkAsRead = async () => {
    const formData = new FormData();
    formData.append("attend", true);
    const response = await patchApiWithAuth(
      CREATE_CALL_NOTE + `${callId}/update/`,
      formData
    );
    if (response.data !== undefined) {
      setCalls([]);
      setPage(1);
      loadCalls(1);
      setmarkConfirmModal(false);
      setCallId("");
    } else {
      setCalls([]);
      setPage(1);
      loadCalls(1);
      setmarkConfirmModal(false);
      setCallId("");
    }
  };

  return (
    <Box>
      <div ref={callListRef}>
        {calls.map((item, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              padding: "8px",
              marginBottom: "10px",
              borderRadius: "10px",
              boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Left section with text data */}
            <Box
              sx={{ display: "flex", flexDirection: "column", flex: 3, gap: 1 }}
            >
              <Box sx={{ display: "flex" }}>
                {item?.order?.name && <Typography
                  sx={{ fontWeight: 600, fontSize: "12px", color: "#000", marginRight: 4 }}
                >
                  {item?.order?.name}
                </Typography>}
                {item?.order?.slug && <Typography
                  sx={{ fontWeight: 600, fontSize: "12px", color: "#000", marginRight: 4 }}
                >
                  Order ID: {item?.order?.slug}
                </Typography>}
                {item.missed && !item.attend && (
                  <Typography
                    sx={{ fontWeight: 700, fontSize: "12px", color: "red", marginRight: 4 }}
                  >
                    (Return Call Needed)
                  </Typography>
                )}
                {item.missed && !item.attend &&
                  <Typography
                    sx={{ fontWeight: 600, fontSize: "11px", color: "#0b6699", marginRight: 4 }}
                  >
                    Mark As Done
                    <Checkbox sx={{ width: "30px", height: "10px" }} onClick={() => handleOpenMarkConfirmModel(item)} />
                  </Typography>
                }
              </Box>
              <Typography
                variant="body2"
                sx={{
                  color: "#000",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                Client Number: {item.clientNumber} | Agent: {item.agent_name} |
                {moment(item.created_at).format("MMMM DD YYYY, h:mm:ss a")}
              </Typography>
            </Box>

            {/* Middle section with audio player */}
            {(activeTab === "all" ||
              activeTab === "received" ||
              activeTab === "dailed") && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  {item.recording_file && item.recording_file !== null && (
                    <div className="audio-player">
                      <audio controls>
                        <source src={item.recording_file} type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  )}
                </Box>
              )}

            {/* Right section with duration and call icon */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: activeTab === 0 ? "center" : "flex-end",
                flex: 0.5,
              }}
            >
              <Typography
                sx={{
                  color: getCallStatusColor(item.status),
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                {item.duration ? formatDuration(item.duration) : "00:00:00"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 0.3,
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {renderCallIcon(item)}
                <IconButton onClick={() => makeCall(item)}>
                  <CallIcons />
                </IconButton>
                {(activeTab === "all" ||
                  activeTab === "received" ||
                  activeTab === "dailed") && (
                    <IconButton onClick={() => handleCreateFacilityNote(item)}>
                      <img
                        src="icons/order/NotesBlack.svg"
                        alt="Notes"
                        style={{ width: "14px" }}
                      />
                    </IconButton>
                  )}
                {activeTab === "unknown" &&
                  (orderCheck !== item.id ? (
                    <Typography onClick={() => setOrderCheck(item.id)}>
                      Set Order
                    </Typography>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="text"
                        value={orderSlug}
                        onChange={(e) => setOrderSlug(e.target.value)}
                        placeholder="Enter order slug"
                        autoComplete="off"
                        style={{
                          padding: "5px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          width: "100px",
                        }}
                      />
                      <button
                        onClick={() => handlefetchOrderDetails()}
                        disabled={isSaving}
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        Search
                      </button>
                    </div>
                  ))}
              </Box>
            </Box>
          </Paper>
        ))}
      </div>
      {initiateCall && (
        <VoiceComponent
          orderCall={callingOrder}
          setSelectedOrder={setCallingOrder}
        />
      )}

      <Modal
        open={isModalOpen}
        onClose={handleCancelSave}
        aria-labelledby="order-details-modal"
        aria-describedby="modal-to-confirm-order-details"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="order-details-modal">Confirm Order Details</h2>
          {orderDetails ? (
            <div>
              <p>
                <strong>Name:</strong> {orderDetails?.name}
              </p>
              <p>
                <strong>Address:</strong> {orderDetails?.address}
              </p>
              <p>
                <strong>Order Slug:</strong> {orderDetails?.slug}
              </p>
              <p>
                <strong>Zipcode:</strong> {orderDetails?.zip}
              </p>
            </div>
          ) : (
            <p>Loading order details...</p>
          )}
          <div style={{ marginTop: "20px", textAlign: "right" }}>
            <Button onClick={handleCancelSave} style={{ marginRight: "10px" }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveUnknownCall}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Confirm"}
            </Button>
          </div>
        </Box>
      </Modal>

      <Dialog
        open={modalFacilityNoteOpen}
        onClose={handleModalFacilityNoteClose}
        aria-labelledby="confirmation-dialog-title"
        PaperProps={{
          style: {
            width: "400px",
            height: "250px",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Typography variant="h6">Staff Notes</Typography>
        </DialogTitle>

        <DialogContent>
          {error !== "" && <Typography variant="body1">{error}</Typography>}
          <TextareaAutosize
            fullWidth
            variant="outlined"
            minRows={5}
            size="large"
            name="staffnote"
            value={staffNote}
            onChange={(e) => setStaffNote(e.target.value)}
            style={{
              width: "100%",
              fontSize: "12px",
              padding: "5px",
              "& .MuiInputBase-root": {
                fontSize: "12px",
              },
            }}
            placeholder="Delivery Note"
          />
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginX: "30px",
            marginY: "15px",
          }}
        >
          <Button
            onClick={handleModalFacilityNoteClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveCallNote}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={markConfirmModal}
        title="Mark Attended"
        description={`Are You Sure to Mark Attended this Call?`}
        onConfirm={handleMarkAsRead}
        onCancel={handleCloseMarkConfirmModel}
      />
    </Box>
  );
};

export default CallListView;
