import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
import { EmojiEmotions } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import EmojiPicker from "emoji-picker-react";

const ChatComponent = ({ chatDetail, handleSendTwilioSms, text, setText }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleEmojiClick = (emojiObject) => {
    setText((prevMessage) => prevMessage + emojiObject.emoji);
    handleCloseEmojiPicker();
  };
  const handleEmojiIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseEmojiPicker = () => {
    setAnchorEl(null);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendTwilioSms();
    }
  };

  const isEmojiPickerOpen = Boolean(anchorEl);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "80vh",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ flex: 1, overflowY: "auto", padding: "16px" }}>
          {chatDetail
            .slice()
            .reverse()
            .map((msg) => (
              <Box
                key={msg.id}
                sx={{
                  display: "flex",
                  flexDirection:
                    msg.type === "outgoing" ? "row-reverse" : "row",
                  alignItems: "flex-start",
                  marginBottom: "16px",
                }}
              >
                {/* <Avatar
                  src={msg.type === "outgoing" ? "U" : "A"}
                  alt={msg.type === "outgoing" ? "U" : "A"}
                  sx={{
                    width: 40,
                    height: 40,
                    marginRight: msg.type === "outgoing" ? 0 : "8px",
                    marginLeft: msg.type === "outgoing" ? "8px" : 0,
                  }}
                /> */}
                {msg.type === "outgoing" ?
                  msg.agent_pic ?
                    <Avatar
                      src={msg.agent_pic}
                      alt="Profile Image"
                      sx={{
                        width: 38.71,
                        height: 37.66,
                        marginRight: msg.type === "outgoing" ? 0 : "8px",
                        marginLeft: msg.type === "outgoing" ? "8px" : 0,
                        cursor: "pointer",
                      }}
                    />
                    :
                    <Avatar sx={{ width: 40, height: 40 }}>
                      {msg.agent_name ? msg.agent_name.charAt(0) : 'A'}
                    </Avatar>
                  :
                  <Avatar sx={{ width: 40, height: 40 }}>
                    {msg.order?.name ? msg.order.name.charAt(0) : 'U'}
                  </Avatar>
                }
                <Box sx={{display: "flex", flexDirection: "column", alignItems: msg.type === "outgoing" ? "end" : "start"}}>
                  <Typography variant="caption" color="textSecondary" sx={{textAlign: 'right'}}>
                    {msg.type === "outgoing"
                      ? `${msg?.agent_name}` :`${msg?.order?.name} | ${msg?.order?.slug}`
                      }{" "}
                    • {msg.created_at.split("T")[0]}{" "}
                    {msg.created_at.split("T")[1].split(".")[0]}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor:
                        msg.type === "outgoing"
                          ? theme.palette.custom.layoutBgColor
                          : "#F5F5F5",
                      color:
                        msg.type === "outgoing"
                          ? theme.palette.custom.layoutFontColor
                          : "#000",
                      padding: "8px 12px",
                      borderRadius: "12px",
                      marginTop: "4px",
                      whiteSpace: "pre-wrap",
                      width: "650px",
                      fontSize: "14px"
                    }}
                  >
                    {msg.body}
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "8px",
            borderTop: "1px solid #ddd",
            gap: "8px",
          }}
        >
          <IconButton onClick={handleEmojiIconClick}>
            <EmojiEmotions />
          </IconButton>
          <Popover
            open={isEmojiPickerOpen}
            anchorEl={anchorEl}
            onClose={handleCloseEmojiPicker}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </Popover>
          <Box sx={{ position: "relative", flex: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              autoComplete="off"
              size="small"
              placeholder="Type a message"
              value={text}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </Box>
          <Button
            variant="contained"
            onClick={handleSendTwilioSms}
            sx={{
              color: theme.palette.custom.layoutFontColor,
              backgroundColor: theme.palette.custom.layoutBgColor,
              width: "160px",
              marginRight: "5%",
              paddingTop: "7.5px",
              paddingBottom: "7.5px",
              "&:hover": {
                backgroundColor: theme.palette.custom.layoutBgColor,
              },
            }}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatComponent;
