import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Topbar from "../components/addpharmacyPageCompo/Topbar";
import PharmaciesOverview from "../components/addpharmacyPageCompo/PharmaciesOverview";
import { getApiWithAuth } from "../utils/api";
import { ALL_PHARMACY_LIST } from "../utils/apiUrl";

const AddPharmacy = () => {
  const [pharmacies, setPharmacies] = useState([]);
  const [filteredPharmacies, setFilteredPharmacies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPharmacies = async () => {
      try {
        const response = await getApiWithAuth(ALL_PHARMACY_LIST);
        console.log("Full API Response:", response);

        if (response && Array.isArray(response.data.data)) {
          // Sort pharmacies by id in ascending order
          const sortedPharmacies = response.data.data.sort(
            (a, b) => a.id - b.id
          );
          setPharmacies(sortedPharmacies);
          setFilteredPharmacies(sortedPharmacies); // Initialize filtered list with sorted pharmacies
        } else {
          console.error("Unexpected data format:", response);
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching pharmacies:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchPharmacies();
  }, []);

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = pharmacies.filter((pharmacy) => {
      // Safely check if fields exist before accessing them
      const nameMatch = pharmacy.name?.toLowerCase().includes(lowerCaseQuery);
      const emailMatch = pharmacy.email?.toLowerCase().includes(lowerCaseQuery);
      const contactMatch = pharmacy.contact_no?.includes(query);

      return nameMatch || emailMatch || contactMatch;
    });

    // Sort filtered pharmacies by id in ascending order
    const sortedFilteredPharmacies = filtered.sort((a, b) => a.id - b.id);
    setFilteredPharmacies(sortedFilteredPharmacies);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error.message}</Typography>;
  }

  if (!Array.isArray(pharmacies)) {
    console.error("Pharmacies data is not an array:", pharmacies);
    return <Typography>Error: Unexpected data format</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Topbar onSearch={handleSearch} />
      {filteredPharmacies.map((pharmacy, index) => (
        <PharmaciesOverview
          key={pharmacy.id}
          pharmacy={pharmacy}
          index={index}
        />
      ))}
      {filteredPharmacies.length === 0 && (
        <Typography>No pharmacies match your search.</Typography>
      )}
    </Box>
  );
};

export default AddPharmacy;
