import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  Table,
  // TextField,
  // Button,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PrintDocList from "../../components/PDF/PrintDocList";
import { DownloadIcon, EyeIcon, PrinterIcon } from "../Icons";
import { useTheme } from "@mui/material";
import JSZip from "jszip";
import moment from "moment";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DRIVER_REPORT_COUNT } from "../../utils/apiUrl";
import { postApiWithAuth } from "../../utils/api";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@mui/styles";
import { pilldropLogo } from "../../assests";
const columns2 = [
  { id: "date", label: "Date", minWidth: 120 },
  { id: "shift1", label: "Routes", minWidth: 120 },
  { id: "shift5", label: "Stops", minWidth: 120 },
  { id: "shift2", label: "Delivered", minWidth: 120 },
  { id: "shift3", label: "Failed", minWidth: 120 },
  { id: "shift4", label: "Pending", minWidth: 120 },
  { id: "totalamount", label: "Delivered Amount", minWidth: 120 },
  { id: "amount", label: "Failed Amount", minWidth: 120 },
];

const useStyles = makeStyles(() => ({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    padding: 10,
    fontWeight: "700",
    flexGrow: 1,
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    marginTop: "0px",
    fontWeight: "700",
    textAlign: "center",
    border: "1px solid black",
    minWidth: "30%",
    padding: "0 10px",
  },
  header2: {
    fontSize: 18,
    marginBottom: 0,
    fontWeight: "700",
    textAlign: "center",
  },
  header3: {
    fontSize: 18,
    marginBottom: 0,
    marginTop: "0px",
    fontWeight: "400",
    textAlign: "center",
  },
  header4: {
    fontSize: 18,
    marginBottom: 8,
    marginTop: "0px",
    fontWeight: "400",
    textAlign: "center",
  },
  Total: {
    fontSize: 24,
    float: "right",
    marginBottom: 8,
    marginRight: 0,
    marginTop: "0px",
    fontWeight: "700",
    textAlign: "center",
  },
  Sums: {
    fontSize: 18,
    marginBottom: "1px",
    marginTop: "0px",
    fontWeight: "700",
  },
  table: {
    width: "100%",
    border: "1px solid black",
    marginBottom: 0,
    fontWeight: "700",
  },
  tableHeader: {
    // backgroundColor: '#f0f0f0',
    fontWeight: "900",
    borderBottomWidth: "2px",
  },
  tableRow: {},
  tableCell: {
    padding: 5,
    fontSize: 12,
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    fontWeight: "700",
  },
  firstColumn: {
    fontWeight: "700",
    // borderBottomWidth: 2,
  },
  firstRow: {
    fontWeight: "700",
    borderBottomWidth: "2px",
  },
}));
const DriverReport = ({ pharmaDetails }) => {
  const [invoice, setInvoice] = useState([]);
  const [signedOrders, setSignedOrders] = useState([]);
  const [loading, setLoading] = useState(null);
  const isExtraLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.up("lg")
  );
  const classes = useStyles();
  const componentRef = useRef();
  const createDate = new Date();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  let totalDeliveredAmount = 0;
  let totalFailedAmount = 0;
  function formatDateToMMDDYYYY(inputDate) {
    if (!inputDate) {
      return ""; // Return an empty string if the date is undefined or empty
    }
    const parts = inputDate.split("/");
    const day = parts[0].padStart(2, "0");
    const month = parts[1].padStart(2, "0");
    const year = parts[2];
    return `${month}/${day}/${year}`;
  }
  const fetchInvoice = async (dateRange, driver_id) => {
    const [startDate, endDate] = dateRange.split(" - ");
    const deliveryTime = new Date(startDate);
    const deliveryTimeFrom = new Date(endDate);
    const formData = {
      from_date: startDate,
      to_date: endDate,
      driver: driver_id,
    };
    const response = await postApiWithAuth(DRIVER_REPORT_COUNT, formData);
    if (response.data !== undefined) {
      setInvoice(response.data.data);
    } else {
      console.log(response);
    }
  };

  useEffect(() => {
    if (invoice.length > 0) {
      handleDownloadPDF();
      setInvoice([]);
    }
  }, [invoice]);

  const handleDownloadPDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Order_File.pdf",
  });
  async function handleDownloadImages(pharmacy, dateRange) {
    try {
      const [dateStr] = dateRange.split(" - ");
      const formatDate = (dateStr) => {
        const [month, day, year] = dateStr.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      };

      const formattedDate = formatDate(dateStr);
      const folderName = `${pharmacy}_${formattedDate}_${formattedDate}_images`;
      const handle = await window.showDirectoryPicker();
      const folderHandle = await handle.getDirectoryHandle(folderName, {
        create: true,
      });
      const fileUrl = `https://pilldrop-s3-bucket.s3.amazonaws.com/images/merge_sign_files/all/${pharmacy}_${formattedDate}_${formattedDate}_images.zip`;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const zip = await JSZip.loadAsync(blob);
      for (const fileName of Object.keys(zip.files)) {
        const file = await zip.files[fileName].async("blob");
        const fileHandle = await folderHandle.getFileHandle(fileName, {
          create: true,
        });
        const writable = await fileHandle.createWritable();
        await writable.write(file);
        await writable.close();
      }

      console.log("Files successfully saved to folder!");
    } catch (error) {
      console.error("Error during folder creation or file download:", error);
    }
  }
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#fff",
          borderRadius: "12px",
          boxShadow: 1,
          marginBottom: "15px",
          transition: "background-color 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#f0f0f0",
            boxShadow: 1,
          },
        }}
      >
        {pharmaDetails && isExtraLargeScreen && (
          <Box sx={{ height: "80%", paddingLeft: "10px", width: "330px" }}>
            <Box
              sx={{
                backgroundColor: theme.palette.custom.layoutBgColor,
                color: theme.palette.custom.layoutFontColor,
                marginY: "8px",
                borderRadius: "8px",
                padding: "6px 6px",
                width: "300px",
                height: "100%",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: theme.palette.custom.layoutHoverColor,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "inherit" }}
                >
                  {pharmaDetails?.pharmacyDetail?.name}
                </Typography>
                <Typography
                  sx={{ fontSize: "11px", fontWeight: 400, color: "inherit" }}
                >
                  {pharmaDetails?.pharmacyDetail?.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {pharmaDetails?.weeklyData.map((weeklyItem, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingX: "30px",
              paddingY: "10px",
              height: "auto",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "11px", color: "#333" }}
                >
                  {weeklyItem.dateRange.replace(" - ", " To ")}
                  <span style={{ fontWeight: "normal" }}></span>
                </Typography>
                <PrinterIcon
                  onClick={() => {
                    fetchInvoice(
                      weeklyItem.dateRange,
                      pharmaDetails?.pharmacyDetail.id
                    );
                  }}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <div style={{ display: "none" }}>
        <div ref={componentRef} style={{ margin: 20 }}>
          <div className={classes.page}>
            <div className={classes.section}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "400" }}>
                  Date Issued: {moment(createDate).format("MM/DD/YYYY")}
                </p>
                <p style={{ fontWeight: "400" }}>Page 1/1</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "80px",
                }}
              >
                <div style={{ minWidth: "550px" }}>
                  <img
                    src={pilldropLogo}
                    alt="Mantis"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <h4 className={classes.header}>
                  Invoice{" "}
                  <span style={{ marginLeft: "10px" }}>
                    ({invoice[0]?.dateRange})
                  </span>
                </h4>
              </div>
              <h4 className={classes.header2}>
                {invoice[0]?.pharmacyDetail?.name}
              </h4>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns2.map((column) => (
                      <TableCell
                        key={column.id}
                        className={`${classes.tableCell} ${classes.tableHeader}`}
                        align="center"
                        style={{
                          border: "1px solid black",
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={`${classes.tableCell} ${classes.firstColumn}`}
                      align="center"
                      style={{ border: "1px solid black", fontWeight: "bold" }}
                    >
                      $Amount
                    </TableCell>
                    {[
                      invoice[0]?.pharmacyDetail?.shift4Price,
                      invoice[0]?.pharmacyDetail?.shift4Price,
                      invoice[0]?.pharmacyDetail?.shift4Price,
                      invoice[0]?.pharmacyDetail?.shift4Price,
                      invoice[0]?.pharmacyDetail?.shift4Price,
                      invoice[0]?.pharmacyDetail?.shift1Price,
                      invoice[0]?.pharmacyDetail?.failedDeliveryPrice,
                    ].map((price, index) => (
                      <TableCell
                        key={index}
                        className={`${classes.tableCell}`}
                        align="center"
                        style={{ border: "1px solid black" }}
                      >
                        {price?.toFixed(2)} $
                      </TableCell>
                    ))}
                  </TableRow>
                  {invoice &&
                    invoice[0]?.dailyData &&
                    invoice[0].dailyData.map((dailyItem, k) => {
                      const dailyAmount =
                        dailyItem.completed_orders *
                          invoice[0]?.pharmacyDetail?.shift1Price || 0;
                      const dailyFailedAmount =
                        dailyItem.failed_orders *
                          invoice[0]?.pharmacyDetail?.failedDeliveryPrice || 0;

                      totalDeliveredAmount += dailyAmount;
                      totalFailedAmount += dailyFailedAmount;

                      return (
                        <TableRow key={k}>
                          <TableCell
                            className={`${classes.tableCell} ${classes.firstColumn}`}
                            align="center"
                            style={{ border: "1px solid black" }}
                          >
                            {/* {formatDateToMMDDYYYY(dailyItem.date)} */}
                            {dailyItem.date}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell}`}
                            align="center"
                            style={{
                              border: "1px solid black",
                              fontWeight: "bold",
                            }}
                          >
                            {dailyItem.dailyroutes}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell}`}
                            align="center"
                            style={{
                              border: "1px solid black",
                              fontWeight: "bold",
                            }}
                          >
                            {dailyItem.dailytotalorders}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell}`}
                            align="center"
                            style={{
                              border: "1px solid black",
                              fontWeight: "bold",
                            }}
                          >
                            {dailyItem.completed_orders}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell}`}
                            align="center"
                            style={{
                              border: "1px solid black",
                              fontWeight: "bold",
                            }}
                          >
                            {dailyItem.failed_orders}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell}`}
                            align="center"
                            style={{
                              border: "1px solid black",
                              fontWeight: "bold",
                            }}
                          >
                            {dailyItem.pending_orders}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell}`}
                            align="center"
                            style={{
                              border: "1px solid black",
                              fontWeight: "bold",
                            }}
                          >
                            {dailyAmount.toFixed(2)} $
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell}`}
                            align="center"
                            style={{
                              border: "1px solid black",
                              fontWeight: "bold",
                            }}
                          >
                            {dailyFailedAmount.toFixed(2)} $
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* Add a row for totals */}
                  <TableRow>
                    <TableCell
                      className={`${classes.tableCell}`}
                      align="center"
                      style={{ border: "1px solid black", fontWeight: "bold" }}
                      colSpan={6}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      className={`${classes.tableCell}`}
                      align="center"
                      style={{ border: "1px solid black", fontWeight: "bold" }}
                    >
                      {totalDeliveredAmount.toFixed(2)} $
                    </TableCell>
                    <TableCell
                      className={`${classes.tableCell}`}
                      align="center"
                      style={{ border: "1px solid black", fontWeight: "bold" }}
                    >
                      {totalFailedAmount.toFixed(2)} $
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriverReport;
