import { Box } from "@mui/material";
import React from "react";
import RouteList from "../components/routeStatusCompo/RouteList";
import TeamMemberList from "../components/routeStatusCompo/TeamMemberList";
import RouteProgress from "../components/routeStatusCompo/RouteProgress";
import { RouteMapImage } from "../components/Icons";
import OrderStatus from "../components/routeStatusCompo/OrderStatus";
import OrderTable from "../components/routeStatusCompo/OrderTable";

const RoutesStatus = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flex: 1.4,
            height: "518px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          <RouteList />
          <TeamMemberList />
          <RouteProgress />
        </Box>
        <Box sx={{ maxHeight: "518px", paddingTop: 2, flex: 2 }}>
          <RouteMapImage />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <OrderStatus />
        <OrderTable />
      </Box>
    </Box>
  );
};

export default RoutesStatus;
