import React from "react";
import { Box, Typography } from "@mui/material";

const teamMembers = [
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
  { name: "NICOL ASUELDO", date: "09-27-2024", percentage: "70%" },
];

const TeamMemberList = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="body2" sx={{ marginBottom: 2, fontSize: "1.25rem" }}>
        Team Member
      </Typography>
      {teamMembers.map((member, index) => (
        <Box
          sx={{
            width: "146px",
            height: "47px",
            marginBottom: "10px",
          }}
          key={index}
        >
          <Box sx={{ border: "1px solid #dce0e4", borderRadius: 1 }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 500, padding: 1 }}
            >
              NICOLL ASUELDO
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              {member.date}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                color: "#000",
              }}
            >
              {member.percentage}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TeamMemberList;
