import React, { useContext } from "react";
import { Box, Button } from "@mui/material";
import { OrderDataContext } from "../../context/OrderDataContext";

const TabButtons = ({ activeTab, onTabChange, handlePrintSlip=null, pharmacyDetail=null, downloadUploadSlip=null }) => {
  const { orderData } = useContext(OrderDataContext);

  // Define tabs conditionally
  const tabs = [
    ...(orderData?.order?.order_file !== "https://pilldrop-s3-bucket.s3.amazonaws.com/null"
      ? [{ label: "Uploaded Slip", value: "uploaded" }]
      : []),
    { label: "Signed Slip", value: "signed" },
    { label: "Delivery Proof", value: "delivery" },
    { label: "Communication", value: "communication" },
    { label: "Previous Order", value: "previous" },
  ];


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        maxWidth: "100%",
        gap: 2,
      }}
    >
      {tabs.map((tab) => (
        <Button
          key={tab.value}
          variant="contained"
          onClick={() => onTabChange(tab.value)}
          sx={{
            backgroundColor: activeTab === tab.value ? "#000" : "transparent",
            color: activeTab === tab.value ? "#fff" : "#000",
            boxShadow: "none",
            border: "1px solid #919eab",
            height: "38px",
            borderRadius: "8px",
            paddingLeft: "5px",
            paddingRight: "5px",
            width: "125px",
            fontSize: "12px",
            fontWeight: "600",
            textTransform: "none",
            "&:hover": {
              backgroundColor: activeTab === tab.value ? "#000" : "transparent",
            },
          }}
        >
          {tab.label}
        </Button>
      ))}
      {(activeTab === 'signed' && orderData?.order?.status === "5") ? (
        <img
          src="/icons/order/PrinterBlack.svg"
          onClick={() => {

            if (pharmacyDetail?.pos_types === 'PrimeRX') {
              console.log("Post Type is PrimeRX, calling handlePrintSlip()");
              handlePrintSlip();
            } else if (pharmacyDetail?.pos_types === 'BestRx' || pharmacyDetail?.pos_types === 'PioneerRx') {
              console.log("Post Type is BestRx or PioneerRx, opening PDF URL in new tab");
              if (orderData?.order?.status === "5") {
                console.log("Order status is 5, opening signed PDF");
                window.open(`https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${orderData?.order?.slug}_signed.pdf?embedded=true`, '_blank');
              } else {
                console.log("Order status is not 5, opening order file URL");
                window.open(orderData?.order?.order_file, "_blank");
              }
            } else {
              console.log("Post Type does not match PrimeRX or BestRx/PioneerRx");
            }
          }}
          style={{cursor: "pointer"}}
          alt="print"
        />
      ) : activeTab === 'uploaded' && (
        <img
          src="/icons/order/PrinterBlack.svg"
          onClick={() => {
            console.log("Upload slip tab active, calling downloadUploadSlip()");
            downloadUploadSlip();
          }}
          style={{cursor: "pointer"}}
          alt="print"
        />
      )}
    </Box>
  );
};

export default TabButtons;
