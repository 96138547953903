import { Box } from "@mui/material";
import React from "react";
import Topbar from "../components/dailyOrderPageCompo/Topbar";
import OrderOverview from "../components/dailyOrderPageCompo/OrderOverview";

const PharmacyOrders = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, my: 1 }}>
      <Topbar />
      <OrderOverview />
    </Box>
  );
};

export default PharmacyOrders;
