import React, { useState } from "react";
import { Box, Typography, Tooltip, Button } from "@mui/material";

const TrafficByWebsite = ({ orderNum }) => {
  const pharmacyOrders = orderNum?.pharmacy_orders || {};
  const trafficData = Object.entries(pharmacyOrders).map(([name, value]) => ({
    name,
    value,
  }));

  const [showAll, setShowAll] = useState(false);
  const displayedData = showAll ? trafficData : trafficData.slice(0, 6);

  const getDashWidth = (value) => Math.max(value / 3, 10); // Ensure a minimum width for visibility

  return (
    <Box
      sx={{
        padding: "16px",
        borderRadius: "12px",
        backgroundColor: "#f7f9fb",
        flex: 1,
      }}
    >
      {/* Title */}
      <Typography
        variant="h1"
        sx={{ fontSize: "14px", fontWeight: 600, marginBottom: "16px" }}
      >
        Orders by Pharmacy
      </Typography>

      {/* Traffic Data */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {displayedData.map((item) => (
          <Box
            key={item.name}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "12px",
              width: "80%",
            }}
          >
            {/* Pharmacy Name */}
            <Typography variant="h1" sx={{ fontSize: "12px", fontWeight: 400 }}>
              {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
            </Typography>

            {/* Dashed Progress Bar with Tooltip */}
            <Tooltip title={`Orders: ${item.value}`} arrow>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "4px",
                  marginLeft: "10px",
                  paddingLeft: 2,
                  width: "80px",
                  cursor: "pointer",
                }}
              >
                {[1, 0.4, 0.1].map((opacity, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: `${getDashWidth(item.value)}px`,
                      height: "3px",
                      borderRadius: "2px",
                      backgroundColor: `rgba(0, 0, 0, ${opacity})`,
                    }}
                  ></Box>
                ))}
              </Box>
            </Tooltip>
          </Box>
        ))}
      </Box>

      {/* "See More" Button */}
      {trafficData.length > 6 && (
        <Button
          variant="text"
          onClick={() => setShowAll(!showAll)}
          sx={{ marginTop: "10px", textTransform: "none", fontSize: "12px" }}
        >
          {showAll ? "See Less" : "See More"}
        </Button>
      )}
    </Box>
  );
};

export default TrafficByWebsite;
