import React from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { SearchIcon } from "../components/Icons";

const SearchbarSmall = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff", // Light grey background to match screenshot
        border: "1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
        width: "173px", // Set width to control size
      }}
    >
      <TextField
        placeholder="Search"
        autoComplete="off"
        variant="standard"
        InputProps={{
          disableUnderline: true, // Remove underline
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: {
            padding: "4px 5px", // Padding for inner spacing
            fontSize: "11px", // Font size
          },
        }}
        fullWidth
      />
    </Box>
  );
};

export default SearchbarSmall;
