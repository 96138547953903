import React, { useEffect, useState } from "react";
import UserOverview from "../components/Expensescompo/UserOverview";
import Topbar from "../components/Expensescompo/Topbar";
import { Box, Typography } from "@mui/material";
import { EXPENSE_LIST } from "../utils/apiUrl";
import { getApiWithAuth } from "../utils/api";
import moment from "moment";
const ExpenseList = () => {
  const [facilityData, setFacilityData] = useState([]);
  const [facilityFilteredData, setFacilityFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
  ]);
  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };
  const fetchFacilityData = async () => {
    const validEndDate =
      dateRange[1] && !isNaN(dateRange[1].getTime())
        ? dateRange[1]
        : new Date();
    try {
      const response = await getApiWithAuth(EXPENSE_LIST + `?page=${1}&from=${moment(
            dateRange[0]
          ).format("MM-DD-YYYY")}&to=${moment(validEndDate).format(
            "MM-DD-YYYY"
          )}`);
      setFacilityData(response.data.data);
      setFacilityFilteredData(response.data.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching facility data:", error);
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchFacilityData();
  }, [dateRange]);

  const handleSearch = (query) => {
    console.log("Searchhhhhh", query)
    if (!query) {
      // If query is empty, reset the filtered data to the original data
      setFacilityFilteredData(facilityData);
      return;
    }

    const lowerCaseQuery = query.toLowerCase();
    const filtered = facilityData.filter((data) => {
      const firstName = data.firstName ? data.firstName.toLowerCase() : "";
      const lastName = data.lastName ? data.lastName.toLowerCase() : "";
      const cellNumber = data.cellNumber ? data.phoneNumber.toLowerCase() : "";

      return (
        firstName.includes(lowerCaseQuery) ||
        lastName.includes(lowerCaseQuery) ||
        cellNumber.includes(query)
      );
    });
    setFacilityFilteredData(filtered);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }
  if (error) {
    return <Typography>Error: {error.message}</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Topbar onSearch={handleSearch} onDateChange={handleDateRangeChange} />
      {facilityFilteredData.map((data, index) => (
        <UserOverview key={data.id} data={data} index={index} refreshData={fetchFacilityData} 
        />
      ))}
    </Box>
  );
};

export default ExpenseList;
