import { Box } from "@mui/material";
import React from "react";
import Topbar from "../../components/driversPageCompo/subPages/Topbar";
import DriverInformationForm from "../../components/driversPageCompo/subPages/DriverInformationForm";
import { ContainedButton } from "../../components/buttonCompo/Buttons";

const AddInfo = () => {
  return (
    <Box sx={{ mx: 3, marginBottom: 8 }}>
      <DriverInformationForm />
    </Box>
  );
};

export default AddInfo;
