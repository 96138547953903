import React, { useState } from "react";
import { Box } from "@mui/material";
import PatientDetails from "../components/createOrderPageCompo/PatientDetails";
import PatientOrderDetails from "../components/createOrderPageCompo/PatientOrderDetails";
import DeliveryInstructions from "../components/createOrderPageCompo/DeliveryInstructions";
import ActionButtons from "../components/createOrderPageCompo/ActionButtons";
import TabButtons from "../components/createOrderPageCompo/TabButtons";
import SlipPreview from "../components/createOrderPageCompo/SlipPreview";
import DateTimeFilters from "../components/createOrderPageCompo/DateTimeFilters";

const DeliveryPage = () => {
  const [activeTab, setActiveTab] = useState("signed");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "800px",
        overflow: "hidden",
        maxHeight: "100%",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <TabButtons activeTab={activeTab} onTabChange={handleTabChange} />
      <SlipPreview activeTab={activeTab} />
    </Box>
  );
};

export default DeliveryPage;
