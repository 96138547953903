import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getApiWithAuth } from '../utils/api';
import { ALL_ZONES_LIST } from '../utils/apiUrl';

export const fetchZones = createAsyncThunk(
  'zone/fetchZones',
  async (boroughId) => {
    const response = await getApiWithAuth(`${ALL_ZONES_LIST}borros/${boroughId}/`);
    return response.data.data.zones;
  }
);

export const fetchBoroughs = createAsyncThunk(
  'zone/fetchBoroughs',
  async () => {
    const response = await getApiWithAuth(`${ALL_ZONES_LIST}borros/`, { justList: true });
    return response.data.data;
  }
);

const zoneSlice = createSlice({
  name: 'zone',
  initialState: {
    zones: [],
    boroughs: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Add synchronous reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchZones.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchZones.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.zones = action.payload;
      })
      .addCase(fetchZones.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchBoroughs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBoroughs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.boroughs = action.payload;
      })
      .addCase(fetchBoroughs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default zoneSlice.reducer;
