import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const Numbers = ({ orderNum }) => {
  const statusData = [
    { status: "Ready For Pickup", key: "1", bgColor: "#0f4df2" },
    { status: "In Transit", key: "4", bgColor: "#fe9902" },
    { status: "Delivered", key: "5", bgColor: "#2daf49" },
    { status: "Cancelled", key: "6", bgColor: "#ff1109" },
  ];

  const [counts, setCounts] = useState(statusData.map(() => 0));
  const [hoveredDigit, setHoveredDigit] = useState(null);
  const [hoveredDigitCount, setHoveredDigitCount] = useState(null);

  useEffect(() => {
    if (orderNum) {
      const inTransitKeys = ["3", "2"]; 
      const inTransitCount = inTransitKeys.reduce(
        (sum, key) => sum + (orderNum[key] || 0),
        orderNum["4"] || 0 
      );
      const updatedCounts = statusData.map((status) => {
        if (status.key === "4") {
          return inTransitCount; 
        }
        return orderNum[status.key] || 0;
      });

      setCounts(updatedCounts);
    }
  }, [orderNum]);

  useEffect(() => {
    const intervals = statusData.map((status, index) => {
      const stepTime = Math.ceil(1000 / (counts[index] || 1));
      const increment = () => {
        setCounts((prevCounts) =>
          prevCounts.map((count, i) => {
            if (i === index && count < (orderNum[status.key] || 0)) {
              return count + 1;
            }
            return count;
          })
        );
      };
      return setInterval(increment, stepTime);
    });

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, [counts, orderNum, statusData]);

  const getDigitsArray = (number) => number.toString().split("").map(Number);

  const handleMouseEnter = (index, digitIndex, digit) => {
    setHoveredDigit({ index, digitIndex });
    setHoveredDigitCount(digit);

    const fasterCountdown = setInterval(() => {
      setHoveredDigitCount((prev) => {
        if (prev > 0) return prev - 1;
        clearInterval(fasterCountdown);
        return 0;
      });
    }, 50);

    return () => clearInterval(fasterCountdown);
  };

  const handleMouseLeave = () => {
    setHoveredDigit(null);
    setHoveredDigitCount(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 6,
      }}
    >
      {statusData.map((status, index) => {
        const digits = getDigitsArray(counts[index]);

        return (
          <Box
            key={index}
            sx={{
              backgroundColor: status.bgColor,
              padding: "16px",
              borderRadius: "16px",
              color: "#fff",
              margin: "8px 0",
              width: "243px",
              height: "112px",
              flex: 1,
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, marginBottom: 2 }}
            >
              {status.status}
            </Typography>
            <Typography
              sx={{ fontSize: "24px", fontWeight: 600, cursor: "pointer" }}
            >
              {digits.map((digit, digitIndex) => (
                <span
                  key={digitIndex}
                  onMouseEnter={() =>
                    handleMouseEnter(index, digitIndex, digit)
                  }
                  onMouseLeave={handleMouseLeave}
                  style={{
                    display: "inline-block",
                    transition: "all 0.3s ease-in-out",
                    color:
                      hoveredDigit &&
                      hoveredDigit.index === index &&
                      hoveredDigit.digitIndex === digitIndex
                        ? "black"
                        : "#fff",
                    animation:
                      hoveredDigit &&
                      hoveredDigit.index === index &&
                      hoveredDigit.digitIndex === digitIndex
                        ? "bounce 0.6s ease forwards"
                        : "none",
                  }}
                >
                  {hoveredDigit &&
                  hoveredDigit.index === index &&
                  hoveredDigit.digitIndex === digitIndex
                    ? hoveredDigitCount
                    : digit}
                </span>
              ))}
            </Typography>
          </Box>
        );
      })}
      <style>
        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            10% {
              transform: translateY(-15px);
            }
            20% {
              transform: translateY(-5px);
            }
            30% {
              transform: translateY(0px);
            }
            40% {
              transform: translateY(5px);
            }
            50% {
              transform: translateY(8px);
            }
            60% {
              transform: translateY(0px);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Numbers;