import React, { useState } from "react";
import { Box, Typography, IconButton, TextField } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { BluePencilIcon, DeleteRedIcon } from "../../Icons";
import { ContainedButton } from "../../buttonCompo/Buttons";
import { toast } from "react-toastify";
import { putApiWithAuth, deleteApiWithAuth, postApiWithAuth } from "../../../utils/api";
import { ALL_ZONES_LIST } from "../../../utils/apiUrl";
import CheckIcon from '@mui/icons-material/Check';

const NumberedItems = ({ zone, getZips, setZone }) => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formState, setFormState] = useState({
    zipcode: "",
    zone: zone?.id,
  });

  const handleSaveZone = async (i = null) => {
    console.log(isEdit);

    // If editing, update the existing zipcode
    if (isEdit) {
      if (!formState.zipcode) {
        toast.error("Zip Code cannot be empty!", { theme: "colored" });
        return;
      }
      const response = await putApiWithAuth(
        `${ALL_ZONES_LIST}ZipmdList/${formState.zipid}/update`,
        formState
      );
      response.status !== 400
        ? toast.success("Zip Code Edited Successfully!", { theme: "colored" })
        : toast.error(response.data[0] || "Error updating Zip Code!", {
          theme: "colored",
        });
    } else {
      // Adding a new zipcode
      const newZipcode = i !== null ? zone.zipcode[i].zipcode : formState.zipcode;

      // Check for duplicates
      if (zone.zipcode.some((item, index) => item.zipcode === newZipcode && index !== i)) {
        toast.error("Zip Code already exists!", { theme: "colored" });
        return;
      }

      if (!newZipcode) {
        toast.error("Zip Code cannot be empty!", { theme: "colored" });
        return;
      }

      const response = await postApiWithAuth(`${ALL_ZONES_LIST}ZipmdList/`, {
        zone: zone?.id,
        zipcode: newZipcode,
      });

      response.status !== 400
        ? toast.success("Zip Created Successfully!", { theme: "colored" })
        : toast.error(response.data[0] || "Backend Error!", { theme: "colored" });

      setZone((prevZone) => {
        const updatedZipcodes = [...prevZone.zipcode];
        updatedZipcodes[i].isEditable = false;
        return { ...prevZone, zipcode: updatedZipcodes };
      });
    }

    handleClose();
  };


  const handleClickOpen = (zid) => {
    setOpen(true);
    setIsEdit(true);
    setFormState({
      zipcode: zid.zipcode,
      zone: zone?.id,
      zipid: zid.id
    });
  };

  const handleAddZip = () => {
    setOpen(true);
    setIsEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setFormState({
      zipcode: "",
      zone: zone?.id,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Enforce maxLength in the handler
    if (value.length <= 7) {
      setFormState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputChangeList = (index, value) => {
    setZone((prevZone) => {
      // Ensure prevZone and zipcode are defined
      const updatedZipcodes = prevZone?.zipcode ? [...prevZone.zipcode] : [];

      // Check if the index exists in the array to avoid undefined errors
      if (updatedZipcodes[index]) {
        updatedZipcodes[index].zipcode = value;
      }

      return { ...prevZone, zipcode: updatedZipcodes };
    });
    setFormState((prev) => ({ ...prev, "zipcode": value }));
  };

  const handleDeleteClick = async (zid) => {
    const response = await deleteApiWithAuth(ALL_ZONES_LIST + `ZipmdList/${zid.id}/delete`);
    if (response.status !== 400) {
      toast.success('Zip Delete Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    getZips();
  };

  const handleAddZipcode = () => {
    const newZipcode = { zipcode: "", isEditable: true };
    setZone((prevZone) => ({
      ...prevZone,
      zipcode: [...(prevZone?.zipcode || []), newZipcode],
    }));
  };

  const handleRemoveZipcode = (index) => {
    setZone((prevZone) => ({
      ...prevZone,
      zipcode: prevZone?.zipcode?.filter((_, i) => i !== index),
    }));
  };


  return (
    <>
      <Button
        variant="outlined"
        onClick={handleAddZip}
        sx={{ alignSelf: "flex-start" /* adjust styling as needed */ }}
      >
        + Add Zipcode
      </Button>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          boxShadow: 1,
          padding: "30px 5px",
          borderRadius: "8px",
          flexWrap: "wrap",
          gap: 5,
        }}
      >
        {zone?.zipcode?.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "130px",
            }}
          >
            <Typography
              sx={{
                backgroundColor: "#126599",
                color: "#fff",
                borderRadius: "50%",
                width: "27px",
                height: "27px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 500,
                fontSize: "13px",
              }}
            >
              {index + 1} {/* Display the original number */}
            </Typography>

            <TextField
              type="text"
              autoComplete="off"
              disabled={!item.isEditable}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 7, // Enforce max length at input level
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              sx={{
                width: "60px",
                height: "24px",
                fontSize: "11px",
                fontWeight: 500,
                "& .MuiOutlinedInput-root": {
                  padding: "2px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input": {
                  padding: "5px 5px",
                },
              }}
              name="zipcode"
              id={`zipcode-${index}`}
              value={item.zipcode}
              onChange={(e) => handleInputChangeList(index, e.target.value)}
            />

            <Box sx={{ display: "flex", gap: 1 }}>
              {!item.isEditable ?
                <IconButton
                  size="small"
                  sx={{ padding: 0 }}
                >
                  <BluePencilIcon onClick={() => handleClickOpen(item)} sx={{ cursor: "pointer" }} />
                </IconButton>
                :
                <IconButton
                  size="small"
                  sx={{ padding: 0 }}
                >
                  <CheckIcon onClick={() => handleSaveZone(index)} sx={{ cursor: "pointer" }} />
                </IconButton>
              }

              <IconButton
                size="small"
                sx={{ padding: 0 }}
              >
                <DeleteRedIcon onClick={() => item.isEditable ? handleRemoveZipcode(index) : handleDeleteClick(item)} sx={{ cursor: "pointer" }} />
              </IconButton>
            </Box>
          </Box>
        ))}
        <IconButton
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={handleAddZipcode}
        >
          <Typography
            sx={{
              backgroundColor: "#126599",
              color: "#fff",
              borderRadius: "50%",
              width: "27px",
              height: "27px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 500,
              fontSize: "13px",
            }}
          >
            +
          </Typography>
        </IconButton>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
            height: "315px",
            padding: "13px",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
          {isEdit ? "Edit Zip Code" : "Add Zip Code"}
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 0,
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="zipcode"
            name="zipcode"
            label="Zip Code"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            value={formState.zipcode}
            onChange={handleInputChange}
            sx={{ width: "150px" }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <ContainedButton
            label="Save"
            border="none"
            borderRadius="8px"
            onClick={handleSaveZone}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NumberedItems;
