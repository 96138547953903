import React from "react";
import { Button, Box } from "@mui/material";
import { ContainedButton } from "../buttonCompo/Buttons";

const DateRange = ({pharmacyDetails}) => {
  const dateRange = pharmacyDetails?.dateRange;
  return (
    <Box>
      <ContainedButton
        label={dateRange}
        borderRadius="8px"
      ></ContainedButton>
    </Box>
  );
};

export default DateRange;
