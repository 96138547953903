// src/context/DisplayModeContext.js
import React, { createContext, useContext, useState } from "react";

const DisplayModeContext = createContext();

export const DisplayModeProvider = ({ children }) => {
  const [displayMode, setDisplayMode] = useState(
    localStorage.getItem("displayMode") || "large"
  );

  const toggleDisplayMode = () => {
    const newMode = displayMode === "large" ? "small" : "large";
    setDisplayMode(newMode);
    localStorage.setItem("displayMode", newMode);
  };

  return (
    <DisplayModeContext.Provider value={{ displayMode, toggleDisplayMode }}>
      {children}
    </DisplayModeContext.Provider>
  );
};

export const useDisplayMode = () => useContext(DisplayModeContext);
