import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";

// Sample data to display in the table
const data = [
  {
    stop: 1,
    orderId: "11-01504",
    name: "Sign, Diljeet",
    address:
      "313 Plymouth St. Huntington Station, NY 11746-7018, United States",
    copay: "$3.00",
    pharmacy: "FAMILY CARE SPECIALTY PHARMACY",
    status: "Delivered",
    updatedAt: "09-28-2024 14:09 PM",
    updatedBy: "Driver Name",
  },
  {
    stop: 2,
    orderId: "11-01504",
    name: "Sign, Diljeet",
    address:
      "313 Plymouth St. Huntington Station, NY 11746-7018, United States",
    copay: "$3.00",
    pharmacy: "FAMILY CARE SPECIALTY PHARMACY",
    status: "Delivered",
    updatedAt: "09-28-2024 14:09 PM",
    updatedBy: "Driver Name",
  },
  {
    stop: 3,
    orderId: "11-01504",
    name: "Sign, Diljeet",
    address:
      "313 Plymouth St. Huntington Station, NY 11746-7018, United States",
    copay: "$3.00",
    pharmacy: "FAMILY CARE SPECIALTY PHARMACY",
    status: "Delivered",
    updatedAt: "09-28-2024 14:09 PM",
    updatedBy: "Driver Name",
  },
  {
    stop: 4,
    orderId: "11-01504",
    name: "Sign, Diljeet",
    address:
      "313 Plymouth St. Huntington Station, NY 11746-7018, United States",
    copay: "$3.00",
    pharmacy: "FAMILY CARE SPECIALTY PHARMACY",
    status: "Delivered",
    updatedAt: "09-28-2024 14:09 PM",
    updatedBy: "Driver Name",
  },
  // Add more rows as needed
];

const OrderTable = () => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="order table">
        {/* Table header */}
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "11px",
                color: "#000",
                padding: "8px", // Decrease padding
                borderBottom: "1px solid #dce0e4",
                borderTop: "1px solid #dce0e4",
              }}
            >
              Stop #
            </TableCell>
            <TableCell
              sx={{
                fontSize: "11px",
                color: "#000",
                padding: "8px", // Decrease padding
                borderBottom: "1px solid #dce0e4",
                borderTop: "1px solid #dce0e4",
              }}
            >
              Order ID
            </TableCell>
            <TableCell
              sx={{
                fontSize: "11px",
                color: "#000",
                padding: "8px", // Decrease padding
                borderBottom: "1px solid #dce0e4",
                borderTop: "1px solid #dce0e4",
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                fontSize: "11px",
                color: "#000",
                padding: "8px", // Decrease padding
                borderBottom: "1px solid #dce0e4",
                borderTop: "1px solid #dce0e4",
              }}
            >
              Address
            </TableCell>
            <TableCell
              sx={{
                fontSize: "11px",
                color: "#000",
                padding: "8px", // Decrease padding
                borderBottom: "1px solid #dce0e4",
                borderTop: "1px solid #dce0e4",
              }}
            >
              Copay
            </TableCell>
            <TableCell
              sx={{
                fontSize: "11px",
                color: "#000",
                padding: "8px", // Decrease padding
                borderBottom: "1px solid #dce0e4",
                borderTop: "1px solid #dce0e4",
              }}
            >
              Pharmacy
            </TableCell>
            <TableCell
              sx={{
                fontSize: "11px",
                color: "#000",
                padding: "8px", // Decrease padding
                borderBottom: "1px solid #dce0e4",
                borderTop: "1px solid #dce0e4",
              }}
            >
              Status
            </TableCell>
            <TableCell
              sx={{
                fontSize: "11px",
                color: "#000",
                padding: "8px", // Decrease padding
                borderBottom: "1px solid #dce0e4",
                borderTop: "1px solid #dce0e4",
              }}
            >
              Update At
            </TableCell>
            <TableCell
              sx={{
                fontSize: "11px",
                color: "#000",
                padding: "8px", // Decrease padding
                borderBottom: "1px solid #dce0e4",
                borderTop: "1px solid #dce0e4",
              }}
            >
              Update By
            </TableCell>
          </TableRow>
        </TableHead>

        {/* Table body */}
        <TableBody
          sx={{
            transition: "box-shadow 0.3s ease",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  fontSize: "12px",
                  color: "#000",
                  padding: "15px", // Decrease padding
                  borderBottom: "1px solid #dce0e4",
                  fontWeight: 600,
                  borderTop: "1px solid #dce0e4",
                }}
              >
                {row.stop}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  color: "#000",
                  padding: "15px", // Decrease padding
                  paddingLeft: "8px",
                  borderBottom: "1px solid #dce0e4",
                  fontWeight: 600,

                  borderTop: "1px solid #dce0e4",
                }}
              >
                {row.orderId}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  color: "#000",
                  padding: "15px",
                  paddingLeft: "8px",
                  fontWeight: 600,

                  borderBottom: "1px solid #dce0e4",
                  borderTop: "1px solid #dce0e4",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  color: "#000",
                  padding: "15px", // Decrease padding
                  borderBottom: "1px solid #dce0e4",
                  paddingLeft: "8px",
                  fontWeight: 600,

                  borderTop: "1px solid #dce0e4",
                }}
              >
                {row.address}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  color: "#000",
                  padding: "15px", // Decrease padding
                  borderBottom: "1px solid #dce0e4",
                  paddingLeft: "8px",
                  fontWeight: 600,

                  borderTop: "1px solid #dce0e4",
                }}
              >
                {row.copay}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  color: "#000",
                  padding: "15px", // Decrease padding
                  borderBottom: "1px solid #dce0e4",
                  paddingLeft: "8px",
                  fontWeight: 600,

                  borderTop: "1px solid #dce0e4",
                  marginBottom: "10px",
                }}
              >
                {row.pharmacy}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  color: "#000",
                  padding: "15px", // Decrease padding
                  borderBottom: "1px solid #dce0e4",
                  paddingLeft: "8px",
                  fontWeight: 600,

                  borderTop: "1px solid #dce0e4",
                }}
              >
                {row.status}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  color: "#000",
                  padding: "12px", // Decrease padding
                  borderBottom: "1px solid #dce0e4",
                  paddingLeft: 0,
                  fontWeight: 600,
                  borderTop: "1px solid #dce0e4",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {row.updatedAt.split(" ")[0]}
                </Typography>{" "}
                {/* Date */}
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {row.updatedAt.split(" ")[1]}
                </Typography>{" "}
                {/* Time */}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  color: "#000",
                  padding: "15px", // Decrease padding
                  borderBottom: "1px solid #dce0e4",
                  paddingLeft: "8px",
                  fontWeight: 600,

                  borderTop: "1px solid #dce0e4",
                }}
              >
                {row.updatedBy}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderTable;
