import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { OrderDataContext } from "../../context/OrderDataContext";
import { useDisplayMode } from "../../context/DisplayModeContext";
import ChatWindow from "./tabButtonsCompo/ChatWindow";
import PreviousOrder from "./tabButtonsCompo/PreviousOrder";
import DateTimeFilters from "./DateTimeFilters";
import PatientDetails from "./PatientDetails";
import PatientOrderDetails from "./PatientOrderDetails";
import DeliveryInstructions from "./DeliveryInstructions";
import ActionButtons from "./ActionButtons";

const SlipPreview = ({ activeTab }) => {
  const { orderData } = useContext(OrderDataContext);
  const [pdfFile, setPdfFile] = useState(null);

  useEffect(() => {
    if (orderData.file && orderData.file instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => setPdfFile(reader.result);
      reader.readAsDataURL(orderData.file);
    } else {
      console.error("orderData.file is not a valid File or Blob:", orderData.file);
    }
  }, [orderData]);

  const { displayMode } = useDisplayMode();

  const getSlipContent = () => {
    switch (activeTab) {
      case "uploaded":
        return { title: "Uploaded Slip", images: [] };
      case "signed":
        return { title: "Signed Slip", images: [] };
      case "delivery":
        return {
          title: "Delivery Proof",
          images: [
            "/icons/deliveryIcons/image 1.png",
            "/icons/deliveryIcons/image 2.png",
            "/icons/deliveryIcons/image 3.png",
            "/icons/deliveryIcons/image 4.png",
          ],
        };
      case "communication":
        return { title: "Communication", images: [] };
      case "previous":
        return { title: "Previous", images: [] };
      default:
        return { title: "Slip Preview", images: [] };
    }
  };

  const { title, images } = getSlipContent();

  return (
    <Box
      sx={{
        maxWidth: "100%",
        minHeight: "92.5%",
        borderRadius: "5px",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      {activeTab === "uploaded" && displayMode === "small" && (
        <Box sx={{ width: "100%", p: 2 }}>
          <DateTimeFilters />
          <PatientDetails />
          <PatientOrderDetails />
          <DeliveryInstructions />
          <ActionButtons />
        </Box>
      )}

      {/* Render PDF if available in 'uploaded' tab */}
      {activeTab === "uploaded" && pdfFile && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <iframe
            src={`${pdfFile}#toolbar=0&navpanes=0&scrollbar=0`}
            width="100%"
            height="100%"
            style={{ marginTop: 10, border: "none" }}
            title="Uploaded PDF"
          />
        </Box>
      )}
      {activeTab === "communication" && (
        <Box sx={{ maxHeight: "90%", overflowY: "auto", width: "100%" }}>
          <ChatWindow />
        </Box>
      )}

      {activeTab === "previous" && (
        <Box sx={{ maxHeight: "90%", overflowY: "auto", width: "100%" }}>
          <PreviousOrder />
        </Box>
      )}

      {images.length > 1 && (
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 2 }}>
          {images.map((image, index) => (
            <Box
              key={index}
              component="img"
              src={image}
              alt={`${title} ${index + 1}`}
              sx={{ width: "30%", objectFit: "cover", borderRadius: "5px" }}
            />
          ))}
        </Box>
      )}
      {images.length === 1 && (
        <Box>
          <img src={images[0]} alt="img" style={{ width: "100%", objectFit: "contain" }} />
        </Box>
      )}
    </Box>
  );
};

export default SlipPreview;
