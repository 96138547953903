import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Toolbar, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import { getTheme } from "../../Theme";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { useDisplayMode } from "../../context/DisplayModeContext";
import { useTheme } from "@mui/material/styles";
import SupportButtonManager from "../SupportComponent/SupportButtonManager";
import VoiceComponent from "../TwilioCalls/TwilioCalls";
import Dialpad from "../dialpadCompo/Dialpad";

const Layout = ({ hasSidebar = true, hasNavbar = true }) => {
  const [selectedColor, setSelectedColor] = useState(
    localStorage.getItem("selectedColor") || "#0B6699"
  );
  const [selectedFontColor, setSelectedFontColor] = useState(
    localStorage.getItem("selectedFontColor") || "#FFFFFF"
  );
  const [selectedIconColor, setSelectedIconColor] = useState(
    localStorage.getItem("selectedIconColor") || "#FFFFFF"
  );
  const [selectedHoverColor, setSelectedHoverColor] = useState(
    localStorage.getItem("selectedHoverColor") || "#044367"
  );
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  // Access display mode from context
  const { displayMode } = useDisplayMode();

  // Update localStorage whenever theme colors change
  useEffect(() => {
    localStorage.setItem("selectedColor", selectedColor);
    localStorage.setItem("selectedFontColor", selectedFontColor);
    localStorage.setItem("selectedIconColor", selectedIconColor);
    localStorage.setItem("selectedHoverColor", selectedHoverColor);
  }, [selectedColor, selectedFontColor, selectedIconColor, selectedHoverColor]);
  const theme = useTheme();
  return (
    <ThemeProvider
      theme={getTheme(
        selectedColor,
        selectedFontColor,
        selectedIconColor,
        selectedHoverColor
      )}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: selectedColor,
          color: selectedFontColor,
        }}
      >
        <CssBaseline />
        {hasSidebar && (
          <Sidebar
            isCollapsed={isSidebarCollapsed}
            setIsCollapsed={setIsSidebarCollapsed}
          />
        )}
        <Navbar
          hasSidebar={hasSidebar}
          hasNavbar={hasNavbar}
          setSelectedColor={setSelectedColor}
          setSelectedFontColor={setSelectedFontColor}
          setSelectedIconColor={setSelectedIconColor}
          setSelectedHoverColor={setSelectedHoverColor}
          isSidebarCollapsed={isSidebarCollapsed}
        />
        <VoiceComponent />
        <SupportButtonManager />
        <Dialpad />
        {/* Main content area */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: displayMode === "large" ? 2 : 2,
            backgroundColor: displayMode === "large" ? "#fafafb" : "#fbfbfb",
            transition: "all 0.3s ease",
            display: displayMode === "small" ? "flex" : "block",
            justifyContent: "center",
            alignItems: "start",
            minHeight: displayMode === "small" ? "calc(100vh - 60px)" : "auto",
            marginTop: displayMode === "small" ? "60px" : 0,
          }}
        >
          <Toolbar />
          <Outlet context={{ isSidebarCollapsed }} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
