import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { getTheme } from "./Theme";

import { Provider } from 'react-redux';
import store from './store';

const Root = () => {
  const [selectedColor, setSelectedColor] = useState("#0B6699");

  return (
    <Provider store={store}>
      <ThemeProvider theme={getTheme(selectedColor)}>
        <App setSelectedColor={setSelectedColor} />
      </ThemeProvider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
