import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getApiWithAuth } from '../utils/api';
import { DRIVERS_ROUTE } from '../utils/apiUrl';

export const fetchDrivers = createAsyncThunk(
  'driver/fetchDrivers',
  async () => {
    const response = await getApiWithAuth(DRIVERS_ROUTE, { justList: true });
    return response.data.data.map(item => ({
      id: item.id,
      name: item.name,
      isactive: item.isactive,
    }));
  }
);

const driverSlice = createSlice({
  name: 'driver',
  initialState: {
    list: [],
    activeList: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Add synchronous reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDrivers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
        state.activeList = action.payload.filter(driver => driver.isactive);
      })
      .addCase(fetchDrivers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default driverSlice.reducer;
