
import { Box } from "@mui/material";
import React from "react";
import Topbar from "../../components/driversPageCompo/subPages/Topbar";
import PharmacyEmployeeInformationForm from "../../components/pharmacyemployeePageCompo/subPages/PharmacyEmployeeInformationForm";
import { ContainedButton } from "../../components/buttonCompo/Buttons";

const AddPharmacyEmployee = () => {
  return (
    <Box sx={{ mx: 3, marginBottom: 1 }}>
      <PharmacyEmployeeInformationForm />
    </Box>
  );
};

export default AddPharmacyEmployee;
