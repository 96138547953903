import React from "react";
import { Box, Typography, Avatar, Divider, Tooltip } from "@mui/material";
import moment from "moment";


const TimelineItem = ({
  label,
  time,
  icon,
  user,
  extra,
  reason,
  user2,
  time2,
  smsDetails,
  isOutForDelivery
}) => (
  <Box display="flex" alignItems="center" position="relative">
    <Box
      width="100%"
      py="8px"
      display="flex"
      alignItems="center"
      flexDirection="row"
    >
      <Box sx={{ width: icon ? "120%" : "50%" }}>
        <Box sx={{ display: 'flex', gap: 12}}>
          <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "10px" }}>
            {label}
          </Typography>
          {extra && (
            <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "10px" }}>
              {extra}
            </Typography>
          )}
        </Box>
        {isOutForDelivery &&
          <>
            {smsDetails?.orderDetail?.out_delivery_sms && (
              <Box sx={{ display: 'flex', minWidth: '100%', gap: 0.5, justifyContent: "space-between" }}>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  SMS sent to {`${smsDetails?.orderDetail?.contact} ETA ${moment(smsDetails?.orderDetail?.out_delivery_at).format("MM-DD-YY")}`} &nbsp;{" "}
                  {moment(smsDetails?.orderDetail?.out_delivery_at).format("hh:mm:ssa")}
                  <br />

                </Typography>
                <Typography>
                  <Tooltip title={smsDetails?.orderDetail?.out_delivery_sms || "No SMS available"} arrow>
                    <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                      {smsDetails?.orderDetail?.out_delivery_sms ? <img src="/icons/Eye.svg" alt="Eye" /> : "No SMS"}
                    </span>
                  </Tooltip>
                </Typography>
              </Box>
            )}
            {smsDetails?.orderDetail?.navigate_sms && (
              <Box sx={{ display: 'flex', minWidth: '100%', gap: 0.5, justifyContent: "space-between" }}>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  Arrival SMS sent to {`${smsDetails?.orderDetail?.contact} ETA ${moment(smsDetails?.orderDetail?.navigate_at).format("MM-DD-YY")}`} &nbsp;{" "}
                  {moment(smsDetails?.orderDetail?.navigate_at).format("hh:mm:ssa")}
                </Typography>
                <Typography>
                  <Tooltip title={smsDetails?.orderDetail?.navigate_sms || "No SMS available"} arrow>
                    <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                      {smsDetails?.orderDetail?.navigate_sms ? <img src="/icons/Eye.svg" alt="Eye" /> : "No SMS"}
                    </span>
                  </Tooltip>
                </Typography>
              </Box>
            )}
            {smsDetails?.orderDetail?.e_sign_sms && (
              <Box sx={{ display: 'flex', minWidth: '100%', gap: 0.5, justifyContent: "space-between" }}>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  E-Signature request sent to {smsDetails?.orderDetail?.contact}
                  {/* {moment(smsDetails?.orderDetail?.e_sign_at).format("MM-DD-YY")} &nbsp;{" "}
                {moment(smsDetails?.orderDetail?.e_sign_at).format("hh:mm:ssa")} */}
                </Typography>
                <Typography>
                  <Tooltip title={smsDetails?.orderDetail?.e_sign_sms || "No SMS available"} arrow>
                    <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                      {smsDetails?.orderDetail?.e_sign_sms ? <img src="/icons/Eye.svg" alt="Eye" /> : "No SMS"}
                    </span>
                  </Tooltip>
                </Typography>
              </Box>
            )}
          </>
        }

        {reason && (
          <Typography variant="body2" fontSize="10px">
            {reason}
          </Typography>
        )}
      </Box>
      {/* Icon Section */}
      {icon && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ width: "45%" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0, // Adjust this to control the starting point of the line
              bottom: "50%",
              width: "2px",
              backgroundColor: "#e0e0e0", // Light gray color for the line
            }}
          />

          <Avatar
            src={icon}
            alt={label+"111"}
            sx={{ width: 26, height: 26, zIndex: 2 }}
          />

          <Box
            sx={{
              position: "absolute",
              top: "50%", // Adjust this to control the starting point of the line
              bottom: 0,
              width: "2px",
              backgroundColor: "#e0e0e0", // Light gray color for the line
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          width: "52%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        {time && (
          <Typography variant="body2" fontSize="10px">
            At: {time}
          </Typography>
        )}
        {user && (
          <Typography variant="body2" fontSize="10px">
            By: {user}
          </Typography>
        )}
        {time2 && (
          <Typography variant="body2" fontSize="10px">
            At: {time2}
          </Typography>
        )}
        {user2 && (
          <Typography variant="body2" fontSize="10px">
            By: {user2}
          </Typography>
        )}
      </Box>
    </Box>
  </Box>
);
const VerticalTimeline = ({ orderHistory }) => {
  const createdByItem = {
    label: `Created By: ${orderHistory?.orderDetail?.createdby?.username || "Unknown"
      }`,
    time: orderHistory?.orderDetail?.createdAt
      ? `${moment(orderHistory.orderDetail.createdAt).format(
        "MM-DD-YY"
      )} ${moment(orderHistory.orderDetail.createdAt).format("hh:mm:ss a")}`
      : "Unknown Time",
    user: null,
    icon: null,
  };
  let readyPickItem = {
    label: "At Ready For Pickup",
    time: orderHistory?.orderDetail?.createdAt
      ? `${moment(orderHistory.orderDetail.createdAt).format(
        "MM-DD-YY"
      )} ${moment(orderHistory.orderDetail.createdAt).format("hh:mm:ss a")}`
      : "Unknown Time",
    user: orderHistory?.orderDetail?.createdby?.username || "Unknown",
    icon: "/icons/historymodalicons/Lock.svg",
    time2: "",
    user2: "",
  };
  const getAttemptMessage = (array, i) => {
    const attempt = array
      .slice(0, i + 1)
      .filter(
        (obj) =>
          obj.name === "returned_Failed" &&
          obj.comment &&
          !obj.comment.startsWith("Failed by Admin")
      ).length;
    return attempt > 0 ? ` (Attempt ${attempt})` : "";
  };
  const recivedfacility = orderHistory?.orderhistory?.find(
    (item) => item.name === "recieved_Facilty_At"
  );
  let recivedfacilityOrderItem = {};
  if (recivedfacility) {
    recivedfacilityOrderItem = {
      label: "Received At Facility",
      time: `${moment(recivedfacility?.date).format("MM-DD-YY")} ${moment(
        recivedfacility.date
      ).format("hh:mm:ss a")}`,
      user: recivedfacility?.updatedBy || "Unknown",
      icon: "/icons/historymodalicons/Received.svg",
    };
  }
  const pickedup = orderHistory?.orderhistory?.find(
    (item) => item.name === "picked_Up"
  );
  let pickedupOrderItem = {};
  if (pickedup) {
    pickedupOrderItem = {
      label: "Picked Up",
      time: `${moment(pickedup?.date).format("MM-DD-YY")} ${moment(
        pickedup.date
      ).format("hh:mm:ss a")}`,
      user: pickedup?.updatedBy || "Unknown",
      icon: "/icons/historymodalicons/pickedUp.svg",
    };
  }

  // In Transit
  const transitat = orderHistory?.orderhistory?.find(
    (item) => item.name === "transit_At"
  );
  let transitatOrderItem = {};
  if (transitat) {
    transitatOrderItem = {
      label: "Route Created At",
      time: `${moment(transitat?.date).format("MM-DD-YY")} ${moment(
        transitat.date
      ).format("hh:mm:ss a")}`,
      user: transitat?.updatedBy || "Unknown",
      icon: "/icons/historymodalicons/Transit.svg",
    };
  }
  const isOutForDelivery = orderHistory?.orderhistory?.find(
    (item) => item.name === "out_For_Delivery"
  );
  const isDelivered = orderHistory?.orderhistory?.find(
    (item) => item.name === "Delivered"
  );
  const isReturned = orderHistory?.orderhistory?.find(
    (item) => item.name === "returned_Failed"
  );
  const isCancelled = orderHistory?.orderhistory?.find(
    (item) => item.name === "cancelled"
  );
  const isreturncreated = orderHistory?.orderhistory?.find(
    (item) => item.name === "returned_created_At"
  );
  const isreturned = orderHistory?.orderhistory?.find(
    (item) => item.name === "Returned"
  );
  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Divider sx={{ borderBottom: "2px solid #d9d9d9", width: "100%" }} />

      <TimelineItem
        label={createdByItem.label}
        time={createdByItem.time}
        user={createdByItem.user}
        icon={createdByItem.icon}
      />
      <Divider sx={{ borderBottom: "2px solid #d9d9d9", width: "100%" }} />
      <TimelineItem
        label={readyPickItem.label}
        time={readyPickItem.time}
        user={readyPickItem.user}
        icon={readyPickItem.icon}
      />
      <Divider sx={{ borderBottom: "2px solid #d9d9d9", width: "100%" }} />
      {orderHistory?.orderhistory?.filter(historyItem => historyItem.name !== "out_delivery_sms").map((historyItem, index) => {
        const attemptMessage =
          historyItem.name === "returned_Failed"
            ? getAttemptMessage(orderHistory.orderhistory, index)
            : "";

        return (
          <React.Fragment key={`history-item-${index}`}>
            {console.log(historyItem.name, "historyItem.name")}
            <TimelineItem
              label={
                historyItem.display_name
                  ? (historyItem.display_name === "transit At"
                    ? "Route created At"
                    : historyItem.display_name === "dispatched At"
                      ? "Dispatching"
                      : capitalizeWords(historyItem.display_name) + attemptMessage)
                  : "Unknown Status"
              }
              time={
                historyItem.date
                  ? `${moment(historyItem.date).format("MM-DD-YY")} ${moment(
                    historyItem.date
                  ).format("hh:mm:ss a")}`
                  : "Unknown Time"
              }
              user={historyItem.updatedBy || "Unknown"}
              icon={`/icons/historymodalicons/${historyItem.name}.svg` || null}
              extra={
                historyItem.name === "cancelled"
                  ? orderHistory?.orderDetail?.cancel_note || ""
                  : historyItem.name === "out_For_Delivery"
                    ? `ETD ${historyItem.comment}`
                    : historyItem.name === "returned_Failed"
                      ? historyItem.comment.replace(/Failed by Admin./g, "").trim()
                      : historyItem.name === "update_Order"
                        ? ""
                        : historyItem.comment || ""
              }
              smsDetails={orderHistory}
              isOutForDelivery={historyItem.name === "out_For_Delivery"}
            />
            <Divider
              sx={{ borderBottom: "2px solid #d9d9d9", width: "100%" }}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default VerticalTimeline;
