import React, { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ButtonsComponent from "../components/homePageCompo/tabComponents/ButtonsComponent";
import FilterComponent from "../components/homePageCompo/FilterComponent";
import ScanAndRouteButtons from "../components/homePageCompo/ScanAndRouteButtons";
import OrderDetails from "../components/homePageCompo/OrderDetails/OrderDetails";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { v4 as uuidv4 } from "uuid";
import { postApiWithAuth } from "../utils/api";
import {
  FCM_TOKEN,
  UNSEEN_NOTIFICATION_LIST,
  AUTH_ME,
  MARK_SEEN,
  TWILIO_UNREAD_SMS_COUNT,
} from "../utils/apiUrl";
import AnnouncementPopup from "../components/Ui/AnnouncementPopup";
import { getApiWithAuth } from "../utils/api";
import { toast } from "react-toastify";
const HomePage = () => {
  const userId = localStorage.getItem("UID");
  const [filterData, setFilterData] = useState(null);
  const [orders, setOrders] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [buttonData, setButtonData] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [popupOpen, setPopupOpen] = useState(true);
  const [response, setResponse] = useState("");
  const [selectedTab, setSelectedTab] = useState("all");
  const userType = localStorage.getItem("userType");
  const fetchOrdersRef = useRef();
  const handledNotifications = useRef(new Set());
  const [status, setStatus] = useState("all");
  const [label, setLabel] = useState();
  const [unreadCounts, setUnreadCounts] = useState({});
  
  const [transitOpen, setTransitOpen] = useState();
  const scrollContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [triggerFilterFetch, setTriggerFilterFetch] = useState(false);

  const firebaseConfig = {
    apiKey: "AIzaSyACIeDtvL3fE__Cmvx3XHUZ9PaEQGDz6xk",
    authDomain: "pilldrop-e5ee9.firebaseapp.com",
    projectId: "pilldrop-e5ee9",
    storageBucket: "pilldrop-e5ee9.appspot.com",
    messagingSenderId: "393895297538",
    appId: "1:393895297538:web:69f0761a352f36d4a09d1b",
    measurementId: "G-SJB9HDV4M5",
  };

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  function requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const messaging = getMessaging(app);
        getToken(messaging, {
          vapidKey:
            "BEemXXGaQ8Lc8GuuWT8YAlESr2Wia9jaVBpwiZpgq1HBrFTSCr2cp7mKByjgBqnV38zIZBnHbr5pjIUAJy0fF1k",
        }).then((currentToken) => {
          if (currentToken) {
            sendTokenToBackend(currentToken);
          } else {
            console.log("Can't get token");
          }
        });
      } else {
        console.log("Don't have permission");
      }
    });
  }

  const sendTokenToBackend = async (token) => {
    try {
      // Check if token has already been added
      const tokenAdded = localStorage.getItem("tokenAdded");

      if (tokenAdded === "true") {
        return;
      }

      const user_id = localStorage.getItem("UID");
      const pharmacyId = localStorage.getItem("pharmacyId");
      let deviceId = localStorage.getItem("deviceId");

      // If device ID doesn't exist, generate a new one
      if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem("deviceId", deviceId);
      }

      const response = await postApiWithAuth(FCM_TOKEN, {
        token,
        user_id,
        pharmacyId,
        deviceId,
      });

      // If token was successfully sent to the backend, set tokenAdded to true
      if (response.data.status === 200 || response.data.status === 201) {
        localStorage.setItem("tokenAdded", "true");
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const fetchAuthUserDetail = async () => {
    const response = await getApiWithAuth(AUTH_ME);
    if (response && response.data) {
      setUserDetail(response.data.data.data);
    }
  };

  useEffect(() => {
    fetchAuthUserDetail();
  }, []);

  useEffect(() => {
    requestPermission();
  
    const unsubscribe = onMessage(messaging, (payload) => {
      const notificationId = payload.messageId;
      if (handledNotifications.current.has(notificationId)) {
        return;
      }
      handledNotifications.current.add(notificationId);

      const { title, body, icon, image } = payload.notification;
      const notificationIcon = icon || "https://www.test.pilldrop.ai/logo192.png";
      const notificationImage = image;
  
      const notificationOptions = {
        body,
        icon: notificationIcon,
        image: notificationImage,
        data: {
          url: payload.data?.openUrl || "https://www.pilldrop.ai",
        },
      };
  
      const notification = new Notification(title, notificationOptions);
  
      notification.onclick = (event) => {
        event.preventDefault();
        const url = notificationOptions.data.url;
        if (url) {
          window.open(url, "_blank");
        }
      };
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const userType = localStorage.getItem("userType"); // Get user type from localStorage

        if (userType === "PharmacyOwner" || userType === "PharmacyEmployee") {
          const response = await getApiWithAuth(UNSEEN_NOTIFICATION_LIST);

          if (response && Array.isArray(response.data.data)) {
            setNotifications(response.data.data);
          } else {
            console.error("Unexpected data format:", response);
          }
        }
      } catch (err) {
        console.error("Error fetching notifications:", err);
        toast.error("Failed to fetch notifications.");
      }
    };

    fetchNotifications();
  }, []);
  const fetchUnreadSmsCount = async () => {
    const response = await getApiWithAuth(TWILIO_UNREAD_SMS_COUNT)
    const data = response.data.data;
    setUnreadCounts(data.unread_counts);
  };

  useEffect(() => {
    fetchUnreadSmsCount();
  }, []);

  const handleFilterData = (data) => {
    setFilterData(data);
  };

  const handleOrderData = (data) => {
    setOrders(data);
  };

  const markReadOrderChat = (id) => {
    console.log(id);
  };

  const handleButtonValue = (button) => {
    setButtonData(button);
    setSelectedTab(button);
  };

  const handleStatusValue = (status, label, transitOpen) => {
    setStatus(status);
    setLabel(label);
    setTransitOpen(transitOpen);
    setSelectedTab({'label': label})
  };

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } =
      scrollContainerRef.current;

    // Check if scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      if (!isLoading) {
        setIsLoading(true);
        fetchOrdersRef.current(true);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLoading]);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };
  const handleMarkSeen = async (notification_id) => {
    if (notification_id) {
      const colorFormData = new FormData();
      colorFormData.append("notification_id", notification_id);
      colorFormData.append("user_id", userId);
      try {
        const colorResponse = await postApiWithAuth(MARK_SEEN, colorFormData);
        if (colorResponse.data) {
          console.log("Mark read successfully");
        } else {
          console.log("Failed to update", colorResponse);
        }
      } catch (error) {
        console.error("Error updating:", error);
      }
    }
  };
  // Function to close the popup
  const handleClosePopup = (notification_id) => {
    setPopupOpen(false);
    handleMarkSeen(notification_id); // Pass the notification_id
  };

  const handleSubmitResponse = (userInput) => {
    setResponse(userInput); // Save the response in state
  };


  // useEffect(() => {
  //   // Initialize WebSocket connection
  //   const unreadSMSClient = new W3CWebSocket(
  //     "wss://testadmin.pilldrop.ai/ws/unread_sms/"
  //   );

  //   // Handle connection open
  //   unreadSMSClient.onopen = () => {
  //     console.log("WebSocket connection established for unread SMS counts");
  //   };

  //   // Handle incoming messages
  //   unreadSMSClient.onmessage = (e) => {
  //     try {
  //       const data = JSON.parse(e.data);
  //       if (data.type === "unread_sms_counts") {
  //         const counts = data.data;
  //         const { total_unread_count, unread_counts } = counts;

  //         // Update the state with new counts
  //         // setTotalUnreadCount(total_unread_count);
  //         // setUnreadCountsByOrder(unread_counts);

  //         setUnreadCounts(unread_counts);
  //       }
  //     } catch (error) {
  //       console.error("Error parsing WebSocket message:", error);
  //     }
  //   };

  //   // Handle connection close
  //   unreadSMSClient.onclose = () => {
  //     console.log("WebSocket connection closed for unread SMS counts");
  //     // Optionally, implement reconnection logic here
  //   };

  //   // Handle errors
  //   unreadSMSClient.onerror = (error) => {
  //     console.error("WebSocket error for unread SMS counts:", error);
  //   };

  //   // Cleanup function to close WebSocket connection when component unmounts
  //   return () => {
  //     unreadSMSClient.close();
  //   };
  // }, []);

  return (
    <>
      <Box
        ref={scrollContainerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "40px",
          minWidth: "100%",
          minHeight: "80vh",
          maxHeight: "100vh",
          overflowY: "auto",
          "@media (max-width: 1500px)": {
            minWidth: "1400px",
          },
        }}
      >
        <ButtonsComponent
          filterData={filterData}
          onButtonClick={handleButtonValue}
          fetchorders={() => fetchOrdersRef.current()}
          label={label}
          transitOpen={transitOpen}
        />
        <FilterComponent
          onFilterData={handleFilterData}
          onOrderData={handleOrderData}
          buttonData={buttonData}
          // markReadOrderChatMain={markReadOrderChatMain}
          statuschild={status}
          fetchOrdersRef={fetchOrdersRef}
          triggerFetch={triggerFilterFetch}
          resetTrigger={() => setTriggerFilterFetch(false)}
        />
        {notifications.length > 0 &&
          notifications.map((notification) => (
            <AnnouncementPopup
              key={notification.notification_id}
              open={popupOpen}
              title={notification.heading}
              description={notification.description}
              onClose={() => handleClosePopup(notification.notification_id)} // Pass the notification_id here
              onSubmit={handleSubmitResponse}
            />
          ))}
        {orders?.map((order, index) => (
          <OrderDetails
            key={order.id || index}
            showTicket={order.last_orderticket !== null}
            showUrgent={order.deliverydate === "Urgent"}
            showphotoId={order.photoid}
            showRefrigerated={order.refrigrated}
            order={order}
            fetchorders={() => fetchOrdersRef.current()}
            userDetail={userDetail}
            markReadOrderChat={markReadOrderChat}
            unreadCounts={unreadCounts?.[order?.id] || 0}
          />
        ))}
      </Box>
      {userType !== "PharmacyOwner" && userType !== "PharmacyEmployee" && (
        <ScanAndRouteButtons
          onstatusChange={handleStatusValue}
          selectedTab={selectedTab}
          onSuccess={() => setTriggerFilterFetch(true)}
        />
      )}
    </>
  );
};

export default HomePage;
