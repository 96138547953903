import React from "react";
import { Box } from "@mui/material";
import { ContainedButton } from "../buttonCompo/Buttons";

const DriverDateRange = ({ pharmacyDetails }) => {
  const dateRangeStart =
    pharmacyDetails?.month_start || "Start Date Not Available";
  const dateRangeEnd = pharmacyDetails?.month_end || "End Date Not Available";

  return (
    <Box sx={{ marginBottom: "10px" }}>
      <ContainedButton
        label={`${dateRangeStart} - ${dateRangeEnd}`}
        borderRadius="8px"
        sx={{
          backgroundColor: "#0B6699",
          color: "white",
          borderRadius: "8px",
          padding: "6px 12px",
          minWidth: "auto",
          fontSize: "12px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#08517B",
          },
        }}
      />
    </Box>
  );
};

export default DriverDateRange;
