import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  CallIcon,
  LocationIconBlack,
  MailIcon,
  PersonIconDriver,
  SettingsIcon,
  DeleteRedIcon
} from "../Icons";
import { deleteApiWithAuth } from "../../utils/api";
import { updateExpense } from "../../utils/apiUrl";
import {
  ContainedButton,
  ContainedButtonWithIcon,
} from "../buttonCompo/Buttons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const UserOverview = ({ data,refreshData }) => {
  const navigate = useNavigate();

  const handleSettingsClick = async (data, onDelete) => {
    const response = await deleteApiWithAuth(`${updateExpense}/${data.id}/delete/`);
    console.log(response)
    if (response.status !== 400) {
      toast.success('Expense Delete Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
        refreshData();
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 20px",
        boxShadow: 1,
        borderRadius: "8px",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f0f0f0",
          boxShadow: 1,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: "700px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            width: "207px",
            padding: "7px",
            border: "none",
            backgroundColor: "#f1f3f4",
            color: "#000",
            gap: 1,
          }}
        >
          <PersonIconDriver />
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, fontSize: "11.71px" }}
          >
            {data.name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            minWidth: "129px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <CallIcon />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {data.expense_type}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            maxWidth: "237px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <MailIcon />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {data.payment_type}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            maxWidth: "237px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {data.amount}
          </Typography>
        </Box>
        {data.description && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            maxWidth: "237px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {data.description}
          </Typography>
        </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
  <Box 
    sx={{ width: 32, height: 32, cursor: 'pointer' }} 
    onClick={() => handleSettingsClick(data)}
  >
    <DeleteRedIcon style={{ width: '100%', height: '100%' }} />
  </Box>
</Box>


      {/* <Box sx={{ display: "flex", gap: 1 }}>
        {!data.isActive && (
          <ContainedButton
            label="Deactivated"
            bgColor="#fc1002"
            width="91px"
            height="31.23px"
            border="none"
          />
        )}
        {data.isActive && (
          <ContainedButton
            label="Active"
            bgColor="#05b320"
            width="60.51px"
            height="31.23px"
            border="none"
          />
        )}

        <ContainedButtonWithIcon
          label="Setting"
          icon={<SettingsIcon />}
          bgColor="#000"
          borderRadius="8px"
          width="87px"
          height="32px"
          padding="4.19px"
          onClick={() => handleSettingsClick(data)}
        />
      </Box> */}
    </Box>
  );
};

export default UserOverview;
