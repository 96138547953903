import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, Divider } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "@mui/material/styles";
const yAxisTicks = [0, 1000, 2000, 3000, 4000,4000,5000,6000,7000, 8000, 9000, 10000];
const formatYAxis = (tick) => {
  return `${tick / 1000}k`;
};
const CustomTooltip = ({ active, payload, label }) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          border: "none",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: theme.palette.text.primary,
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            color: theme.palette.text.primary,
          }}
        >
          {payload[0].value.toLocaleString()}
        </Typography>
      </Box>
    );
  }

  return null;
};

const LineChartComponent = ({ orderNum }) => {
  console.log("orderdata.montly", orderNum);
  const [activeTab, setActiveTab] = useState(0);
  const monthsMap = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const lineColors = ["#0f4df2", "#2daf49", "#ff1109"]; // Created, Delivered, Canceled

  const transformData = (data) => {
    if (!data) return [];
    return Object.entries(data).map(([yearMonth, count]) => {
      const month = parseInt(yearMonth.split("-")[1], 10); // Extract month from "YYYY-MM"
      return {
        name: monthsMap[month] || `Month ${month}`,
        thisYear: count,
        lastYear: 0, // Update this if you have last year's data
      };
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Get the correct data based on the active tab
  const getDataForTab = () => {
    if (activeTab === 0) return transformData(orderNum?.monthly_data?.created);
    if (activeTab === 1)
      return transformData(orderNum?.monthly_data?.delivered);
    if (activeTab === 2) return transformData(orderNum?.monthly_data?.canceled);
    return [];
  };

  return (
    <Box
      sx={{
        padding: "20px",
        borderRadius: "12px",
        backgroundColor: "#f7f9fb",
        width: "100%",
        height: "330px",
        flex: 3,
      }}
    >
      {/* Tabs for switching between datasets */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="none"
          aria-label="chart tabs"
          sx={{ marginBottom: 2 }}
        >
          <Tab
            label="Created Orders"
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontWeight: activeTab === 0 ? 600 : 400, // Conditional font weight
            }}
          />
          <Tab
            label="Delivered Orders"
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontWeight: activeTab === 1 ? 600 : 400, // Conditional font weight
            }}
          />
          <Tab
            label="Canceled Orders"
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontWeight: activeTab === 2 ? 600 : 400, // Conditional font weight
            }}
          />
        </Tabs>
        {/* Divider */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            marginRight: 2,
            marginTop: 1.3,
            height: 24, // Decrease height of the divider
            alignSelf: "start",
          }} // Adjust height as needed
        />
        {/* Custom Legend aligned horizontally */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 2,
            gap: 4,
          }}
        ></Box>
      </Box>
      <ResponsiveContainer width="100%" height={210}>
        <LineChart data={getDataForTab()}>
          <XAxis
            dataKey="name"
            axisLine={false} // Remove the X-axis line
            tickLine={false} // Remove the X-axis tick line
            interval={0} // Show all ticks
            minTickGap={5} // Decrease gap between X-axis ticks
            tick={{ fontSize: 12, fill: "#9fa1a2" }} // Set font size and color of X-axis ticks
            dx={-10}
            dy={10}
          />
          <YAxis
            ticks={yAxisTicks} // Custom tick values for Y-axis
            tickFormatter={formatYAxis} // Format the ticks to show 'k' suffix
            axisLine={false} // Remove the Y-axis line
            tickLine={false} // Remove the Y-axis tick line
            interval={0} // Display every tick value from the array
            tick={{
              fontSize: 12,
              fill: "#9fa1a2",
              dx: -30,
              dy: 0, // Control vertical alignment of the Y-axis labels
            }} // Set font size and color of Y-axis ticks
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="thisYear"
            stroke={lineColors[activeTab]} // Dynamic color
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default LineChartComponent;
