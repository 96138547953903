import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { ChooseImageIcon } from "../../Icons"; // Assuming you have an icon

const Input = styled("input")({
  display: "none",
});

const Topbar = ({ onLogoUpload, logo }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(null);

  // If logo exists in props (for edit mode), generate the preview
  useEffect(() => {
    if (logo) {
      setSelectedImage(logo); // Set the logo from props
      if (logo instanceof File) {
        setPreview(URL.createObjectURL(logo)); // Generate preview URL from the file
      } else {
        setPreview(logo); // If logo is a URL or base64 string, use it directly for preview
      }
    }
  }, [logo]); // When logo changes, update the preview

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const { name, size, type, lastModified } = file;
      const fileBlob = new Blob([file], { type });
      const newFile = new File([fileBlob], name, { lastModified });

      setSelectedImage(newFile); // Update the selected image file state

      // Generate base64 for preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result); // Set base64 preview
      };
      reader.readAsDataURL(newFile);

      // Pass the file to the parent component
      onLogoUpload(newFile); // Update parent component's state with the new file

      toast.success("File selected successfully!");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
      }}
    >
      {/* File Input Section */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <label htmlFor="contained-button-file">
          <Input
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <Button
            variant="outlined"
            component="span"
            sx={{
              borderColor: "#05b320",
              color: "#05b320",
              width: "115px",
              padding: "10px 10px",
              textTransform: "none",
              fontSize: "13px",
              height: "32px",
            }}
          >
            Choose File
          </Button>
        </label>
        <Typography variant="body2" sx={{ color: "#757575" }}>
          {selectedImage ? selectedImage.name : "No File Chosen"}
        </Typography>

        {/* Display Preview or Default Icon */}
        <Box
          sx={{
            width: "40px",
            height: "40px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f4f4f4",
          }}
        >
          {preview ? (
            <img
              src={preview}
              alt="Logo Preview"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <ChooseImageIcon />
          )}
        </Box>
      </Box>

      {/* Action Buttons Section */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Button
          variant="contained"
          onClick={() => {}}
          sx={{
            backgroundColor: "#05b320",
            boxShadow: "none",
            textTransform: "none",
            borderRadius: "8px",
            width: "88px",
            height: "32px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#43a047",
            },
          }}
        >
          Activate
        </Button>
        <Button
          variant="outlined"
          sx={{
            borderColor: "#fc1002",
            boxShadow: "none",
            borderRadius: "8px",
            width: "105px",
            height: "32px",
            textTransform: "none",
            color: "#fc1002",
            "&:hover": {
              borderColor: "#d32f2f",
              backgroundColor: "#ffcdd2",
            },
          }}
        >
          Deactivate
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
