import React, { useState, useEffect, useRef } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import {
  getApiWithAuth,
  postApiWithAuth,
  patchApiWithAuth,
} from "../utils/api";
import {
  SEND_TWILIO_SMS,
  ALL_PHARMACY_LIST,
  TWILIO_SMS_LIST,
  ORDER_CHAT_LIST,
  TWILIO_UNREAD_SMS_COUNT,
} from "../utils/apiUrl";
import ChatHeader from "../components/ChatPageCompo/ChatHeader";
import ChatComponent from "../components/ChatPageCompo/ChatComponent";
import ChatCard from "../components/ChatPageCompo/ChatCard";
import ChatCard2 from "../components/ChatPageCompo/ChatCard2";
import ChatCard3 from "../components/ChatPageCompo/ChatCard3";

const ChatPage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [ticketOrders, setTicketOrders] = useState([]);
  const [smsId, setSMSId] = useState("");
  const [smsDetails, setSMSDetails] = useState();
  const [search, setSearch] = useState("");
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const [pharmacy, setPharmacy] = useState("");
  const [timer, setTimer] = useState("");
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [text, setText] = useState("");
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
  ]);

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (smsDetails) {
      scrollToBottom();
    }
  }, [smsDetails]);

  const fetchPharmacies = async () => {
    try {
      const response = await getApiWithAuth(ALL_PHARMACY_LIST, {
        justList: true,
      });
      if (response.data !== undefined) {
        setUniquePharmacy(
          response.data.data.map((item) => ({ id: item.id, name: item.name }))
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTicketClick = (smsId) => {
    setSMSId(smsId);
    setSelectedTicketId(smsId);
    getChatOrders({ needId: smsId });
    markReadOrderChat(smsId);
  };

  const getChatOrders = async ({ needId = null } = {}) => {
    setLoading(true);
    try {
      const response = await getApiWithAuth(TWILIO_SMS_LIST);
      if (response.data && Array.isArray(response.data.data)) {
        const ticketData = response.data.data; // Now an array of { group_key, sms }
        setTicketOrders(ticketData);
        setLoading(false);

        // Logging for debugging
        console.log("Current smsId:", smsId, "Need ID:", needId);

        if (smsId === "" && ticketData.length > 0) {
          const firstGroup = ticketData[0];
          setSMSId(firstGroup.group_key);
          setSelectedTicketId(firstGroup.group_key);
          setSMSDetails(firstGroup.sms);
        } else if (smsId !== "") {
          const currentGroup = ticketData.find(
            (group) => group.group_key === smsId
          );
          if (currentGroup && needId === smsId) {
            setSMSDetails(currentGroup.sms);
          }
        }
      } else {
        setLoading(false);
        console.warn("Unexpected response structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching ticket orders:", error);
      setLoading(false);
    }
  };

  const fetchUnreadSmsCount = async () => {
    const response = await getApiWithAuth(TWILIO_UNREAD_SMS_COUNT);
    const data = response.data.data;
    setUnreadCounts(data.unread_counts);
  };

  useEffect(() => {
    // Initialize WebSocket connection
    const unreadSMSClient = new W3CWebSocket(
      "wss://testadmin.pilldrop.ai/ws/unread_sms/"
    );

    // Handle connection open
    unreadSMSClient.onopen = () => {
      console.log("WebSocket connection established for unread SMS counts");
    };

    // Handle incoming messages
    unreadSMSClient.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data.type === "unread_sms_counts") {
          const counts = data.data;
          const { total_unread_count, unread_counts } = counts;

          // Update the state with new counts
          // setTotalUnreadCount(total_unread_count);
          // setUnreadCountsByOrder(unread_counts);

          setUnreadCounts(unread_counts);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    // Handle connection close
    unreadSMSClient.onclose = () => {
      console.log("WebSocket connection closed for unread SMS counts");
      // Optionally, implement reconnection logic here
    };

    // Handle errors
    unreadSMSClient.onerror = (error) => {
      console.error("WebSocket error for unread SMS counts:", error);
    };

    // Cleanup function to close WebSocket connection when component unmounts
    return () => {
      unreadSMSClient.close();
    };
  }, []);

  const markReadOrderChat = async (smsId) => {
    await patchApiWithAuth(
      ORDER_CHAT_LIST + `update_by_order/?order_id=${smsId}`,
      { is_read: true }
    );
    fetchUnreadSmsCount();
  };

  useEffect(() => {
    getChatOrders();
  }, [pharmacy, search, timer, dateRange]);

  useEffect(() => {
    fetchPharmacies();
    fetchUnreadSmsCount();
  }, []);

  const handleSendTwilioSms = async () => {
    if (smsId !== "") {
      setLoading(true);
      const response = await postApiWithAuth(SEND_TWILIO_SMS, {
        to_number: smsDetails[smsDetails.length - 1]?.clientNumber,
        text: text,
        order_id: smsDetails[smsDetails.length - 1]?.order?.id,
      });
      console.log(response.data);
      if (response.data.error === undefined) {
        toast.success(response.data.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setText("");
    } else {
      console.log("Add number to send sms");
    }

    setText("");
    getChatOrders({ needId: smsId });
    setLoading(false);
  };
  const [activeCard, setActiveCard] = useState(null);

  const handleCardClick = (cardId) => {
    setActiveCard(cardId); // Set active card when clicked
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {/* <Topbar /> */}
      <ChatHeader
        pharmacy={pharmacy}
        pharmacyList={uniquePharmacy}
        setPharmacy={setPharmacy}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setSearch={setSearch}
      />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxHeight: "80vh",
            overflowY: "auto",

            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "10px",
              border: "2px solid transparent",
              backgroundClip: "content-box",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-track:hover": {
              backgroundColor: "#e1e1e1",
            },
          }}
        >
          {ticketOrders &&
            ticketOrders.map((ticket, i) => (
              <Box
                display="flex"
                minWidth="500px"
                backgroundColor="#EBEBEC"
                borderRadius="12px"
                flexDirection="column"
                onClick={() => {
                  handleTicketClick(ticket.group_key);
                  setSMSDetails(ticket.sms);
                }}
                key={i}
              >
                <ChatCard3
                  key={i}
                  chat={ticket.sms}
                  unreadCounts={unreadCounts[ticket.group_key] || 0}
                  onClick={() => handleCardClick(ticket.group_key)} // Pass the card's ID to handle click
                  isActive={activeCard === ticket.group_key} // Check if this card is active
                />
              </Box>
            ))}
        </Box>
        <Box
          sx={{ flex: 3, paddingX: 2, maxHeight: "80vh", overflowY: "auto" }}
        >
          {smsDetails !== undefined && smsDetails.length > 0 && (
            <ChatComponent
              chatDetail={smsDetails}
              handleSendTwilioSms={handleSendTwilioSms}
              text={text}
              setText={setText}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatPage;
