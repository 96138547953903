import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from "recharts";

const TrafficByLocation = ({ orderNum }) => {
  const [activeIndex, setActiveIndex] = useState(null); // Track the hovered slice
  const [updatedPieData, setUpdatedPieData] = useState([]);

  const pieData = [
    { name: "Ready For Pickup", key: "1", color: "#0f4df2", value: 0 },
    { name: "In Transit", key: "4", color: "#fe9902", value: 0 },
    { name: "Delivered", key: "5", color: "#2daf49", value: 0 },
    { name: "Cancelled", key: "6", color: "#ff1109", value: 0 },
  ];

  useEffect(() => {
    if (orderNum) {
      const inTransitKeys = ["3", "2"];
      const inTransitCount = inTransitKeys.reduce(
        (sum, key) => sum + (orderNum[key] || 0),
        orderNum["4"] || 0
      );

      const updatedData = pieData.map((status) => {
        if (status.key === "4") {
          return { ...status, value: inTransitCount };
        }
        return { ...status, value: orderNum[status.key] || 0 };
      });

      setUpdatedPieData(updatedData);
    }
  }, [orderNum]);

  const onPieEnter = (data, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(null);
  };

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
      midAngle,
    } = props;

    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 0) * cos;
    const sy = cy + (outerRadius + 0) * sin;
    const ex = cx + (outerRadius - 25) * cos;
    const ey = cy + (outerRadius + 0) * sin;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 10}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <foreignObject x={ex - 35} y={ey - 20} width={50} height={30}>
          <Box
            sx={{
              backgroundColor: "rgba(0,0,0, 0.8)",
              color: "#fff",
              borderRadius: "10px",
              padding: "4px 8px",
              textAlign: "center",
              fontSize: "12px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {`${value}`}
          </Box>
        </foreignObject>
      </g>
    );
  };

  return (
    <Box
      sx={{
        padding: "16px",
        borderRadius: "12px",
        flex: 2,
        backgroundColor: "#f7f9fb",
        maxWidth: "445px",
        height: "280px",
      }}
    >
      <Typography
        variant="h1"
        sx={{ fontSize: "14px", fontWeight: 600, marginBottom: "16px" }}
      >
        Traffic by Location
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "180px", height: "180px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={updatedPieData}
                cx="50%"
                cy="50%"
                innerRadius={35}
                outerRadius={70}
                dataKey="value"
                startAngle={90}
                endAngle={-270}
                paddingAngle={3}
                cornerRadius={5}
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                onMouseEnter={onPieEnter}
                onMouseLeave={onPieLeave}
              >
                {updatedPieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>

        <Box>
          {updatedPieData.map((entry, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: entry.color,
                      marginRight: "8px",
                    }}
                  />
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      marginRight: "8px",
                    }}
                  >
                    {entry.name}
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                  {entry.value}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TrafficByLocation;
