import { Box, Typography, Tabs, Tab } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import SingleFieldDateRangePicker from "../dateRangePicker/DateRangePicker";

const CallBadge = styled(Box)(({ color }) => ({
  backgroundColor: color,
  borderRadius: "6px",
  padding: "4px 6px",
  color: "#fff",
  fontSize: "12px",
  display: "inline-block",
  fontWeight: "bold",
}));

const Topbar = ({ activeTab, handleTabChange, handleDateChange, dateRange }) => {

  return (
    <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%"
    }}>
      <Box
        sx={{
          display: "flex",
          gap: 4, // Reduced gap for tighter spacing
          alignItems: "center",
          paddingBottom: "8px",
        }}
      >
        {/* Tabs for Received and Missed Calls */}
        <Tabs
          value={activeTab} // Controlled by the parent component
          onChange={handleTabChange} // Passed down from the parent component
          TabIndicatorProps={{
            style: {
              backgroundColor: "#0b508e", // Custom indicator color (blue underline)
            },
          }}
          sx={{
            minHeight: "32px", // Decrease the height of the Tabs
          }}
        >
          <Tab
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px", // Slightly smaller font
                    color: "#637381",
                    textTransform: "none",
                  }}
                >
                  All Calls
                </Typography>
              </Box>
            }
            value="all"
            sx={{
              padding: "4px 8px", // Reduced padding
              marginRight: 1, // Smaller margin
              minHeight: "32px", // Reduced height for the Tab
            }}
          />

          {/* Dailed Calls Tab */}
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#637381",
                    textTransform: "none",
                  }}
                >
                  Dailed Calls
                </Typography>
              </Box>
            }
            value="dailed"
            sx={{
              padding: "4px 8px",
              marginRight: 1,
              minHeight: "32px",
            }}
          />

          {/* Received Calls Tab */}
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#637381",
                    textTransform: "none",
                  }}
                >
                  Received Calls
                </Typography>
              </Box>
            }
            value="received"
            sx={{
              padding: "4px 8px",
              marginRight: 1,
              minHeight: "32px",
            }}
          />

          {/* Missed Calls Tab */}
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#637381",
                    textTransform: "none",
                  }}
                >
                  Missed Calls
                </Typography>
              </Box>
            }
            value="missed"
            sx={{
              padding: "4px 8px",
              marginRight: 1,
              minHeight: "32px",
            }}
          />

          {/* Unknown Calls Tab */}
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#637381",
                    textTransform: "none",
                  }}
                >
                  Unknown Calls
                </Typography>
              </Box>
            }
            value="unknown"
            sx={{
              padding: "4px 8px",
              marginRight: 1,
              minHeight: "32px",
            }}
          />
        </Tabs>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <SingleFieldDateRangePicker
          value={dateRange}
          onChange={handleDateChange}
        />
      </Box>
    </Box>
  );
};

export default Topbar;
