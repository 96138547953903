import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Modal,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import {
  ContainedButton,
  OutlinedButtonWithIcon,
} from "../buttonCompo/Buttons";
const ActionButtons = ({ onSaveAndPrint, isEditable, onCancel, orderData }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cancelnote, setCancelNote] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
      {/* Left side buttons */}
      <Box sx={{ display: "flex", gap: 2 }}>
      {isEditable && [1, 2, 3].includes(Number(orderData?.order?.status)) && (
  <Button
    sx={{
      textTransform: "none",
      width: "139px",
      border: "1px solid #919eab",
      borderRadius: "5px",
      color: "black",
      fontWeight: "700",
      fontSize: "13px",
      display: "flex", // Flexbox for alignment
      alignItems: "center", // Center the icon and text
      padding: "5px 10px", // Add padding to the button
    }}
    onClick={(event) => handleClick(event)}
  >
    <img src="/icons/deliveryIcons/XCircle.svg" alt="icon" />
    Cancel Order
  </Button>
)}


        <Button
          sx={{
            textTransform: "none",
            border: "1px solid #919eab",
            borderRadius: "5px",
            color: "black",
            fontWeight: "700",
            fontSize: "13px",
            display: "flex", // Flexbox for alignment
            alignItems: "center", // Center the icon and text
            padding: "5px 25px", // Add padding to the button
          }}
          onClick={() => navigate("/order-list")}
        >
          Back
        </Button>
        {isEditable && (
          <Button
            onClick={onSaveAndPrint}
            sx={{
              textTransform: "none",
              border: "1px solid #919eab",
              borderRadius: "5px",
              color: "black",
              fontWeight: "700",
              fontSize: "13px",
              display: "flex", // Flexbox for alignment
              alignItems: "center", // Center the icon and text
              padding: "5px 10px", // Add padding to the button
            }}
          >
            <img src="/icons/deliveryIcons/Printer.svg" alt="icon" />
            Update & Print
          </Button>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            padding: 1,
            borderRadius: "10px",
            width: "254px",
            height: "121px",
            backgroundImage: "linear-gradient(45deg, #Fff5f3, #f1fbfd)",
          },
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "12px", color: "#0b6699" }}>
          Cancel Note
        </Typography>
        <Typography variant="body2"></Typography>
        <TextField
          fullWidth
          autoComplete="off"
          variant="outlined"
          // placeholder="To Add or Remove Any Staff Notes. If Note is Cleared and Saved Then Blue Highlight Will Be Removed."
          multiline
          rows={3}
          value={cancelnote}
          onChange={(e) => setCancelNote(e.target.value)}
          sx={{
            mb: 2,
          }}
          InputProps={{
            sx: {
              padding: 0, // Remove padding from the outer wrapper
              "& .MuiInputBase-input": {
                padding: 0, // Remove padding from the input field itself
                fontSize: "11px",
                fontWeight: 500,
                lineHeight: "1.2",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove the notched outline (border)
              },
            },
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <OutlinedButtonWithIcon
            label="Clear"
            onClick={handleClose}
            width="46px"
            height="20px"
            padding={1.3} // Ensure padding is set as desired
          />
          <ContainedButton
            label="Save"
            onClick={() => {
              onCancel(cancelnote); // Pass the cancelnote to onCancel
              handleClose(); // Close the popover after saving
            }}
            width="46px"
            height="20px"
          >
            Save
          </ContainedButton>
        </Box>
      </Popover>
    </Box>
  );
};

export default ActionButtons;
