import { Box } from "@mui/material";
import React from "react";
import ButtonsComponent from "../components/homePageCompo/tabComponents/ButtonsComponent";
import FilterComponent from "../components/homePageCompo/FilterComponent";
import OrderCard from "../smallComponents/orderCard";

const HomeSmall = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "40px",
          overflowX: "auto",
          width: "970px",
        }}
      >
        <ButtonsComponent />
        <FilterComponent />
        <OrderCard />
        <OrderCard />
        <OrderCard />
        <OrderCard />
      </Box>
    </>
  );
};

export default HomeSmall;
