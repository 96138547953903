import { Box, TextField, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { ContainedButtonWithIcon } from "../buttonCompo/Buttons";
import { AddIcon, SearchIcon } from "../Icons";

const Topbar = ({ onSearch }) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleAddDriverClick = () => {
    navigate("/adddrivers/add-info");
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onSearch(query); // Trigger the parent component's search function
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TextField
        value={searchQuery}
        onChange={handleSearchChange} // Call the handler on change
        variant="outlined"
        placeholder="Search"
        autoComplete="off"
        InputProps={{
          sx: {
            fontSize: "11px",
            border: "1px solid #dce0e4",
          },
          startAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        sx={{
          width: 408,
          backgroundColor: "#fff",
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            height: "36px",
            paddingLeft: 0,
            "& fieldset": {
              border: "none",
            },
          },
        }}
        size="small"
      />
      <ContainedButtonWithIcon
        label="Add Driver"
        icon={<AddIcon />}
        borderRadius="8px"
        width="105px"
        fontSize="13px"
        padding="4px"
        onClick={handleAddDriverClick}
      />
    </Box>
  );
};

export default Topbar;
