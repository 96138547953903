import React, {useEffect, useState} from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
} from "@mui/material";
import { getApiWithAuth } from "../utils/api";
import { NOTIFICATION_LIST } from "../utils/apiUrl";
import moment from "moment";
const AnnoucmentNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };
  const fetchNotifications = async () => {
    // const validEndDate =
    //   dateRange[1] && !isNaN(dateRange[1].getTime())
    //     ? dateRange[1]
    //     : new Date();
    try {
      const response = await getApiWithAuth(NOTIFICATION_LIST);
      setNotifications(response.data.data);
    } catch (error) {
      console.error("Error fetching facility data:", error);
    }
  };
  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Announcement Notifications
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: "80vh" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Title</strong>
              </TableCell>
              <TableCell>
                <strong>Description</strong>
              </TableCell>
              <TableCell sx={{ width: "150px" }}>
                <strong>Created Date</strong>
              </TableCell>
              <TableCell sx={{ width: "150px" }}>
                <strong>End Date</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.heading}</TableCell>
                <TableCell>
                  <Tooltip title={row.description}>
                    <span>{truncateText(row.description, 200)}</span>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: "150px" }}> {moment(row.create_date).format("MM-DD-YYYY")}</TableCell>
                <TableCell sx={{ width: "150px" }}>{moment(row.end_date).format("MM-DD-YYYY")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AnnoucmentNotification;
