import React, { useState, useEffect, useCallback, useRef } from "react";
import { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Dialog, DialogContent, Button, DialogTitle } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import Printdoc from "../../src/components/PDF/PrintDoc";
import moment from "moment";
import {
  landline,
  mobile,
  iphone,
  buildings,
  route_report,
  locationImg,
  upload,
  pdfLogo,
  registered,
  photoId,
  pharmacyCallBlack,
} from "../assests";
import PatientDetails from "../components/createOrderPageCompo/PatientDetails";
import PatientOrderDetails from "../components/createOrderPageCompo/PatientOrderDetails";
import DeliveryInstructions from "../components/createOrderPageCompo/DeliveryInstructions";
import ActionButtons from "../components/createOrderPageCompo/ActionButtons";
import TabButtons from "../components/createOrderPageCompo/TabButtons";
import SlipPreview from "../components/createOrderPageCompo/SlipPreview";
import DateTimeFilters from "../components/createOrderPageCompo/DateTimeFilters";
import { getApiWithAuth, postApiWithAuth } from "../utils/api";
import { ALL_PHARMACY_LIST, CREATE_ORDER, MERGE_ORDER } from "../utils/apiUrl";
import { useNavigate } from "react-router-dom";
import { OrderDataContext } from "../context/OrderDataContext";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import styles

import { useReactToPrint } from "react-to-print";

const DeliveryPage = () => {
  const [activeTab, setActiveTab] = useState("uploaded");
  const location = useLocation();
  const [loading, setLoading] = useState("");
  const { orderData, setOrderData } = useContext(OrderDataContext);
  const { finalOrderData, setFinalOrderData } = useContext(OrderDataContext);
  const [orderResponse, setOrderResponse] = useState({});
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);
  const [duplicateOrder, setDuplicateOrder] = useState(null);
  const [isMerge, setIsMerge] = useState(false); // Initially false
  const [selectedOrderFile, setSelectedOrderFile] = useState(null);

  const [addressError, setAddressError] = useState(null);


  useEffect(() => {
    if (orderData && orderData?.file) {
      const selectedOrderFile = orderData?.file;
    }
    getPharmacyDetail();
  }, [orderData, setOrderData]);

  const pid = localStorage.getItem("pharmacyId");
  const [pharmacyDetail, setPharmacyDetail] = useState({});
  const navigation = useNavigate();
  const componentRef = useRef(null);
  const reportTemplateRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => {
      if (!componentRef.current) {
        return null;
      }
      return componentRef.current;  // Return the component for printing
    },
    onBeforeGetContent: () => {
      // console.log("Before getting content...");
    },
    onAfterPrint: () => {
      navigation("/order-list");
      setOrderData({ order: {} })
    }
  });

  const componentRef2 = useRef();
  const handleMergeOrder = async (isMergeAction) => {
    setDuplicateModalVisible(false);
    setIsMerge(isMergeAction);
    // Check if the order details are empty
    if (orderData?.orderdetail.length === 0) {
      toast.error("Order details are empty. Cannot process the order.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    const fileName = localStorage.getItem("myFile");
    // Validate address details before proceeding
    if (orderData?.zip !== "") {
      // console.log("Order Details:", orderData);
      setLoading(true);

      const formData = new FormData();

      // Append order details to formData
      formData.append("deliverydate", orderData?.timeWindow);
      formData.append("order_file_name", fileName || "");
      formData.append("language", orderData?.language);
      formData.append("duplicateorder", duplicateOrder.id);
      formData.append("merge", isMergeAction);
      formData.append("apart", orderData?.apart);
      console.log("orderData?.deliveryDate",orderData?.deliveryDate)
      console.log("orderData?.deliveryDate",moment(orderData?.deliveryDate).format("MM/DD/YYYY"))
      formData.append(
        "deliveryTime",
        moment(orderData?.deliveryDate).format("MM/DD/YYYY")
      );
      formData.append("name", orderData?.name);
      formData.append("address", orderData?.address);
      formData.append("contact", orderData?.phoneNumber && orderData?.phoneNumber !== "(___) ___-____" ? orderData?.phoneNumber : "");
      formData.append("cell", orderData?.cellNumber && orderData?.cellNumber !== "(___) ___-____" ? orderData?.cellNumber : "");
      formData.append("workNumber", orderData?.workNumber && orderData?.workNumber !== "(___) ___-____" ? orderData?.workNumber : "");
      formData.append("zip", orderData?.zip);
      formData.append("notes", orderData?.notes ?? "");
      formData.append("status", "1");
      formData.append("location_image", orderData?.streetViewUrl);
      formData.append("signature", orderData?.signRequired);
      formData.append("photoid", orderData?.reqPhotoId);
      formData.append("refrigrated", orderData?.registed);
      formData.append("collectcopay", orderData?.collectCopay);
      formData.append("hippaform", orderData?.hippaForm);
      formData.append("lineform", orderData?.lineForm);
      formData.append("nfaqbform", orderData?.nfForm);
      formData.append("totalcopay", orderData?.totalCopay);
      formData.append("addresstype", orderData?.addresstype);
      if (orderData?.addresstype) {
        formData.append("business_start_time", orderData?.business_start_time || "");
        formData.append("business_end_time", orderData?.business_end_time || "");
      } else {
        formData.append("business_start_time", "");
        formData.append("business_end_time", "");
      }
      formData.append("parcelPlace", orderData?.parcelPlace);
      formData.append("lat", orderData?.lat);
      formData.append("lng", orderData?.lng);
      formData.append(
        "daily_order_id",
        parseInt(pharmacyDetail.todayOrderCount) + 1
      );
      // console.log("selecteddorderfile", selectedOrderFile, orderData?.file);
      formData.append("order_file", selectedOrderFile || null); // selected file
      formData.append("products", JSON.stringify(orderData?.orderdetail));

      try {
        const responsePersonal = await postApiWithAuth(MERGE_ORDER, formData);
        // console.log("response dataa", responsePersonal);
        localStorage.removeItem("myFile");
        localStorage.removeItem("orderData");
        if (
          responsePersonal.data.data !== undefined &&
          responsePersonal.data.data.message !== "Duplicate order found"
        ) {
          toast.success("Order Created Successfully!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOrderResponse(responsePersonal.data.data);
        } else if (
          responsePersonal.data.data.message === "Duplicate order found"
        ) {
          setDuplicateOrder(responsePersonal.data.data.order_details); // Display duplicate order details in modal
          setDuplicateModalVisible(true);
        } else if (responsePersonal.data.error) {
          toast.error("Order File Format Does Not Match Our Criteria", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error) {
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } finally {
        setLoading(false);
      }
    }

  };

  const handleCloseDuplicateDialog = () => {
    setDuplicateModalVisible(false);
  };

  const handleOrderDetailChange = useCallback(
    (updatedData) => {
      setOrderData((prevData) => {
        const updatedOrderData = { ...prevData, ...updatedData };
        console.log("updated order data in parnrt", updatedOrderData)
        const hasChanged = Object.keys(updatedData).some(
          (key) => updatedData[key] !== prevData[key]
        );
        return hasChanged ? updatedOrderData : prevData;
      });
    },
    [setOrderData]
  );

  React.useEffect(() => {
    const pharmacyId = localStorage.getItem("pharmacyId");
    const userRole = localStorage.getItem("userType");
    if (userRole !== "PharmacyOwner" && userRole !== "PharmacyEmployee") {
      return;
    }
    if (pid !== undefined && parseInt(pid) !== parseInt(pharmacyId)) {
      navigation("/404");
    }
  }, [pid, navigation]);

  const getPharmacyDetail = async () => {
    const response = await getApiWithAuth(ALL_PHARMACY_LIST + `/${pid}`);
    if (response.data) {
      setPharmacyDetail(response.data.data);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSaveAndPrint = async (event) => {
    event.preventDefault();
    console.log(orderData)
    if (orderData?.orderdetail.length === 0) {
      toast.error("Order details are empty. Cannot process the order.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    if (addressError === "" && orderData?.zip !== "") {
      setLoading(true);
      const fileName = localStorage.getItem("myFile");

      const formData = new FormData();
      const safeValue = (value) => (value === undefined || value === null ? "" : value);

      formData.append("deliverydate", safeValue(orderData?.timeWindow));
      formData.append("order_file_name", safeValue(fileName));
      formData.append("language", safeValue(orderData?.language));
      formData.append("apart", safeValue(orderData?.apart));

      console.log("orderData?.deliveryDate",orderData?.deliveryDate)
      console.log("orderData?.deliveryDate",moment(orderData?.deliveryDate).format("MM/DD/YYYY"))
      console.log("orderData?.deliveryDate",moment.utc(orderData?.deliveryDate).format("MM/DD/YYYY"))
      formData.append(
        "deliveryTime",
        safeValue(moment(orderData?.deliveryDate).format("MM/DD/YYYY"))
      );
      formData.append("name", safeValue(orderData?.name));
      formData.append("address", safeValue(orderData?.address));
      formData.append(
        "contact",
        orderData?.phoneNumber && orderData?.phoneNumber !== "(___) ___-____"
          ? orderData?.phoneNumber
          : ""
      );
      formData.append(
        "cell",
        orderData?.cellNumber && orderData?.cellNumber !== "(___) ___-____"
          ? orderData?.cellNumber
          : ""
      );
      formData.append(
        "workNumber",
        orderData?.workNumber && orderData?.workNumber !== "(___) ___-____"
          ? orderData?.workNumber
          : ""
      );
      formData.append("zip", safeValue(orderData?.zip));
      formData.append("notes", safeValue(orderData?.notes));
      formData.append("status", "1");
      formData.append("location_image", safeValue(orderData?.streetViewUrl));
      formData.append("signature", safeValue(orderData?.signRequired));
      formData.append("photoid", safeValue(orderData?.reqPhotoId));
      formData.append("refrigrated", safeValue(orderData?.registed));
      formData.append("collectcopay", safeValue(orderData?.collectCopay));
      formData.append("hippaform", safeValue(orderData?.hippaForm));
      formData.append("lineform", safeValue(orderData?.lineForm));
      formData.append("nfaqbform", safeValue(orderData?.nfForm));
      formData.append("totalcopay", safeValue(orderData?.totalCopay));
      formData.append("addresstype", safeValue(orderData?.addresstype));
      formData.append(
        "business_start_time",
        orderData?.addresstype ? safeValue(orderData?.business_start_time) : ""
      );
      formData.append(
        "business_end_time",
        orderData?.addresstype ? safeValue(orderData?.business_end_time) : ""
      );
      formData.append("parcelPlace", safeValue(orderData?.parcelPlace));
      formData.append("lat", safeValue(orderData?.lat));
      formData.append("lng", safeValue(orderData?.lng));
      formData.append(
        "daily_order_id",
        parseInt(pharmacyDetail.todayOrderCount) + 1
      );
      formData.append("order_file", selectedOrderFile || null);

      formData.append("products", JSON.stringify(orderData?.orderdetail));

      try {
        const responsePersonal = await postApiWithAuth(CREATE_ORDER, formData);
        if (
          responsePersonal.data.data !== undefined &&
          responsePersonal.data.data.message !== "Duplicate order found"
        ) {
          toast.success("Order Created Successfully!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOrderResponse(responsePersonal.data.data);
          localStorage.removeItem("myFile");
          localStorage.removeItem("orderData");
        } else if (
          responsePersonal.data.data.message === "Duplicate order found"
        ) {
          setDuplicateOrder(responsePersonal.data.data.order_details);
          setDuplicateModalVisible(true);
        } else if (responsePersonal.data.error) {
          toast.error("Order File Format Does Not Match Our Criteria", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          localStorage.removeItem("myFile");
          localStorage.removeItem("orderData");
        }
      } catch (error) {
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        localStorage.removeItem("myFile");
        localStorage.removeItem("orderData");
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (orderResponse && Object.keys(orderResponse).length > 0) {
      if (componentRef && componentRef.current) {
        try {
          handlePrint();
        } catch (error) {
          console.error("Error occurred during handlePrint execution:", error);
        }
      } else {
        console.error("componentRef is not set or invalid. Skipping handlePrint.");
      }
    }
  }, [orderResponse]);


  return (
    <>
      <ToastContainer />
      <Box
        sx={{
          px: 4,
          py: 1,
          display: "flex",
          flexDirection: "column",
          gap: 3,
          mx: "auto",
          maxWidth: "1700px",
          height: "calc(100vh - 60px)",
          overflow: "hidden",
        }}
      >
        <Box sx={{ display: "flex", gap: 4, flexGrow: 1, height: "100%" }}>
          <Box
            sx={{
              flexGrow: 2,
              display: "flex",
              flexDirection: "column",
              minWidth: "775px",
              height: "100%", // Restrict height to container
              overflowY: "auto", // Enable vertical scrolling
              paddingRight: "32px", // Add space on the right for the scrollbar
              "&::-webkit-scrollbar": {
                width: "4px", // Change width of the scrollbar
                backgroundColor: "#fff", // Background color of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#cbd3da", // Color of the scrollbar thumb
                borderRadius: "5px", // Round edges of the scrollbar thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555", // Darker color when hovered
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ flexGrow: 1 }}>
                <DateTimeFilters
                  onOrderDetailChange={handleOrderDetailChange}
                />
              </Box>
            </Box>
            <PatientDetails
              pharmacyDetail={pharmacyDetail}
              onOrderDetailChange={handleOrderDetailChange}
              setAddressError={setAddressError}
              addressError={addressError}
            />
            <PatientOrderDetails
              onOrderDetailChange={handleOrderDetailChange}
            />
            <DeliveryInstructions
              onOrderDetailChange={handleOrderDetailChange}
            />
            <ActionButtons onSaveAndPrint={handleSaveAndPrint} />
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "600px",
              maxHeight: "100%",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <TabButtons activeTab={activeTab} onTabChange={handleTabChange} />
            <SlipPreview activeTab={activeTab} orderData={orderData} />
          </Box>
        </Box>
      </Box>
      <div style={{ display: "none" }}>
        <div
          style={{ margin: "13px", padding: "16px", maxWidth: "3.75in", color: "black", }}
          ref={componentRef}
        >
          <div style={{ display: "flex", justifyContent: "space-between", color: "black" }}>
            <div>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Date: {moment(orderResponse?.createdAt).format("MM/DD/YYYY")}
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Time: {moment(orderResponse?.createdAt).format("hh:mm:ss A")}
              </p>
            </div>
            <img
              src={pdfLogo}
              style={{ width: "120px", fontFamily: "Saira, sans-serif" }}
              alt="logo"
            />
            <div>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Order #: {parseInt(pharmacyDetail.todayOrderCount) + 1}
              </p>
            </div>
          </div>
          <hr />
          <div style={{ display: "flex", justifyContent: "space-between", color: "black", }}>
            <div>
              <h3
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Order ID:{" "}
                <span style={{ textDecoration: "underline" }}>
                  {orderResponse && orderResponse.slug}
                </span>
              </h3>
              <h3
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                CLIENT:{" "}
                <span style={{ textDecoration: "underline", color: "black" }}>
                  {orderResponse?.name}
                </span>
              </h3>
              <h5
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 300,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Rx Count: {orderResponse?.orderdetail?.length}
              </h5>
              <h5
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  fontFamily: "Saira, sans-serif",
                  width: "150px",
                  color: "black",
                }}
              >
                Delivery Preference:
              </h5>
              <h5
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: 300,
                  padding: "0 5px",
                  textTransform: "uppercase",
                  fontFamily: "Saira, sans-serif",
                  width: "150px",
                  color: "black",
                }}
              >
                {orderResponse?.parcelPlace}
              </h5>
              <h4
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Delivery Date:{" "}
                {moment(orderResponse?.deliverAtSelected).format("MM-DD-YYYY")}
              </h4>
              <h4
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                Time Window: {orderResponse?.deliverydate}
              </h4>
              {orderResponse?.notes !== "" && (
                <>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "13px",
                      fontFamily: "Saira, sans-serif",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontFamily: "Saira, sans-serif",
                        color: "black",
                      }}
                    >
                      Delivery Note:
                    </span>
                  </p>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "13px",
                      padding: "5px 0",
                      fontFamily: "Saira, sans-serif",
                      width: "2.1in",
                      color: "black",
                    }}
                  >
                    {orderResponse?.notes}
                  </p>
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "5px 0",
                color: "black",
              }}
            >
              {orderResponse?.totalcopay !== undefined &&
                orderResponse?.totalcopay !== 0 && (
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: "16px",
                      border: "2px solid black",
                      padding: "0 2px",
                      textAlign: "center",
                      marginBottom: "10px",
                      width: "90px",
                      color: "black",
                    }}
                  >
                    COPAY: $ {orderResponse?.totalcopay.toFixed(2)}
                  </p>
                )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "black",
                }}
              >
                {orderResponse && Object.keys(orderResponse).length !== 0 ? (
                  <QRCodeSVG
                    value={orderResponse.id.toString()}
                    renderAs="svg"
                    size={80}
                  />
                ) : (
                  <QRCodeSVG renderAs="svg" size={80} value="no Order" />
                )}
                <h5
                  style={{
                    margin: "10px 0",
                    fontSize: "22px",
                    fontWeight: 800,
                    textTransform: "uppercase",
                    fontFamily: "Saira, sans-serif",
                    color: "black",
                  }}
                >
                  {orderResponse.city_code ? (
                    <>
                      {orderResponse.city_code}
                      {orderResponse.zone_code
                        ? ` - ${orderResponse.zone_code}`
                        : ""}
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              fontFamily: "Saira, sans-serif",
              color: "black",
            }}
          >
            {(orderResponse?.hippaform ||
              orderResponse?.lineform ||
              orderResponse?.nfaqbform) && (
                <>
                  <h4
                    style={{
                      margin: "0",
                      fontSize: "14px",
                      fontWeight: 700,
                      padding: "10px 0",
                      fontFamily: "Saira, sans-serif",
                      color: "black",
                    }}
                  >
                    Get Forms Signed:
                  </h4>
                  <h5
                    style={{
                      margin: "0",
                      fontSize: "12px",
                      fontWeight: 700,
                      fontFamily: "Saira, sans-serif",
                      color: "black",
                    }}
                  >
                    {orderResponse?.hippaform && <span>Hipaa Form</span>}
                    {orderResponse?.lineform && (
                      <span
                        style={{
                          marginLeft: "8px",
                          fontFamily: "Saira, sans-serif",
                          color: "black",
                        }}
                      >
                        Lien Form
                      </span>
                    )}
                    {orderResponse?.nfaqbform && (
                      <span
                        style={{
                          marginLeft: "8px",
                          fontFamily: "Saira, sans-serif",
                          color: "black",
                        }}
                      >
                        NF AOB Form
                      </span>
                    )}
                  </h5>
                </>
              )}
            <div style={{ textAlign: "center", margin: 0 }}>
              {/* Header text */}
              <h4
                style={{
                  margin: 0,
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Saira, sans-serif",
                  color: "black",
                }}
              >
                {orderResponse?.refrigrated && orderResponse?.photoid
                  ? "Refrigerated / Photo ID"
                  : orderResponse?.photoid
                    ? "Photo ID"
                    : orderResponse?.refrigrated
                      ? "Refrigerated"
                      : ""}
              </h4>

              {/* Icon(s) */}
              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {orderResponse?.refrigrated && orderResponse?.photoid ? (
                  <>
                    <img
                      src={registered}
                      style={{ marginRight: "5px" }}
                      alt="Refrigerated Icon"
                    />
                    <img
                      src={photoId}
                      style={{ marginLeft: "5px" }}
                      alt="Photo ID Icon"
                    />
                  </>
                ) : orderResponse?.photoid ? (
                  <img src={photoId} alt="Photo ID Icon" />
                ) : orderResponse?.refrigrated ? (
                  <img src={registered} alt="Refrigerated Icon" />
                ) : null}
              </div>
            </div>
            <h5
              style={{
                margin: "0",
                marginTop: "10px",
                fontSize: "13px",
                fontWeight: 700,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              From
            </h5>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 700,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              {pharmacyDetail.name}
            </h4>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 300,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              {pharmacyDetail.location}
            </h4>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 300,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
                color: "black",
              }}
            >
              Phone {pharmacyDetail.contact_no}
            </h4>
          </div>
        </div>
      </div>
      <Dialog open={duplicateModalVisible}>
        <DialogTitle>
          <div style={{ textAlign: "center", justifyContent: "center" }}>
            {" "}
            Duplicate Order Found
          </div>
        </DialogTitle>
        <DialogContent>
          <p>
            <strong>Order Name:</strong> {duplicateOrder?.name}
          </p>
          <p>
            <strong>Address:</strong> {duplicateOrder?.address}
          </p>
          <p>
            <strong>Phone:</strong> {duplicateOrder?.contact}
          </p>
          <p>
            <strong>Cell:</strong> {duplicateOrder?.cell}
          </p>
          <p>
            <strong>Work:</strong> {duplicateOrder?.workNumber}
          </p>

          <Button
            onClick={() => handleMergeOrder(true)} // Pass true for merge using an arrow function
            style={{ marginRight: "10px", marginTop: "10px" }}
            variant="contained"
            color="primary"
          >
            Merge Order
          </Button>
          <Button
            onClick={() => handleMergeOrder(false)} // Pass true for merge using an arrow function
            style={{ marginRight: "10px", marginTop: "10px" }}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeliveryPage;
