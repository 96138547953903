import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { OrderDataContext } from "../../context/OrderDataContext";

const DeliveryInstructions = ({ onOrderDetailChange }) => {
  const theme = useTheme();
  const { orderData, setOrderData } = useContext(OrderDataContext);
  const [localData, setLocalData] = useState(orderData);
  useEffect(() => {
    setLocalData(orderData);
  }, [orderData]);
  const handleInputChange = (field, value) => {
    setLocalData((prev) => {
      const updatedRxList =
        field === "collectCopay"
          ? prev.orderdetail.map((rx) => ({ ...rx, select: value }))
          : prev.orderdetail;

      let totalCopay = 0;

      if (field === "collectCopay" && value) {
        totalCopay = updatedRxList.reduce(
          (sum, rx) => sum + parseFloat(rx.pat_pay_amount || 0),
          0
        );
      }

      return {
        ...prev,
        [field]: value,
        orderdetail: updatedRxList,
        totalCopay: totalCopay,
      };
    });
  };
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      onOrderDetailChange(localData);
    }, 300);
    return () => clearTimeout(debounceTimer);
  }, [localData, onOrderDetailChange]);

  return (
    <Box sx={{ mb: 1, width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "38px",
          marginBottom: "5px",
          backgroundColor: "#f4f6f8",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            opacity: "0.7",
            paddingLeft: "15px",
          }}
        >
          Delivery Information
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <TextField
          placeholder="Write any additional Indications/Missing Information"
          multiline
          rows={4.5}
          autoComplete="off"
          sx={{
            mb: 2,
            margin: 0,
            width: "376px",
            padding: 0,
            "& .MuiOutlinedInput-root": {
              paddingX: 0,
              paddingTop: 0,
            },
            "& .MuiInputBase-input": {
              padding: 0,
              paddingX: "5px",
              fontSize: "11px",
            },
          }}
          name="notes"
          value={localData?.notes}
          onChange={(e) => handleInputChange("notes", e.target.value)}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: 0.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.signRequired}
                  onChange={(e) =>
                    handleInputChange("signRequired", e.target.checked)
                  }
                  name="signRequired"
                  sx={{ transform: "scale(0.8)" }}
                />
              }
              label={
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  Signature Required
                </Typography>
              }
              sx={{
                marginRight: 0,
              }}
            />
            <FormControl
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "12px",
                  paddingRight: "30px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  height: "34px",
                  width: "auto",
                },
              }}
            >
              <Select
                labelId="dropdown-label"
                name="parcelPlace"
                value={localData?.parcelPlace}
                onChange={(e) =>
                  handleInputChange("parcelPlace", e.target.value)
                }
                sx={{
                  fontSize: "11px",
                  "& .MuiSvgIcon-root": {
                    color: "black", // Change icon color
                  },
                }}
                IconComponent={(props) => (
                  <img
                    src="/icons/deliveryIcons/Dropdown.svg"
                    alt="dropdown"
                    {...props}
                  />
                )}
              >
                <MenuItem value="">
                  <Typography variant="body2">Select Parcel Place</Typography>
                </MenuItem>
                <MenuItem value="Face to face">
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    Face to Face
                  </Typography>
                </MenuItem>
                <MenuItem value="Leave with receptionist">
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    Leave with receptionist
                  </Typography>
                </MenuItem>
                <MenuItem value="Leave at door if pt e-signs">
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    Leave at door if PT E-Signs
                  </Typography>
                </MenuItem>
                <MenuItem value="Leave at door">
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    Leave at door
                  </Typography>
                </MenuItem>
                <MenuItem value="Leave in the mailbox">
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    Leave in the mail box
                  </Typography>
                </MenuItem>
                <MenuItem value="Leave in mail box if pt e-signs">
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    Leave in the mail box if PT E-Signs
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              Get Signed From
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.hippaForm}
                  onChange={(e) =>
                    handleInputChange("hippaForm", e.target.checked)
                  }
                  name="hippaForm"
                  sx={{ transform: "scale(0.8)" }}
                />
              }
              label={<Typography variant="body2">Hipaa Form</Typography>}
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.lineForm}
                  onChange={(e) =>
                    handleInputChange("lineForm", e.target.checked)
                  }
                  name="lineForm"
                  sx={{ transform: "scale(0.8)" }}
                />
              }
              label={<Typography variant="body2">Lien Form</Typography>}
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.nfForm}
                  onChange={(e) =>
                    handleInputChange("nfForm", e.target.checked)
                  }
                  name="nfForm"
                  sx={{ transform: "scale(0.8)" }}
                />
              }
              label={<Typography variant="body2">NF AOB Form</Typography>}
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.registed}
                  onChange={(e) =>
                    handleInputChange("registed", e.target.checked)
                  }
                  name="registed"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#00b8d9",
                    "&.Mui-checked": {
                      color: "#00b8d9",
                    },
                  }}
                />
              }
              label="Refrigerated"
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "#00b8d9",
                },
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.reqPhotoId}
                  onChange={(e) =>
                    handleInputChange("reqPhotoId", e.target.checked)
                  }
                  name="reqPhotoId"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#fe9604",
                    "&.Mui-checked": {
                      color: "#fe9604",
                    },
                  }}
                />
              }
              label="Required Photo ID"
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "#fe9604",
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.collectCopay || false} // Ensure it's always boolean
                  onChange={(e) =>
                    handleInputChange("collectCopay", e.target.checked)
                  }
                  name="collectCopay"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#05b320",
                    "&.Mui-checked": {
                      color: "#05b320",
                    },
                  }}
                />
              }
              label={`Copay ($${localData?.totalCopay?.toFixed(2) || "0.00"})`} // Display total copay
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "#05b320",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeliveryInstructions;
