import React from "react";
import { Box, Typography } from "@mui/material";
import VerticalTimeline from "./TimelineItem";
import { ContainedButton } from "../../buttonCompo/Buttons";
import { CallIcon } from "../../Icons";
import moment from "moment";
import RouteTimelineItem from "./RouteTimelineItem";

function floatToTime(floatValue) {
  const hours = Math.floor(floatValue / 60);
  const remainingMinutes = floatValue % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes % 1) * 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
  }

  return parts.join(" ");
}

const HistoryComponent = ({ isRoutes, orderHistory, onClose, handlePharmacyCallModelOpen = {} }) => {
  const userType = localStorage.getItem("userType");


  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "8px",
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {!isRoutes ? (
            <Box>
              <Typography variant="body2" fontSize="12px" fontWeight="bold">
                {orderHistory?.orderDetail?.pharmacy?.name}
              </Typography>
              <Typography variant="body2" fontSize="12px" fontWeight="bold">
                Order ID: {orderHistory?.orderDetail?.slug}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="body2"
                fontSize="12px"
                fontWeight="bold"
                color="#fe9604"
              >
                In Progress
              </Typography>
              <Typography variant="body2" fontSize="12px" fontWeight="bold">
                Route Name: All Orders Route
              </Typography>
              <Typography variant="body2" fontSize="12px" fontWeight="bold">
                Driver: XYZ DRIVER
              </Typography>
            </Box>
          )}
          {!isRoutes && (
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 0.5,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (userType === "Admin" || userType === "Staff") {
                      onClose();
                      handlePharmacyCallModelOpen(orderHistory?.orderDetail?.pharmacy?.contact_no);
                    }
                  }}
                >
                  <CallIcon />
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      fontSize: "11px",
                    }}
                  >
                    {orderHistory?.orderDetail?.pharmacy?.contact_no}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 0.5,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (userType === "Admin" || userType === "Staff") {
                      onClose();
                      handlePharmacyCallModelOpen(orderHistory?.orderDetail?.pharmacy?.emergency_contact);
                    }
                  }}
                >
                  <CallIcon />
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      fontSize: "11px",
                    }}
                  >
                    {orderHistory?.orderDetail?.pharmacy?.emergency_contact}
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="body2"
                fontSize="12px"
                sx={{
                  fontWeight: 600,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                Client: {orderHistory?.orderDetail?.name}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {isRoutes ? (
            <Box>
              <Typography variant="body2" fontSize="12px">
                Total Stops: {orderHistory?.routeDetail?.totalOrderStop}
              </Typography>
              <Typography variant="body2" fontSize="12px">
                ETC: {floatToTime(orderHistory?.routeDetail?.estimatedTimeCal)}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="body2" fontSize="12px">
                Delivery Date:
                {moment(orderHistory?.orderDetail?.deliverAtSelected).format(
                  "MM-DD-YY"
                )}
              </Typography>
              <Typography variant="body2" fontSize="12px">
                Delivery Prefrence: {orderHistory?.orderDetail?.parcelPlace}
              </Typography>
            </Box>
          )}
          {!isRoutes ? (
            <Box>
              <Typography
                variant="body2"
                fontSize="11px"
                sx={{ fontWeight: 500 }}
              >
                Time Window: {orderHistory?.orderDetail?.deliverydate}
              </Typography>
              <Typography
                variant="body2"
                fontSize="11px"
                sx={{ fontWeight: 500 }}
              >
                Signatures:
                {orderHistory?.orderDetail?.signature
                  ? "Required"
                  : "Not Required"}
              </Typography>
              {orderHistory?.orderDetail?.totalcopay !== 0 && (
                <Typography
                  variant="body2"
                  fontSize="11px"
                  sx={{ fontWeight: 500 }}
                >
                  Copay: {orderHistory?.orderDetail?.totalcopay.toFixed(2)}
                </Typography>
              )}
              {orderHistory?.orderDetail?.totalcopay !== 0 &&
                orderHistory?.orderDetail?.status === "5" &&
                orderHistory?.orderDetail?.iscollected && (
                  <Typography
                    variant="body2"
                    fontSize="11px"
                    sx={{ fontWeight: 500 }}
                  >
                    Collected By:
                    {orderHistory?.orderDetail?.driver?.firstName +
                      " " +
                      orderHistory?.orderDetail?.driver?.lastName}
                  </Typography>
                )}
              {orderHistory?.orderDetail?.totalcopay !== 0 &&
                orderHistory?.orderDetail?.status === "5" &&
                orderHistory?.orderDetail?.ispaidpharmacy && (
                  <Typography
                    variant="body2"
                    fontSize="11px"
                    sx={{ fontWeight: 500 }}
                  >
                    Received By: {orderHistory?.orderDetail?.receiverName}
                  </Typography>
                )}
              {orderHistory?.orderDetail?.totalcopay !== 0 &&
                orderHistory?.orderDetail?.status === "5" &&
                orderHistory?.orderDetail?.iswaivedpharmacy && (
                  <Typography
                    variant="body2"
                    fontSize="11px"
                    sx={{ fontWeight: 500 }}
                  >
                    Waived By: {orderHistory?.orderDetail?.waivedBy}
                  </Typography>
                )}
            </Box>
          ) : (
            <Box>
              <Typography variant="body2" fontSize="12px">
                Total Distance:
                {(
                  parseFloat(orderHistory?.routeDetail?.distanceCal) * 0.621371
                ).toFixed(2)}
                mi
              </Typography>
              {orderHistory?.routeDetail?.routeStatus === "Done" && (
                <Typography variant="body2" fontSize="12px">
                  ATC:
                  {floatToTime(orderHistory?.routeDetail?.completedTimeCal)}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Box>
            {orderHistory?.orderDetail?.notes &&
              orderHistory?.orderDetail?.notes !== "undefined" && (
                <Typography
                  variant="body2"
                  fontSize="11px"
                  sx={{ fontWeight: 500 }}
                >
                  Note: {orderHistory?.orderDetail?.notes}
                </Typography>
              )}
            {/* <Typography
              variant="body2"
              fontSize="11px"
              sx={{ fontWeight: 500 }}
            >
              BUILDING ENTRANCE CODE IS 8249
            </Typography> */}
            {orderHistory?.orderDetail?.orderticket && (
              <Typography
                variant="body2"
                fontSize="11px"
                sx={{
                  fontWeight: 600,
                  lineHeight: "20.24px",
                  width: "300px",
                }}
              >
                <span style={{ color: "#0b6699" }}>Ticket:</span>
                {
                  orderHistory?.orderDetail?.orderticket?.ticketcomment_set[0]
                    .comment
                }
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {orderHistory?.orderDetail?.orderticket && (
              <Typography
                variant="body2"
                fontSize="10px"
                sx={{
                  fontWeight: 600,
                }}
              >
                Created At:
                {moment(
                  orderHistory?.orderDetail?.orderticket?.created_at
                ).format("MM-DD-YY")}
                &nbsp;
                {moment(
                  orderHistory?.orderDetail?.orderticket?.created_at
                ).format("hh:mm:ssa")}
                By:
                {orderHistory?.orderDetail?.orderticket?.created_by?.username}
              </Typography>
            )}
            {orderHistory?.orderDetail?.orderticket?.ticket_status ===
              "Completed" && (
                <Typography
                  variant="body2"
                  fontSize="10px"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  <span style={{ color: "#e23333" }}>Closed At:</span>
                  {moment(
                    orderHistory?.orderDetail?.orderticket?.updated_at
                  ).format("MM-DD-YY")}
                  &nbsp;
                  {moment(
                    orderHistory?.orderDetail?.orderticket?.updated_at
                  ).format("hh:mm:ssa")}
                  By:
                  {orderHistory?.orderDetail?.orderticket?.updated_by
                    ?.first_name +
                    orderHistory?.orderDetail?.orderticket?.updated_by?.last_name}
                </Typography>
              )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ height: "400px", overflowY: "auto", pr: 1 }}>
        {!isRoutes ? (
          <VerticalTimeline orderHistory={orderHistory} />
        ) : (
          <RouteTimelineItem orderHistory={orderHistory} />
        )}
      </Box>

      {/* Footer Section */}
      <Box sx={{ textAlign: "end", mt: 3 }}>
        <ContainedButton
          label="OK"
          onClick={onClose}
        >
          OK
        </ContainedButton>
      </Box>
    </Box>
  );
};

export default HistoryComponent;
