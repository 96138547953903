import { Box } from "@mui/material";
import React, {useState, useEffect} from "react";
import Topbar from "../components/zonePageCompo/Topbar";
import ZoneOverview from "../components/zonePageCompo/ZoneOverview";
import { getApiWithAuth } from "../utils/api";
import { ALL_ZONES_LIST } from "../utils/apiUrl";
const ZonePage = () => {
  const [loading, setLoading] = useState(false);
  const [borough, setBorough] = useState([]);
  const getBorough = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ALL_ZONES_LIST+ 'borros/');
    console.log(response)
    if (response.data !== undefined) {
      setBorough(response.data.data)
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBorough();
  }, []);
  return (
    <Box>
      <Topbar getBorough={getBorough} borough={borough} />
      <ZoneOverview  getBorough={getBorough} borough={borough} />
    </Box>
  );
};

export default ZonePage;
