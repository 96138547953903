import React from 'react';
import { AcUnit } from "@mui/icons-material";
import moment from 'moment';
import './times-new-roman-reg.ttf';
import { pdfLogo } from "../../assests";
import QRCode from "qrcode.react";

const RouteSlipReturn = ({ routeOrders, route }) => {
    console.log("routeprderrss", routeOrders, route)
    const today = moment().format('MM-DD-YYYY'); // Format the date as "MM-DD-YYYY"
    const dayOfWeek = moment().format('dddd'); // Get the day of the week

    const styles = {
        page: {
            width: '7.7in',
            height: '9.25in',
            margin: '10px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Times New Roman, sans-serif',
            pageBreakBefore: 'always',
        },
        qrCode: {
            margin: '10px auto', // Center the QR code
        },
        pageHead: {
            display: 'flex',
            flexDirection: 'column', // Change to column
            fontFamily: 'Times New Roman, sans-serif',
            fontSize: '11px',
            fontWeight: '600',
            marginBottom: '5px', // Reduce margin
            padding: '5px', // Add padding
            marginLeft: '15px',
        },
        headerTop: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '5px', // Adjust margin
            marginLeft: '15px',
        },
        headerBottom: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: '15px',
        },
        orderList: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '15px',
            marginRight: '50px',
        },
        table: {
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: '5px',
        },
        tableHeader: {
            borderBottom: '1px solid black',
            fontSize: '14px',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '5px',
        },
        tableRow: {
            textAlign: 'center',
            fontSize: '12px',
        },
        icon: {
            marginLeft: '5px',
            fontSize: '12px',
        },
        driverInfo: {
            marginLeft: '15px', // Added left margin for driver info
        },
        orderBottom: {
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '20px',
            marginTop: '20px',
            marginRight: "30px",
            fontSize: '12px',
           }
    };

    return (
        <>
            {routeOrders.map((order, i) => (
                <div key={i} style={styles.page}>
                    <div style={styles.pageHead}>
                        <div style={styles.headerTop}>
                            <p>{dayOfWeek}, {today}</p>
                            <img src={pdfLogo} style={{ width: "120px" }} alt="logo" />
                        </div>
                        <div style={styles.headerBottom}>
                            {/* <p>Route {route.detail_name}</p> */}
                            <p>Pharmacy Returns</p>

                        </div>
                        {/* <div style={styles.headerBottom}>
                            <p>Assigned Driver Name: {route?.driver?.name}</p>
                        </div> */}
                    </div>
                    <div style={styles.qrCode}>
                        <QRCode value={order.pharmacy.toString()} renderAs="svg" size={120} />
                    </div>
                    
                    <div style={styles.orderList}>
                        <table style={styles.table}>
                            <thead>
                                <tr>
                                    <th style={{ ...styles.tableHeader, width: '80px' }}>No</th>
                                    <th style={{ ...styles.tableHeader, width: '140px' }}>Order Id</th>
                                    <th style={{ ...styles.tableHeader, width: '280px' }}>Name</th>
                                    <th style={{ ...styles.tableHeader, width: '280px' }}>Pharmacy Name</th>
                                    <th style={{ ...styles.tableHeader, width: '140px' }}>Refrigerated</th>
                                    {/* <th style={{ ...styles.tableHeader, width: '210px' }}>Copay $</th>
                                    <th style={{ ...styles.tableHeader, width: '140px' }}>Status</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {order.order.map((item, j) => (
                                    <tr key={j} style={styles.tableRow}>
                                        <td>{j + 1} </td>
                                        <td style={{ fontWeight: '400' }}>{item.slug}</td>
                                        <td>{item.name}</td>
                                        <td>{item.pharmacy_name}</td>
                                        <td>
                                            {item.refrigrated ? (
                                                <>
                                                    Yes
                                                    <AcUnit style={styles.icon} />
                                                </>
                                            ) : (
                                                'No'
                                            )}
                                        </td>
                                        {/* <td>
                                            {item.totalcopay > 0 && (
                                                <>
                                                    $ {item.totalcopay}
                                                    {item.iscollected && ' Collected'}
                                                    {item.iswaivedpharmacy && ' Waived'}
                                                </>
                                            )}
                                        </td>
                                        <td>{order.status}</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={styles.orderBottom}>
                  <p>Copay <span style={{paddingLeft: '30px', fontWeight: '800', paddingRight: '30px', borderBottom: '1px solid black'}}>{order.total_copay}</span></p>
                  <div style={{display: 'flex'}}>
                     <p>From Date <span style={{paddingLeft: '10px', fontWeight: '800', paddingRight: '10px', borderBottom: '1px solid black'}}>{moment(order.from_date).format('MM/DD/YYYY')}</span></p>
                     <p>To Date <span style={{paddingLeft: '10px', fontWeight: '800', paddingRight: '10px', borderBottom: '1px solid black'}}>{moment(order.to_date).format('MM/DD/YYYY')}</span></p>
                  </div>
                  </div>
                    <div style={styles.orderBottom}>
                  <p>Receiver Name <span style={{paddingLeft: '30px', fontWeight: '800', paddingRight: '30px', borderBottom: '1px solid black'}}>{order.receiverName}</span></p>
                  <div style={{display: 'flex'}}>
                     <p>Date <span style={{paddingLeft: '10px', fontWeight: '800', paddingRight: '10px', borderBottom: '1px solid black'}}>{moment(order.date).format('MM/DD/YYYY')}</span></p>
                     <p>Time <span style={{paddingLeft: '10px', fontWeight: '800', paddingRight: '10px', borderBottom: '1px solid black'}}>{moment(order.date).format('hh:mm:ss a')}</span></p>
                  </div>
               
               </div>
               
               <div style={styles.orderBottom}>
                  {/* <p>Receiver Signature <span style={{paddingLeft: '30px', fontWeight: '800', paddingRight: '30px', borderBottom: '1px solid black'}}><img src={process.env.REACT_APP_LINK_BASE_IMAGE_URL + orderDetail.signature_img} alt="signature_img" /></span></p> */}
                  <p style={{ display: 'flex' }}>
                     Receiver Signature 
                     <span style={{ paddingLeft: '30px', fontWeight: '800', paddingRight: '30px' }}>
                        {order.signature_image && (
                           <img 
                           style={{ height: "35px", width: '90px' }} 
                           src={order.signature_image} 
                           alt="signature" 
                           />
                        )}
                     </span>
                  </p>
                  <p>Delivered by <span style={{paddingLeft: '30px', fontWeight: '800', paddingRight: '30px', borderBottom: '1px solid black'}}>
                    {route?.driver?.name}
                </span></p>
               </div>
               
                </div>
            ))}
        </>
    );
};

export default RouteSlipReturn;
