import { Box } from "@mui/material";
import React from "react";
import Numbers from "./Numbers";

const StatusCounter = ({orderNum}) => {
  return (
    <Box>
      <Numbers orderNum={orderNum} />
    </Box>
  );
};

export default StatusCounter;
