import React, { useState, useRef, useContext, useEffect } from "react";
import { Box, Button } from "@mui/material";
import SubTabData from "./SubTabData";
import { useNavigate } from "react-router";
import { UPLOAD_FILE, ORDER_FILE_UPLOAD } from "../../../utils/apiUrl";
import { postApiWithAuth } from "../../../utils/api";
import { useDisplayMode } from "../../../context/DisplayModeContext";
import SearchbarSmall from "../../../smallComponents/SearchbarSmall";
import { OrderDataContext } from "../../../context/OrderDataContext";
import OrderDetails from "../OrderDetails/OrderDetails";

const ButtonsComponent = ({ filterData, onButtonClick, fetchorders, label, transitOpen }) => {
  const userType = localStorage.getItem("userType");
  const buttonData = [
    {
      label: "all",
      color: "#212B36",
      num: String(filterData?.all || 0).padStart(2, "0"),
    },
    {
      label: "Ready to pick up",
      color: "#0d4df2",
      num: String(filterData?.[1] || 0).padStart(2, "0"),
    },
    {
      label: "In Transit",
      color: "#fe9901",
      num: String(
        (filterData?.[2] || 0) +
          (filterData?.[3] || 0) +
          (filterData?.[4] || 0) +
          (filterData?.[7] || 0) +
          (filterData?.[8] || 0) +
          (filterData?.[9] || 0)
      ).padStart(2, "0"),
    },
    {
      label: "Delivered",
      color: "#2cb04b",
      num: (() => {
        const numValue = String(filterData?.[5] || 0).padStart(2, "0");
        return numValue;
      })(),
    },
    {
      label: "Canceled",
      color: "#fb1001",
      num: String(
            (filterData?.[6] || 0) +
              (filterData?.[10] || 0) +
              (filterData?.[11] || 0) +
              (filterData?.[12] || 0)
          )
    },
  ];
  const navigate = useNavigate();
  const { displayMode } = useDisplayMode();
  const fileInputRef = useRef(null);
  const [activeButton, setActiveButton] = useState("All");
  const [file, setFile] = useState("");
  const [showSubTabData, setShowSubTabData] = useState({
    inTransit: false,
    canceled: false,
  });
  const { setOrderData } = useContext(OrderDataContext);
  useEffect(() => {
    if (userType === "PharmacyOwner" || userType === "PharmacyEmployee") {
      console.log("abc");
      handleButtonClick("1")
    }
  }, []);
  useEffect(() => {
    let newSubTabData = {};
    if (transitOpen === undefined) {
      newSubTabData = {
        ...filterData,
        inTransit: false,
        canceled: false
      };
    }
    else if (transitOpen) {
      setActiveButton("In Transit")
      newSubTabData = {
        ...filterData,
        inTransit: true,
        canceled: false
      };
    } else if (!transitOpen) {
      setActiveButton("Canceled")
      newSubTabData = {
        ...filterData,
        inTransit: false,
        canceled: true
      };
    }
    setShowSubTabData(newSubTabData);
  }, [label, transitOpen])

  const handleButtonClick = (index) => {
    const clickedButton = buttonData[index];
    setActiveButton(clickedButton.label);
    onButtonClick && onButtonClick(clickedButton);
    let newSubTabData = {};
    if (index === 2) {
      newSubTabData = {
        ...filterData,
        inTransit: !showSubTabData.inTransit,
        canceled: false,
      };
    }
    // else if (index === 4) {
    //   newSubTabData = {
    //     ...filterData,
    //     inTransit: false,
    //     canceled: !showSubTabData.canceled,
    //   };
    // } else {
    //   newSubTabData = {
    //     ...filterData,
    //     inTransit: false,
    //     canceled: false,
    //   };
    // }
    else if (index === 4) {
      if (userType === "Admin" || userType === "SubAdmin") {
        newSubTabData = {
          ...filterData,
          inTransit: false,
          canceled: !showSubTabData.canceled,
        };
      } else {
        newSubTabData = {
          ...filterData,
          inTransit: false,
          canceled: false,
        };
        console.log(
          "Access denied: Only admin or subadmin can view canceled orders."
        );
      }
    } else {
      newSubTabData = {
        ...filterData,
        inTransit: false,
        canceled: false,
      };
    }
    setShowSubTabData(newSubTabData);
  };

  const uploadFile = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile || selectedFile.type !== "application/pdf") {
      alert("Please select a valid PDF file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const content = reader.result;
      localStorage.setItem("File", content);
    };
    reader.readAsDataURL(selectedFile);

    try {
      const formData = new FormData();
      formData.append("file_uploaded", selectedFile);
      setFile(selectedFile);

      const response = await postApiWithAuth(ORDER_FILE_UPLOAD, formData);
      const responseUpload = await postApiWithAuth(UPLOAD_FILE, formData);

      if (responseUpload.data) {
        localStorage.setItem("myFile", responseUpload.data.data.file_name);
      }

      if (response.data) {
        const patientData = response.data.data;
        const clientData = patientData.client; // This is where client data resides
        setOrderData((prev) => ({
          // ...prev,
          patientData, // Merge the fetched data into the context
          file: selectedFile, // Set the selected file data
          name: clientData.name || prev.name, // Optionally update fields
          address: clientData.address || prev.address,
          cellNumber: clientData.cell_number || prev.cellNumber,
          workNumber: clientData.work_number || prev.workNumber,
          phoneNumber: clientData.phone_number || prev.phone_number,
          zip: clientData.zip || prev.zip,
          orderdetail: patientData.text || prev.orderdetail,
          createdAt: new Date(),
          deliveryDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000), // 1 day ahead
          timeWindow: "Anytime",
          language: "english",
          apart: "",
          note: "",
          signRequired: true,
          reqPhotoId: false,
          registed: false,
          collectCopay: false,
          hippaForm: false,
          lineForm: false,
          nfForm: false,
          addresstype: false,
          business_start_time: "09:00",
          business_end_time: "17:00",
          parcelPlace: "Face to face",
          totalCopay: 0,
        }));
        navigate("/add-order", { replace: true });
      }
    } catch (error) {
      console.error("File upload failed", error);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  console.log("--sdsd", label)

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, mb: 2, ml: 1 }}>
          {buttonData.map((button, index) => (
            <Button
              key={index}
              onClick={() => handleButtonClick(index)}
              sx={{
                backgroundColor: "transparent",
                color: activeButton === button.label ? "#000" : "#434242",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "22.62px",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                gap: 1,
                position: "relative",
                padding: 0,
                paddingBottom: "10px",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "2px",
                  backgroundColor: activeButton === button.label ? "black" : "#f2f3f5",
                  zIndex: activeButton === button.label ? 3 : 2,
                },
              }}
            >
              {button.label}
              {index === 2 && (
                <Box
                  component="img"
                  src="/icons/Dropdown.svg"
                  alt="Arrow"
                  sx={{
                    width: "8px",
                    height: "8px",
                    transform: showSubTabData[
                      index === 2 ? "inTransit" : "canceled"
                    ]
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                />
              )}
              {index === 4 &&
                (userType === "SubAdmin" || userType === "Admin") && (
                  <Box
                    component="img"
                    src="/icons/Dropdown.svg"
                    alt="Arrow"
                    sx={{
                      width: "8px",
                      height: "8px",
                      transform: showSubTabData[
                        index === 2 ? "inTransit" : "canceled"
                      ]
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  />
                )}
              <Box
                sx={{
                  backgroundColor: button.color,
                  color: "#fff",
                  borderRadius: "25%",
                  padding: "2px 6px",
                  fontSize: "12.34px",
                  fontWeight: "bold",
                }}
              >
                {button.num}
              </Box>
            </Button>
          ))}
        </Box>

        <input
          type="file"
          ref={fileInputRef}
          accept="application/pdf"
          style={{ display: "none" }}
          onChange={uploadFile}
        />

        <Box sx={{ paddingBottom: 2 }}>
          {displayMode === "small" ? (
            <SearchbarSmall />
          ) : (
            (userType === "PharmacyOwner" ||
              userType === "PharmacyEmployee") && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#289A16",
                  color: "#fff",
                  borderRadius: "10px",
                  width: "94px",
                  height: "32px",
                  textTransform: "none",
                }}
                onClick={handleUploadClick}
                endIcon={
                  <img
                    src="/icons/Upload.svg"
                    alt="Upload Icon"
                    style={{ width: 15, height: 15 }}
                  />
                }
              >
                Upload
              </Button>
            )
          )}
          {/* <Button
            variant="contained"
            sx={{
              display: "flex",
              marginBottom: 2,
              backgroundColor: "#289A16",
              color: "#fff",
              borderRadius: "10px",
              width: "94px",
              height: "32px",
              textTransform: "none",
            }}
          >
            Count 00
          </Button> */}
        </Box>
      </Box>

      {showSubTabData.inTransit && (
        <SubTabData onSubTabClick={onButtonClick} filterData={filterData} labelShow={label} />
      )}
      {showSubTabData.canceled && (
        <SubTabData
          onSubTabClick={onButtonClick}
          canceled={true}
          filterData={filterData}
          labelShow={label}
        />
      )}
    </Box>
  );
};

export default ButtonsComponent;
