import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Select,
  FormControl,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { OrderDataContext } from "../../context/OrderDataContext";
import debounce from "lodash/debounce";

const DateTimeFilters = ({ onOrderDetailChange, isEditable }) => {
  const theme = useTheme();
  const { orderData, setOrderData } = useContext(OrderDataContext);
  const [localOrderData, setLocalOrderData] = useState(orderData?.order); // State to track local changes
  useEffect(() => {
    console.log("OrderData on mount/update:", orderData);
    setLocalOrderData(orderData.order);
  }, [orderData]);
  // Handle form field changes
  const handleOrderChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (!name) return;
    let updatedValue = value;
    if (type === "checkbox") {
      updatedValue = checked;
    }
    // Update localOrderData with changes
    setLocalOrderData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  // Handle delivery date change
  // const handleDeliveryDateChange = (event) => {
  //   const dateValue = event.target.value;
  //   console.log("date changes", dateValue, new Date(dateValue));
  //   setLocalOrderData((prevData) => ({
  //     ...prevData,
  //     deliverAtSelected: new Date(dateValue),
  //   }));
  // };
  const handleDeliveryDateChange = (event) => {
    const dateValue = event.target.value; // YYYY-MM-DD
    console.log("dateeeeeeeeeeee",dateValue)
    setLocalOrderData((prevData) => ({
        ...prevData,
        deliverAtSelected: dateValue, // Keep it as a string, don't convert to Date()
    }));
};
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      onOrderDetailChange(localOrderData);
    }, 300);
    return () => clearTimeout(debounceTimer);
  }, [localOrderData, onOrderDetailChange]);
  const getFormattedDate = (date) => {
    if (date) {
      const dateObj = new Date(date);
      return dateObj.toISOString().split("T")[0]; // Format to "yyyy-MM-dd"
    }
    return "";
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ mb: 1, display: "flex", gap: 2, width: "626px" }}>
        {/* Created Date */}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 0.5,
          }}
        >
          <Typography variant="h1" sx={{ fontSize: "13px", fontWeight: 600 }}>
            Created Date
          </Typography>
          <TextField
            type="date"
            autoComplete="off"
            value={getFormattedDate(localOrderData?.createdAt)}
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "11px",
                paddingX: "5px",
                color: "black",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
              "& .MuiInputLabel-root": { fontSize: "11px" },
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                height: "34px",
                width: "100px",
              },
            }}
            disabled
          />
        </Box>

        {/* Delivery Date */}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 0.5,
          }}
        >
          <Typography variant="h1" sx={{ fontSize: "13px", fontWeight: 600 }}>
            Delivery Date
          </Typography>
          <TextField
            type="date"
            name="deliveryDate"
            autoComplete="off"
            disabled={!isEditable}
            value={getFormattedDate(localOrderData?.deliverAtSelected)}
            onChange={handleDeliveryDateChange}
            sx={{
              "& .MuiInputBase-input": { fontSize: "11px", paddingX: "5px" },
              "& .MuiInputLabel-root": { fontSize: "11px" },
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                height: "34px",
                width: "100px",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
          />
        </Box>

        {/* Time Window Select */}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 0.5,
          }}
        >
          <Typography variant="h1" sx={{ fontSize: "13px", fontWeight: 600 }}>
            Time Window
          </Typography>
          <FormControl
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "11px",
                paddingRight: "30px",
                color: "black",
              },
              "& .MuiInputLabel-root": { fontSize: "11px", color: "black" },
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                height: "34px",
                width: "100px",
                backgroundColor: "white",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
          >
            <Select
              labelId="dropdown-label"
              value={localOrderData?.deliverydate || ""} // Ensure it gets a default value if undefined
              name="deliverydate"
              disabled={!isEditable}
              onChange={handleOrderChange}
              sx={{
                fontSize: "11px",
                color: "black",
                "& .MuiSvgIcon-root": { color: "black" },
              }}
            >
              <MenuItem
                value="Anytime"
                sx={{ fontSize: "13px", color: "black" }}
              >
                Anytime
              </MenuItem>
              <MenuItem
                value="9am-3pm"
                sx={{ fontSize: "13px", color: "black" }}
              >
                9am-3pm
              </MenuItem>
              <MenuItem
                value="2pm-6pm"
                sx={{ fontSize: "13px", color: "black" }}
              >
                2pm-6pm
              </MenuItem>
              <MenuItem
                value="5pm-9pm"
                sx={{ fontSize: "13px", color: "black" }}
              >
                5pm-9pm
              </MenuItem>
              <MenuItem
                value="Urgent"
                sx={{ fontSize: "13px", color: "black" }}
              >
                Urgent
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default DateTimeFilters;
