import React from "react";
import { Box, Typography, Button, Avatar, Icon, Badge } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import { LocationIconBlack, PersonIconBlack } from "../Icons";
import moment from "moment";

const ChatCard3 = ({ chat, unreadCounts, onClick, isActive }) => {
  return (
    <Box
      backgroundColor={isActive ? "#EBEBEC" : "#FFFFFF"}
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        borderRadius: "8px",
        // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        // margin: "8px 0",
        width: "100%",
      }}
    >
      {/* Left Section */}
      <Box sx={{ display: "flex", gap: "16px", flex: 1 }}>
        {/* Avatar */}

        {/* Details */}
        <Box>
          {/* Name */}

          <Badge badgeContent={unreadCounts} color="error">
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Order ID {chat[0]?.order?.slug ? chat[0]?.order?.slug : "N/A"}
            </Typography>
          </Badge>

          {/* Client Name */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PersonIconBlack />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                color: "#0b6699",
                marginTop: "4px",
              }}
            >
              {chat[0]?.order?.name ? chat[0]?.order?.name : "N/A"}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {/* Agent Name */}
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                marginTop: "4px",
              }}
            >
              User ID: {chat[0]?.agent_name}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Right Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        {/* Chat Status */}
        <Button
          variant="outlined"
          sx={{
            fontSize: "12px",
            color: `${chat[0]?.type === "outgoing" ? "#28a745" : "#FE9604"}`,
            borderColor: `${chat[0]?.type === "outgoing" ? "#28a745" : "#FE9604"}`,
            borderRadius: "6px",
            padding: "4px 12px",
            marginBottom: "8px",
            textTransform: "none",
          }}
        >
          {chat[0]?.type === "outgoing" ? "Sent" : "Received"}
        </Button>

        <Typography
          sx={{ fontSize: "12px", color: "#000", marginBottom: "4px", fontWeight: 700 }}
        >
          Number: {chat[0]?.clientNumber}
        </Typography>

        {/* Timestamp */}
        <Typography
          sx={{ fontSize: "12px", color: "#000", marginBottom: "4px", fontWeight: 700 }}
        >
          {chat[0]?.type === "outgoing" ? "Sent" : "Received"} At: {moment(chat[0]?.created_at).format("DD/MM/YYYY HH:mm:ss")}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatCard3;
