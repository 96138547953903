import React from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  FormControl,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ContainedButton, OutlinedButtonWithIcon } from "../../components/buttonCompo/Buttons";


const DefaultRouteSetting = ({ onClose, handleSaveSettings, formData, onChange, mapType, handleMapTypeChange, handleOptimizeChanges, optimizeRouteDone }) => {

  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box>
          <Typography
            variant="body2"
            sx={{ fontSize: "14px", fontWeight: 600 }}
          >
            Default Route Setting 2
          </Typography>
        </Box>
        <div style={{ width: "300px" }}>
          {/* Map Type */}
          <FormControl component="div" style={{ marginBottom: 20, width: "100%" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                color: "#707070",
                fontWeight: 500,
                marginTop: 1,
                paddingY: 0.5,
              }}
            >
              Map Type
            </Typography>
            <RadioGroup
              aria-label="optimizeFor"
              name="mapType"
              value={mapType}
              onChange={handleMapTypeChange}
            >
              <FormControlLabel
                value="LiveMap"
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Live Request"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
              <FormControlLabel
                value="StorePolyline"
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Stored Map"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>

          {/* Optimize For */}
          <FormControl component="div" style={{ marginBottom: 20, width: "100%" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                color: "#707070",
                fontWeight: 500,
                marginTop: 1,
                paddingY: 0.5,
              }}
            >
              Optimize For
            </Typography>
            <RadioGroup
              aria-label="optimizeFor"
              name="optimizeFor"
              value={formData.optimizeFor}
              onChange={(e) => onChange("optimizeFor", e.target.value)}
            >
              <FormControlLabel
                value="TIME"
                disabled={mapType === "StorePolyline"}
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Optimize by Time"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
              <FormControlLabel
                value="DISTANCE"
                disabled={mapType === "StorePolyline"}
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Optimize by Mile"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
              <FormControlLabel
                value="MANUAL"
                disabled={mapType === "StorePolyline"}
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Optimize Manually"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>

          {/* Sort Order */}
          {(mapType !== 'StorePolyline' || formData.optimizeFor !== 'MANUAL') &&
            <FormControl component="div" style={{ marginBottom: 20, width: "100%" }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  color: "#707070",
                  fontWeight: 500,
                  marginTop: 1,
                  paddingY: 0.5,
                }}
              >Sort Order</Typography>
              <RadioGroup
                aria-label="routeOption"
                name="routeOption"
                value={formData.sortOrder}
                onChange={(e) => onChange("sortOrder", e.target.value)}
              >
                <FormControlLabel
                  disabled={mapType === 'StorePolyline' || formData.optimizeFor === 'MANUAL'}
                  value="ascending"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="Ascending"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
                <FormControlLabel
                  value="descending"
                  disabled={mapType === 'StorePolyline' || formData.optimizeFor === 'MANUAL'}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="Descending"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
          }

          {/* Route Option */}
          <FormControl component="div" style={{ marginBottom: 20, width: "100%" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                color: "#707070",
                fontWeight: 500,
                marginTop: 1,
                paddingY: 0.5,
              }}
            >Route Option</Typography>
            <RadioGroup
              aria-label="routeOption"
              name="routeOption"
              value={formData.routeOption}
              onChange={(e) => onChange("routeOption", e.target.value)}
            >
              <FormControlLabel
                value="startToEnd"
                disabled={mapType === 'StorePolyline' || formData.optimizeFor === 'MANUAL'}
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Start & End At Same Location"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
              <FormControlLabel
                value="endAnywhere"
                disabled={mapType === 'StorePolyline' || formData.optimizeFor === 'MANUAL'}
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="End At Any Location"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>

          {/* Clustering Option */}
          {(formData.optimizeFor !== 'MANUAL') &&
            <FormControl component="div" style={{ marginBottom: 20, width: "100%" }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  color: "#707070",
                  fontWeight: 500,
                  marginTop: 1,
                  paddingY: 0.5,
                }}
              >Clustering Option</Typography>
              <RadioGroup
                aria-label="clusterOption"
                name="clusterOption"
                value={formData.clusterOption}
                onChange={(e) => onChange("clusterOption", e.target.value)}
              >
                <FormControlLabel
                  value="none"
                  disabled={mapType === 'StorePolyline'}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="None"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
                <FormControlLabel
                  value="topologySegment"
                  disabled={mapType === 'StorePolyline'}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="Topology Segment"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
                <FormControlLabel
                  value="drivingDistance"
                  disabled={mapType === 'StorePolyline'}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        transform: "scale(0.8)",
                        color: "#0b6699",
                        "&.Mui-checked": {
                          color: "#0b6699",
                        },
                      }}
                    />
                  }
                  label="Driving Distance"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#232323",
                    },
                  }}
                />
              </RadioGroup>
              {formData.clusterOption === 'drivingDistance' && (
                <TextField
                  label="Driving Distance (meters)"
                  type="number"
                  autoComplete="off"
                  value={formData.driving_distance_value}
                  onChange={(e) => onChange("driving_distance_value", e.target.value)}
                  fullWidth
                  style={{ marginTop: 10 }}
                />
              )}
            </FormControl>
          }

          {/* Traffic Option */}
          <FormControl component="div" style={{ marginBottom: 20, width: "100%" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                color: "#707070",
                fontWeight: 500,
                marginTop: 1,
                paddingY: 0.5,
              }}
            >Traffic</Typography>
            <RadioGroup
              aria-label="traffic"
              name="traffic"
              value={formData.traffic}
              onChange={(e) => onChange("traffic", e.target.value)}
            >
              <FormControlLabel
                value="enabled"
                disabled={mapType === 'StorePolyline' || formData.optimizeFor === 'MANUAL'}
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Enable"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
              <FormControlLabel
                value="disable"
                disabled={mapType === 'StorePolyline' || formData.optimizeFor === 'MANUAL'}
                control={
                  <Radio
                    size="small"
                    sx={{
                      transform: "scale(0.8)",
                      color: "#0b6699",
                      "&.Mui-checked": {
                        color: "#0b6699",
                      },
                    }}
                  />
                }
                label="Disable"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#232323",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={formData.departureDateTime}
              label="Departure Date & Time"
              onChange={(e) => {
                onChange("departureDateTime", e.target.value);
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  InputProps: { disableUnderline: true },
                  sx: { color: "black" },
                },
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" autoComplete="off" fullWidth />
              )}
            />
          </LocalizationProvider>
        </div>
      </Box>


      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >

        <OutlinedButtonWithIcon
          label="Optimize"
          disabled={mapType === 'StorePolyline'}
          onClick={(e) => {
            handleOptimizeChanges(e);
          }}
        />
        <ContainedButton
          label="Save"
          border="none"
          disabled={mapType === 'StorePolyline' || !optimizeRouteDone}
          onClick={() => {
            handleSaveSettings()
          }}
        />
      </Box>
    </Box>
  );
};

export default DefaultRouteSetting;
