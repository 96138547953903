import React, { useState, useEffect, useParams, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Checkbox,
  Button,
  styled,
  Modal,
} from "@mui/material";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import styles

import { ChooseImageIcon, UploadIcon } from "../../Icons";
import CustomDropdown from "../../CustomDropdown";
import { PhoneNumberField } from "../../Ui/PhoneNumberField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Topbar from "./Topbar";
import { ContainedButton } from "../../buttonCompo/Buttons";
import axios from "axios";
import { ALL_PHARMACY_LIST, ALL_USERS } from "../../../utils/apiUrl";
import {
  postApiWithAuth,
  getApiWithAuth,
  putApiWithAuth,
} from "../../../utils/api";
import { CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
/* Muhammad Usman */
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const PharmacyEmployeeInformationForm = () => {
  const [searchParams] = useSearchParams();
  const eid = searchParams.get("id");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [pharmacyDetail, setPharmacyDetail] = useState({});
  const [pharmacyEmplyeeData, setPharmacyEmployeeData] = useState({
    firstName: pharmacyDetail?.name,
    lastName: "Employee",
    address: "",
    cellNumber: "",
    isActive: true,
    logo: "",
    email: "",
    username: "",
    password: "",
    show_report: false,
    show_team: false,
    profile_pic: "",
  });

  const pid = localStorage.getItem("pharmacyId");
  const getPharmacy = async () => {
    const response = await getApiWithAuth(ALL_PHARMACY_LIST + `/${pid}`);
    if (response.data !== undefined) {
      setPharmacyDetail(response.data.data);
    } else {
      console.log("Error");
    }
  };

  useEffect(() => {
    const getPharmacyEmployee = async () => {
      const response = await getApiWithAuth(ALL_USERS + `all?user_id=${eid}`);
      if (response.data !== undefined) {
        setPharmacyEmployeeData((prevState) => ({
          ...prevState, // Spread the previous state to maintain other key-value pairs
          firstName: response.data.data.first_name,
          lastName: response.data.data.last_name,
          address: response.data.data.address,
          phoneNumber: response.data.data.contact,
          cellNumber: response.data.data.contact,
          email: response.data.data.email,
          username: response.data.data.username,
          isActive: response.data.data.is_active,
          logo: response.data.data.profile_pic,
          show_report: response.data.data.show_report,
          show_team: response.data.data.show_team,
        }));
        setFronts(response.data.data.profile_pic);
        setStaffValue(response.data.data.type.type);
        // if (response.data.data?.is_active === true) {
        //   setIsChecked(true);
        //   setIsChecked2(false);
        // } else if (response.data.data?.is_active === false) {
        //   setIsChecked2(true);
        //   setIsChecked(false);

        // }
      }
    };
    if (eid) getPharmacyEmployee();
  }, [eid]);

  useEffect(() => {
    getPharmacy();
  }, []);

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [staffValue, setStaffValue] = useState("PharmacyEmployee");
  const validateForm = () => {
    let tempErrors = {};
    // Validate pharmacyEmplyeeData
    if (!pharmacyEmplyeeData.username.trim())
      tempErrors.username = "User Name is required";
    if (!pharmacyEmplyeeData.email.trim())
      tempErrors.email = "Email is required";
    if (!pharmacyEmplyeeData.cellNumber.trim())
      tempErrors.cellNumber = "Cell Number is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // Return true if no errors
  };
  const handleInputChange = (setter, key) => (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleInputChangeActivate = (field, value) => {
    setPharmacyEmployeeData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate form before proceeding
    if (!validateForm()) {
      toast.error("Please fill all the required fields.");
      console.log(errors);
      return;
    }

    // Prepare form data
    const updatedPersonalDetail = new FormData();
    updatedPersonalDetail.append("first_name", pharmacyDetail.name);
    updatedPersonalDetail.append("last_name", pharmacyEmplyeeData.lastName);
    updatedPersonalDetail.append("address", pharmacyDetail.location);
    updatedPersonalDetail.append("contact", pharmacyEmplyeeData.cellNumber);
    updatedPersonalDetail.append("email", pharmacyEmplyeeData.email);
    updatedPersonalDetail.append("username", pharmacyEmplyeeData.username);
    updatedPersonalDetail.append(
      "show_report",
      pharmacyEmplyeeData.show_report
    );
    updatedPersonalDetail.append("show_team", pharmacyEmplyeeData.show_team);
    updatedPersonalDetail.append("pharmacy_id", pid);
    updatedPersonalDetail.append("type", staffValue); // Assuming staffValue is correctly set
    updatedPersonalDetail.append("password", pharmacyEmplyeeData.password);
    updatedPersonalDetail.append("is_active", pharmacyEmplyeeData.isActive);

    if (pharmacyEmplyeeData.logo instanceof File) {
      updatedPersonalDetail.append(
        "profile_pic",
        pharmacyEmplyeeData.logo !== null ? pharmacyEmplyeeData.logo : ""
      );
    } else {
      updatedPersonalDetail.append("profile_pic", "");
    }
    updatedPersonalDetail.append("user_id", eid);
    updatedPersonalDetail.append("action", "update_details");

    try {
      // API call based on the presence of `eid`
      let responsePersonal;
      if (eid) {
        responsePersonal = await putApiWithAuth(
          `${ALL_USERS}all`,
          updatedPersonalDetail
        );
      } else {
        responsePersonal = await postApiWithAuth(
          ALL_USERS,
          updatedPersonalDetail
        );
      }

      console.log("Response Data:", responsePersonal);

      // Determine response structure
      const responseData = eid
        ? responsePersonal.data // For PUT
        : responsePersonal.data.data; // For POST

      // Handle response
      if (responseData) {
        toast.success("Account created successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate(`/addemployee`);
      } else {
        toast.error(responsePersonal.data.password?.[0] || "Error occurred", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast.error("An error occurred while submitting the form.", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const dropdownOptions = [
    { value: "PharmacyOwner", label: "PharmacyOwner" },
    { value: "PharmacyEmployee", label: "PharmacyEmployee" },
  ];

  const handleChange = (setter) => (event) => {
    const value = event.target ? event.target.value : event;
    setter(value);
  };

  {
    /* Muhammad Usman Start */
  }
  const [fronts, setFronts] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const cropperRef = useRef(null);

  const handleImageUpload3 = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    setFileObject(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setFronts(reader.result);
      setOpenModal(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        croppedCanvas.toBlob((blob) => {
          const croppedFile = new File([blob], fileObject.name, {
            type: "image/png",
          });

          setPharmacyEmployeeData((prevState) => ({
            ...prevState,
            logo: croppedFile,
          }));

          const reader = new FileReader();
          reader.onloadend = () => {
            setFronts(reader.result);
          };
          reader.readAsDataURL(blob);
        }, "image/png");

        setOpenModal(false);
      }
    }
  };
  {
    /* Muhammad Usman End */
  }

  const Input = styled("input")({
    display: "none",
  });

  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Muhammad Usman Start */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={handleImageUpload3}
                    sx={{ display: "none" }}
                  />
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      borderColor: "#05b320",
                      color: "#05b320",
                      width: "120px",
                      textTransform: "none",
                      fontSize: "13px",
                      height: "35px",
                    }}
                  >
                    Choose File
                  </Button>
                </label>

                <Typography variant="body2" sx={{ color: "#757575" }}>
                  {fileObject ? fileObject.name : "No File Chosen"}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "80px",
                  height: "80px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f4f4f4",
                  border: "1px solid #ddd",
                }}
              >
                {fronts ? (
                  <img
                    src={fronts}
                    alt="Cropped Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "48px", color: "#757575" }}
                  >
                    📷
                  </Typography>
                )}
              </Box>

              <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Crop & Adjust Image
                  </Typography>

                  <Cropper
                    src={fronts}
                    style={{ height: 250, width: "100%" }}
                    initialAspectRatio={1}
                    aspectRatio={1}
                    guides={true}
                    ref={cropperRef}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpenModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCrop}
                    >
                      Save Cropped
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
            {/* Muhammad Usman End */}

            <Box sx={{ display: "flex", gap: 1 }}>
              <ContainedButton
                label="Activate"
                border="none"
                bgColor={
                  pharmacyEmplyeeData.isActive ? "#05b320" : "transparent"
                }
                borderRadius="4px"
                textColor={pharmacyEmplyeeData.isActive ? "#fff" : "#05b320"}
                onClick={() => handleInputChangeActivate("isActive", true)}
              />
              <ContainedButton
                label="Deactivate"
                border="1px solid #fc1002"
                bgColor={
                  !pharmacyEmplyeeData.isActive ? "#fc1002" : "transparent"
                }
                borderRadius="4px"
                textColor={!pharmacyEmplyeeData.isActive ? "#fff" : "#fc1002"}
                onClick={() => handleInputChangeActivate("isActive", false)}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
            >
              Account Credentials
              <span style={{ color: "#fb1001" }}>(Mandatory Field)</span>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: 5,
              rowGap: 0.7,
            }}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Type
              </Typography>
              <CustomDropdown
                customstyles={{
                  height: "32px",
                  width: "137px",
                }}
                label=""
                name="type"
                value={staffValue}
                onChange={handleChange(setStaffValue)}
                options={dropdownOptions}
                isMultiSelect={false}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                User Name
              </Typography>
              <TextField
                type="text"
                autoComplete="new-password"
                value={pharmacyEmplyeeData?.username}
                onChange={handleInputChange(
                  setPharmacyEmployeeData,
                  "username"
                )}
                placeholder="User Name"
                sx={{
                  width: "199px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px", // Decrease font size for input text
                    padding: "8px 12px", // Adjust padding
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px", // Set height to 36px
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px", // Decrease font size for the label
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563", // Set placeholder color to light gray
                  },
                }}
              />
            </Box>
            <PhoneNumberField
              value={pharmacyEmplyeeData.cellNumber}
              onChange={(value) =>
                setPharmacyEmployeeData((prevState) => ({
                  ...prevState,
                  cellNumber: value,
                }))
              }
              error={Boolean(errors.cellNumber)}
              helperText={errors.cellNumber}
              label="Mobile Number"
              placeholder="(675) 454-2724"
              height="34px"
              width="237px"
            />
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Email
              </Typography>
              <TextField
                type="email"
                autoComplete="new-password"
                value={pharmacyEmplyeeData?.email}
                placeholder="Email"
                onChange={handleInputChange(setPharmacyEmployeeData, "email")}
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px", // Decrease font size for input text
                    padding: "8px 12px", // Adjust padding
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px", // Set height to 36px
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px", // Decrease font size for the label
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563", // Set placeholder color to light gray
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Password
              </Typography>
              <TextField
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                autoComplete="new-password"
                onChange={handleInputChange(
                  setPharmacyEmployeeData,
                  "password"
                )}
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                        sx={{
                          padding: "4px",
                          width: "24px",
                          height: "24px",
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOff
                            sx={{ fontSize: "16px", color: "#000" }}
                          />
                        ) : (
                          <Visibility
                            sx={{ fontSize: "16px", color: "#000" }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.5,
            marginTop: "10px",
          }}
        >
          {/* <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px", marginTop: "5px" }}
            >
              Pharmacy Name
            </Typography>
            <TextField
              type="text"
              value={pharmacyDetail?.name}
              disabled
              placeholder="User Name"
              sx={{
                width: "199px",
                "& .MuiInputBase-input": {
                  fontSize: "11px", // Decrease font size for input text
                  padding: "8px 12px", // Adjust padding
                },
                "& .MuiOutlinedInput-root": {
                  height: "34px", // Set height to 36px
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px", // Decrease font size for the label
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563", // Set placeholder color to light gray
                },
              }}
            />
            
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px", marginTop: "5px" }}
            >
              Pharmacy Address
            </Typography>
            <TextField
              type="text"
              value={pharmacyDetail?.location}
              disabled
              placeholder="User Name"
              sx={{
                width: "199px",
                "& .MuiInputBase-input": {
                  fontSize: "11px", // Decrease font size for input text
                  padding: "8px 12px", // Adjust padding
                },
                "& .MuiOutlinedInput-root": {
                  height: "34px", // Set height to 36px
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px", // Decrease font size for the label
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563", // Set placeholder color to light gray
                },
              }}
            />
            
          </Box> */}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
            >
              Permissions
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: 5,
              rowGap: 0.7,
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 0.2,
                }}
              >
                <Checkbox
                  checked={pharmacyEmplyeeData?.show_report}
                  onChange={handleInputChange(
                    setPharmacyEmployeeData,
                    "show_report"
                  )}
                  sx={{
                    width: "10px",
                    height: "10px",
                    transform: "scale(0.6)",
                    color: "#d2d5da",
                    "&.Mui-checked": {
                      color: "#000",
                    },
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Show Report
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 0.2,
                }}
              >
                <Checkbox
                  checked={pharmacyEmplyeeData?.show_team}
                  onChange={handleInputChange(
                    setPharmacyEmployeeData,
                    "show_team"
                  )}
                  sx={{
                    width: "10px",
                    height: "10px",
                    transform: "scale(0.6)",
                    color: "#d2d5da",
                    "&.Mui-checked": {
                      color: "#000",
                    },
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Show Team
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            marginTop: 5,
          }}
        >
          <ContainedButton
            label={
              loading ? (
                <CircularProgress size={16} sx={{ color: "white" }} />
              ) : (
                "Save"
              )
            }
            borderRadius="5px"
            fontSize="13px"
            width="66px"
            height="32px"
            onClick={handleSubmit}
            disabled={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default PharmacyEmployeeInformationForm;
