import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  Collapse,
  IconButton,
} from "@mui/material";
import { removeToken } from "../../utils/localStorage";
import { getApiWithAuth, patchApiWithOutAuth } from "../../utils/api";
import { AGENTS, USER_PERMISSIONS } from "../../utils/apiUrl";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  ArrowIcon,
  ChatsIcon,
  CitiesIcon,
  CustomHomeIcon,
  CustomOrderIcon,
  DashboardIcon,
  DriversIcon,
  LogoutIcon,
  PharmaciesIcon,
  ReportsIcon,
  RoutesIcon,
  RoutesStatusIcon,
  RxOrderIcon,
  SettingsIcon,
  SettingsMenuIcon,
  SupportIcon,
  UsersIcon,
} from "../Icons";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const SubMenuItem = ({ item, open, toggleOpen, isCollapsed, navigate }) => {
  const theme = useTheme();
  return (
    <>
      <ListItem
        onClick={toggleOpen}
        sx={{
          display: "flex",
          alignItems: "center",
          paddingTop: "15px",
          paddingBottom: "6px",
          justifyContent: isCollapsed ? "center" : "flex-start",
          "&:hover": {
            backgroundColor: theme.palette.custom.layoutHoverColor,
            cursor: "pointer",
          },
          transition: "all 0.3s ease",
          overflow: "hidden",
        }}
      >
        <ListItemIcon sx={{ minWidth: isCollapsed ? "auto" : "40px" }}>
          {item.icon(theme.palette.custom.layoutFontColor)}
        </ListItemIcon>
        {!isCollapsed && (
          <>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px",
                  lineHeight: "19px",
                  paddingRight: "10px",
                  fontWeight: "500",
                  color: theme.palette.custom.layoutFontColor,
                },
              }}
            />
            <ArrowIcon
              fill={theme.palette.custom.layoutFontColor}
              rotate={open ? 180 : 0}
            />
          </>
        )}
      </ListItem>
      <Collapse in={open && !isCollapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.subItems?.map((subItem, subIndex) => (
            <ListItem
              key={subIndex}
              sx={{
                pl: 4,
                "&:hover": {
                  backgroundColor: theme.palette.custom.layoutHoverColor,
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                if (subItem.onClick) {
                  subItem.onClick(navigate);
                } else {
                  navigate(subItem.route || "#");
                }
              }}
              component={Link}
              to={subItem.route || "#"}
            >
              <ListItemText
                primary={subItem.text}
                primaryTypographyProps={{
                  style: {
                    fontSize: "16px",
                    lineHeight: "19px",
                    fontWeight: "500",
                    color: theme.palette.custom.layoutFontColor,
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const Sidebar = ({ isCollapsed, setIsCollapsed }) => {
  const theme = useTheme();
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [showReport, setShowReport] = useState(false);
  const [showTeam, setShowTeam] = useState(false);
  const [forAdmin, setForAdmin] = useState(false);
  const [showDashboard, setshowDashboard] = useState(false);
  const [showRx, setshowRx] = useState(false);
  const [showFacility, setshowFacility] = useState(false);
  const [showDriver, setshowDriver] = useState(false);
  const [showPharm, setshowPharm] = useState(false);
  const [showRoutes, setshowRoutes] = useState(false);
  const [showCities, setshowCities] = useState(false);
  const [showReports, setshowReports] = useState(false);

  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    if (userType === "Admin") {
      setForAdmin(true);
    }
    if (userType === "PharmacyEmployee") {
      console.log("user type is",userType)
      const showReportValue = localStorage.getItem("show_report") === 'true';
      const showTeamValue = localStorage.getItem("show_team") === 'true';
      console.log("Retrieved from localStorage:",showReportValue,showTeamValue)
      setShowReport(showReportValue);
      setShowTeam(showTeamValue);
    }
    if (userType === "Staff" || userType === "SubAdmin") {
      fetchPermissions();
    }
  }, [userType]);

  const fetchPermissions = async () => {
    try {
      const response = await getApiWithAuth(USER_PERMISSIONS);
      const permissions = response.data.data;
      setshowDashboard(permissions.show_dashboard);
      setshowFacility(permissions.show_facuser);
      setshowReports(permissions.show_reports);
      setshowDriver(permissions.show_driver);
      setshowPharm(permissions.show_pharm);
      setshowRx(permissions.show_rx);
      setshowCities(permissions.show_cities);
      setshowRoutes(permissions.show_routes);
    } catch (error) {
      console.error("Failed to fetch permissions", error);
    }
  };

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  const handleToggle = (index) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleLogout = async () => {
    removeToken();
    const set_agent = localStorage.getItem("sessionId");
    await patchApiWithOutAuth(
      AGENTS + `delete-by-session/?session_id=${set_agent}`
    );
    localStorage.clear();
    window.location.href = "/";
  };

  const menuItems = [
    {
      text: "Dashboard",
      icon: (fill) => <DashboardIcon fill={fill} />,
      route: "/dashboard",
      roles: ["Admin", "SubAdmin", "Staff"],
    },
    {
      text: "Rx Orders",
      icon: (fill) => <RxOrderIcon fill={fill} />,
      route: "/order-list",
      roles: ["Admin", "SubAdmin", "Staff"],
    },
    {
      text: "Rx Orders",
      icon: (fill) => <RxOrderIcon fill={fill} />,
      route: "/order-list",
      roles: ["PharmacyOwner", "PharmacyEmployee"],
      subItems: [
        {
          text: "Create Order",
          icon: "/icons/Rx.svg",
          route: "/add-manual-order",
          onClick: (navigate) => {
            localStorage.removeItem("orderData");
            navigate("/add-manual-order");
          },
        },
      ],
    },
    {
      text: "Pharmacies",
      icon: (fill) => <PharmaciesIcon fill={fill} />,
      route: "/addpharmacy",
      roles: ["Admin", "SubAdmin", "Staff"],
    },
    {
      text: "Users",
      icon: (fill) => <UsersIcon fill={fill} />,
      route: "/facilityuser",
      roles: ["Admin", "SubAdmin", "Staff"],
    },
    {
      text: "Drivers",
      icon: (fill) => <DriversIcon fill={fill} />,
      route: "/adddrivers",
      roles: ["Admin", "SubAdmin", "Staff"],
    },
    {
      text: "Pharmacy Team",
      icon: (fill) => <UsersIcon fill={fill} />,
      route: "/addemployee",
      roles: ["PharmacyOwner", "PharmacyEmployee"],
      subItems: [
        {
          text: "Create Employee",
          icon: "/icons/Rx.svg",
          route: "/addemployee/add-info",
        },
      ],
    },
    {
      text: "Routes",
      icon: (fill) => <RoutesIcon fill={fill} />,
      route: "/routes",
      roles: ["Admin", "SubAdmin", "Staff"],
    },
    {
      text: "Reports",
      icon: (fill) => <ReportsIcon fill={fill} />,
      route: "/reports",
      subItems: [
        {
          text: "Weekly Reports",
          icon: "/icons/Reports.svg",
          route: "/reports",
        },
        {
          text: "Driver Weekly Report",
          icon: "/icons/Reports.svg",
          route: "/reports/driver",
        },
        // {
        //   text: "Pharmacy Daily Orders",
        //   icon: "/icons/Reports.svg",
        //   route: "/reports/report2",
        // },
        // {
        //   text: "Driver Scan",
        //   icon: "/icons/Reports.svg",
        //   route: "/reports/pharmacyorders",
        // },
      ],
      roles: ["Admin", "SubAdmin", "Staff"],
    },
    
    {
      text: "Reports",
      icon: (fill) => <ReportsIcon fill={fill} />,
      route: "/reports",
      roles: ["PharmacyOwner", "PharmacyEmployee"],
    },
    {
      text: "Cities",
      icon: (fill) => <CitiesIcon fill={fill} />,
      route: "/zones",
      roles: ["Admin", "SubAdmin", "Staff"],
    },
    // {
    //   text: "Settings",
    //   icon: (fill) => <SettingsMenuIcon fill={fill} />,
    //   subItems: [
    //     {
    //       text: "Notifications",
    //       icon: "/icons/Routes.svg",
    //       route: "/notifications-form",
    //     },
    //     {
    //       text: "Expense",
    //       icon: "/icons/Routes.svg",
    //       route: "/list",
    //     },
    //   ],
    //   roles: ["Admin", "SubAdmin"],
    // },
    //  {
    //   text: "Support",
    //   icon: (fill) => <SupportIcon fill={fill} />,
    //   route: "/support",
    //   roles: ["Admin", "SubAdmin"],
    // },
    // {
    //   text: "SMS",
    //   icon: (fill) => <ChatsIcon fill={fill} />,
    //   route: "/sms-list",
    //   roles: ["Admin", "SubAdmin"],
    // },
    {
      text: "Log Out",
      icon: (fill) => <LogoutIcon fill={fill} />,
      onClick: handleLogout,
    },
  ];
  const isItemVisible = (item) => {
    if (userType === "Admin") {
      return true;
    }
    if (userType === "PharmacyEmployee") {
      console.log("pharmacy employeee",showReport,showTeam  )
      if (item.text === "Reports" && !showReport) return false;
      if (item.text === "Pharmacy Team" && !showTeam) return false;
      if (item.text === "Rx Orders") return true;
    }
  
    // For Staff or SubAdmin, check permissions
    if (userType === "Staff" || userType === "SubAdmin") {
      if (item.text === "Dashboard" && !showDashboard) return false;
      if (item.text === "Rx Orders" && !showRx) return false;
      if (item.text === "Users" && !showFacility) return false;
      if (item.text === "Pharmacies" && !showPharm) return false;
      if (item.text === "Drivers" && !showDriver) return false;
      if (item.text === "Cities" && !showCities) return false;
      if (item.text === "Routes" && !showRoutes) return false;
      if (item.text === "Reports" && !showReports) return false;
    }
  
    // For other roles (like PharmacyOwner or others), check their permissions
    if (item.roles && item.roles.length > 0 && item.roles.includes(userType)) {
      return true;
    }
  
    // For subItems under a main item, check visibility for each subItem based on permissions
    if (item.subItems) {
      item.subItems = item.subItems.filter((subItem) => {
        if (userType === "Admin" || subItem.roles?.includes(userType)) {
          return true;
        }
        return false;
      });
  
      // If no subItems are visible, hide the main item
      if (item.subItems.length === 0) {
        return false;
      }
    }
  
    return true;
  };
  const filteredMenuItems = menuItems.filter((item) => {
    const isVisible = isItemVisible(item);
    const hasValidRole = !item.roles || item.roles.includes(userType);
    console.log(`Item: ${item.text} Visible: ${isVisible} Has Valid Role: ${hasValidRole}`);  
    return isVisible && hasValidRole;
  });
  

  return (
    <Drawer
    variant="permanent"
    sx={{
      width: isCollapsed ? 65 : 240,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        width: isCollapsed ? 65 : 240,
        boxSizing: "border-box",
        backgroundColor: theme.palette.custom.layoutBgColor,
        color: theme.palette.custom.layoutFontColor,
        display: "flex",
        flexDirection: "column",
        border: "none",
        transition: "width 0.3s ease",
        overflowX: "hidden",
      },
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pt: "9px",
        pl: isCollapsed ? 0 : "12px",
        width: isCollapsed ? 65 : 240,
        height: "60px",
        transition: "all 0.3s ease",
      }}
    >
      {theme.palette.custom.layoutBgColor !== "#E6E6E6" ? (
        <IconButton onClick={toggleSidebar}>
          <img
            src={
              isCollapsed
                ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAitJREFUeNrsWV1OwkAQ3ta+Ww9gUk8AN7C8+aacgN5AboCeoHIC9AT0BuAJwBOwCU++Fd98qjNkSCqR7Gwp26lhki80oZvONzM7P7ueInm7jkL4GQG6qh5ZAjaADiDc+y8HfAA0YD5Ya131I16JwBR+HlQzggTGgFcgk9ss9EvPoWpOIkAKWIEhnygarAlIkF0YL4BE3EYCZY/M0BttJbCTEZCYtJkASgIkFof2RRsIKErtUxOBsXAS8V/h5LnUABSIaIMiblEperaRPtSKrBECB0hh8XwkMhzBQnezK3iN7wG0JqCHliXlOLUiFeOBPW+gcjNmP4Ze0KKyEIVFjxpBY40Q54G9zb5g9GdXFxIJZF953r8Mv+HxzvDqp0gPlDyxMqTZ5a89UBRFBIgFcTAV165fUh6rHDKeFaeTFWBoE0023WjkwKLbwYWMxclKmqY1cd1oYhGqIglsDWxxOCCSADdkN20faDpSCWiLQV8kAe4AFUsjgJbve55nbNigEhu70sDiwzmzSzwk77geFM8s1gzqJPAMH39xXS9sKvFR+fgEjVzCaKdlplGazFLOu1LrwEQxD5t9gdbHbpV9zO8LVD61WRMIivmJqnDBEghQPKEThkoXLEEDSmMXihX2nix+1M1QcAIFU1LMxYRXLwHqXYYuPVp3FnJ+UdiWC44zAa7EZw80TCCXTICjXOaYxNJ4Ol0UBeZ2DdNYZcVKl3tHy2Ct5+o/yY8AAwDTHOzLKwEuPQAAAABJRU5ErkJggg=="
                : "/icons/Logo.svg"
            }
            alt="Logo"
            style={{ marginLeft: isCollapsed ? "0" : "10px" }}
          />
        </IconButton>
      ) : (
        <IconButton onClick={toggleSidebar}>
          <img
            src={
              isCollapsed
                ? "/icons/Pilldrop-logo 2 1.svg"
                : "/icons/LogoBlack.svg"
            }
            alt="Logo"
            style={{ marginLeft: isCollapsed ? "0" : "10px" }}
          />
        </IconButton>
      )}
    </Box>
    <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "calc(100% - 44px)",
          overflowY: "auto",
          paddingBottom: "60px",
        }}
      >
        <List sx={{ width: "100%" }}>
        {filteredMenuItems.map((item, index) => {
            const open = openDropdowns[index] || false;
            const hasSubItems = Boolean(item.subItems);

            return (
              <React.Fragment key={index}>
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    justifyContent: isCollapsed ? "center" : "space-between",
                    "&:hover": {
                      backgroundColor: theme.palette.custom.layoutHoverColor,
                      cursor: "pointer",
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  {/* Clickable Route Text */}
                  <Box
                    component={Link}
                    to={item.route || "#"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: theme.palette.custom.layoutFontColor,
                      width: "100%",
                    }}
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick(); // Execute custom onClick
                      } else if (!hasSubItems) {
                        navigate(item.route); // Navigate for non-subItem menus
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={{ minWidth: isCollapsed ? "auto" : "40px" }}
                    >
                      {item.icon(theme.palette.custom.layoutFontColor)}
                    </ListItemIcon>
                    {!isCollapsed && (
                      <ListItemText
                        primary={item.text}
                        primaryTypographyProps={{
                          style: {
                            fontSize: "16px",
                            lineHeight: "19px",
                            paddingRight: "10px",
                            fontWeight: "500",
                            color: theme.palette.custom.layoutFontColor,
                          },
                        }}
                      />
                    )}
                  </Box>

                  {/* Dropdown Arrow for SubItems */}
                  {hasSubItems && !isCollapsed && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent Link navigation
                        handleToggle(index); // Toggle dropdown
                      }}
                    >
                      {open ? (
                        <ArrowIcon
                          fill={theme.palette.custom.layoutFontColor}
                          rotate={open ? 180 : 0}
                        />
                      ) : (
                        <ArrowIcon
                          fill={theme.palette.custom.layoutFontColor}
                        />
                      )}
                    </IconButton>
                  )}
                </ListItem>

                {hasSubItems && !isCollapsed && (
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem, subIndex) => (
                        <ListItem
                          key={subIndex}
                          component={Link}
                          to={subItem.route || "#"}
                          sx={{
                            pl: 4,
                            "&:hover": {
                              backgroundColor:
                                theme.palette.custom.layoutHoverColor,
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => {
                            if (subItem.onClick) {
                              subItem.onClick(navigate); // Custom onClick
                            } else {
                              navigate(subItem.route); // Default navigation
                            }
                          }}
                        >
                          <ListItemText
                            primary={subItem.text}
                            primaryTypographyProps={{
                              style: {
                                color: theme.palette.custom.layoutFontColor,
                              },
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            );
          })}
        </List>

        <Divider />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
