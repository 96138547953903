import { Box, Typography } from "@mui/material";
import React from "react";

const DeliveryTime = ({ time, sameDay = false }) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      {sameDay ? (
        <>
          <Typography variant="body2" sx={{ fontSize: "12px" }}>
            SAME DAY <span style={{ color: "#0455a6" }}>{time}</span>
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "10px" }}>
            READY FOR PICKUP : <span style={{ color: "#0455a6" }}>22</span>
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "10px" }}>
            PICKED UP : <span>0</span>
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "10px" }}>
            BY : Jose Aurhto
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body2" sx={{ fontSize: "12px" }}>
            NEXT DAY <span style={{ color: "#fe9604" }}>{time}</span>
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "10px" }}>
            READY FOR PICKUP : <span style={{ color: "#0455a6" }}>22</span>
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "10px" }}>
            PICKED UP : <span>0</span>
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "10px" }}>
            BY : Jose Aurhto
          </Typography>
        </>
      )}
    </Box>
  );
};

export default DeliveryTime;
