import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import {
  ContainedButton,
  ContainedButtonWithIcon,
} from "../buttonCompo/Buttons";
import { ALL_ZONES_LIST } from "../../utils/apiUrl";
import { postApiWithAuth } from "../../utils/api";
import { AddIcon } from "../Icons";
import { toast } from "react-toastify";
const Topbar = ({getBorough, borough}) => {
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    comment: "Comment will be here!",
  });
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleInputChange = (e) => {
    const { name, value , code} = e.target;
    setFormState({
      ...formState,
      [name]: value,
      [code]: value,
    });
  };
  const handleSaveBorough = async () => {
    if (!borough.some(item => item.name === formState.name)) {

    setLoading(true);
    const response = await postApiWithAuth(ALL_ZONES_LIST+ 'borros/', formState);
    console.log(response)
    if (response.status !== 400) {
      toast.success('Borough Created Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    setOpen(false);
    setLoading(false);
    setFormState({
      name: "",
      comment: "Comment will be here!"
    });
    }
    else {
      toast.error('Name already present!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setOpen(false);
      setLoading(false);
      setFormState({
        name: "",
        comment: "Comment will be here!"
      });
    }
    getBorough();
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <ContainedButtonWithIcon
          label="Add City"
          icon={<AddIcon />}
          onClick={handleOpen}
        />
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Add City</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="City Name"
            autoComplete="off"
            value={formState.name}
            name="name"
            fullWidth
            variant="outlined"
            onChange={handleInputChange}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Unique ID"
            autoComplete="off"
            value={formState.code}
            name="code"
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <ContainedButton
            label="Save"
            onClick={handleSaveBorough}
            borderRadius="4px"
            variant="contained"
            color="primary"
          />
          <ContainedButton
            label="Cancel"
            textColor="#000"
            bgColor="transparent"
            onClick={handleClose}
            borderRadius="4px"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Topbar;
