import { createTheme } from "@mui/material/styles";

export const getTheme = (
  customBgColor = "#0B6699",
  customFontColor = "#000000",
  customIconColor = "#fff",
  customHoverColor = "#044367"
) => {
  return createTheme({
    typography: {
      fontFamily: "Public Sans, Arial, sans-serif",
    },
    palette: {
      primary: { main: "#000" },
      custom: {
        layoutFontColor: customFontColor,
        layoutBgColor: customBgColor,
        layoutIconColor: customIconColor,
        layoutHoverColor: customHoverColor,
      },
      background: { default: "#fafafb" },
      text: { primary: "#000000" },
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            justifyContent: "center", // Center-aligns AM and PM items
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "black", // Ensures typography elements are black
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          labelContainer: {
            color: "black",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: "black",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "black",
          },
        },
      },
    },
  });
};
