import React, { useState } from "react";
import { Box, Typography, TextField, FormHelperText } from "@mui/material";

export const PhoneNumberField = ({
  label,
  placeholder,
  value = "",
  onChange,
  height = "36px",
  width = "162px",
  error = false,
  helperText = "",
}) => {
  const handlePhoneNumberChange = (e) => {
    if (!onChange) return;

    let newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

    if (newValue.length <= 3) {
      newValue = newValue ? `(${newValue}` : "";
    } else if (newValue.length <= 6) {
      newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3)}`;
    } else if (newValue.length <= 10) {
      newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(
        3,
        6
      )}-${newValue.slice(6)}`;
    }

    onChange(newValue); // Pass only the formatted value
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && value.length > 0) {
      e.preventDefault();
      let newValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      newValue = newValue.slice(0, -1); // Remove the last character

      if (newValue.length <= 3) {
        newValue = newValue ? `(${newValue}` : "";
      } else if (newValue.length <= 6) {
        newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3)}`;
      } else if (newValue.length <= 10) {
        newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(
          3,
          6
        )}-${newValue.slice(6)}`;
      }

      if (onChange) {
        onChange(newValue); // Pass only the formatted value
      }
    }
  };

  return (
    <Box>
      <Typography
        variant="h1"
        sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
      >
        {label}
      </Typography>
      <TextField
        value={value}
        autoComplete="off"
        onChange={handlePhoneNumberChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        inputProps={{ maxLength: 14 }}
        sx={{
          width: width,
          "& .MuiInputBase-input": { fontSize: "11px", padding: "8px 12px" },
          "& .MuiOutlinedInput-root": { height: height },
          "& .MuiInputLabel-root": { fontSize: "11px" },
          "& .MuiInputBase-input::placeholder": { color: "#4b5563" },
        }}
      />
      {helperText && (
        <FormHelperText error={error} sx={{ marginLeft: "12px" }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};
export const ZipCodeField = ({
  label,
  placeholder = "11320",
  value = "",
  onChange,
}) => {
  const handleZipCodeChange = (e) => {
    let newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

    if (newValue.length > 5) {
      newValue = `${newValue.slice(0, 5)}-${newValue.slice(5, 9)}`;
    }

    if (onChange) {
      onChange({ target: { value: newValue } }); // Pass as an event-like object
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && value.length > 0) {
      e.preventDefault();
      let newValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      newValue = newValue.slice(0, -1); // Remove the last digit

      if (newValue.length > 5) {
        newValue = `${newValue.slice(0, 5)}-${newValue.slice(5, 9)}`;
      }

      if (onChange) {
        onChange({ target: { value: newValue } }); // Pass as an event-like object
      }
    }
  };

  return (
    <Box>
      <Typography
        variant="h1"
        sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
      >
        {label}
      </Typography>
      <TextField
        value={value}
        autoComplete="off"
        onChange={handleZipCodeChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        inputProps={{ maxLength: 10 }}
        sx={{
          width: "100px",
          "& .MuiOutlinedInput-root": { height: "36px" },
          "& .MuiInputBase-input": { fontSize: "11px", padding: "8px 12px" },
          "& .MuiInputBase-input::placeholder": { color: "#4b5563" },
        }}
      />
    </Box>
  );
};
