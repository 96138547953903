import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import H from '@here/maps-api-for-javascript';

import { ROUTE_DETAIL } from "../../utils/apiUrl";
import { getApiWithLocalAuth } from "../../utils/api";

import "./routeReturnDetail.css";
import moment from "moment";

function floatToTime(floatValue) {
  const hours = Math.floor(floatValue / 60);
  const remainingMinutes = floatValue % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes % 1) * 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
  }

  return parts.join(" ");
}

function modifyDateTime(dateTimeString, addedMinutes) {
  const dateTime = new Date(dateTimeString);
  dateTime.setMinutes(dateTime.getMinutes() + Math.floor(addedMinutes));
  return dateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

const apikey = process.env.REACT_APP_HERE_API;

function RouteReturnHereDetailMobile() {

  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const ui = useRef(null);

  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState();
  const [routeOrders, setRouteOrders] = useState([]);
  const [userPosition, setUserPosition] = useState([]);
  const [allRouteOrders, setAllRouteOrders] = useState([]);
  const [reOrdering, setReOrdering] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [currentBubble, setCurrentBubble] = useState(null)
  const [mapType, setMapType] = useState('StorePolyline');
  const [optimizeFor, setOptimizeFor] = useState('DISTANCE');
  const [estimatedTime, setEstimatedTime] = useState();
  const [distanceTotal, setDistanceTotal] = useState();



  const { rid, token } = useParams();

  const getRouteDetail = async () => {
    setLoading(true);

    const response = await getApiWithLocalAuth(token, ROUTE_DETAIL + `?route=${rid}`);
    if (response.data !== undefined) {
      setRouteOrders(response.data.data.orders);
      setRoutes(response.data.data.route);
      setEstimatedTime(response.data.data.route.estimatedTimeCal)
      setDistanceTotal(response.data.data.route.distanceCal)
      setUserPosition({ lat: response.data.data.route.latitude, lng: response.data.data.route.longitude })
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!map.current && routeOrders?.length > 0) {
      platform.current = new H.service.Platform({ apikey });
      const defaultLayers = platform.current.createDefaultLayers({
        pois: true
      });

      map.current = new H.Map(
        mapRef.current,
        defaultLayers.vector.normal.map, {
          zoom: 10,
          center: userPosition,
        }
      );

      ui.current = H.ui.UI.createDefault(map.current, defaultLayers);
      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(map.current)
      );

      // if (routeOrders.length > 0) {
      //   if (routeOrders[0].polyline === null || routeOrders[0].polyline === "") {
      //     findAndDisplayRoute(platform.current, map.current, userPosition, routeOrders);
      //   } else {
      //       routeResponseHandler(map.current, routeOrders, null);
      //   }
      // }
    }
    if (routeOrders?.length > 0) {
      if ((mapType === "LiveMap" && !reOrdering)) {
        setLoading(true)
        findAndDisplayRoute(platform.current, map.current, userPosition, routeOrders, optimizeFor);
      } else if(mapType === "StorePolyline") {
        routeResponseHandler(map.current, routeOrders, null);
      }
    }

    if (routeOrders?.length < 1) {
      getRouteDetail();
    }
  }, [apikey, routeOrders, optimizeFor, mapType]);

//  // Function to find and display the best route,
//  async function findAndDisplayRoute(platform, map, start, orderList, optimizeFor) {
//   const destination = orderList[orderList.length - 1]; // Assuming last restaurant as the final destination
//   // const viaPoints = orderList.slice(0, -1); // All except the last point

//   const bestRoute = await findBestRouteSequence(apikey, start, orderList, destination, optimizeFor);

//   if (bestRoute) {
//     const waypoints = bestRoute[0].waypoints

//     const sortedWaypoints = sortOrder === 'ascending' 
//       ? waypoints 
//       : [waypoints[0], ...waypoints.slice(1).reverse()];
    
//     const allSections = [];

//     for (let i = 0; i < sortedWaypoints.length - 1; i++) {
//       const start = sortedWaypoints[i];
//       const end = sortedWaypoints[i + 1];
//       const routeDetails = await fetchRouteDetails(apikey, start, end);

//       if (routeDetails && routeDetails.routes[0] && routeDetails.routes[0].sections) {
//         allSections.push(...routeDetails.routes[0].sections);
//       }
//     }
//     // Once all sections are collected, display them on the map
//     const reorderList = await reorderOrderList(routeOrders, allSections);
//     if (reorderList) {
//       routeResponseHandler(map, allSections, reorderList);
//     }
//   }
// }
 // Function to find and display the best route,
 async function findAndDisplayRoute(platform, map, start, orderList, optimizeFor) {
  const destination = orderList[orderList.length - 1]; // Assuming last restaurant as the final destination
  // const viaPoints = orderList.slice(0, -1); // All except the last point

  const bestRoute = await findBestRouteSequence(apikey, start, orderList, destination, optimizeFor);

  if (bestRoute) {
    const waypoints = bestRoute[0].waypoints
    // calculateRoute(platform, map, start, destination, bestRoute);
    const allSections = [];

    for (let i = 0; i < waypoints.length - 1; i++) {
      const start = waypoints[i];
      const end = waypoints[i + 1];

      // Fetch route details between each waypoint
      const routeDetails = await fetchRouteDetails(apikey, start, end);
      if (routeDetails && routeDetails.routes[0] && routeDetails.routes[0].sections) {
        allSections.push(...routeDetails.routes[0].sections);
      }
    }
    // Once all sections are collected, display them on the map
    const reorderList = await reorderOrderList(routeOrders, allSections);
    if (reorderList) {
      routeResponseHandler(map, allSections, reorderList);
    }
  }
}

  async function reorderOrderList(orderList, sections) {
    const locationToOrder = orderList.reduce((acc, order) => {
      const latLngKey = `${parseFloat(order.pharmacy_details.latitude).toFixed(5)},${parseFloat(order.pharmacy_details.longitude).toFixed(5)}`;
      if (!acc[latLngKey]) {
          acc[latLngKey] = [];
      }
      acc[latLngKey].push(order);
      return acc;
    }, {});

    const newOrderList = [];
    const processedOrders = new Set();
    let time = 0.0
    let distance = 0.0
    sections.forEach((section, index) => {
      const arrivalLocation = section.arrival.place.originalLocation;
      const duration = section.summary.baseDuration;
      const sectionDistance = section.summary.length;
      const durationMinutes = (parseInt(duration) / 60) + 5;
      const distanceMiles = parseInt(sectionDistance) / 1609.34;
      const latLngKey = `${arrivalLocation.lat.toFixed(5)},${arrivalLocation.lng.toFixed(5)}`;
      time += durationMinutes;
      distance += distanceMiles;
      if (latLngKey in locationToOrder) {
        locationToOrder[latLngKey].forEach((order, i) => {
            if (!processedOrders.has(order)) {
                processedOrders.add(order);
                order.number = index + i + 1;
                order.duration = durationMinutes;
                order.distance = distanceMiles;
                order.polyline = section.polyline; // Placeholder for the polyline URL
                newOrderList.push(order);
            }
        })
      }
      
    })


    setEstimatedTime(time)
    setDistanceTotal(distance)
    setRouteOrders(newOrderList);
    setAllRouteOrders(newOrderList);
    setReOrdering(true);
    setLoading(false)
    return newOrderList
  };

  async function findBestRouteSequence(apikey, start, waypoints, end, optimizeFor) {
    const baseUrl = "https://wps.hereapi.com/v8/findsequence2";
    const departureTime = encodeURIComponent("2021-10-15T10:30:00+05:00"); // Correctly encoding the time string
    let url = `${baseUrl}?mode=fastest;car;traffic:enabled&start=${start.lat},${start.lng}&apikey=${apikey}&departure=${departureTime}&improveFor=${optimizeFor}`;

    waypoints.forEach((point, index) => {
      url += `&destination${index + 1}=${point.pharmacy_details.latitude},${point.pharmacy_details.longitude}`;
    });

    try {
      const copyUrl = `https://wps.hereapi.com/v8/findsequence2?mode=fastest;car;traffic:enabled&start=40.5967467,-73.7387068&apikey=6CgVwXbgNzlj3uCnA38M0Du16b2o_0DKBOLGYyc1SH8&departure=2024-06-03T01:25:08-0400&improveFor=TIME&destination1=40.85271,-73.90732&destination2=40.85371,-73.90545&destination3=40.85362,-73.90502&destination4=40.85269,-73.90503`
      const response = await fetch(url);
      const data = await response.json();
      return data.results; // Assuming 'results' contains the ordered list of waypoints
    } catch (error) {
      console.error('Error finding best route sequence:', error);
    }
  }

  async function fetchRouteDetails(apikey, origin, destination) {
    const baseUrl = 'https://router.hereapi.com/v8/routes';
    const url = `${baseUrl}?apikey=${apikey}&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&routingMode=fast&transportMode=car&return=polyline,summary`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching route details:', error);
    }
  }

  async function routeResponseHandler(map, response, reorderList) {
    const sections = response;
    if (reorderList === null) {
      async function fetchPolyline(url) {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch polyline');
        const data = await response.text();
        return H.geo.LineString.fromFlexiblePolyline(data);
      }

      // Map over sections and convert polylines
      const promises = sections.map(section => {
        return fetchPolyline(section.polyline);
      });

      const lineStrings = await Promise.all(promises);
      const multiLineString = new H.geo.MultiLineString(lineStrings);
      const bounds = multiLineString.getBoundingBox();
      const routePolyline = new H.map.Polyline(multiLineString, { style: { lineWidth: 5 } });
      map.removeObjects(map.getObjects());
      map.addObject(routePolyline);

      const startMarker = createMarker({ pharmacy_details: { latitude: routes?.latitude, longitude: routes?.longitude, route: routes } }, '#0b6699', "S", true);
      // const endMarker = createMarker(restaurantList[restaurantList.length - 1], 'green');

      let lastWaypoint = null;
      let lastIndex = 0;
      let indices = [];

      response?.forEach((waypoint, i) => {
          const currentLatLng = `${parseFloat(waypoint.pharmacy_details.latitude).toFixed(5)},${parseFloat(waypoint.pharmacy_details.longitude).toFixed(5)}`;
          
          if (lastWaypoint && currentLatLng === lastWaypoint) {
              indices.push(i + 1);
          } else {
              if (indices.length) {
                  const markerLabel = indices.join(',');
                  const waypointMarker = createMarker(response[lastIndex], '#FF7733', markerLabel, false, indices.length);
                  map.addObject(waypointMarker);
              }
              indices = [i + 1];
              lastIndex = i;
          }
          lastWaypoint = currentLatLng;
      });

      if (indices.length) {
          const markerLabel = indices.join(',');
          const waypointMarker = createMarker(response[lastIndex], '#FF7733', markerLabel, false, indices.length);
          map.addObject(waypointMarker);
      }
      map.addObjects([startMarker]);
      map.getViewModel().setLookAtData({ bounds });
    } else {
      const lineStrings = sections.map(section => H.geo.LineString.fromFlexiblePolyline(section.polyline));
      const multiLineString = new H.geo.MultiLineString(lineStrings);
      const bounds = multiLineString.getBoundingBox();

      const routePolyline = new H.map.Polyline(multiLineString, { style: { lineWidth: 5 } });
      map.removeObjects(map.getObjects());
      map.addObject(routePolyline);

      const startMarker = createMarker({ pharmacy_details: { latitude: routes?.latitude, longitude: routes?.longitude }, route: routes }, '#0b6699', "S", true);
      // const endMarker = createMarker(restaurantList[restaurantList.length - 1], 'green');
      let lastWaypoint = null;
      let lastIndex = 0;
      let indices = [];

      reorderList?.forEach((waypoint, i) => {
          const currentLatLng = `${parseFloat(waypoint.pharmacy_details.latitude).toFixed(5)},${parseFloat(waypoint.pharmacy_details.longitude).toFixed(5)}`;
          
          if (lastWaypoint && currentLatLng === lastWaypoint) {
              indices.push(i + 1);
          } else {
              if (indices.length) {
                  console.log(indices.length)
                  const markerLabel = indices.join(',');
                  const waypointMarker = createMarker(reorderList[lastIndex], '#FF7733', markerLabel, false, indices.length);
                  map.addObject(waypointMarker);
              }
              indices = [i + 1];
              lastIndex = i;
          }
          lastWaypoint = currentLatLng;
      });

      if (indices.length) {
          const markerLabel = indices.join(',');
          const waypointMarker = createMarker(reorderList[lastIndex], '#FF7733', markerLabel, false, indices.length);
          map.addObject(waypointMarker);
      }
      map.addObjects([startMarker]);
      map.getViewModel().setLookAtData({ bounds });
    }
  }

  function createMarker(coords, color, number, home, count=1) {
    if (!home) {
      if (coords?.status === 'failed') {
        color = 'red';
      } else if (coords?.status === 'delivered') {
        color = 'green';
      } else {
        color = '#FF7733'
      }
    }
    const icon = getMarkerIcon(color, number);
    const marker = new H.map.Marker({ lat: coords.pharmacy_details.latitude, lng: coords.pharmacy_details.longitude }, { icon });
    const infoContent = `
    <div style="width: 400px; font-size: 12px;">
      ${home ? (
        `<div>
          <p>
            <span style="font-weight: 700;">Address: </span>
            ${routes?.address}
          </p>
          ${routes?.routeStatus === "In Progress" ? (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${modifyDateTime(routes?.startTime, 0.0)}
            </p>`
          ) : (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              Not Started Yet
            </p>`
          )}
        </div>`
      ) : (
        `<div>
          <p>
            <span style="font-weight: 700;">Name: </span>
            ${coords.pharmacy_details.name}
          </p>
          <p>
            <span style="font-weight: 700;">Address: </span>
            ${coords.pharmacy_details.location}
          </p>
          ${routes?.routeStatus === "In Progress" ? (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${modifyDateTime(routes?.startTime, coords.estimatedTimeCal)}
            </p>`
          ) : routes?.routeStatus === "Done" ? (
            `<div>
              <p>
                <span style="font-weight: 700;">
                  Planned Arrival Time: 
                </span>
                ${modifyDateTime(routes?.startTime, coords.estimatedTimeCal)}
              </p>
              <p>
                <span style="font-weight: 700;">
                  Delivery Time: 
                </span>
                ${moment(coords.order.deliverAt).format("hh:mm A")}
              </p>
            </div>`
          ) : (
            `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${floatToTime(coords.estimatedTimeCal)} (after start)
            </p>`
          )}
        </div>`
      )}
    </div>
    `;

    if(currentBubble) {
      ui.current.removeBubble(currentBubble);
      setCurrentBubble(null);
    }

    marker.addEventListener('tap', () => showInfoBubble(marker, infoContent));
    
    const markers = [];
    for (let i = 0; i < count; i++) {
      markers.push(marker)
    }

    setMarkers(prevMarkers => [...prevMarkers, ...markers]);
    return marker;
  }

  function getMarkerIcon(color, number) {
    return new H.map.Icon(
      `<svg width="30" height="20" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="30" height="20" fill="${color}" stroke="${color}" stroke-width="2" />
        <path d="M189.5 350L111.441 257.726L267.559 257.726L189.5 350Z" fill="${color}"/>
        <text x="50%" y="50%" font-family="Arial" font-size="10" font-weight="bold" text-anchor="middle" alignment-baseline="middle" fill="white">${number}</text>
      </svg>`, { anchor: { x: 15, y: 15 } }
      );
  }

  function showInfoBubble(marker, text) {
    const bubble = new H.ui.InfoBubble(marker.getGeometry(), { content: text });
    ui.current.addBubble(bubble);
    setCurrentBubble(bubble);
  }


  return (
    <div>
        <div style={{ width: "100%", height: "100vh" }} ref={mapRef} />
    </div>
  );
}

export default RouteReturnHereDetailMobile;
