import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ConfirmationDialog = ({
  open,
  title,
  description,
  onConfirm,
  onCancel,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirmation-dialog-title"
      PaperProps={{
        style: {
          width: "400px",
          height: "250px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Typography variant="h6">{title || "Are you sure?"}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {description || "Do you want to proceed with this action?"}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginX: "30px",
          marginY: "15px",
        }}
      >
        <Button onClick={onCancel} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.layoutBgColor,
            color: theme.palette.custom.layoutFontColor,
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
