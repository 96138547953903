import React from "react";
import NotificationForm from "../components/AdminNotificationPageCompo/NotificationForm";

const AdminNotifications = () => {
  const handleNotificationSubmit = (notificationData) => {
    console.log("New Notification:", notificationData);
  };

  return (
    <div>
      <NotificationForm onSubmit={handleNotificationSubmit} />
    </div>
  );
};

export default AdminNotifications;
