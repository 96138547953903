import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Checkbox,
  IconButton,
} from "@mui/material";
import {
  getApiWithAuth,
  putApiWithAuth,
  postApiWithAuth,
  deleteApiWithAuth,
} from "../../utils/api";
import {
  CREATE_ORDER,
  CHANGE_ORDER_STATUS,
  REOPTIMIZED_ROUTE,
  CREATE_ROUTE_ZONE,
  CREATE_ROUTE_ORDER,
  CREATE_ROUTE,
  CREATE_ROUTE_BOROUGH,
  CREATE_ROUTE_RETURN_ZONE, CREATE_ROUTE_RETURN_BOROUGH,
  CREATE_ROUTE_RETURN_ORDER
} from "../../utils/apiUrl";
import { useTheme } from "@mui/material";
import { ContainedButtonWithIcon } from "../buttonCompo/Buttons";
import { CreateRouteIcon, ScanIcon } from "../Icons";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ScanAndRouteButtons = ({ onSuccess, onstatusChange, selectedTab }) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [returnAcceptModel, setReturnAcceptModel] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [orderDetail, setOrderDetail] = useState([]);
  const [orderIdReturn, setOrderIdReturn] = useState();
  const [alreadyRouteList, setAlreadyRouteList] = useState([]);
  const [scanReceivingNum, setScanReceivingNum] = useState(0);
  const [scanDispatchingNum, setScanDispatchingNum] = useState(0);
  const [scanReturningNum, setScanReturningNum] = useState(0);
  const [edit, setEdit] = useState(false);
  const [routeCreation, setRouteCreation] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState(
    "1535 Street Ave, Bronx, NY 10461, USA"
  );
  const [facility, setFacility] = useState("Pilldrop Facility");
  const getOrderDetail = async (id) => {
    try {
      const response = await getApiWithAuth(CREATE_ORDER + `/${id}`);
      setOrderDetail(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const closeReturnOrderModal = () => {
    setReturnAcceptModel(false);
  };
  const HandleRouteCreateByZone = async () => {
    if (selectedTab.num > 0) {
      setOpenModal(false);
      setLoading(true);
      const response = await postApiWithAuth(CREATE_ROUTE_ZONE, {});
      if (response.data.status === 201) {
        toast.success(
          `Route creation process started and Orders set by zone!`,
          {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setRouteCreation(true);
        setTaskId(response.data.data.task_id);
      } else {
        setLoading(false);
        toast.error(`Backend Error!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOpenModal(false);
      }
      setLoading(false);
    } else {
      toast.error(`please go on dispatching tab first for creating route`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };
  const HandleRouteCreate = async () => {
    if (selectedTab.num > 0) {
      let routePayload = {
        name: "All Orders Route",
        detail_name: "All Route Detail",
        address: address,
        driver_status: "Start",
      };
      setOpenModal(false);
      setLoading(true);
      const response = await postApiWithAuth(CREATE_ROUTE, routePayload);
      if (response.data.data != undefined) {
        const routeId = response.data.data.id;
        let routeOrderPayload = {
          route: routeId,
        };

        const routeOrderResponse = await postApiWithAuth(
          CREATE_ROUTE_ORDER,
          routeOrderPayload
        );
        if (routeOrderResponse.data.status === 201) {
          toast.success(`Route Created and Orders Set!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // setRouteCreation(true);
          // setTaskId(response.data.data.task_id)
          setOpenModal(false);
          setLoading(false);
          onstatusChange("7", "Route Created", true);
          setLoading(false);
        } else if (
          routeOrderResponse.data.error ===
          "No Orders to Create Route, Make Sure Their are orders on Dispatching Tab"
        ) {
          setLoading(false);
          toast.error(`Backend Error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          await deleteApiWithAuth(CREATE_ROUTE + `${response.data.data.id}/`);
          setOpenModal(false);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(`Backend Error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          await deleteApiWithAuth(CREATE_ROUTE + `${response.data.data.id}/`);
          setOpenModal(false);
          setLoading(false);
        }
        setLoading(false);
      }
      setLoading(false);
    } else {
      toast.error(`You don't have any order for route`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };
  const HandleRouteReturnCreateByZone = async () => {
    if (selectedTab.label === "Canceled") {
      if (selectedTab.num > 0) {
        setOpenModal(false);
        setLoading(true);
        const response = await postApiWithAuth(CREATE_ROUTE_RETURN_ZONE, {});
        if (response.data.status === 201) {
          setLoading(false);
          toast.success(`Route creation process started and Orders set by zone!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRouteCreation(true);
          setTaskId(response.data.data.task_id)
        } else {
          setLoading(false);
          toast.error(`Backend Error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOpenModal(false);
        }
        setLoading(false);
      } else {
        toast.error(`You don't have any order for route`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    } else {
      toast.error(`please go on dispatching tab first for creating route`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };
  const HandleRouteCreateByBorough = async () => {
    if (selectedTab.num > 0) {
      setOpenModal(false);
      setLoading(true);
      const response = await postApiWithAuth(CREATE_ROUTE_BOROUGH, {});
      if (response.data.status === 201) {
        setLoading(false);
        toast.success(
          `Route creation process started and Orders set by cities!`,
          {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setRouteCreation(true);
        setTaskId(response.data.data.task_id);
      } else {
        setLoading(false);
        toast.error(`Backend Error!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOpenModal(false);
        setLoading(false);
      }
      setLoading(false);
    } else {
      toast.error(`You don't have any order for route`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };
  const HandleRouteReturnCreate = async () => {
    if (selectedTab.label === "Canceled") {
      if (selectedTab.num > 0) {
        let routePayload = {
          name: "All Orders Route Return Pharmacy",
          detail_name: "All Route Detail Return Pharmacy",
          address: address,
          driver_status: "Start",
        };
        setOpenModal();
        setLoading(true);
        const response = await postApiWithAuth(CREATE_ROUTE, routePayload);
        if (response.data.data != undefined) {
          const routeId = response.data.data.id;
          let routeOrderPayload = {
            route: routeId,
          };

          const routeOrderResponse = await postApiWithAuth(
            CREATE_ROUTE_RETURN_ORDER,
            routeOrderPayload
          );

          if (routeOrderResponse.data.status === 201) {
            toast.success(`Route Created and Orders Set!`, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setRouteCreation(true);
            setOpenModal(false);
            onstatusChange("6", "Cancelled", false);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(`Backend Error!`, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            await deleteApiWithAuth(CREATE_ROUTE + `${response.data.data.id}/`)
            setOpenModal(false);
            setLoading(false);
          }
          setLoading(false);
        }
        setLoading(false);
      } else {
        toast.error(`You don't have any order for route`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
    else {
      toast.error(`please go on Returned tab first for creating route`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };
  const HandleRouteReturnCreateByBorough = async () => {
    if (selectedTab.label === "Canceled") {
      if (selectedTab.num > 0) {
        setOpenModal();
        setLoading(true);
        const response = await postApiWithAuth(CREATE_ROUTE_RETURN_BOROUGH, {});
        if (response.data.status === 201) {
          setLoading(false);
          toast.success(`Route creation process started and Orders set by cities!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRouteCreation(true);
          setTaskId(response.data.data.task_id)
          onstatusChange("7", "Route Created", true);
          // fetchOrderNum();
          setOpenModal(false);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(`Backend Error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOpenModal(false);
          setLoading(false);
        }
        setLoading(false);
      } else {
        toast.error(`You don't have any order for route`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    } else {
      toast.error(`please go on dispatching tab first for creating route`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };
  let debounceTimeout = null;
  const handleDebouncedScan = async (data, accept_to_return) => {
    clearTimeout(debounceTimeout);
    if (data) {
      try {
        const response = await putApiWithAuth(
          CHANGE_ORDER_STATUS + `${data.target.value}`,
          { id: "3", current: "3", accept_to_return: accept_to_return }
        );
        if (response.data.error != undefined) {
          if (response.data?.in_route === true) {
            setReturnAcceptModel(true);
            setOrderIdReturn(response.data?.orderId);
            setAlreadyRouteList(response.data?.route_orders);
          }
          toast.error(`Order ${response.data.error}`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (response.data !== undefined) {
          if (response.data?.route_orders) {
            const route_Ids = response.data?.route_orders;
            route_Ids.forEach(async (route_Id) => {
              await getApiWithAuth(REOPTIMIZED_ROUTE + `${route_Id}/`);
            });
          }
          setReturnAcceptModel(false);
          onstatusChange("3", "Received at Facility", true);
          setAlreadyRouteList([]);
          // fetchOrderNum();
          toast.success(
            `QR Code scanned order ${data.target.value} Successfully!`,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          onSuccess();
          // setSelectedTab('receivedAtFacility')
          setScanReceivingNum(parseInt(scanReceivingNum) + 1);
        }
      } catch (error) {
        if (error.response.data.error === "already on that status") {
          toast.error(
            `Order ${data.target.value} ${error.response.data.error}`,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        } else if (error.response.data.error === "delivered") {
          toast.error(`Order ${data.target.value} is delivered`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (error.response.data.error === "cancelled") {
          toast.error(`Order ${data.target.value} is cancelled`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`Order ${data.target.value} have backend error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setReturnAcceptModel(false);
      }
    }
  };
  let debounceTimeout2 = null;
  let debounceTimeout3 = null;

  const handleDebouncedScan2 = async (data) => {
    clearTimeout(debounceTimeout2);
    if (data) {
      try {
        const response = await putApiWithAuth(
          CHANGE_ORDER_STATUS + `${data.target.value}`,
          { id: "4", current: "3", scan: "dispatching" }
        );
        if (response.data.error != undefined) {
          toast.error(`Order ${response.data.error}`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (response.data !== undefined) {
          onstatusChange("4", "Dispatching", true);
          // fetchOrderNum();
          toast.success(
            `QR Code scanned order ${data.target.value} Successfully!`,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          // setSelectedTab('dispatching')
          onSuccess();
          setScanDispatchingNum(parseInt(scanDispatchingNum) + 1);
        }
      } catch (error) {
        if (error.response.data.error === "already on that status") {
          toast.error(
            `Order ${data.target.value} ${error.response.data.error}`,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        } else if (error.response.data.error === "delivered") {
          toast.error(`Order ${data.target.value} is delivered`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (error.response.data.error === "cancelled") {
          toast.error(`Order ${data.target.value} is cancelled`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`Order ${data.target.value} have backend error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };
  const handleDebouncedScan3 = async (data) => {
    clearTimeout(debounceTimeout3);
    if (data) {
      try {
        const response = await putApiWithAuth(
          CHANGE_ORDER_STATUS + `${data.target.value}`,
          { id: "7", current: "6" }
        );
        if (response.data.error != undefined) {
          toast.error(`Order ${response.data.error}`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (response.data !== undefined) {
          onstatusChange("6", "Cancelled", false);
          // fetchOrderNum();
          toast.success(
            `QR Code scanned order ${data.target.value} Successfully!`,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          // setSelectedTab('cancelled');
          onSuccess();
          setScanReturningNum(parseInt(scanReturningNum) + 1)
        }
      } catch (error) {
        if (error.response.data.error === "already on that status") {
          toast.error(
            `Order ${data.target.value} ${error.response.data.error}`,
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        } else if (error.response.data.error === "delivered") {
          toast.error(`Order ${data.target.value} is delivered`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (error.response.data.error === "cancelled") {
          toast.error(`Order ${data.target.value} is cancelled`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`Order ${data.target.value} have backend error!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };
  const handleChangeRecieve = (event, accept_to_return = false) => {
    console.log(event);
    clearTimeout(debounceTimeout); // Clear the previous debounce timeout
    debounceTimeout = setTimeout(() => {
      handleDebouncedScan(event, accept_to_return);
      getOrderDetail(event.target.value);
      debounceTimeout = null;
      event.target.value = "";
    }, 1000); // Set a new timeout of 2 seconds
  };
  const handleChangeReturn = (event) => {
    clearTimeout(debounceTimeout3);
    debounceTimeout3 = setTimeout(() => {
      handleDebouncedScan3(event);
      getOrderDetail(event.target.value);
      debounceTimeout3 = null;
      event.target.value = "";
    }, 1000);
  };
  const handleChangeDispatch = (event) => {
    clearTimeout(debounceTimeout2);
    debounceTimeout2 = setTimeout(() => {
      handleDebouncedScan2(event);
      getOrderDetail(event.target.value);
      debounceTimeout2 = null;
      event.target.value = "";
    }, 1000);
  };
  const handleOpenModal = (title) => {
    setModalTitle(title);
    setOpenModal(true);
  };
  const handleEditClick = () => {
    if (isEditMode) {
      // Save functionality and close modal logic can be added here
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };


  return (
    <Box>
      {(selectedTab.label !== "Canceled" && selectedTab.label !== "Cancelled" && selectedTab.label !== "Return Route Created" && selectedTab.label !== "Delivered" && selectedTab.label !== "Return Created" && selectedTab.label !== "Return to Pharmacy") && (
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          backgroundColor: "#fff",
          paddingBottom: 0,
          display: "flex",
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            paddingX: 2,
            backgroundColor: theme.palette.custom.layoutBgColor,
            color: theme.palette.custom.layoutFontColor,
            borderRadius: "10px",
            boxShadow: "none",
          }}
          onClick={() => handleOpenModal("Receiving Scan")}
        >
          Receiving Scan
          <ScanIcon fill={theme.palette.custom.layoutFontColor} />
        </Button>

        <Button
          variant="contained"
          sx={{
            paddingX: 2,
            backgroundColor: theme.palette.custom.layoutBgColor,
            color: theme.palette.custom.layoutFontColor,
            borderRadius: "10px",
            boxShadow: "none",
          }}
          onClick={() => handleOpenModal("Dispatching Scan")}
        >
          Dispatching Scan
          <ScanIcon fill={theme.palette.custom.layoutFontColor} />
        </Button>
        <Button
          variant="contained"
          sx={{
            paddingX: 2,
            backgroundColor: theme.palette.custom.layoutBgColor,
            color: theme.palette.custom.layoutFontColor,
            borderRadius: "10px",
            boxShadow: "none",
            cursor:
              selectedTab.label === "Dispatching" ? "pointer" : "not-allowed",
            opacity: selectedTab.label === "Dispatching" ? 1 : 0.5,
            "&.Mui-disabled": {
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
              opacity: 0.5,
            },
          }}
          disabled={selectedTab.label !== "Dispatching"}
          onClick={() => {
            if (selectedTab.label === "Dispatching") {
              handleOpenModal("Create Route");
            }
          }}
        >
          Create Route
          <CreateRouteIcon fill={theme.palette.custom.layoutFontColor} />
        </Button>
      </Box>
      )}
      {selectedTab.label === "Cancelled" && (
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          backgroundColor: "#fff",
          paddingBottom: 0,
          display: "flex",
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            paddingX: 2,
            backgroundColor: theme.palette.custom.layoutBgColor,
            color: theme.palette.custom.layoutFontColor,
            borderRadius: "10px",
            boxShadow: "none",
          }}
          onClick={() => handleOpenModal("Return Scan")}
        >
          Return Scan
          <ScanIcon fill={theme.palette.custom.layoutFontColor} />
        </Button>
      </Box>
      )}
      {selectedTab.label === "Return Created" && (
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          backgroundColor: "#fff",
          paddingBottom: 0,
          display: "flex",
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            paddingX: 2,
            backgroundColor: theme.palette.custom.layoutBgColor,
            color: theme.palette.custom.layoutFontColor,
            borderRadius: "10px",
            boxShadow: "none",
            cursor:
              selectedTab.label === "Return Created" ? "pointer" : "not-allowed",
            opacity: selectedTab.label === "Return Created" ? 1 : 0.5,
            "&.Mui-disabled": {
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
              opacity: 0.5,
            },
          }}
          disabled={selectedTab.label !== "Return Created"}
          onClick={() => {
            if (selectedTab.label === "Return Created") {
              handleOpenModal("Create Route Return");
            }
          }}
        >
          Create Route
          <CreateRouteIcon fill={theme.palette.custom.layoutFontColor} />
        </Button>
      </Box>
      )}
      {/* Modals */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        PaperProps={{
          sx:
            modalTitle === "Create Route"
              ? {
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  margin: 0,
                  borderRadius: "10px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                }
              : {
                  // Position modal for other scans
                  position: "absolute",
                  bottom: 50,
                  left: 255,
                  margin: 0,
                  borderRadius: "10px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                },
        }}
      >
        <Box sx={{}}>
          <DialogTitle
            sx={{ fontSize: "18px", fontWeight: 600, paddingBottom: 0 }}
          >
            {modalTitle}
          </DialogTitle>
          <DialogContent>
            {(modalTitle === "Create Route" || modalTitle === "Create Route Return" ) ? (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "space-between",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <ContainedButtonWithIcon
                    label="All In One Route"
                    height="36px"
                    onClick={
                      modalTitle === "Create Route"
                        ? HandleRouteCreate
                        : HandleRouteReturnCreate
                    }
                    icon={
                      <img
                        src="/icons/Route.svg"
                        alt="icon"
                        style={{ marginLeft: "8px" }}
                      />
                    }
                  />
                  <ContainedButtonWithIcon
                    label="Create According to Cities"
                    onClick={
                      modalTitle === "Create Route"
                        ? HandleRouteCreateByBorough
                        : HandleRouteReturnCreateByBorough
                    }
                    height="36px"
                    icon={
                      <img
                        src="/icons/Route.svg"
                        alt="icon"
                        style={{ marginLeft: "8px" }}
                      />
                    }
                  />
                  <ContainedButtonWithIcon
                    label="Create According to Zones"
                    onClick={
                      modalTitle === "Create Route"
                        ? HandleRouteCreateByZone
                        : HandleRouteReturnCreateByZone
                    }
                    height="36px"
                    icon={
                      <img
                        src="/icons/Route.svg"
                        alt="icon"
                        style={{ marginLeft: "8px" }}
                      />
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "33px",
                    mb: "15px",
                  }}
                >
                  <Typography variant="body1" fontSize="16px" fontWeight={600}>
                    Starting Address
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      sx={{
                        textTransform: "none",
                        color: "white",
                        backgroundColor: "#000",
                        borderRadius: "12px",
                      }}
                    >
                      Make this Default
                    </Button>
                    <Checkbox />
                  </Box>
                </Box>

                {/* Editable text fields */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    fullWidth
                    variant="filled"
                    autoComplete="off"
                    value={facility}
                    onChange={(e) => setFacility(e.target.value)}
                    InputProps={{
                      readOnly: !isEditMode, // Set read-only when not in edit mode
                    }}
                    sx={{
                      width: "170px",
                      height: "40px",
                      "& .MuiFilledInput-root": {
                        height: "100%",
                        borderRadius: "8px",
                        backgroundColor: "#f5f5f5",
                        "&:before": {
                          borderBottom: "none",
                        },
                        "&:after": {
                          borderBottom: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px",
                        fontSize: "12px",
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    autoComplete="off"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    InputProps={{
                      readOnly: !isEditMode,
                    }}
                    sx={{
                      width: "430px",
                      height: "40px",
                      "& .MuiFilledInput-root": {
                        height: "100%",
                        borderRadius: "8px",

                        backgroundColor: "#f5f5f5",
                        "&:before": {
                          borderBottom: "none",
                        },
                        "&:after": {
                          borderBottom: "none",
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px",
                        fontSize: "12px",
                      },
                    }}
                  />
                  <Button
                    fontSize="14px"
                    onClick={handleEditClick}
                    sx={{ textTransform: "none", padding: 0 }}
                  >
                    {isEditMode ? "Save" : "Edit"}
                    <IconButton>
                      {!isEditMode && (
                        <img src={"/icons/order/EditBlack.svg"} alt={"edit"} />
                      )}
                    </IconButton>
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                {/* Receiving Scan and Dispatching Scan Modal */}
                <Box sx={{ display: "flex", gap: 2, flexDirection: "row" }}>
                  <Box width="74px">
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                      Scan #
                    </Typography>
                    <TextField
                      placeholder="Scan #"
                      autoComplete="off"
                      variant="outlined"
                      sx={{ flex: 1, height: "40px", width: "100%" }}
                      value={
                        modalTitle === "Receiving Scan"
                          ? scanReceivingNum
                          : modalTitle === "Return Scan"
                          ? scanReturningNum
                          : scanDispatchingNum
                      }
                       // Conditional value based on modalTitle
                      InputProps={{
                        sx: {
                          fontSize: "10px",
                          "& .MuiOutlinedInput-input": {
                            padding: "10px 5px",
                            fontSize: "10px",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box width="107px">
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                      Order Id
                    </Typography>
                    <TextField
                      placeholder="Order ID"
                      autoComplete="off"
                      value={orderDetail?.slug}
                      variant="outlined"
                      sx={{ flex: 2, height: "40px", width: "100%" }}
                      InputProps={{
                        sx: {
                          fontSize: "10px",
                          "& .MuiOutlinedInput-input": {
                            padding: "10px 5px",
                            fontSize: "10px",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box width="229px">
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                      Client
                    </Typography>
                    <TextField
                      placeholder="Name"
                      autoComplete="off"
                      value={orderDetail?.name}
                      variant="outlined"
                      sx={{ flex: 2, height: "40px", width: "100%" }}
                      InputProps={{
                        sx: {
                          fontSize: "10px",
                          "& .MuiOutlinedInput-input": {
                            padding: "10px 5px",
                            fontSize: "10px",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box width="226px">
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                      Pharmacy
                    </Typography>
                    <TextField
                      placeholder="Pharmacy"
                      autoComplete="off"
                      variant="outlined"
                      value={orderDetail?.pharmacy?.name}
                      sx={{
                        flex: 2,
                        height: "40px",
                        width: "100%",
                        fontSize: "10px",
                      }}
                      InputProps={{
                        sx: {
                          fontSize: "10px",
                          "& .MuiOutlinedInput-input": {
                            padding: "10px 5px",
                            fontSize: "10px",
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{ display: "flex", gap: 2, flexDirection: "row", mb: 2 }}
                >
                  <Box width="439px">
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                      City Zone
                    </Typography>
                    <TextField
                      placeholder="Undefined/Undefined"
                      autoComplete="off"
                      value={
                        orderDetail?.zone_name + " / " + orderDetail?.city_name
                      }
                      variant="outlined"
                      sx={{ flex: 1, height: "30px", width: "100%" }}
                      InputProps={{
                        sx: {
                          fontSize: "10px",
                          "& .MuiOutlinedInput-input": {
                            padding: "10px 5px",
                            fontSize: "10px",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box width="228px">
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                      Scan
                    </Typography>
                    <TextField
                      placeholder="Scan Receiving Order"
                      autoComplete="off"
                      autoFocus
                      variant="outlined"
                      sx={{ flex: 1, height: "30px", width: "100%" }}
                      InputProps={{
                        sx: {
                          fontSize: "10px",
                          "& .MuiOutlinedInput-input": {
                            padding: "10px 5px",
                            fontSize: "10px",
                          },
                        },
                      }}
                      onChange={
                        modalTitle === "Receiving Scan"
                          ? handleChangeRecieve
                          : modalTitle === "Return Scan"
                          ? handleChangeReturn
                          : handleChangeDispatch
                      }                      
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </DialogContent>
        </Box>
      </Dialog>
      <Dialog
        maxWidth={600}
        open={returnAcceptModel}
        onClose={closeReturnOrderModal}
        className="RouteModalBtns"
      >
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <h1 style={{ fontWeight: "700", margin: "0 150px" }}>
            Are you sure you want to return this order to "Received at Facility"
            status?
            <br />
            Please note, this order is already in a "Ready" status route named:
          </h1>
          {alreadyRouteList.length > 0 &&
            alreadyRouteList?.map((route, index) => (
              <p key={index} style={{ fontWeight: "700", margin: "0 150px" }}>
                {route.route_detail_name}
              </p>
            ))}
          <div className="action-btns">
            <button
              className="route-btn text-[16px] font-[700]"
              disabled={edit}
              onClick={() =>
                handleChangeRecieve({ target: { value: orderIdReturn } }, true)
              }
            >
              Yes
            </button>
            <button
              className="route-btn text-[16px] font-[700]"
              disabled={edit}
              onClick={closeReturnOrderModal}
            >
              No
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ScanAndRouteButtons;
