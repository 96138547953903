
import { configureStore } from '@reduxjs/toolkit';
import pharmacyReducer from './slices/pharmacySlice';
import zoneReducer from './slices/zoneSlice';
import driverReducer from './slices/driverSlice';
import userReducer from './slices/userSlice';


const store = configureStore({
  reducer: {
    pharmacy: pharmacyReducer,
    zone: zoneReducer,
    driver: driverReducer,
    user: userReducer,
  },
});

export default store;
