import React from "react";
import { Box } from "@mui/material";
import OrderInfo from "./orderInfo/OrderInfo";
import DeliveryNotes from "./deliveryNotes/DeliveryNotes";
import ActionIcons from "./actionIcons/ActionIcons";
import StatusInfo from "./statusInfo/StatusInfo";

const OrderDetails = ({
  showTicket,
  showUrgent,
  showphotoId,
  showRefrigerated,
  order,
  fetchorders,
  userDetail,
  markReadOrderChat,
  unreadCounts
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      mt={1.5}
      px={2}
      py={1}
      width="100%"
      height="142px"
      backgroundColor="#fff"
      borderRadius="10px"
      boxShadow={2}
      sx={{
        transition: "background-color 0.5s ease, box-shadow 0.5s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f0f0f0",
          boxShadow: 3,
        },
      }}
    >
      <OrderInfo
        showUrgent={showUrgent}
        showRefrigerated={showRefrigerated}
        showphotoId={showphotoId}
        order={order}
        userDetail={userDetail}
        markReadOrderChat={markReadOrderChat}
        unreadCounts={unreadCounts}
      />
      <StatusInfo
        showTicket={showTicket}
        order={order}
        fetchorders={fetchorders}
      />
      <DeliveryNotes order={order} />
      <ActionIcons order={order} fetchorders={fetchorders} userDetail={userDetail} />
    </Box>
  );
};

export default OrderDetails;
