import { Box } from "@mui/material";
import React from "react";
import TopBar from "../components/routePageCompo/TopBar";
import RouteDetailsSmall from "../smallComponents/RouteDetailsSmall";

const RouteSmall = () => {
  return (
    <Box sx={{ height: "90vh" }}>
      <TopBar />
      <RouteDetailsSmall />
    </Box>
  );
};

export default RouteSmall;
