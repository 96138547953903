import { Box, TextField, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { ContainedButtonWithIcon } from "../buttonCompo/Buttons";
import { AddIcon, SearchIcon } from "../Icons";
import SingleFieldDateRangePicker from "../dateRangePicker/DateRangePicker";
const Topbar = ({ onSearch, onDateChange }) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
  ]);

  const handleAddDriverClick = () => {
    navigate("/list/expense-form");
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onSearch(query); 
  };
  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    onDateChange(newDateRange);  // Call the parent component's function to update the date range
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TextField
        value={searchQuery}
        onChange={handleSearchChange} // Call the handler on change
        variant="outlined"
        autoComplete="off"
        placeholder="Search"
        InputProps={{
          sx: {
            fontSize: "11px",
            border: "1px solid #dce0e4",
          },
          startAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        sx={{
          width: 408,
          backgroundColor: "#fff",
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            height: "36px",
            paddingLeft: 0,
            "& fieldset": {
              border: "none",
            },
          },
        }}
        size="small"
      />
      <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
      }}
    >
      <SingleFieldDateRangePicker   value={dateRange}
          onChange={handleDateChange} />
      <ContainedButtonWithIcon
        label="Add Expense"
        icon={<AddIcon />}
        borderRadius="8px"
        width="150px"
        fontSize="13px"
        padding="4px"
        onClick={handleAddDriverClick}
      />
      </Box>
    </Box>
  );
};

export default Topbar;
