import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { OrderDataContext } from "../../context/OrderDataContext";

const DeliveryInstructions = ({ onOrderDetailChange, isEditable }) => {
  const theme = useTheme();
  const { orderData, setOrderData } = useContext(OrderDataContext);
  const [localData, setLocalData] = useState(orderData?.order);
  useEffect(() => {
    setLocalData(orderData?.order);
  }, [orderData]);
  const handleInputChange = (field, value) => {
    setLocalData((prev) => {
      const updatedRxList =
        field === "collectcopay"
          ? prev.orderdetail.map((rx) => ({ ...rx, select: value }))
          : prev.orderdetail;

      let totalCopay = 0;

      if (field === "collectcopay" && value) {
        totalCopay = updatedRxList.reduce(
          (sum, rx) => sum + parseFloat(rx.copay || 0),
          0
        );
      }

      return {
        ...prev,
        [field]: value,
        orderdetail: updatedRxList,
        totalcopay: totalCopay,
      };
    });
  };
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      onOrderDetailChange(localData);
    }, 300);
    return () => clearTimeout(debounceTimer);
  }, [localData, onOrderDetailChange]);

  return (
    <Box sx={{ mb: 1, width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "38px",
          marginBottom: "5px",
          backgroundColor: "#f4f6f8",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            opacity: "0.7",
            paddingLeft: "15px",
          }}
        >
          Delivery Information
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <TextField
          placeholder="Write any additional Indications/Missing Information"
          multiline
          rows={4.5}
          autoComplete="off"
          disabled={!isEditable}
          sx={{
            mb: 2,
            margin: 0,
            width: "376px",
            padding: 0,
            "& .MuiOutlinedInput-root": {
              paddingX: 0,
              paddingTop: 0,
            },
            "& .MuiInputBase-input": {
              padding: 0,
              paddingX: "5px",
              fontSize: "11px",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          name="notes"
          value={localData?.notes}
          onChange={(e) => handleInputChange("notes", e.target.value)}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: 0.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.signature}
                  onChange={(e) =>
                    handleInputChange("signature", e.target.checked)
                  }
                  name="signature"
                  sx={{ transform: "scale(0.8)" }}
                />
              }
              disabled={!isEditable}
              label={
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  Signature Required
                </Typography>
              }
              sx={{
                marginRight: 0,
              }}
            />
            <FormControl
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  paddingRight: "30px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  height: "34px",
                  width: "auto",
                },
              }}
            >
              <Select
                labelId="dropdown-label"
                name="parcelPlace"
                value={localData?.parcelPlace}
                disabled={!isEditable}
                onChange={(e) =>
                  handleInputChange("parcelPlace", e.target.value)
                }
                sx={{
                  fontSize: "11px",
                  "& .MuiSvgIcon-root": {
                    color: "black", // Change icon color
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                IconComponent={(props) => (
                  <img
                    src="/icons/deliveryIcons/Dropdown.svg"
                    alt="dropdown"
                    {...props}
                  />
                )}
              >
                <MenuItem value="">
                  <Typography variant="body2">Select Parcel Place</Typography>
                </MenuItem>
                <MenuItem value="Face to face">
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    Face To Face
                  </Typography>
                </MenuItem>
                <MenuItem value="Leave with receptionist">
                  <Typography variant="body2">
                    LEAVE WITH RECEPTIONIST
                  </Typography>
                </MenuItem>
                <MenuItem value="Leave at door if pt e-signs">
                  <Typography variant="body2">
                    LEAVE AT DOOR IF PT E-SIGNS
                  </Typography>
                </MenuItem>
                <MenuItem value="Leave at door">
                  <Typography variant="body2">LEAVE AT DOOR</Typography>
                </MenuItem>
                <MenuItem value="Leave in the mailbox">
                  <Typography variant="body2">LEAVE IN THE MAILBOX</Typography>
                </MenuItem>
                <MenuItem value="Leave in mail box if pt e-signs">
                  <Typography variant="body2">
                    LEAVE IN MAIL BOX IF PT E-SIGNS
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              Get Signed From
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.hippaform}
                  disabled={!isEditable}
                  onChange={(e) =>
                    handleInputChange("hippaform", e.target.checked)
                  }
                  name="hippaform"
                  sx={{ transform: "scale(0.8)" }}
                />
              }
              label={
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  Hipaa Form
                </Typography>
              }
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "13px",
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.lineform}
                  disabled={!isEditable}
                  onChange={(e) =>
                    handleInputChange("lineform", e.target.checked)
                  }
                  name="lineform"
                  sx={{ transform: "scale(0.8)" }}
                />
              }
              label={
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  Lien Form
                </Typography>
              }
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.nfaqbform}
                  onChange={(e) =>
                    handleInputChange("nfaqbform", e.target.checked)
                  }
                  disabled={!isEditable}
                  name="nfaqbform"
                  sx={{ transform: "scale(0.8)" }}
                />
              }
              label={
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  NF AOB Form
                </Typography>
              }
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.refrigrated}
                  onChange={(e) =>
                    handleInputChange("refrigrated", e.target.checked)
                  }
                  disabled={!isEditable}
                  name="refrigrated"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#00b8d9",
                    "&.Mui-checked": {
                      color: "#00b8d9",
                    },
                    "&.Mui-disabled": {
                      color: "#00b8d9", // Checkbox color when disabled
                    },
                  }}
                />
              }
              label="Refrigerated"
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "black", // Ensures label text is black
                  "&.Mui-disabled": {
                    color: "black", // Overrides label color when disabled
                  },
                },
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.photoid}
                  onChange={(e) =>
                    handleInputChange("photoid", e.target.checked)
                  }
                  disabled={!isEditable}
                  name="photoid"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#fe9604",
                    "&.Mui-checked": {
                      color: "#fe9604",
                    },
                    "&.Mui-disabled": {
                      color: "#fe9604",
                    },
                  }}
                />
              }
              label="Required Photo ID"
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "black",
                  "&.Mui-disabled": {
                    color: "black",
                  },
                },
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={localData?.collectcopay}
                  onChange={(e) =>
                    handleInputChange("collectcopay", e.target.checked)
                  }
                  disabled={!isEditable}
                  name="collectcopay"
                  sx={{
                    transform: "scale(0.8)",
                    color: "#05b320",
                    "&.Mui-checked": {
                      color: "#05b320",
                    },
                    "&.Mui-disabled": {
                      color: "#05b320", // Checkbox color when disabled
                    },
                  }}
                />
              }
              label={`Copay ($${localData?.totalcopay?.toFixed(2)})`} // Display total copay with fixed decimal
              sx={{
                marginRight: 0,
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "black", // Always keep the label text black
                  "&.Mui-disabled": {
                    color: "black", // Overrides label color when disabled
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeliveryInstructions;
