import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import CustomDropdown from "../CustomDropdown";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ContainedButton } from "../buttonCompo/Buttons";
import { postApiWithAuth } from "../../utils/api";
import { ADD_EXPENSE } from "../../utils/apiUrl";
import { CircularProgress } from "@mui/material";
const ExpenseForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [expenseDetail, setExpenseDetail] = useState({
    expense_type: "",
    payment_type: "",
    name: "",
    amount: "",
    expense_file: "", 
    description: "",
    allow_add: false,
  });
  const dropdownOptions = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
  ];
  const dropdownOptionsp = [
    { value: "Payment In", label: "Payment In" },
    { value: "Payment Out", label: "Payment Out" },
  ];
  const [fieldErrors, setFieldErrors] = useState({
    expense_type: "",
    payment_type: "",
    name: "",
    description:"",
    amount: "",
    expense_file: "", // Add file field error
  });

  const [notifications, setNotifications] = useState([]);

  const handleInputChange = (eventOrValue, name) => {
    const value =
      eventOrValue && eventOrValue.target
        ? eventOrValue.target.value
        : eventOrValue; // Handle both TextField and Dropdown inputs

    setExpenseDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setFieldErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleFileChange = (event) => {
    setExpenseDetail({
      ...expenseDetail,
      expense_file: event.target.files[0], // Set selected file
    });
    setFieldErrors({ ...fieldErrors, expense_file: "" });
  };
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!expenseDetail.expense_type) {
      errors.expense_type = "Expense Type is required";
      isValid = false;
    }
    if (!expenseDetail.payment_type) {
      errors.payment_type = "Payment Type is required";
      isValid = false;
    }
    if (!expenseDetail.name) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!expenseDetail.amount) {
      errors.amount = "Amount is required";
      isValid = false;
    }

    setFieldErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      return;
    }
      const formData = new FormData();
      formData.append("expense_type", expenseDetail.expense_type);
      formData.append("payment_type", expenseDetail.payment_type);
      formData.append("name", expenseDetail.name);
      formData.append("description", expenseDetail.description);
      formData.append("amount", expenseDetail.amount);
      formData.append("expense_file", expenseDetail.expense_file);
      formData.append("allow_add", expenseDetail.allow_add);
      try {
        const response = await postApiWithAuth(ADD_EXPENSE, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("responsssssssssssssssssss",response)
        if (response.data.status === 201) {
          toast.success("Expense Added successfully!");
        }
      } catch (error) {
        console.error("Error adding expense:", error);
      }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Expense Information
            {/* <span style={{ color: "#fb1001" }}>(Mandatory Field)</span> */}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Expense Type
            </Typography>
            <CustomDropdown
              customstyles={{
                height: "32px",
                width: "137px",
              }}
              value={expenseDetail.expense_type}
              name="expense_type"
              options={dropdownOptions}
              isMultiSelect={false}
              onChange={(selectedValue) =>
                handleInputChange(selectedValue, "expense_type")
              }
              error={!!fieldErrors.expense_type}
              helperText={fieldErrors.expense_type || ""}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Payment Type
            </Typography>
            <CustomDropdown
              customstyles={{
                height: "32px",
                width: "137px",
              }}
              value={expenseDetail.payment_type}
              label=""
              name="payment_type"
              onChange={(selectedValue) =>
                handleInputChange(selectedValue, "payment_type")
              }
              error={!!fieldErrors.expense_type}
              helperText={fieldErrors.expense_type || ""}
              options={dropdownOptionsp}
              isMultiSelect={false}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Name
            </Typography>
            <TextField
              type="text"
              name="name"
              autoComplete="off"
              value={expenseDetail.name}
              onChange={(event) => handleInputChange(event.target.value, "name")}
              placeholder="Name"
              error={!!fieldErrors.name}
              helperText={fieldErrors.name}
              sx={{
                width: "199px",
                "& .MuiInputBase-input": {
                  fontSize: "11px", // Decrease font size for input text
                  padding: "8px 12px", // Adjust padding
                },
                "& .MuiOutlinedInput-root": {
                  height: "34px", // Set height to 36px
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px", // Decrease font size for the label
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563", // Set placeholder color to light gray
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Description
            </Typography>
            <TextField
              type="text"
              name="description"
              autoComplete="off"
              value={expenseDetail.description}
              onChange={(event) => handleInputChange(event.target.value, "description")}
              placeholder="Description"
              error={!!fieldErrors.description}
              helperText={fieldErrors.description}
              sx={{
                width: "199px",
                "& .MuiInputBase-input": {
                  fontSize: "11px", // Decrease font size for input text
                  padding: "8px 12px", // Adjust padding
                },
                "& .MuiOutlinedInput-root": {
                  height: "34px", // Set height to 36px
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px", // Decrease font size for the label
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563", // Set placeholder color to light gray
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Amount
            </Typography>
            <TextField
              type="text"
              placeholder="Amount"
              name="amount"
              autoComplete="off"
              error={!!fieldErrors.amount}
              helperText={fieldErrors.amount}
              value={expenseDetail.amount}
              onChange={(event) => handleInputChange(event.target.value, "amount")}
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px", // Decrease font size for input text
                  padding: "8px 12px", // Adjust padding
                },
                "& .MuiOutlinedInput-root": {
                  height: "34px", // Set height to 36px
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px", // Decrease font size for the label
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563", // Set placeholder color to light gray
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Upload File
            </Typography>
            <TextField
              type="file"
              name="expense_file"
              onChange={(event) =>
                handleInputChange(event.target.files[0], "expense_file")
              }
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px", // Decrease font size for input text
                  padding: "8px 12px", // Adjust padding
                },
                "& .MuiOutlinedInput-root": {
                  height: "34px", // Set height to 36px
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px", // Decrease font size for the label
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563", // Set placeholder color to light gray
                },
              }}
            />
          </Box>
          <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                onChange={(event) => handleInputChange(event.target.value, "allow_add")}
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Auto Add
              </Typography>
            </Box>
          </Box>
        </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: 5,
          rowGap: 0.5,
          marginTop: "10px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          marginTop: 5,
        }}
      >
        <ContainedButton
          label={
            loading ? (
              <CircularProgress size={16} sx={{ color: "white" }} />
            ) : (
              "Save"
            )
          }
          borderRadius="5px"
          fontSize="13px"
          width="66px"
          height="32px"
          onClick={handleSubmit}
          disabled={loading}
        />
      </Box>
    </Box>
  );
};

export default ExpenseForm;
