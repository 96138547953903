import { Box } from "@mui/material";
import React, { useState } from "react";
import Topbar from "../components/callsCompo/Topbar";
import CallListView from "../components/callsCompo/CallListView";

const Calls = () => {
  const [activeTab, setActiveTab] = useState("missed");
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
  ]);


  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Topbar activeTab={activeTab} handleTabChange={handleTabChange} handleDateChange={handleDateChange} dateRange={dateRange} />
      <CallListView activeTab={activeTab} dateRange={dateRange} />
    </Box>
  );
};

export default Calls;
