import React from "react";
import { Box, Button } from "@mui/material";

const TabButtons = ({ activeTab, onTabChange }) => {
  const tabs = [
    { label: "Uploaded Slip", value: "uploaded" },
    { label: "Signed Slip", value: "signed" },
    { label: "Delivery Proof", value: "delivery" },
    { label: "Communication", value: "communication" },
    { label: "Previous Order", value: "previous" },
  ];
  const isAddManualOrder = window.location.pathname === "/add-manual-order";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        maxWidth: "100%",
        gap: 2,
      }}
    >
      {tabs.map((tab, index) => {
        // Skip rendering the "Uploaded Slip" button if the URL is add-manual-order
        if (isAddManualOrder && tab.value === "uploaded") {
          return null;
        }

        return (
          <Button
            key={tab.value}
            variant="contained"
            onClick={() => onTabChange(tab.value)}
            disabled={index !== 0 && index !== tabs.length - 1}
            sx={{
              backgroundColor: activeTab === tab.value ? "#000" : "transparent",
              color: activeTab === tab.value ? "#fff" : "#000",
              boxShadow: "none",
              border: "1px solid #919eab",
              height: "38px",
              borderRadius: "8px",
              paddingLeft: "5px",
              paddingRight: "5px",
              width: "125px",
              fontSize: "12px",
              fontWeight: "600",
              textTransform: "none",
              "&:hover": {
                backgroundColor: activeTab === tab.value ? "#000" : "transparent",
              },
            }}
          >
            {tab.label}
          </Button>
        );
      })}
    </Box>
  );
};

export default TabButtons;
