import React, { useState, useEffect, useRef } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import {
  getApiWithAuth,
  postApiWithAuth,
  patchApiWithAuth,
} from "../utils/api";
import {
  SEND_TWILIO_SMS,
  ALL_PHARMACY_LIST,
  TWILIO_SMS_LIST,
  ORDER_CHAT_LIST,
  TWILIO_UNREAD_SMS_COUNT,
  USER_CHAT_ROOMS,
  GET_MESSAGES,
} from "../utils/apiUrl";
import ChatHeader from "../components/ChatPageCompo/ChatHeader";
import ChatComponent from "../components/ChatPageCompo/ChatComponent";
import ChatCard from "../components/ChatPageCompo/ChatCard";
import ChatCard2 from "../components/ChatPageCompo/ChatCard2";
import ChatCard3 from "../components/ChatPageCompo/ChatCard3";
import SMSCard from "../components/ChatPageCompo/SMSCard";
import SMSComponent from "../components/ChatPageCompo/SMSComponent";

const SMSList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [ticketOrders, setTicketOrders] = useState([]);
  const [smsId, setSMSId] = useState("");
  const [smsDetails, setSMSDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const [pharmacy, setPharmacy] = useState("");
  const [timer, setTimer] = useState("");
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [text, setText] = useState("");
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
    new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
  ]);

  const userId = parseInt(localStorage.getItem("UID"), 10);

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (smsDetails) {
      scrollToBottom();
    }
  }, [smsDetails]);

  const fetchPharmacies = async () => {
    try {
      const response = await getApiWithAuth(ALL_PHARMACY_LIST, {
        justList: true,
      });
      if (response.data !== undefined) {
        setUniquePharmacy(
          response.data.data.map((item) => ({ id: item.id, name: item.name }))
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTicketClick = async (smsId) => {
    setSMSId(smsId);
    setSelectedTicketId(smsId);
    fetchChatRooms(smsId);
    await fetchMessages(smsId, userId);
  };

  const fetchMessages = async (roomId, userId) => {
    try {
      const response = await getApiWithAuth(
        GET_MESSAGES + `?status=all&roomId=${roomId}&userId=${userId}`
      );

      console.log("Messages API Response:", response.data);
      setSMSDetails(response.data.data);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      setSMSDetails([]);
    }
  };

  const fetchChatRooms = async ({ needId = null } = {}) => {
    setLoading(true);
    try {
      const response = await getApiWithAuth(USER_CHAT_ROOMS);
      const ticketData = response.data.data;
      setTicketOrders(ticketData);
      
      if (smsId === "" && ticketData.length > 0) {
        const firstRommId = response.data.data[0].roomId
        await fetchMessages(firstRommId, userId);
        setActiveCard(firstRommId);
        setSMSId(firstRommId);
        setSelectedTicketId(firstRommId);
      } else if (smsId !== "") {
        const currentGroup = ticketData.find(
          (group) => group.roomId === smsId
        );
        if (currentGroup && needId === smsId) {
          await fetchMessages(needId, userId);
        }
      }
    } catch (error) {
      console.error("Failed to fetch chat rooms:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    // Initialize WebSocket connection
    const unreadSMSClient = new W3CWebSocket(
      "wss://testadmin.pilldrop.ai/ws/unread_sms/"
    );

    // Handle connection open
    unreadSMSClient.onopen = () => {
      console.log("WebSocket connection established for unread SMS counts");
    };

    // Handle incoming messages
    unreadSMSClient.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data.type === "unread_sms_counts") {
          const counts = data.data;
          const { total_unread_count, unread_counts } = counts;

          // Update the state with new counts
          // setTotalUnreadCount(total_unread_count);
          // setUnreadCountsByOrder(unread_counts);

          setUnreadCounts(unread_counts);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    // Handle connection close
    unreadSMSClient.onclose = () => {
      console.log("WebSocket connection closed for unread SMS counts");
      // Optionally, implement reconnection logic here
    };

    // Handle errors
    unreadSMSClient.onerror = (error) => {
      console.error("WebSocket error for unread SMS counts:", error);
    };

    // Cleanup function to close WebSocket connection when component unmounts
    return () => {
      unreadSMSClient.close();
    };
  }, []);

  useEffect(() => {
    fetchChatRooms();
  }, [dateRange]);

  useEffect(() => {
    fetchPharmacies();
  }, []);

  const handleSendTwilioSms = async () => {
    if (smsId !== "") {
      setLoading(true);
      const response = await postApiWithAuth(SEND_TWILIO_SMS, {
        to_number: smsDetails[smsDetails.length - 1]?.clientNumber,
        text: text,
        order_id: smsDetails[smsDetails.length - 1]?.order?.id,
      });
      console.log(response.data);
      if (response.data.error === undefined) {
        toast.success(response.data.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setText("");
    } else {
      console.log("Add number to send sms");
    }

    setText("");
    fetchChatRooms(smsId);
    setLoading(false);
  };

  const handleCardClick = (cardId) => {
    setActiveCard(cardId);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {/* <Topbar /> */}
      <ChatHeader
        pharmacy={pharmacy}
        pharmacyList={uniquePharmacy}
        setPharmacy={setPharmacy}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setSearch={setSearch}
      />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxHeight: "80vh",
            overflowY: "auto",

            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "10px",
              border: "2px solid transparent",
              backgroundClip: "content-box",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-track:hover": {
              backgroundColor: "#e1e1e1",
            },
          }}
        >
          {ticketOrders &&
            ticketOrders.map((chat, i) => (
              <Box
                display="flex"
                minWidth="500px"
                backgroundColor="#EBEBEC"
                borderRadius="12px"
                flexDirection="column"
                onClick={() => {
                  handleTicketClick(chat.roomId);
                }}
                key={i}
              >
                <SMSCard
                  key={i}
                  chat={chat}
                  unreadCounts={chat.unread_messages_count || 0}
                  onClick={() => handleCardClick(chat.roomId)} // Pass the card's ID to handle click
                  isActive={activeCard === chat.roomId} // Check if this card is active
                />
              </Box>
            ))}
        </Box>
        <Box
          sx={{ flex: 3, paddingX: 2, maxHeight: "80vh", overflowY: "auto" }}
        >
          {(smsDetails !== undefined && smsDetails?.length > 0 )&& (
            <SMSComponent
              chatDetail={smsDetails}
              handleSendTwilioSms={handleSendTwilioSms}
              text={text}
              setText={setText}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SMSList;
