import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Input,
  Modal,
} from "@mui/material";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ChooseImageIcon, UploadIcon } from "../../Icons";
import CustomDropdown from "../../CustomDropdown";
import { PhoneNumberField } from "../../Ui/PhoneNumberField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Topbar from "./Topbar";
import { ContainedButton } from "../../buttonCompo/Buttons";
import axios from "axios";
import {
  Bank_API_2,
  Doc_API_2,
  DRIVERS_API_2,
  driver_update_api,
  VEHICLE_API,
  DRIVERS_API,
  vehicle_API_2,
  driver_vehicle_up_api,
  driver_bank_up_api,
} from "../../../utils/apiUrl";
import {
  postApiWithAuth,
  getApiWithAuth,
  postFormDataAPI,
  patchApiWithAuth,
  deleteApiWithAuth,
} from "../../../utils/api";
import { CircularProgress } from "@mui/material";
/* Muhammad Usman */
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const DriverInformationForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dId = queryParams.get("id");
  const { driverId } = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [vehicleTypes, setVehicleTypes] = useState([
    { value: "sedan", label: "Sedan" },
    { value: "suv", label: "SUV" },
    { value: "truck", label: "Truck" },
    { value: "van", label: "Van" },
    { value: "motorbike", label: "Motorbike" },
  ]);
  const [front, setFront] = useState("");
  const [back, setBack] = useState("");
  const [payType, setPayType] = useState("paybytimewindow");
  const [idImage, setIdImage] = useState(
    "https://cdn2.vectorstock.com/i/1000x1000/37/61/id-card-icon-male-user-person-profile-avatar-vector-23703761.jpg"
  );
  const [idImage3, setIdImage3] = useState(
    "https://cdn2.vectorstock.com/i/1000x1000/37/61/id-card-icon-male-user-person-profile-avatar-vector-23703761.jpg"
  );
  const [vehicleDetail, setVehicleDetail] = useState({
    make: "",
    model: "",
    color: "",
    modelYear: "",
    plateNo: "",
    price: 0.0,
    documentfile: null,
    vehicleType: null,
  });
  const [driverData, setDriverData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    sms_allowed: false,
    calling_allowed: false,
    email: "",
    phoneNumber: "",
    date: "",
    socialSecurityNo: "",
    licenseId: "",
    licenseIssued: "",
    licenseExpired: "",
    isactive: true,
    shift1Price: 0,
    shift2Price: 0,
    shift3Price: 0,
    failedDeliveryPrice: 0,
    urgentDeliveryPrice: 0,
    addtionalNote: "",
    name: "",
    profilefile: null,
    driver: null,
    password: "",
    confirmPassword: "",
    salaryType: null,
    id1card: "",
    id2card: "",
    allow_reordering: false,
    pay_type: payType,
  });
  const [bankDetails, setBankDetails] = useState({
    id: 0,
    bankname: "",
    accountholdername: "",
    accountno: "",
    achRouting: "",
  });
  const [errors, setErrors] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const fetchVehicleTypes = async () => {
    try {
      const response = await getApiWithAuth(VEHICLE_API);
      console.log("This is vehicle api response", response.data);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setVehicleTypes(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      } else {
        // If API response is empty, retain the initial dummy data
        console.warn("API returned no vehicle types, retaining default data.");
      }
    } catch (error) {
      console.error("Error fetching vehicle types:", error);
    }
  };

  const validateForm = () => {
    let tempErrors = {};

    // Check that strings are non-empty and are strings before calling .trim()
    if (
      !driverData.firstName ||
      typeof driverData.firstName !== "string" ||
      !driverData.firstName.trim()
    ) {
      tempErrors.firstName = "First Name is required";
    }

    if (
      !driverData.lastName ||
      typeof driverData.lastName !== "string" ||
      !driverData.lastName.trim()
    ) {
      tempErrors.lastName = "Last Name is required";
    }

    if (
      !driverData.address ||
      typeof driverData.address !== "string" ||
      !driverData.address.trim()
    ) {
      tempErrors.address = "Address is required";
    }

    if (
      !driverData.email ||
      typeof driverData.email !== "string" ||
      !driverData.email.trim()
    ) {
      tempErrors.email = "Email is required";
    }

    if (
      !driverData.phoneNumber ||
      typeof driverData.phoneNumber !== "string" ||
      !driverData.phoneNumber.trim()
    ) {
      tempErrors.phoneNumber = "Phone Number is required";
    }

    if (
      !driverData.licenseId ||
      typeof driverData.licenseId !== "string" ||
      !driverData.licenseId.trim()
    ) {
      tempErrors.licenseId = "License ID is required";
    }

    // Validate bank details - ensuring they're strings and not empty
    // if (
    //   !bankDetails.bankname ||
    //   typeof bankDetails.bankname !== "string" ||
    //   !bankDetails.bankname.trim()
    // ) {
    //   tempErrors.bankname = "Bank Name is required";
    // }

    // if (
    //   !bankDetails.accountholdername ||
    //   typeof bankDetails.accountholdername !== "string" ||
    //   !bankDetails.accountholdername.trim()
    // ) {
    //   tempErrors.accountholdername = "Account Holder Name is required";
    // }

    // if (
    //   !bankDetails.accountno ||
    //   typeof bankDetails.accountno !== "string" ||
    //   !bankDetails.accountno.trim()
    // ) {
    //   tempErrors.accountno = "Account Number is required";
    // }

    // if (
    //   !bankDetails.achRouting ||
    //   typeof bankDetails.achRouting !== "string" ||
    //   !bankDetails.achRouting.trim()
    // ) {
    //   tempErrors.achRouting = "ACH Routing is required";
    // }

    // Validate vehicle details - ensuring they're strings and not empty
    // if (
    //   !vehicleDetail.make ||
    //   typeof vehicleDetail.make !== "string" ||
    //   !vehicleDetail.make.trim()
    // ) {
    //   tempErrors.make = "Make is required";
    // }

    // if (
    //   !vehicleDetail.model ||
    //   typeof vehicleDetail.model !== "string" ||
    //   !vehicleDetail.model.trim()
    // ) {
    //   tempErrors.model = "Model is required";
    // }

    // if (
    //   !vehicleDetail.color ||
    //   typeof vehicleDetail.color !== "string" ||
    //   !vehicleDetail.color.trim()
    // ) {
    //   tempErrors.color = "Color is required";
    // }

    // if (
    //   !vehicleDetail.modelYear ||
    //   typeof vehicleDetail.modelYear !== "string" ||
    //   !vehicleDetail.modelYear.trim()
    // ) {
    //   tempErrors.modelYear = "Model Year is required";
    // }

    // if (
    //   !vehicleDetail.plateNo ||
    //   typeof vehicleDetail.plateNo !== "string" ||
    //   !vehicleDetail.plateNo.trim()
    // ) {
    //   tempErrors.plateNo = "Plate Number is required";
    // }

    // if (
    //   !vehicleDetail.vehicleType ||
    //   typeof vehicleDetail.vehicleType !== "string" ||
    //   !vehicleDetail.vehicleType.trim()
    // ) {
    //   tempErrors.vehicleType = "Vehicle Type is required";
    // }

    // Set the errors to state
    setErrors(tempErrors);

    // Return true if no errors, false otherwise
    return Object.keys(tempErrors).length === 0;
  };

  const fetchDriverData = async () => {
    console.log("Driver ID:", dId);
    if (dId) {
      setLoading(true);
      try {
        const response = await getApiWithAuth(`${DRIVERS_API}${dId}/`);
        console.log("driver Data", response.data.data);
        setFronts(response.data.data.profilefile);
        setFront(response.data.data.id1card);
        setBack(response.data.data.id2card);

        if (response.data && response.data.data) {
          const driverData = response.data.data;
          setPayType(driverData.pay_type);
          setDriverData((prev) => ({
            ...prev,
            phoneNumber: driverData.phoneNumber,
            cellNumber: driverData.cellNumber,
            socialSecurityNo: driverData.socialSecurityNo,
            decument: driverData.decument,
            additionInfo: driverData.additionInfo,
            licenseId: driverData.licenseId,
            licenseIssued: driverData.licenseIssued,
            licenseExpired: driverData.licenseExpired,
            sms_allowed: driverData.driver.sms_allowed,
            calling_allowed: driverData.driver.calling_allowed,
            allow_reordering: driverData.allow_reordering,
            pay_type: driverData.pay_type || "",
            shift1Price: parseFloat(driverData.shift1Price).toFixed(2),
            shift2Price: parseFloat(driverData.shift2Price).toFixed(2),
            shift3Price: parseFloat(driverData.shift3Price).toFixed(2),
            email: driverData.email,
            failedDeliveryPrice: parseFloat(
              driverData.failedDeliveryPrice
            ).toFixed(2),
            urgentDeliveryPrice: parseFloat(
              driverData.urgentDeliveryPrice
            ).toFixed(2),
            firstName: driverData.firstName,
            lastName: driverData.lastName,
            profilefile: driverData?.profilefile,
            address: driverData.address,
            salaryType: driverData.salaryType,
            date: driverData.date,
            addtionalNote: driverData.addtionalNote,
            id1card: driverData?.id1card,
            id2card: driverData?.id2card,
            isactive: driverData?.isactive || false,
          }));
          setVehicleDetail((prevState) => ({
            ...prevState,
            id: driverData?.vehicle_driver[0]?.id,
            make: driverData?.vehicle_driver[0]?.make,
            model: driverData?.vehicle_driver[0]?.model,
            color: driverData?.vehicle_driver[0]?.color,
            modelYear: driverData?.vehicle_driver[0]?.modelYear,
            plateNo: driverData?.vehicle_driver[0]?.plateNo,
            price: driverData?.vehicle_driver[0]?.price,
            documentfile: response.data.data?.vehicle_driver[0]?.documentfile,
            vehicleType: driverData?.vehicle_driver[0]?.vehicleType,
          }));
          setBankDetails((prevState) => ({
            ...prevState,
            id: driverData.bank_detail_driver[0]?.id,
            bankname: driverData.bank_detail_driver[0]?.bankname,
            accountholdername:
              driverData.bank_detail_driver[0]?.accountholdername,
            accountno: driverData.bank_detail_driver[0]?.accountno,
            achRouting: driverData.bank_detail_driver[0]?.achRouting,
          }));
        } else {
          console.error("Driver data not found");
        }
      } catch (error) {
        console.error("Error fetching driver data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDriverData();
    fetchVehicleTypes();
  }, [dId]);

  const handleInputChange = (setter, key, value) => {
    console.log("Updating:", key, "with value:", value);
    setter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Driver ID:", dId);

    // Check validation
    if (!validateForm()) {
      toast.error("Please fill all the required fields.");
      console.log(errors);
      return;
    }

    try {
      setLoading(true);

      // Prepare the driver personal details payload
      const updatedPersonalDetail = {
        ...driverData,
        name: `${driverData.firstName || ""} ${driverData.lastName || ""}`,
        isactive: driverData.isactive ? "true" : "false",
        sms_allowed: driverData.sms_allowed ? "true" : "false",
        calling_allowed: driverData.calling_allowed ? "true" : "false",
        allow_reordering: driverData.allow_reordering ? "true" : "false",
        cellNumber: driverData.phoneNumber,
      };

      const payload = new FormData();

      // Append basic fields
      Object.entries(updatedPersonalDetail).forEach(([key, value]) => {
        if (
          key !== "profilefile" &&
          key !== "id1card" &&
          key !== "id2card" &&
          value !== undefined &&
          value !== null
        ) {
          payload.append(key, value);
        }
      });

      if (updatedPersonalDetail.profilefile instanceof File) {
        payload.append("profilefile", updatedPersonalDetail.profilefile);
      }

      if (updatedPersonalDetail.id1card instanceof File) {
        payload.append("id1card", updatedPersonalDetail.id1card);
      }
      if (updatedPersonalDetail.id2card instanceof File) {
        payload.append("id2card", updatedPersonalDetail.id2card);
      }
      let responsePersonal;

      // If driverId exists, update the driver details (PATCH request)
      if (dId) {
        console.log("updating1");
        responsePersonal = await patchApiWithAuth(
          `${driver_update_api}/${dId}`,
          payload // Include FormData payload
        );
      } else {
        console.log("creating1");
        responsePersonal = await postFormDataAPI(DRIVERS_API_2, payload);
        console.log("driver :", responsePersonal);
      }

      const savedDriverId = responsePersonal.id;
      const savedDriverId2 = dId;

      // Handle vehicle details submission
      if (vehicleDetail.make !== "" && savedDriverId2) {
        console.log("updating2");
        const vehiclePayload = new FormData();
        vehiclePayload.append("make", vehicleDetail.make);
        vehiclePayload.append("model", vehicleDetail.model);
        vehiclePayload.append("color", vehicleDetail.color);
        vehiclePayload.append("modelYear", vehicleDetail.modelYear);
        vehiclePayload.append("plateNo", vehicleDetail.plateNo);
        vehiclePayload.append("driver", savedDriverId2); // Link to the driver

        const vehicleResponse = await patchApiWithAuth(
          `${driver_vehicle_up_api}/${vehicleDetail.id}`,
          vehiclePayload
        );
        console.log("vehicleResponse :", vehicleResponse);
      } else {
        console.log("creating2");
        if (vehicleDetail.make !== "") {
          const vehiclePayload = new FormData();
          vehiclePayload.append("make", vehicleDetail.make);
          vehiclePayload.append("model", vehicleDetail.model);
          vehiclePayload.append("color", vehicleDetail.color);
          vehiclePayload.append("modelYear", vehicleDetail.modelYear);
          vehiclePayload.append("plateNo", vehicleDetail.plateNo);
          vehiclePayload.append("driver", savedDriverId); // Link to the driver

          const vehicleResponse = await postApiWithAuth(
            vehicle_API_2,
            vehiclePayload
          );
        }
      }

      console.log("savedDriverId :", savedDriverId, bankDetails.id);
      // Handle bank details submission
      if (bankDetails.id) {
        console.log("updating3");
        const bankPayload = new FormData();
        bankPayload.append("bankname", bankDetails.bankname);
        bankPayload.append("accountholdername", bankDetails.accountholdername);
        bankPayload.append("accountno", bankDetails.accountno);
        bankPayload.append("achRouting", bankDetails.achRouting);
        bankPayload.append("driver", savedDriverId); // Link to the driver

        const bankResponse = await patchApiWithAuth(
          `${driver_bank_up_api}/${bankDetails.id}`,
          bankPayload
        );

        if (!bankResponse || bankResponse.data.status !== 200) {
          console.error(
            "Failed to update bank details. Response:",
            bankResponse
          );
          throw new Error(
            bankResponse?.data?.message || "Failed to update bank details."
          );
        }
      } else {
        console.log("creating3", bankDetails.bankname);
        if (bankDetails.bankname && bankDetails.bankname !== "") {
          const bankPayload = new FormData();
          bankPayload.append("bankname", bankDetails.bankname);
          bankPayload.append(
            "accountholdername",
            bankDetails.accountholdername
          );
          bankPayload.append("accountno", bankDetails.accountno);
          bankPayload.append("achRouting", bankDetails.achRouting);
          bankPayload.append("driver", savedDriverId); // Link to the driver

          const bankResponse = await postApiWithAuth(Bank_API_2, bankPayload);

          if (!bankResponse || bankResponse.data.status !== 200) {
            console.error(
              "Failed to create bank details. Response:",
              bankResponse
            );
            throw new Error(
              bankResponse?.data?.message || "Failed to create bank details."
            );
          }
        }
      }

      // Handle document upload if necessary
      const documentPayload = new FormData();
      documentPayload.append("description", "descriptions");
      documentPayload.append("driver", dId ? savedDriverId2 : savedDriverId);

      const documentResponse = await postApiWithAuth(
        Doc_API_2,
        documentPayload
      );

      if (!documentResponse || documentResponse.data.status !== 200) {
        console.error(
          "Failed to save document details. Response:",
          documentResponse
        );
      } else {
        console.log("Document Saved Successfully");
      }

      // Handle Success
      setLoading(false);
      toast.success("Form submitted successfully!");
      navigate("/adddrivers");
    } catch (error) {
      console.error("Error occurred during submission:", error);
      setLoading(false);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const dropdownOptions = [
    { value: "staff1", label: "Staff Member 1" },
    { value: "staff2", label: "Staff Member 2" },
    { value: "staff3", label: "Staff Member 3" },
  ];
  const payratesOptions = [
    { value: "paybytimewindow", label: "Pay By Time Window" },
    { value: "payperstop", label: "Pay Per Stop" },
  ];

  const handlePayTypeChange = (value) => {
    console.log("Selected payType:", value);
    setPayType(value);
    handleInputChange(setDriverData, "pay_type", value);
  };

  const handleChange = (setter) => (event) => {
    const value = event.target ? event.target.value : event;
    setter(value);
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  /* Muhammad Usman Start */
  const [fronts, setFronts] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const cropperRef = useRef(null);

  const handleImageUpload3 = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    console.log("File selected:", file);
    setFileObject(file);

    setDriverData({
      ...driverData,
      profilefile: file,
    });

    const reader = new FileReader();
    reader.onloadend = () => {
      setFronts(reader.result);
      setOpenModal(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        croppedCanvas.toBlob((blob) => {
          const croppedFile = new File([blob], fileObject.name, {
            type: "image/png",
          });

          setDriverData({
            ...driverData,
            profilefile: croppedFile,
          });

          const reader = new FileReader();
          reader.onloadend = () => {
            setFronts(reader.result);
          };
          reader.readAsDataURL(blob);
        }, "image/png");

        setOpenModal(false);
      }
    }
  };

  // Muhammad Usman End

  const setFrontImage = (imageData) => {
    setDriverData({
      ...driverData,
      id1card: imageData,
    });
  };
  const handleImageUploaddd = (event) => {
    const fileObject = event.target.files[0];
    setFrontImage(fileObject);

    const { name, size, type, lastModified } = fileObject;

    const fileBlob = new Blob([fileObject], { type });
    const file = new File([fileBlob], name, { lastModified });
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the image URL in the state
        setFront(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const setBackImage = (imageData) => {
    setDriverData({
      ...driverData,
      id2card: imageData,
    });
  };
  const handleImageUploaddd2 = (event) => {
    // const file = event.target.files[0];
    // setBackImage(file);
    const fileObject = event.target.files[0];
    setBackImage(fileObject);
    // Extract the file data from the object
    const { name, size, type, lastModified } = fileObject;

    // Create a Blob from the file data
    const fileBlob = new Blob([fileObject], { type });

    // Create a File instance
    const file = new File([fileBlob], name, { lastModified });
    // setBackImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the image URL in the state
        setBack(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = async () => {
    if (!dId) {
      console.error("No Driver ID found for deletion.");
      toast.error("No Driver selected for deletion.");
      return;
    }

    try {
      const response = await deleteApiWithAuth(`${DRIVERS_API}${dId}/`);

      if (response.data?.status === 200 || response.data?.status === 204) {
        toast.success("Driver deleted successfully!");
        navigate("/addDrivers");
      } else {
        console.error("Failed to delete driver:", response);
        toast.error("An error occurred while deleting the driver.");
      }
    } catch (error) {
      console.error("Error during driver deletion:", error);
      toast.error("An unexpected error occurred during deletion.");
    } finally {
      handleCloseDeleteModal();
    }
  };
  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Muhammad Usman Start */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={handleImageUpload3}
                    sx={{ display: "none" }}
                  />
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      borderColor: "#05b320",
                      color: "#05b320",
                      width: "120px",
                      textTransform: "none",
                      fontSize: "13px",
                      height: "35px",
                    }}
                  >
                    Choose File
                  </Button>
                </label>

                <Typography variant="body2" sx={{ color: "#757575" }}>
                  {fileObject ? fileObject.name : "No File Chosen"}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "80px",
                  height: "80px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f4f4f4",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                }}
              >
                {fronts ? (
                  <img
                    src={fronts}
                    alt="Cropped Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "48px", color: "#757575" }}
                  >
                    📷
                  </Typography>
                )}
              </Box>

              <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Crop & Adjust Image
                  </Typography>

                  <Cropper
                    src={fronts}
                    style={{ height: 250, width: "100%" }}
                    initialAspectRatio={1}
                    aspectRatio={1}
                    guides={true}
                    ref={cropperRef}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpenModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCrop}
                    >
                      Save Cropped
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
            {/* Muhammad Usman End */}

            <Box sx={{ display: "flex", gap: 1 }}>
              <ContainedButton
                label="Activate"
                border="none"
                bgColor={driverData.isactive ? "#05b320" : "transparent"}
                borderRadius="4px"
                textColor={driverData.isactive ? "#fff" : "#05b320"}
                onClick={() =>
                  handleInputChange(setDriverData, "isactive", true)
                }
              />
              <ContainedButton
                label="Deactivate"
                border="1px solid #fc1002"
                bgColor={!driverData.isactive ? "#fc1002" : "transparent"}
                borderRadius="4px"
                textColor={!driverData.isactive ? "#fff" : "#fc1002"}
                onClick={() =>
                  handleInputChange(setDriverData, "isactive", false)
                }
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
            >
              Driver Information
              <span style={{ color: "#fb1001" }}>(Mandatory Field)</span>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: 5,
              rowGap: 0.7,
            }}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                First Name
              </Typography>
              <TextField
                value={driverData.firstName}
                onChange={(e) =>
                  handleInputChange(setDriverData, "firstName", e.target.value)
                }
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
                type="text"
                autoComplete="new-password"
                placeholder="First Name"
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Last Name
              </Typography>
              <TextField
                value={driverData.lastName}
                onChange={(e) =>
                  handleInputChange(setDriverData, "lastName", e.target.value)
                }
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
                type="text"
                autoComplete="new-password"
                placeholder="Last Name"
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                value={driverData.address}
                onChange={(e) =>
                  handleInputChange(setDriverData, "address", e.target.value)
                }
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Address
              </Typography>
              <TextField
                value={driverData.address}
                onChange={(e) =>
                  handleInputChange(setDriverData, "address", e.target.value)
                }
                type="text"
                autoComplete="new-password"
                placeholder="Address"
                error={Boolean(errors.address)}
                helperText={errors.address}
                sx={{
                  width: "396px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Email
              </Typography>
              <TextField
                value={driverData.email}
                onChange={(e) =>
                  handleInputChange(setDriverData, "email", e.target.value)
                }
                error={Boolean(errors.email)}
                helperText={errors.email}
                type="email"
                autoComplete="new-password"
                placeholder="email"
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield", // Hides the arrows for Firefox
                  },
                }}
              />
            </Box>
            <PhoneNumberField
              value={driverData.phoneNumber}
              onChange={(value) =>
                handleInputChange(setDriverData, "phoneNumber", value)
              }
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber}
              label="Mobile Number"
              placeholder="(675) 454-2724"
              height="32px"
              width="237px"
            />
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Date Of Birth
              </Typography>
              <TextField
                value={driverData.date}
                onChange={(e) =>
                  handleInputChange(setDriverData, "date", e.target.value)
                }
                error={Boolean(errors.date)}
                helperText={errors.date}
                type="date"
                autoComplete="new-password"
                placeholder="mm/dd/yyyy"
                sx={{
                  width: "150px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>

            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Social Security Number
              </Typography>
              <TextField
                value={driverData.socialSecurityNo}
                onChange={(e) =>
                  handleInputChange(
                    setDriverData,
                    "socialSecurityNo",
                    e.target.value
                  )
                }
                error={Boolean(errors.socialSecurityNo)}
                helperText={errors.socialSecurityNo}
                type="number"
                autoComplete="new-password"
                placeholder="Number"
                sx={{
                  width: "150px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  }, // Hides the arrows in number input fields
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield", // Hides the arrows for Firefox
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Driver License ID
              </Typography>
              <TextField
                value={driverData.licenseId}
                onChange={(e) =>
                  handleInputChange(setDriverData, "licenseId", e.target.value)
                }
                error={Boolean(errors.licenseId)}
                helperText={errors.licenseId}
                type="text"
                autoComplete="new-password"
                placeholder="Driver License ID"
                sx={{
                  width: "150px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                License Issued
              </Typography>
              <TextField
                value={driverData.licenseIssued}
                onChange={(e) =>
                  handleInputChange(
                    setDriverData,
                    "licenseIssued",
                    e.target.value
                  )
                }
                error={Boolean(errors.licenseIssued)}
                helperText={errors.licenseIssued}
                type="date"
                autoComplete="new-password"
                placeholder="mm/dd/yyyy"
                sx={{
                  width: "150px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  }, // Hides the arrows in number input fields
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield", // Hides the arrows for Firefox
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                License Expires
              </Typography>
              <TextField
                value={driverData.licenseExpired}
                onChange={(e) =>
                  handleInputChange(
                    setDriverData,
                    "licenseExpired",
                    e.target.value
                  )
                }
                error={Boolean(errors.licenseExpired)}
                helperText={errors.licenseExpired}
                type="date"
                autoComplete="new-password"
                placeholder="mm/dd/yyyy"
                sx={{
                  width: "150px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  }, // Hides the arrows in number input fields
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield", // Hides the arrows for Firefox
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2, paddingTop: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                License Front
              </Typography>
              <Box
                sx={{
                  width: "237.47px",
                  height: "120px",
                  border: "1px dashed #d0d0d0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: 1,
                  paddingTop: 1.5,
                  position: "relative",
                }}
              >
                {front ? (
                  <img
                    src={front}
                    alt="License Front"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <>
                    <Typography variant="body2" sx={{ fontSize: "11px" }}>
                      Drop here to attach or upload
                    </Typography>
                    <Typography variant="caption" sx={{ fontSize: "10px" }}>
                      Max size: 1MB
                    </Typography>
                  </>
                )}
                <IconButton
                  color="primary"
                  component="label"
                  sx={{ position: "absolute", bottom: "5px" }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleImageUploaddd}
                  />
                  <UploadIcon />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                License Back
              </Typography>
              <Box
                sx={{
                  width: "237.47px",
                  height: "120px",
                  border: "1px dashed #d0d0d0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: 1,
                  paddingTop: 1.5,
                  position: "relative",
                }}
              >
                {back ? (
                  <img
                    src={back}
                    alt="License Back"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <>
                    <Typography variant="body2" sx={{ fontSize: "11px" }}>
                      Drop here to attach or upload
                    </Typography>
                    <Typography variant="caption" sx={{ fontSize: "10px" }}>
                      Max size: 1MB
                    </Typography>
                  </>
                )}
                <IconButton
                  color="primary"
                  component="label"
                  sx={{ position: "absolute", bottom: "5px" }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleImageUploaddd2}
                  />
                  <UploadIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
            >
              Account Credentials
              <span style={{ color: "#fb1001" }}>(Mandatory Field)</span>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: 5,
              rowGap: 0.7,
            }}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                User Name
              </Typography>
              <TextField
                value={driverData.username}
                onChange={(e) =>
                  handleInputChange(setDriverData, "username", e.target.value)
                }
                type="text"
                autoComplete="new-password"
                placeholder="User Name"
                error={!!errors.username}
                helperText={errors.username}
                sx={{
                  width: "199px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Email
              </Typography>
              <TextField
                value={driverData.email}
                onChange={(e) =>
                  handleInputChange(setDriverData, "email", e.target.value)
                }
                type="email"
                autoComplete="new-password"
                placeholder="email"
                error={!!errors.email}
                helperText={errors.email}
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Password
              </Typography>
              <TextField
                value={driverData.password}
                onChange={(e) =>
                  handleInputChange(setDriverData, "password", e.target.value)
                }
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                autoComplete="new-password"
                error={!!errors.password}
                helperText={errors.password}
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                        sx={{
                          padding: "4px", // Reduce padding inside the button
                          width: "24px", // Decrease button width
                          height: "24px", // Decrease button height
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOff
                            sx={{
                              fontSize: "16px",
                              width: "16px",
                              color: "#000",
                            }}
                          />
                        ) : (
                          <Visibility
                            sx={{
                              fontSize: "16px",
                              width: "16px",
                              color: "#000",
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Confirm Password
              </Typography>
              <TextField
                value={driverData.confirmPassword}
                onChange={(e) =>
                  handleInputChange(
                    setDriverData,
                    "confirmPassword",
                    e.target.value
                  )
                }
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Password"
                autoComplete="new-password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                        sx={{
                          padding: "4px", // Reduce padding inside the button
                          width: "24px", // Decrease button width
                          height: "24px", // Decrease button height
                        }}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff
                            sx={{
                              fontSize: "16px",
                              width: "16px",
                              color: "#000",
                            }}
                          />
                        ) : (
                          <Visibility
                            sx={{
                              fontSize: "16px",
                              width: "16px",
                              color: "#000",
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
            >
              Banking Details
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: 5,
              rowGap: 0.7,
            }}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Bank Name
              </Typography>
              <TextField
                value={bankDetails.bankname}
                onChange={(e) =>
                  handleInputChange(setBankDetails, "bankname", e.target.value)
                }
                error={Boolean(errors.bankname)}
                helperText={errors.bankname}
                type="text"
                autoComplete="new-password"
                placeholder="Bank Name"
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Title on Account
              </Typography>
              <TextField
                value={bankDetails.accountholdername}
                onChange={(e) =>
                  handleInputChange(
                    setBankDetails,
                    "accountholdername",
                    e.target.value
                  )
                }
                error={Boolean(errors.accountholdername)}
                helperText={errors.accountholdername}
                type="text"
                autoComplete="new-password"
                placeholder="Title on Account"
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Account Number
              </Typography>
              <TextField
                value={bankDetails.accountno}
                onChange={(e) =>
                  handleInputChange(setBankDetails, "accountno", e.target.value)
                }
                error={Boolean(errors.accountno)}
                helperText={errors.accountno}
                type="number"
                autoComplete="new-password"
                placeholder="Account Number"
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  }, // Hides the arrows in number input fields
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield", // Hides the arrows for Firefox
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                ACH routing
              </Typography>
              <TextField
                value={bankDetails.achRouting}
                onChange={(e) =>
                  handleInputChange(
                    setBankDetails,
                    "achRouting",
                    e.target.value
                  )
                }
                error={Boolean(errors.achRouting)}
                helperText={errors.achRouting}
                type="number"
                autoComplete="new-password"
                placeholder="ACH Routing"
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  }, // Hides the arrows in number input fields
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield", // Hides the arrows for Firefox
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
            >
              Vehicle Information
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: 5,
              rowGap: 0.7,
            }}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Type
              </Typography>
              <CustomDropdown
                customstyles={{
                  height: "32px",
                  width: "137px",
                }}
                label=""
                options={vehicleTypes}
                value={vehicleDetail.vehicleType}
                onChange={(value) =>
                  handleInputChange(setVehicleDetail, "vehicleType", value)
                }
                error={Boolean(errors.vehicleType)}
                helperText={errors.vehicleType}
                isMultiSelect={false}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Make
              </Typography>
              <TextField
                value={vehicleDetail.make}
                onChange={(e) =>
                  handleInputChange(setVehicleDetail, "make", e.target.value)
                }
                error={Boolean(errors.make)}
                helperText={errors.make}
                type="text"
                autoComplete="new-password"
                placeholder="Brand"
                sx={{
                  width: "136px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Model
              </Typography>
              <TextField
                value={vehicleDetail.model}
                onChange={(e) =>
                  handleInputChange(setVehicleDetail, "model", e.target.value)
                }
                error={Boolean(errors.model)}
                helperText={errors.model}
                type="number"
                autoComplete="new-password"
                placeholder="Model"
                sx={{
                  width: "136px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  }, // Hides the arrows in number input fields
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield", // Hides the arrows for Firefox
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Color
              </Typography>
              <TextField
                value={vehicleDetail.color}
                onChange={(e) =>
                  handleInputChange(setVehicleDetail, "color", e.target.value)
                }
                error={Boolean(errors.color)}
                helperText={errors.color}
                type="text"
                autoComplete="new-password"
                placeholder="Color"
                sx={{
                  width: "136px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Model Year
              </Typography>
              <TextField
                value={vehicleDetail.modelYear}
                onChange={(e) =>
                  handleInputChange(
                    setVehicleDetail,
                    "modelYear",
                    e.target.value
                  )
                }
                error={Boolean(errors.modelYear)}
                helperText={errors.modelYear}
                type="text"
                autoComplete="new-password"
                placeholder="Model Year"
                sx={{
                  width: "136px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Plate Number
              </Typography>
              <TextField
                value={vehicleDetail.plateNo}
                onChange={(e) =>
                  handleInputChange(setVehicleDetail, "plateNo", e.target.value)
                }
                error={Boolean(errors.plateNo)}
                helperText={errors.plateNo}
                type="text"
                autoComplete="new-password"
                placeholder="Plate Number"
                sx={{
                  width: "136px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          {/* Pay Rates Section */}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
              <Typography
                variant="h6"
                sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
              >
                Pay Rates
                <span style={{ color: "#fb1001" }}>(Mandatory Field)</span>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: 5,
                rowGap: 0.7,
              }}
            >
              {/* Type Dropdown */}
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    marginBottom: "3px",
                  }}
                >
                  Type
                </Typography>
                <CustomDropdown
                  customstyles={{
                    height: "32px",
                    width: "137px",
                  }}
                  label=""
                  value={payType}
                  onChange={handlePayTypeChange}
                  options={payratesOptions}
                  isMultiSelect={false}
                />
              </Box>

              {payType === "payperstop" ? (
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                      marginBottom: "3px",
                    }}
                  >
                    Pay Per Stop
                  </Typography>
                  <TextField
                    value={driverData.shift1Price}
                    onChange={(e) =>
                      handleInputChange(
                        setDriverData,
                        "shift1Price",
                        e.target.value
                      )
                    }
                    error={Boolean(errors.shift1Price)}
                    helperText={errors.shift1Price}
                    placeholder="0.00"
                    autoComplete="new-password"
                    sx={{
                      width: "182px",
                      "& .MuiInputBase-input": {
                        fontSize: "11px",
                        padding: "8px 12px",
                      },
                      "& .MuiOutlinedInput-root": {
                        height: "34px",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "#4b5563",
                      },
                    }}
                  />
                </Box>
              ) : (
                <>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        marginBottom: "3px",
                      }}
                    >
                      09:00 AM To 03:00 PM
                    </Typography>
                    <TextField
                      value={driverData.shift2Price}
                      onChange={(e) =>
                        handleInputChange(
                          setDriverData,
                          "shift2Price",
                          e.target.value
                        )
                      }
                      error={Boolean(errors.shift2Price)}
                      helperText={errors.shift2Price}
                      type="number"
                      autoComplete="new-password"
                      placeholder="0.00"
                      sx={{
                        width: "182px",
                        "& .MuiInputBase-input": {
                          fontSize: "11px",
                          padding: "8px 12px",
                        },
                        "& .MuiOutlinedInput-root": {
                          height: "34px",
                        },
                        "& .MuiInputBase-input::placeholder": {
                          color: "#4b5563",
                        },
                        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ fontSize: "10px", paddingLeft: "5px" }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        marginBottom: "3px",
                      }}
                    >
                      02:00 AM To 06:00 PM
                    </Typography>
                    <TextField
                      value={driverData.shift3Price}
                      onChange={(e) =>
                        handleInputChange(
                          setDriverData,
                          "shift3Price",
                          e.target.value
                        )
                      }
                      error={Boolean(errors.shift3Price)}
                      helperText={errors.shift3Price}
                      type="number"
                      autoComplete="new-password"
                      placeholder="0.00"
                      sx={{
                        width: "182px",
                        "& .MuiInputBase-input": {
                          fontSize: "11px",
                          padding: "8px 12px",
                        },
                        "& .MuiOutlinedInput-root": {
                          height: "34px",
                        },
                        "& .MuiInputBase-input::placeholder": {
                          color: "#4b5563",
                        },
                        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ fontSize: "10px", paddingLeft: "5px" }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        marginBottom: "3px",
                      }}
                    >
                      5:00 PM To 9:00 PM
                    </Typography>
                    <TextField
                      value={driverData.shift3Price}
                      onChange={(e) =>
                        handleInputChange(
                          setDriverData,
                          "shift3Price",
                          e.target.value
                        )
                      }
                      error={Boolean(errors.shift3Price)}
                      helperText={errors.shift3Price}
                      type="number"
                      placeholder="0.00"
                      sx={{
                        width: "182px",
                        "& .MuiInputBase-input": {
                          fontSize: "11px",
                          padding: "8px 12px",
                        },
                        "& .MuiOutlinedInput-root": {
                          height: "34px",
                        },
                        "& .MuiInputBase-input::placeholder": {
                          color: "#4b5563",
                        },
                        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ fontSize: "10px", paddingLeft: "5px" }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </>
              )}
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    marginBottom: "3px",
                  }}
                >
                  Failed Delivery
                </Typography>
                <TextField
                  value={driverData.failedDeliveryPrice}
                  onChange={(e) =>
                    handleInputChange(
                      setDriverData,
                      "failedDeliveryPrice",
                      e.target.value
                    )
                  }
                  error={Boolean(errors.failedDeliveryPrice)}
                  helperText={errors.failedDeliveryPrice}
                  type="number"
                  placeholder="0.00"
                  sx={{
                    width: "182px",
                    "& .MuiInputBase-input": {
                      fontSize: "11px",
                      padding: "8px 12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "34px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#4b5563",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ fontSize: "10px", paddingLeft: "5px" }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    marginBottom: "3px",
                  }}
                >
                  Urgent Delivery
                </Typography>
                <TextField
                  value={driverData.urgentDeliveryPrice}
                  onChange={(e) =>
                    handleInputChange(
                      setDriverData,
                      "urgentDeliveryPrice",
                      e.target.value
                    )
                  }
                  error={Boolean(errors.urgentDeliveryPrice)}
                  helperText={errors.urgentDeliveryPrice}
                  type="number"
                  placeholder="0.00"
                  sx={{
                    width: "182px",
                    "& .MuiInputBase-input": {
                      fontSize: "11px",
                      padding: "8px 12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "34px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#4b5563",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ fontSize: "10px", paddingLeft: "5px" }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
            >
              Additional Information
            </Typography>
          </Box>

          <TextField
            value={driverData.addtionalNote}
            onChange={(e) =>
              handleInputChange(setDriverData, "addtionalNote", e.target.value)
            }
            error={Boolean(errors.addtionalNote)}
            helperText={errors.addtionalNote}
            type="text"
            autoComplete="new-password"
            placeholder="Text Here"
            multiline
            rows={4}
            sx={{
              width: "830px",
              "& .MuiInputBase-root": {
                padding: "5px 10px",
              },
              "& .MuiInputBase-input": {
                fontSize: "11px",
              },
              "& .MuiOutlinedInput-root": {
                "& textarea": {
                  fontSize: "11px",
                },
              },
              "& .MuiInputLabel-root": {
                fontSize: "11px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#4b5563",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            marginTop: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={driverData.calling_allowed}
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  calling_allowed: e.target.checked,
                })
              }
              sx={{
                width: "10px",
                height: "10px",
                transform: "scale(0.6)",
                color: "#d2d5da",
                "&.Mui-checked": {
                  color: "#000",
                },
              }}
            />
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 400 }}
            >
              Allow Calling
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={driverData.sms_allowed}
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  sms_allowed: e.target.checked,
                })
              }
              sx={{
                width: "10px",
                height: "10px",
                transform: "scale(0.6)",
                color: "#d2d5da",
                "&.Mui-checked": {
                  color: "#000",
                },
              }}
            />
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 400 }}
            >
              Allow SMS
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={driverData.allow_reordering}
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  allow_reordering: e.target.checked,
                })
              }
              sx={{
                width: "10px",
                height: "10px",
                transform: "scale(0.6)",
                color: "#d2d5da",
                "&.Mui-checked": {
                  color: "#000",
                },
              }}
            />
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 400 }}
            >
              Allow ReOrdering
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            marginTop: 5,
          }}
        >
          {dId && (
            <ContainedButton
              label="Delete"
              onClick={handleDelete}
              borderRadius="5px"
              fontSize="13px"
              width="66px"
              height="32px"
              bgColor="#f44336"
            />
          )}
          <ContainedButton
            label={
              loading ? (
                <CircularProgress size={16} sx={{ color: "white" }} />
              ) : (
                "Save"
              )
            }
            borderRadius="5px"
            fontSize="13px"
            width="66px"
            height="32px"
            onClick={handleSubmit}
            disabled={loading}
          />
        </Box>
        <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this Driver?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              sx={{
                backgroundColor: "#f44336",
                color: "white",
                "&:hover": {
                  backgroundColor: "#d32f2f",
                },
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default DriverInformationForm;
