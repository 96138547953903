import React, { useEffect, useState } from "react";
import UserOverview from "../components/userCompo/UserOverview";
import Topbar from "../components/userCompo/Topbar";
import { Box, Typography } from "@mui/material";
import { facility_data } from "../utils/apiUrl";
import { getApiWithAuth } from "../utils/api";

const AddFacility = () => {
  const [facilityData, setFacilityData] = useState([]);
  const [facilityFilteredData, setFacilityFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFacilityData = async () => {
      try {
        const response = await getApiWithAuth(facility_data);
        console.log("Facility Data:", response.data.data);

        setFacilityData(response.data.data);
        setFacilityFilteredData(response.data.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching facility data:", error);
        setError(error);
        setLoading(false);
      }
    };
    fetchFacilityData();
  }, []);

  const handleSearch = (query) => {
    if (!query) {
      // If query is empty, reset the filtered data to the original data
      setFacilityFilteredData(facilityData);
      return;
    }

    const lowerCaseQuery = query.toLowerCase();
    const filtered = facilityData.filter((data) => {
      const firstName = data.firstName ? data.firstName.toLowerCase() : "";
      const lastName = data.lastName ? data.lastName.toLowerCase() : "";
      const cellNumber = data.cellNumber ? data.phoneNumber.toLowerCase() : "";

      return (
        firstName.includes(lowerCaseQuery) ||
        lastName.includes(lowerCaseQuery) ||
        cellNumber.includes(query)
      );
    });
    setFacilityFilteredData(filtered);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }
  if (error) {
    return <Typography>Error: {error.message}</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Topbar onSearch={handleSearch} />
      {facilityFilteredData.map((data, index) => (
        <UserOverview key={data.id} data={data} index={index} />
      ))}
    </Box>
  );
};

export default AddFacility;
