import { Box } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import Topbar from "../components/notificationsPageCompo/Topbar";
import NotificationOverview from "../components/notificationsPageCompo/NotificationOverview";

const NotificationsPage = ({ cancelationReason }) => {
  const [orders, setOrders] = useState([]);
  const fetchOrdersRef = useRef(null);
  const location = useLocation(); 

  const handleOrdersUpdate = (newOrders) => {
    setOrders(newOrders);
    console.log("Updated Orders:", newOrders);
  };

  useEffect(() => {
    if (fetchOrdersRef.current) {
      fetchOrdersRef.current();
    }
  }, [location.pathname]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, my: 1 }}>
      <Topbar
        cancelationReason={cancelationReason}
        onOrdersUpdate={handleOrdersUpdate}
        fetchOrdersRef={(callback) => (fetchOrdersRef.current = callback)} 
      />
      {orders.map((order, index) => (
        <NotificationOverview
          key={index}
          cancelationReason={cancelationReason}
          ord={order}
          fetchorders={() => fetchOrdersRef.current()}
        />
      ))}
    </Box>
  );
};

export default NotificationsPage;
