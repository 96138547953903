import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Popover,
  Modal,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  ContainedButton,
  OutlinedButtonWithIcon,
} from "../../components/buttonCompo/Buttons";
import { useNavigate } from "react-router";
import moment from "moment";
import {
  patchApiWithAuth,
  getApiWithAuth,
  putApiWithAuth,
} from "../../utils/api";
import {
  CREATE_ORDER,
  CREATE_ADMIN_NOTE,
  GET_HISTORY,
} from "../../utils/apiUrl";
import React, { useState, useContext, useEffect } from "react";
import {
  AddressIcon,
  LocationIconBlack,
  MobileIcon,
  PersonIcon,
  TelephoneIcon,
} from "../Icons";
import { useTheme } from "@mui/material";
import HistoryComponent from "../homePageCompo/popups/HistoryComponent";
import { OrderDataContext } from "../../context/OrderDataContext";
import EditPage from "../../pages/Editorder";

function getStatusMessage(statusCode) {
  switch (statusCode) {
    case "1":
      return "Ready to Pick Up";
    case "2":
      return "Picked Up";
    case "3":
      return "Received At Facility";
    case "4":
      return "In Transit";
    case "5":
      return "Delivered";
    case "6":
      return "Cancelled";
    case "7":
      return "Return Created";
    case "8":
      return "Return Route Created";
    case "9":
      return "Returned to Pharmacy";
    default:
      return "Unknown Status";
  }
}
const NotificationOverview = ({
  ord,
  cancelationReason = false,
  fetchorders,
}) => {
  console.log("ordeeeeeeeeeeeeeeeeeeeeee", ord);
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const theme = useTheme();
  const [history, setHistory] = useState(null);
  const [modalViewOpen, setModalViewOpen] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [order, setOrder] = useState("");
  const [adminNote, setAdminNote] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const statusMessage = getStatusMessage(order?.status);
  const { setOrderData } = useContext(OrderDataContext);
  const [opendialog, setOpenDialog] = useState(false);
  const [comment, setComment] = useState("");

  const handleOpenDialog = (comment) => {
    console.log("ipeininggggg dialog");
    setComment(comment);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setComment("");
  };
  const handleMarkAsRead = async (order) => {
    let response = "";

    if (userType === "PharmacyEmployee" || userType === "PharmacyOwner") {
      response = await patchApiWithAuth(CREATE_ORDER + `/${order?.id}`, {
        isPharmacyRead: true,
        driver: order?.driver,
        driverNote_id: order?.driverNote_id,
      });
    } else {
      response = await patchApiWithAuth(CREATE_ORDER + `/${order?.id}`, {
        isAdminRead: true,
        driver: order?.driver,
        driverNote_id: order?.driverNote_id,
      });
    }

    if (response.data !== undefined) {
      console.log(response);
      fetchorders();
    } else {
      console.log("error");
    }
  };
  const handleMarkAsReadcancel = async (order) => {
    let response = "";
    console.log("order in handle", order);
    if (userType === "PharmacyEmployee" || userType === "PharmacyOwner") {
      response = await patchApiWithAuth(CREATE_ORDER + `/${order?.id}`, {
        cancelnotePharmacyRead: true,
        driver: order?.driver,
        driverNote_id: order?.driverNote_id,
      });
    } else {
      response = await patchApiWithAuth(CREATE_ORDER + `/${order?.id}`, {
        cancelnoteAdminRead: true,
        driver: order?.driver,
        driverNote_id: order?.driverNote_id,
      });
    }

    if (response.data !== undefined) {
      console.log(response);
      fetchorders();
    } else {
      console.log("error");
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isVeryLargeScreen = useMediaQuery("(min-width: 1800px)");
  const handleCreateHistory = async () => {
    try {
      const response = await getApiWithAuth(GET_HISTORY + `${ord.id}/`);
      if (response.data !== undefined) {
        setHistory(response.data.data); // Set the fetched history data
        setIsHistoryOpen(true); // Open the modal once data is fetched
      } else {
        console.log("No history data found");
      }
    } catch (error) {
      console.log("Error fetching history:", error);
    }
  };

  const handleHistoryOpen = () => {
    setIsHistoryOpen(true);
    handleCreateHistory(); // Fetch the order history
  };
  const handleHistoryClose = () => setIsHistoryOpen(false);
  const handleViewModalOpen = (id) => {
    setModalViewOpen(true);
    setOrderId(id);
  };
  const handleClick = (event) => {
    setAdminNote(
      userType === "PharmacyOwner" || userType === "	PharmacyEmployee"
        ? ord?.pharmacy_staff_note
        : ord?.admin_staff_note
    );
    setAnchorEl(event.currentTarget);
  };
  const handleSaveFacilityNote = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (adminNote !== "") {
      formData.append("note", adminNote);
      const response = await putApiWithAuth(
        CREATE_ADMIN_NOTE + `${order.id}`,
        formData
      );
      if (response.data !== undefined) {
        console.log(response.data);
        setAdminNote("");
        handleClose();
      } else {
        handleClose();
      }
    } else {
    }
  };
  // Handle Popover Close
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleViewModalClose = () => {
    setModalViewOpen(false);
    setOrderId("");
  };
  // const getDetailOrder = async (id) => {
  //   try {
  //     const response = await getApiWithAuth(
  //       CREATE_ORDER + `/${ord?.id}`
  //     );
  //     console.log(response.data.data);
  //     console.log(response.data.data.orderticket);
  //     setOrder(response.data.data)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //     getDetailOrder(ord?.id);
  // }, [ord]);
  const handleViewOrder = async (ord) => {
    const response = await getApiWithAuth(CREATE_ORDER + `/${ord?.id}`);
    setOrder(response.data.data);
    const order = response.data.data;
    try {
      setOrderData((prev) => ({
        order,
      }));
      handleViewModalOpen(response.data.data?.id);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };
  const actions = [
    {
      src: "icons/order/EyeBlack.svg",
      alt: "View/Edit Order",
      label: "View/Edit Order",
      onClick: () => handleViewOrder(ord),
    },
    {
      src: "icons/order/NotesBlack.svg",
      alt: "Staff Notes",
      label: "Staff Notes",
      labelStyle:
        (ord.pharmacy_staff_note &&
          (userType === "PharmacyOwner" || userType === "PharmacyEmployee")) ||
        (ord.admin_staff_note &&
          userType !== "PharmacyOwner" &&
          userType !== "PharmacyEmployee")
          ? { color: "blue" }
          : { color: "black" },
      onClick: (event) => handleClick(event),
    },
    {
      src: "icons/order/HistoryBlack.svg",
      alt: "History",
      label: "History",
      onClick: handleHistoryOpen, // Open the History popup
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        boxShadow: 1,
        gap: 2,
        px: 3,
        py: 0.5,
        borderRadius: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              height: "32px",
              width: "170px",
              padding: "7px 10px",
              border: "none",
              backgroundColor: "#f1f3f4",
              color: "#000",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 700, fontSize: "13px" }}
            >
              Order ID {ord?.slug}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "none",
              borderRadius: "8px",
              height: "31px",
              width: "200px",
              padding: "7px",
              border: "1px solid #dce0e4", // Border styling like 'outlined'
              color: "#000",
              gap: 1,
            }}
          >
            <PersonIcon />
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                fontSize: "11px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              {ord?.name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          {ord?.address && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                height: "31px",
                width: "350px",
                padding: "7px",
                color: "#000",
                gap: 1,
              }}
            >
              <LocationIconBlack />
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "11px" }}
              >
                {ord?.address}
              </Typography>
            </Box>
          )}
        </Box>
        {/* <Box sx={{ display: "flex", gap: 1 }}>
          {ord?.contact && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                borderRadius: "8px",
                height: "31px",
                width: "172px",
                padding: "7px",
                border: "1px solid #dce0e4", // Border styling like 'outlined'
                color: "#000",
                gap: 1,
              }}
            >
              <AddressIcon />
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "11px" }}
              >
                {ord?.contact}
              </Typography>
            </Box>
          )}
          {ord?.cell && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                borderRadius: "8px",
                height: "31px",
                width: "172px",
                padding: "7px",
                border: "1px solid #dce0e4", // Border styling like 'outlined'
                color: "#000",
                gap: 1,
              }}
            >
              <TelephoneIcon />
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "11px" }}
              >
                {ord?.cell}
              </Typography>
            </Box>
          )}
          {ord?.workNumber && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                borderRadius: "8px",
                height: "31px",
                width: "172px",
                padding: "7px",
                border: "1px solid #dce0e4", // Border styling like 'outlined'
                color: "#000",
                gap: 1,
              }}
            >
              <MobileIcon />
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "11px" }}
              >
                {ord?.workNumber}
              </Typography>
            </Box>
          )}
        </Box> */}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          gap: 1,
          flex: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, flex: 1 }}>
          {ord?.returnFailed?.length > 0 &&
            ord?.returnFailed?.map((failed, index) => {
              const attemptNumber = index + 1;
              const attemptText = `${attemptNumber}${
                attemptNumber === 1
                  ? "st"
                  : attemptNumber === 2
                  ? "nd"
                  : attemptNumber === 3
                  ? "rd"
                  : "th"
              } Attempt Failed`;

              return (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textTransform: "none",
                      borderRadius: "8px",
                      height: "31px",
                      padding: "4px",
                      border: "1px solid #dce0e4", // Border styling like 'outlined'
                      color: "#000",
                      gap: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpenDialog(failed.comment)} // Open dialog with comment
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        fontSize: "10px",
                        color: "#fc1002",
                      }}
                    >
                      {attemptText}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "transparent", // Green color similar to the image
                      width: "106px",
                      borderRadius: "12px", // Rounded corners
                      display: "inline-flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color: "#000", // White text color
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "9px",
                        fontWeight: 500,
                      }}
                    >
                      {moment(failed?.date).format("MM-DD-YYYY")} |{" "}
                      {moment(failed?.date).format("hh:mm:ss a")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "9px",
                        fontWeight: 500,
                      }}
                    >
                      By: {failed.updatedBy.first_name}{" "}
                      {failed.updatedBy.last_name}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            flex: 1,
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontSize: "12px", fontWeight: 500, color: "#0b6699" }}
          >
            {!cancelationReason ? "New Update" : "Cancellation Reason"} :
          </Typography>

          <TextField
            variant="outlined"
            autoComplete="off"
            multiline
            value={cancelationReason ? ord?.cancel_note : ord?.note || ""}
            rows={4} // Adjust rows as needed
            sx={{
              "& .MuiInputBase-root": {
                fontSize: "10px",
                fontWeight: 500,
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "18px",
                padding: 0,
                height: "auto", // Adjust height to grow with content
                "& fieldset": {
                  border: "none", // Removes the border of the TextField
                },
              },
              "& .MuiInputBase-input": {
                overflow: "hidden", // Prevents scrollbar from appearing
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#4b5563", // Set placeholder color to light gray
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            backgroundColor: cancelationReason
              ? "rgba(233,26,26,0.5)"
              : "rgba(6,179,32,0.5)",
            borderRadius: "12px", // Rounded corners
            padding: "4px 12px", // Padding inside the box
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#fff", // White text color
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "11px",
              fontWeight: 500,
            }}
          >
            {cancelationReason
              ? "Cancelled by pd Admin"
              : `Updated by: ${ord?.by}`}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "11px",
              fontWeight: 500,
            }}
          >
            {cancelationReason ? (
              <>
                {moment(ord?.cancelAt).format("MM-DD-YYYY")} |{" "}
                {moment(ord?.cancelAt).format("hh:mm:ss a")}
              </>
            ) : (
              <>
                {moment(ord?.notecreatedAt).format("MM-DD-YYYY")} |{" "}
                {moment(ord?.notecreatedAt).format("hh:mm:ss a")}
              </>
            )}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.3 }}>
          {actions.map((action, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                cursor: "pointer",
              }}
              onClick={action.onClick} // Execute the action's onClick handler
            >
              <img src={action.src} alt={action.alt} />
              <Typography
                variant="body2"
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                  color: "#333",
                  paddingLeft: "3px",
                  ...action.labelStyle,
                }}
              >
                {action.label}
              </Typography>
            </Box>
          ))}
          {!cancelationReason && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={ord?.is_read}
                  onClick={() => handleMarkAsRead(ord)}
                  color="primary"
                  sx={{
                    width: "20px",
                    height: "20px",
                    "& .MuiSvgIcon-root": {
                      fontSize: "15px",
                      borderRadius: "16px",
                    },
                    "& .MuiCheckbox-root": {
                      borderRadius: "16px",
                    },
                  }}
                />
              }
              label="Mark as Read"
              labelPlacement="start"
              sx={{
                color: "#000",
                margin: 0,
                gap: "1px",
                "& .MuiTypography-root": {
                  fontSize: "11px",
                  color: "#000",
                },
              }}
            />
          )}
          {cancelationReason && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    userType === "PharmacyEmployee" ||
                    userType === "PharmacyOwner"
                      ? ord?.cancelnotePharmacyRead
                      : ord?.cancelnoteAdminRead
                  }
                  onClick={() => handleMarkAsReadcancel(ord)}
                  color="primary"
                  sx={{
                    width: "20px",
                    height: "20px",
                    "& .MuiSvgIcon-root": {
                      fontSize: "15px",
                      borderRadius: "16px",
                    },
                    "& .MuiCheckbox-root": {
                      borderRadius: "16px",
                    },
                  }}
                />
              }
              label="Mark as Read"
              labelPlacement="start"
              sx={{
                color: "#000",
                margin: 0,
                gap: "1px",

                "& .MuiTypography-root": {
                  fontSize: "11px",
                  color: "#000", // Ensures label uses theme color
                },
              }}
            />
          )}
        </Box>
      </Box>

      <Modal
        open={isHistoryOpen}
        onClose={handleHistoryClose}
        aria-labelledby="history-modal"
        aria-describedby="history-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 0, // Aligns the modal at the bottom of the screen
            right: 0,
            width: 650,
            bgcolor: "background.paper",
            backgroundColor: "#f4f6f8",
            boxShadow: 24,
            overflowY: "auto",
            p: 2,
          }}
        >
          <HistoryComponent
            orderHistory={history}
            onClose={handleHistoryClose}
          />
        </Box>
      </Modal>
      <Dialog
        open={modalViewOpen}
        onClose={handleViewModalClose}
        fullWidth={true}
        maxWidth="xl"
      >
        <EditPage
          orderid={orderId}
          handleViewModalClose={handleViewModalClose}
        />
      </Dialog>
      {/* Staff Notes Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            padding: 1,
            borderRadius: "10px",
            width: "254px",
            height: "121px",
            backgroundImage: "linear-gradient(45deg, #Fff5f3, #f1fbfd)",
          },
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "12px", color: "#0b6699" }}>
          Staff Notes
        </Typography>
        <Typography variant="body2"></Typography>
        <TextField
          fullWidth
          variant="outlined"
          autoComplete="off"
          // placeholder="To Add or Remove Any Staff Notes. If Note is Cleared and Saved Then Blue Highlight Will Be Removed."
          multiline
          rows={3}
          value={adminNote}
          onChange={(e) => setAdminNote(e.target.value)}
          sx={{
            mb: 2,
          }}
          InputProps={{
            sx: {
              padding: 0, // Remove padding from the outer wrapper
              "& .MuiInputBase-input": {
                padding: 0, // Remove padding from the input field itself
                fontSize: "11px",
                fontWeight: 500,
                lineHeight: "1.2",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove the notched outline (border)
              },
            },
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <OutlinedButtonWithIcon
            label="Clear"
            onClick={handleClose}
            width="46px"
            height="20px"
            padding={1.3} // Ensure padding is set as desired
          />
          <ContainedButton
            label="Save"
            onClick={handleSaveFacilityNote}
            width="46px"
            height="20px"
          >
            Save
          </ContainedButton>
        </Box>
      </Popover>
      {/* Dialog Box */}
      <Dialog open={opendialog} onClose={handleCloseDialog}>
        <DialogTitle>
          Failed Comment
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ fontSize: "14px" }}>
            {comment || "No comment available."}
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NotificationOverview;
