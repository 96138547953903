import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getApiWithAuth } from '../utils/api';
import { ALL_PHARMACY_LIST } from '../utils/apiUrl';

export const fetchPharmacies = createAsyncThunk(
  'pharmacy/fetchPharmacies',
  async () => {
    const response = await getApiWithAuth(ALL_PHARMACY_LIST, { justList: true });
    return response.data.data.map(item => ({ id: item.id, name: item.name }));
  }
);

const pharmacySlice = createSlice({
  name: 'pharmacy',
  initialState: {
    list: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Add any synchronous reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPharmacies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPharmacies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(fetchPharmacies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default pharmacySlice.reducer;
