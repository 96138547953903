import { Box } from "@mui/material";
import React from "react";
import Topbar from "../../components/addpharmacyPageCompo/subPages/Topbar";
import PharmacyInformationForm from "../../components/addpharmacyPageCompo/subPages/PharmacyInformationForm";
import { ContainedButton } from "../../components/buttonCompo/Buttons";

const AddPharmacyInfo = () => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", mx: 3, marginBottom: 5 }}
    >
      <PharmacyInformationForm />
    </Box>
  );
};

export default AddPharmacyInfo;
