import React from "react";
import { Box, Typography, Button, Avatar, Icon, Badge } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import { LocationIconBlack, PersonIconBlack } from "../Icons";
import moment from "moment";

const SMSCard = ({ chat, unreadCounts, onClick, isActive }) => {
  return (
    <Box
      backgroundColor={isActive ? "#EBEBEC" : "#FFFFFF"}
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        borderRadius: "8px",
        // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        // margin: "8px 0",
        width: "100%",
      }}
    >
      {/* Left Section */}
      <Box sx={{ display: "flex", gap: "16px", flex: 1 }}>

        <Box>

          <Badge badgeContent={unreadCounts} color="error">
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              {chat?.name}
            </Typography>
          </Badge>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                marginTop: "4px",
              }}
            >
              Last message: {chat?.last_message?.message}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Right Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >

        {/* Timestamp */}
        <Typography
          sx={{ fontSize: "12px", color: "#000", marginBottom: "4px", fontWeight: 700 }}
        >
          Created At: {moment(chat?.created_at).format("DD/MM/YYYY HH:mm:ss")}
        </Typography>
      </Box>
    </Box>
  );
};

export default SMSCard;
