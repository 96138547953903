import {
  Box,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import { CrossIcon, SearchIcon } from "../Icons";
import SingleFieldDateRangePicker from "../dateRangePicker/DateRangePicker";
import CustomDropdown from "../CustomDropdown";

const ChatHeader
 = ({
  pharmacyList,
  pharmacy,
  setPharmacy,
  dateRange,
  setDateRange,
  setSearch,
}) => {
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      setSearch(inputValue);
    }
  };

  const handlePharmacyChange = (selectedOption) => {
    if (selectedOption) {
      setPharmacy([selectedOption]);
    } else {
      setPharmacy([]);
    }
  };

  const options = pharmacyList
  .filter((pharm) => pharm && pharm.id && pharm.name)
  .map((pharm) => ({
    value: pharm.id,
    label: pharm.name,
  }));

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, marginTop: 1 }}>
        {/* Search Field */}
        <TextField
          variant="outlined"
          placeholder="Search"
          autoComplete="off"
          InputProps={{
            sx: {
              fontSize: "11px",
              border: "1px solid #dce0e4",
            },
            onKeyDown: handleKeyPress,
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={{
            width: 351,
            backgroundColor: "#fff",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              height: "36px",
              paddingLeft: 0,
              "& fieldset": {
                border: "none",
              },
            },
          }}
          size="small"
        />

        {/* Pharmacy Dropdown */}
        {/* <CustomDropdown
            customstyles={{
              height: "36px",
              width: "137px",
            }}
            label="Filter Pharmacy"
            value={pharmacy.length > 0 ? pharmacy[0] : null}
            onChange={handlePharmacyChange}
            options={options}
            isMultiSelect={false}
            search={true}
        /> */}
      </Box>

      {/* Date Picker */}
      <Box>
        <SingleFieldDateRangePicker
          value={dateRange}
          onChange={handleDateChange}
        />
      </Box>
    </Box>
  );
};

export default ChatHeader
;
