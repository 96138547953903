import { Box, Typography } from "@mui/material";
import React from "react";
import {
  CallWhiteIcon,
  EnvelopeWhiteIcon,
  StartWhiteIcon,
  TelephoneWhiteIcon,
} from "../Icons";
import { useTheme } from "@mui/material";

const PharmacyDetails = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.custom.layoutBgColor,
        color: theme.palette.custom.layoutFontColor,
        borderRadius: "8px",
        width: "244px",
        height: "99px",
        py: 1,
        px: 1.3,
        display: "flex",
        flexDirection: "column",
        gap: 0.3,
      }}
    >
      <Typography sx={{ color: "inherit", fontSize: "12px", fontWeight: 500 }}>
        SJS Pharmacy
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            color: "inherit",
            fontSize: "10px",
            fontWeight: 500,
            gap: 1,
          }}
        >
          <CallWhiteIcon />
          (909 000 9999)
        </Typography>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            color: "inherit",
            fontSize: "10px",
            fontWeight: 500,
            gap: 1,
          }}
        >
          <TelephoneWhiteIcon />
          (909 000 9999)
        </Typography>
      </Box>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          color: "inherit",
          fontSize: "10px",
          fontWeight: 500,
          gap: 1,
        }}
      >
        <EnvelopeWhiteIcon /> Pharmacy@gmail.com
      </Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          color: "inherit",
          fontSize: "10px",
          fontWeight: 500,
          gap: 1,
        }}
      >
        <StartWhiteIcon /> 24-07 94th street, first floor east elmhurst, Forest
      </Typography>
    </Box>
  );
};

export default PharmacyDetails;
