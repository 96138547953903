import React from "react";
import { Box, Typography } from "@mui/material";

const routes = [
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#FF5733",
  }, // Red
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#0ea985",
  }, // Green
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#3598dc",
  }, // Blue
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#f4cf40",
  }, // Yellow
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#8a46ad",
  }, // Purple
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#2dcc70",
  }, // Dark Blue
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#164360",
  }, // Magenta
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#f29488",
  }, // Orange
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#753d8f",
  }, // Dark Purple
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#164360",
  }, // Magenta
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#f29488",
  }, // Orange
  {
    name: "Merge Route NASSAU COUNTY",
    date: "NASSAU 9-28-24 NICOLE",
    bgColor: "#753d8f",
  }, // Dark Purple
];

const RouteList = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="body2" sx={{ marginBottom: 2, fontSize: "1.25rem" }}>
        Route Name
      </Typography>
      {routes.map((route, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: route.bgColor,
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
            color: "#fff",
            boxShadow: 1,
            maxWidth: "165px",
            height: "47px",
            transition: " box-shadow 0.3s ease",
            "&:hover": {
              cursor: "pointer",
              boxShadow: 3, // Increase shadow on hover
            },
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "10px",
              fontWeight: 600,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis", // This will add the ellipsis effect
            }}
          >
            {route.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis", // Same ellipsis effect for the second line
            }}
          >
            {route.date}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default RouteList;
