// slices/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getApiWithAuth } from '../utils/api';
import { AUTH_ME } from '../utils/apiUrl';

// Define an async thunk for fetching user details
export const fetchUserDetail = createAsyncThunk(
    'user/fetchUserDetail',
    async (_, thunkAPI) => {
        try {
            const response = await getApiWithAuth(AUTH_ME);
            if (response && response.data) {
                // Return the user details as the payload
                return response.data.data.data;
            } else {
                return thunkAPI.rejectWithValue('No data returned from API');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState: {
        detail: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        clearUserDetail: (state) => {
            state.detail = null;
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserDetail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserDetail.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.detail = action.payload;
            })
            .addCase(fetchUserDetail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { clearUserDetail } = userSlice.actions;
export default userSlice.reducer;
