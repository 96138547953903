import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  Popover,
  TextField,
} from "@mui/material";
import {
  CallIcon,
  DeliveryNotesIcon,
  EditableIcon,
  EyeBlackIcon,
  HistoryIcon,
  HomeIcon,
  MobileIcon,
  PersonIconBlack,
  PrinterIcon,
  StaffNotesIcon,
  TelephoneIcon,
  TruckIcon,
} from "../components/Icons";
import {
  ContainedButton,
  OutlinedButtonWithIcon,
} from "../components/buttonCompo/Buttons";
import HistoryComponent from "../components/homePageCompo/popups/HistoryComponent";

const OrderCard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [ticketAnchorEl, setTicketAnchorEl] = useState(null);

  const infoData = [
    { icon: <PersonIconBlack />, label: "Asad Arshad", width: "551px" },
    { icon: <CallIcon />, label: "(909) 786-9999", width: "169px" },
    { icon: <MobileIcon />, label: "(909) 786-9999", width: "169px" },
    { icon: <TelephoneIcon />, label: "(909) 786-9999", width: "169px" },
  ];

  const addressData = [
    {
      icon: <HomeIcon />,
      label: "House 42 Street 55a Khyber park Sanat Nagar Lahore",
      width: "551px",
    },
    { label: "Apt# 4C", width: "169px" },
  ];

  const dateData = [
    {
      icon: <EditableIcon />,
      label: "Created Date: 08-25-2023, 01:45:25 PM",
      width: "280px",
    },
    {
      icon: <TruckIcon />,
      label: "Delivery Date: 08-25-2023, 2pm to 6pm ",
      width: "280px",
    },
    { label: "Attempts: 0", width: "100px" },
  ];

  const InfoBox = ({ icon, label, width }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        padding: "6px 12px",
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        width: width,
      }}
    >
      {icon && (
        <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
          {icon}
        </Box>
      )}
      <Typography sx={{ fontSize: "11px", color: "#333" }}>{label}</Typography>
    </Box>
  );

  const handleHistoryOpen = () => setHistoryModalOpen(true);
  const handleHistoryClose = () => setHistoryModalOpen(false);
  const handleStaffNotesClick = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);
  const handleCreateTicketClick = (event) =>
    setTicketAnchorEl(event.currentTarget);
  const handleTicketPopoverClose = () => setTicketAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const ticketOpen = Boolean(ticketAnchorEl);
  const ticketPopoverId = ticketOpen ? "create-ticket-popover" : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        p: 2,
        mt: 2,
        border: "1px solid #dcdcdc",
        borderRadius: 4,
        boxShadow: 1,
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 3 }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              backgroundColor: "var(--color-gray200)",
              fontSize: "12px",
              padding: "6px",
              borderRadius: "4px",
            }}
          >
            Order ID 01-69548
          </Typography>
          <Typography
            variant="body1"
            sx={{
              backgroundColor: "var(--color-orange)",
              fontSize: "12px",
              padding: "5px 12px",
              borderRadius: "4px",
              fontWeight: 500,
            }}
          >
            Urgent
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 3 }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              color: "#000",
              border: "1px solid var(--color-gray200)",
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            Copay: $3.00
          </Typography>
          <Typography
            variant="body1"
            sx={{
              backgroundColor: "var(--color-blue200)",
              fontSize: "12px",
              padding: "6px",
              borderRadius: "4px",
              fontWeight: 500,
            }}
          >
            Status: Received At Facility
          </Typography>
        </Box>
      </Box>

      {/* Information Sections */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          {infoData.map((info, index) => (
            <InfoBox
              key={index}
              icon={info.icon}
              label={info.label}
              width={info.width}
            />
          ))}
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          {addressData.map((info, index) => (
            <InfoBox
              key={index}
              icon={info.icon}
              label={info.label}
              width={info.width}
            />
          ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            {dateData.map((info, index) => (
              <InfoBox
                key={index}
                icon={info.icon}
                label={info.label}
                width={info.width}
              />
            ))}
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: "var(--color-blue200)",
              border: "1px solid black",
              padding: "6px 6px",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
              borderColor: "var(--color-blue200)",
              fontSize: "11px",
              fontWeight: 600,
            }}
          >
            Refrigerated/Photo ID
          </Typography>
        </Box>
      </Box>

      {/* Buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--color-gray200)",
              fontSize: "12px",
              padding: "0px 4px",
              borderRadius: "4px",
            }}
          >
            <EyeBlackIcon /> View/Edit Order
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              gap: 1,
              backgroundColor: "var(--color-gray200)",
              fontSize: "12px",
              padding: "0px 4px",
              borderRadius: "4px",
            }}
          >
            <PrinterIcon /> QR Slip
          </Typography>
          <Typography
            variant="body2"
            onClick={handleStaffNotesClick}
            sx={{
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--color-gray200)",
              fontSize: "12px",
              padding: "0px 4px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <StaffNotesIcon /> Staff Notes
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--color-blue200)",
              color: "#fff",
              fontSize: "12px",
              padding: "0px 4px",
              borderRadius: "4px",
            }}
          >
            <DeliveryNotesIcon /> Delivery Notes
          </Typography>
          <Typography
            variant="body2"
            onClick={handleHistoryOpen}
            sx={{
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--color-gray200)",
              color: "#000",
              fontSize: "12px",
              padding: "0px 4px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <HistoryIcon /> History
          </Typography>
        </Box>
        <ContainedButton
          label="Create Ticket"
          bgColor="#000"
          textColor="#fff"
          height="28px"
          borderRadius="8px"
          onClick={handleCreateTicketClick}
        />
      </Box>

      {/* Create Ticket Popover */}
      <Popover
        id={ticketPopoverId}
        open={ticketOpen}
        anchorEl={ticketAnchorEl}
        onClose={handleTicketPopoverClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            padding: 1,
            borderRadius: "10px",
            width: "254px",
            height: "121px",
            backgroundImage: "linear-gradient(45deg, #Fff5f3, #f1fbfd)",
          },
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "12px", color: "#0b6699" }}>
          Create Ticket
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          autoComplete="off"
          placeholder="Enter ticket details here."
          multiline
          rows={3}
          sx={{ mb: 2 }}
          InputProps={{
            sx: {
              padding: 0,
              "& .MuiInputBase-input": {
                padding: 0,
                fontSize: "11px",
                fontWeight: 500,
                lineHeight: "1.2",
              },
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            },
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <OutlinedButtonWithIcon
            label="Clear"
            onClick={handleTicketPopoverClose}
            width="46px"
            height="20px"
            padding={1.3}
          />
          <ContainedButton
            label="Save"
            onClick={handleTicketPopoverClose}
            width="46px"
            height="20px"
          />
        </Box>
      </Popover>

      {/* History Modal */}
      <Modal
        open={historyModalOpen}
        onClose={handleHistoryClose}
        aria-labelledby="history-modal"
        aria-describedby="history-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 650,
            bgcolor: "background.paper",
            backgroundColor: "#f4f6f8",
            boxShadow: 24,
            overflowY: "auto",
            p: 2,
          }}
        >
          <HistoryComponent onClose={handleHistoryClose} />
        </Box>
      </Modal>

      {/* Staff Notes Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            padding: 1,
            borderRadius: "10px",
            width: "254px",
            height: "121px",
            backgroundImage: "linear-gradient(45deg, #Fff5f3, #f1fbfd)",
          },
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "12px", color: "#0b6699" }}>
          Staff Notes
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          autoComplete="off"
          placeholder="To Add or Remove Any Staff Notes. If Note is Cleared and Saved Then Blue Highlight Will Be Removed."
          multiline
          rows={3}
          sx={{ mb: 2 }}
          InputProps={{
            sx: {
              padding: 0,
              "& .MuiInputBase-input": {
                padding: 0,
                fontSize: "11px",
                fontWeight: 500,
                lineHeight: "1.2",
              },
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            },
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <OutlinedButtonWithIcon
            label="Clear"
            onClick={handlePopoverClose}
            width="46px"
            height="20px"
            padding={1.3}
          />
          <ContainedButton
            label="Save"
            onClick={handlePopoverClose}
            width="46px"
            height="20px"
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default OrderCard;
