import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import moment from "moment";
import ChatComponent from "./ChatComponent";
const ChatMessage = ({ message }) => {
  // console.log("messageee",message)
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: message.sender === "user" ? "row-reverse" : "row",
        alignItems: "flex-start",
        gap: 1,
        marginBottom: "10px",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f0f0f0", // Change to a light gray on hover
        },
      }}
    >
      <Avatar
        alt={message.name}
        src={message.avatar}
        sx={{ width: 57, height: 57 }}
      />

      <Box
        sx={{
          maxWidth: "55%",
          textAlign: "left",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: message.isOwn ? "row-reverse" : "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: "10px", fontWeight: 500, color: "#515151" }}
          >
            {message?.comment_by?.username}
          </Typography>
          <Typography variant="body3" sx={{ fontSize: "10px" }}>
            {moment(message.created_at).format("MM-DD-YYYY")} |{" "}
            {moment(message.created_at).format("hh:mm:ss a")}
          </Typography>
        </Box>
        {message.type === "text" || !message.type ? (
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: message.isOwn
                ? "15px 0 15px 15px"
                : "0 15px 15px 15px",
              padding: "10px",
              boxShadow: 2,
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 400 }}
            >
              {message.comment}
            </Typography>
          </Box>
        ) : message.type === "audio" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              padding: "10px",
              backgroundColor: "#0b6699",
              borderRadius: message.isOwn
                ? "15px 0 15px 15px"
                : "0 15px 15px 15px",
              color: "#fff",
            }}
          >
            <PlayCircleIcon />
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: 400 }}
            >
              Audio Message
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: 400 }}
            >
              {message.audioDuration}
            </Typography>
          </Box>
        ) : message.type === "status" ? (
          <Box
            sx={{
              backgroundColor: "#0b6699",
              padding: "10px",
              borderRadius: message.isOwn
                ? "15px 0 15px 15px"
                : "0 15px 15px 15px",
            }}
          >
            <Typography
              variant="body2"
              color="#fff"
              sx={{ fontSize: "13px", fontWeight: 600 }}
            >
              {message.status}
            </Typography>
            <Typography
              variant="body2"
              color="#fff"
              sx={{ fontSize: "13px", fontWeight: 400 }}
            >
              {message.statusDetails}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

const ChatWindow = ({ ticketDetail,getTicketDetail, getTicketOrders,ticketId }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <ChatComponent ticketDetail={ticketDetail} getTicketDetail={getTicketDetail} getTicketOrders={getTicketOrders} ticketId={ticketId}/>
      {/* {ticketDetail?.comments?.map((message) => (
        <ChatMessage key={message.id} message={message} />
      ))} */}
    </Box>
  );
};

export default ChatWindow;
