import React from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { ChooseImageIcon } from "../Icons";

const Input = styled("input")({
  display: "none",
});

const Topbar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <label htmlFor="contained-button-file">
          <Input accept="image/*" id="contained-button-file" type="file" />
          <Button
            variant="outlined"
            component="span"
            sx={{
              borderColor: "#4caf50",
              color: "#4caf50",
              width: "115px",
              padding: "10px 10px",
              textTransform: "none",
              fontSize: "13px",
              height: "32px",
            }}
          >
            Choose File
          </Button>
        </label>
        <Typography variant="body2" sx={{ color: "#757575" }}>
          No File Chosen
        </Typography>
        <IconButton color="default" sx={{ padding: 0 }}>
          <ChooseImageIcon />
        </IconButton>
      </Box>

      {/* Action Buttons Section */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#05b320",
            boxShadow: "none",
            textTransform: "none",
            borderRadius: "8px",
            width: "88px",
            height: "32px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#43a047",
            },
          }}
        >
          Activate
        </Button>
        <Button
          variant="outlined"
          sx={{
            borderColor: "#fc1002",
            boxShadow: "none",

            borderRadius: "8px",
            width: "105px",
            height: "32px",
            textTransform: "none",
            color: "#fc1002",
            "&:hover": {
              borderColor: "#d32f2f",
              backgroundColor: "#ffcdd2",
            },
          }}
        >
          Deactivate
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
