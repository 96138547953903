import React from "react";
import ExpenseForm from "../components/ExpenseFormPageCompo/ExpenseForm";

const AdminExpense = () => {
  const handleNotificationSubmit = (notificationData) => {
    console.log("New Notification:", notificationData);
  };

  return (
    <div>
      <ExpenseForm onSubmit={handleNotificationSubmit} />
    </div>
  );
};

export default AdminExpense;
