import { Box, Typography, Checkbox } from "@mui/material";
import React from "react";
import DeliveryNotes from "../../../homePageCompo/OrderDetails/deliveryNotes/DeliveryNotes";
import OrderInfo from "../../../homePageCompo/OrderDetails/orderInfo/OrderInfo";

const RouteDetails = ({ 
  editable,
  checked,
  onCheckboxChange,
  orderDetail,
  handleOrderSelect,
  index,
  selectedOrder,
  handleRouteOrderingModalOpen,
  handleUpdateDeliveryNote,
  userDetail
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }} onClick={() => handleOrderSelect(orderDetail, index + 1)}>
      {editable && (
        <Checkbox
          checked={checked.includes(orderDetail.id)}
          onChange={() => onCheckboxChange(orderDetail.id)}
          sx={{
            paddingLeft: 0,
            scale: 0.8,
            "&.Mui-checked": {
              color: "#0b6699",
            },
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: selectedOrder === orderDetail.id ? 'rgb(203 213 225)' : '',
          padding: "5px 6px",
          height: "108px",
          width: "490px",
          borderRadius: "10px",
          boxShadow: 1,
          position: "relative",
          alignItems: "center",
          marginLeft: 2,
          marginTop: 1,
          marginBottom: 1,
          ":hover": {
            backgroundColor: 'rgb(203 213 225)'
          }
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            color: "#fff",
            width: "22px",
            height: "22px",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            top: "-5px",
            left: "-10px",
            backgroundColor: "#0b6699",
          }}
        >
          {orderDetail?.number}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", paddingLeft: 1 }}>
          <OrderInfo routes={orderDetail?.routes} order={orderDetail} isOpenRoutePage={true} handleRouteOrderingModalOpen={handleRouteOrderingModalOpen} userDetail={userDetail} />
          <DeliveryNotes orderDetail={orderDetail} isOpenRoutePage={true} handleUpdateDeliveryNote={handleUpdateDeliveryNote} isRoute={true} />
        </Box>
      </Box>
    </Box>
  );
};

export default RouteDetails;
