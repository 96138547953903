import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import { postApiWithAuth, getApiWithAuth, deleteApiWithAuth } from "../../utils/api";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ContainedButton } from "../buttonCompo/Buttons";
import { ADD_NOTIFICATION, NOTIFICATION_LIST,updateNotification} from "../../utils/apiUrl";
const NotificationForm = () => {
  const [NotificationData, setNotificationData] = useState({
    heading: "",
    description: "",
    end_date: null,
  });

  const [notifications, setNotifications] = useState([]);

  const handleInputChange = (field, value) => {
    setNotificationData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleDeleteNotification = async (id) => {
    const response = await deleteApiWithAuth(`${updateNotification}/${id}/delete/`);
    console.log(response)
    if (response.status !== 400) {
      toast.success('Notification Deleted Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
        fetchNotifications();
    } 
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };
  const fetchNotifications = async () => {
    // const validEndDate =
    //   dateRange[1] && !isNaN(dateRange[1].getTime())
    //     ? dateRange[1]
    //     : new Date();
    try {
      const response = await getApiWithAuth(NOTIFICATION_LIST);
      setNotifications(response.data.data);
    } catch (error) {
      console.error("Error fetching facility data:", error);
    }
  };
  useEffect(() => {
    fetchNotifications();
  }, []);
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (
      !NotificationData.heading ||
      !NotificationData.description ||
      !NotificationData.end_date
    ) {
      alert("Please fill in all fields.");
      return;
    }
    const formattedEndDate = NotificationData.end_date
      ? new Date(NotificationData.end_date).toISOString().split("T")[0]
      : null;
    const formData = new FormData();
    formData.append("heading", NotificationData.heading);
    formData.append("end_date", formattedEndDate);
    formData.append("description", NotificationData.description);
    try {
      const response = await postApiWithAuth(ADD_NOTIFICATION, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("responsssssssssssssssssss", response);
      if (response.data.status === 201) {
        toast.success("Notification Added successfully!");
        fetchNotifications();
      }
    } catch (error) {
      toast.success("An Error Occured!");
      console.error("Error adding expense:", error);
    }
    // setNotifications((prevNotifications) => [
    //   ...prevNotifications,
    //   { ...NotificationData, id: prevNotifications.length + 1 },
    // ]);

    // Reset the form
    setNotificationData({
      heading: "",
      description: "",
      end_date: null,
    });
  };

  return (
    <Box
      sx={{
        padding: "24px",
        borderRadius: "8px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        maxWidth: "full",
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: "#1E88E5",
        }}
      >
        Create Notification
      </Typography>

      {/* Form Section */}
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, flex: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 3, flex: 2 }}>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Heading
              </Typography>
              <TextField
                value={NotificationData.heading}
                onChange={(e) => handleInputChange("heading", e.target.value)}
                type="text"
                autoComplete="off"
                placeholder="Heading"
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px", // Decrease font size for input text
                    padding: "8px 12px", // Adjust padding
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "39px", // Set height to 36px
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px", // Decrease font size for the label
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563", // Set placeholder color to light gray
                  },
                }}
              />
            </Box>
            {/* <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Created Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={NotificationData.createdDate}
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(newValue) =>
                    handleInputChange("createdDate", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontSize: "10px",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                          height: "36px",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box> */}
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                End Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={NotificationData.end_date}
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(newValue) =>
                    handleInputChange("end_date", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      autoComplete="off"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Description
            </Typography>
            <TextField
              multiline
              rows={4}
              autoComplete="off"
              fullWidth
              value={NotificationData.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#fff",
                },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 3, flex: 1 }}
        ></Box>
      </Box>
      <Box sx={{ textAlign: "end" }}>
        <ContainedButton
          onClick={handleFormSubmit}
          label="Submit Notifications"
        />
      </Box>

      {/* Table Section */}
      {notifications.length > 0 && (
        <TableContainer component={Paper} sx={{ marginTop: "24px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Heading</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Description</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Created Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>End Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map((notification) => (
                <TableRow key={notification.id}>
                  <TableCell>{notification.heading}</TableCell>
                  <TableCell>{notification.description}</TableCell>
                  <TableCell>
                    {notification?.create_date
                      ? moment(notification.create_date).format("DD/MM/YYYY")
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {notification?.end_date
                      ? moment(notification.end_date).format("DD/MM/YYYY")
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => handleDeleteNotification(notification.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default NotificationForm;
