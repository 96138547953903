import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Topbar from "../components/pharmacyemployeePageCompo/Topbar";
import PharmacyEmployeeOverview from "../components/pharmacyemployeePageCompo/PharmacyEmployeeOverview";
import { getApiWithAuth } from "../utils/api";
import { ALL_USERS } from "../utils/apiUrl";

const AddPharmacyEmployeePage = () => {
  const [pharmacyEmployees, setPharmacyEmployees] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const pid = localStorage.getItem("pharmacyId");

  // Fetch drivers from API
  useEffect(() => {
    const getPharmacyEmployees = async () => {
      setLoading(true);
      const response = await getApiWithAuth(ALL_USERS+`all?pharmacy_id=${pid}&pharmacy=true`);
      console.log("response.data", response)
        setPharmacyEmployees(response.data.data);
        setFilteredDrivers(response.data.data);
        setLoading(false);
    };
    getPharmacyEmployees();
  }, []);

  // Search function to filter drivers
  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = pharmacyEmployees.filter(
      (pharmacyEmployees) =>
        pharmacyEmployees.firstName.toLowerCase().includes(lowerCaseQuery) ||
        pharmacyEmployees.lastName.toLowerCase().includes(lowerCaseQuery) ||
        pharmacyEmployees.cellNumber.includes(query) // Match phone numbers exactly
    );
    setFilteredDrivers(filtered);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error.message}</Typography>;
  }

  if (!Array.isArray(filteredDrivers)) {
    return <Typography>Error: Unexpected data format</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Topbar onSearch={handleSearch} /> {/* Pass the search handler */}
      {filteredDrivers.map((driver, index) => (
        <PharmacyEmployeeOverview
          key={driver.id}
          driver={driver}
          index={index}
          showActive={driver.is_active}
        />
      ))}
    </Box>
  );
};

export default AddPharmacyEmployeePage;
