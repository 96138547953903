import { Box } from "@mui/material";
import React, {useState,useEffect} from "react";
import Topbar from "../../components/zonePageCompo/subPages/Topbar";
import NumberedItems from "../../components/zonePageCompo/subPages/NumberedItems";
import { getApiWithAuth } from "../../utils/api";
import { ALL_ZONES_LIST } from "../../utils/apiUrl";
import { useLocation } from 'react-router-dom';
const ZoneDetailPage = () => {
  const location = useLocation();
  const { zoneId } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [zone, setZone] = useState([]);
  const getZips = async () => {
    setLoading(true);
    const response = await getApiWithAuth(ALL_ZONES_LIST + `ZonemdList/${zoneId}`);
    if (response.data !== undefined) {
      setZone(response.data.data)
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getZips();
  }, []);
  return (
    <Box>
      <Topbar zone={zone} getZips={getZips} zid={zoneId}/>
      <NumberedItems zone={zone} getZips={getZips} setZone={setZone} />
    </Box>
  );
};

export default ZoneDetailPage;
