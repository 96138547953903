import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Topbar from "../components/driversPageCompo/Topbar";
import DriverOverview from "../components/driversPageCompo/DriverOverview";
import { getApiWithAuth } from "../utils/api";
import { ALL_DRIVERS } from "../utils/apiUrl";
import AnnouncementPopup from "../components/Ui/AnnouncementPopup";

const AddDriverPage = () => {
  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch drivers from API
  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await getApiWithAuth(ALL_DRIVERS);
        if (response && Array.isArray(response.data.data)) {
          setDrivers(response.data.data);
          setFilteredDrivers(response.data.data);
        } else {
          console.error("Unexpected data format:", response);
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching drivers:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchDrivers();
  }, []);

  // Search function to filter drivers
  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();

    const filtered = drivers.filter((driver) => {
      // Check if the value is null or undefined before calling .toLowerCase() or .includes()
      const firstNameMatch = driver.firstName
        ? driver.firstName.toLowerCase().includes(lowerCaseQuery)
        : false;

      const lastNameMatch = driver.lastName
        ? driver.lastName.toLowerCase().includes(lowerCaseQuery)
        : false;

      const cellNumberMatch = driver.cellNumber
        ? driver.cellNumber.includes(query)
        : false;

      return firstNameMatch || lastNameMatch || cellNumberMatch;
    });

    setFilteredDrivers(filtered);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error.message}</Typography>;
  }

  if (!Array.isArray(filteredDrivers)) {
    console.error("Drivers data is not an array:", filteredDrivers);
    return <Typography>Error: Unexpected data format</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Topbar onSearch={handleSearch} /> {/* Pass the search handler */}
      {filteredDrivers.map((driver, index) => (
        <DriverOverview
          key={driver.id}
          driver={driver}
          index={index}
          showActive={driver.isactive}
        />
      ))}
    </Box>
  );
};

export default AddDriverPage;
