import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Tooltip,
  Modal,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  AddressIcon,
  MilesIcon,
  PersonIcon,
  StopsIcon,
  TruckIcon,
  EditableIcon,
  DropDownArrowIcon,
  OpenRouteIcon,
  EditRouteIcon,
  SplitRouteIcon,
  MergeRouteIcon,
  RescheduleIcon,
  CopayStopsIcon,
  DriverNoteIcon,
  PrintRouteIcon,
  DeleteRouteIcon,
  RouteHistoryIcon,
  CalendarReportIcon,
} from "../Icons";
import { useTheme } from "@mui/material/styles";
import { useReactToPrint } from "react-to-print";
import ConfirmationDialog from "../Ui/ConfirmationDialog";
import {
  patchApiWithAuth,
  getApiWithAuth,
  deleteApiWithAuth,
} from "../../utils/api";
import { ALL_ROUTES, GET_ROUTE_HISTORY, SPLIT_ROUTE, ROUTE_DETAIL} from "../../utils/apiUrl";
import CustomDropdown from "../CustomDropdown";
import HistoryComponent from "../homePageCompo/popups/HistoryComponent";
import RouteSlip from "../PDF/RouteSlip";
import RouteSlipReturn from "../PDF/RouteSlipReturn";


function floatToTime(floatValue) {
  const hours = Math.floor(floatValue / 60);
  const remainingMinutes = floatValue % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes % 1) * 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} H`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} Min`);
  }

  return parts.join(" ");
}

const RouteDetails = ({ route, onMergeClick, driverList, getRoutes }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [driver, setDriver] = useState(null);
  const [routeDriver, setRouteDriver] = useState(route?.driver);
  const [doneCount, setDoneCount] = useState(13);
  const [failCount, setFailCount] = useState(3);
  const [openDialogDriver, setOpenDialogDriver] = useState(false);
  const [openDialogSplit, setOpenDialogSplit] = useState(false);
  const [openDialogRouteName, setOpenDialogRouteName] = useState(false);
  const [openDialogReschedule, setOpenDialogReschedule] = useState(false);
  const [openDialogRouteDelete, setOpenDialogRouteDelete] = useState(false);
  const [pendingCount, setPendingCount] = useState(4);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [history, setHistory] = useState(null);
  const [routeOrders, setRouteOrders] = useState([]);
  const componentRef2 = useRef();
  const componentRef3 = useRef();

  console.log(route, "saasdsadas")
  const menuItems = [
    { label: "Open Route", icon: <OpenRouteIcon />, route: "/routes/open-route" },
    { label: "Edit route", icon: <EditRouteIcon />, route: "/routes/edit-route" },
    {
      label: "Split route",
      icon: <SplitRouteIcon />,
      route: "/routes/split-route",
    },
    {
      label: "Merge Route",
      icon: <MergeRouteIcon />,
      route: "/routes/merge-route",
    },
    {
      label: "Reschedule",
      icon: <RescheduleIcon />,
      route: "/routes/reschedule",
    },
    {
      label: "Copay Stops",
      icon: <CopayStopsIcon />,
      route: "/routes/copay-stops",
    },
    {
      label: "Driver notes",
      icon: <DriverNoteIcon />,
      route: "/routes/driver-notes",
    },
    {
      label: "Print Route Slip",
      icon: <PrintRouteIcon />,
      route: "/routes/print-route-slip",
    },
    (route.routeStatus === "Ready") && {
      label: "Delete Route",
      icon: <DeleteRouteIcon />,
      route: "/routes/delete-route",
    },
    {
      label: "Route History",
      icon: <RouteHistoryIcon />,
      route: "/routes/route-history",
    },
  ].filter(Boolean);


  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });
  const handlePrintSlipreturn = useReactToPrint({
    content: () => componentRef3.current,
  });
  const getRouteDetail = async (rid) => {
    const response = await getApiWithAuth(ROUTE_DETAIL + `?route=${rid}`);
    if (response.data !== undefined) {
      setRouteOrders(response.data.data.orders);
    } else {
      console.log("Error")
    }
  };
  useEffect(() => {
    if (route && route.type === "Order") {
      handlePrintSlip();
    } else if (route && route.type === "Pharmacy") {
      handlePrintSlipreturn();
    }
  }, [routeOrders, route]);
  const handleHistoryClose = () => {
    setHistoryModalOpen(false);
  };

  const handleCreateHistory = async () => {
    const response = await getApiWithAuth(GET_ROUTE_HISTORY + `${route.id}/`);
    console.log("history response", response.data.data);
    if (response.data !== undefined) {
      setHistory(response.data.data);
      setHistoryModalOpen(true);
    } else {
      console.log("error");
    }
  };

  const [formState, setFormState] = useState({
    detail_name: "",
  });

  const [formStateDate, setFormStateDate] = useState({
    createdAt: "",
  });

  useEffect(() => {
    setDoneCount(route.totalDelivered);
    setFailCount(route.totalFailed);
    setPendingCount(route.totalPending);
  }, [route]);

  const totalOrders = doneCount + failCount + pendingCount;

  const donePercentage = (doneCount / totalOrders) * 100;
  const failPercentage = (failCount / totalOrders) * 100;
  const pendingPercentage = (pendingCount / totalOrders) * 100;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleInputChangeDate = (e) => {
    const { name, value } = e.target;
    setFormStateDate({
      ...formState,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  const handleMenuItemClick = (item) => {
    if (item.label === "Merge Route") {
      onMergeClick();
    } else if (item.label === "Split route") {
      setOpenDialogSplit(true);
    } else if (item.label === "Reschedule") {
      handleOpenDialogReschedule();
    } else if (item.label === "Print Route Slip") {
      getRouteDetail(route.id);
    } 
     else if (item.label === "Delete Route") {
      setOpenDialogRouteDelete(true);
    } else if (item.label === "Open Route") {
      window.open(`${item.route}/${route.id}`, "_blank");
    } else {
      navigate(item.route);
    }
    handleActionsClose();
  };

  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const formControlStyle = {
    minWidth: 120,
    height: 38,
    "& .MuiInputBase-root": {
      height: 38,
      display: "flex",
      alignItems: "center",
    },
  };

  const handleCloseDialogDriver = () => {
    setOpenDialogDriver(false);
  };

  const handleCloseDialogSplit = () => {
    setOpenDialogSplit(false);
  };

  const handleCloseDialogRouteDelete = () => {
    setOpenDialogRouteDelete(false);
  };

  const handleCloseDialogRouteName = () => {
    setOpenDialogRouteName(false);
    setFormState({
      ...formState,
      detail_name: "",
    });
  };

  const handleCloseDialogReschedule = () => {
    setOpenDialogReschedule(false);
    setFormStateDate({
      ...formState,
      createdAt: "",
    });
  };

  const handleOpenDialogRouteName = () => {
    setOpenDialogRouteName(true);
    setFormState({
      ...formState,
      detail_name: route?.detail_name,
    });
  };

  const handleOpenDialogReschedule = () => {
    setOpenDialogReschedule(true);
    setFormStateDate({
      ...formStateDate,
      createdAt: route?.createdAt,
    });
  };

  const handleDriverAssign = async () => {
    try {
      const response = await patchApiWithAuth(
        `${ALL_ROUTES}${route.id}/update_driver/`,
        { driver_id: driver.id }
      );
      if (response.data) {
        setOpenDialogDriver(false);
        setRouteDriver(driver);
        setDriver(null);
      }
    } catch (error) {
      setOpenDialogDriver(false);
      setDriver(null);
    }
  };

  const handleUpdateDetailName = async () => {
    try {
      const response = await patchApiWithAuth(
        `${ALL_ROUTES}${route.id}/update_detail_name/`,
        formState
      );
      if (response.data) {
        setOpenDialogRouteName(false);
        toast.success(`Route detail name updated successfully!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getRoutes();
        setFormState({
          ...formState,
          detail_name: "",
        });
      }
    } catch (error) {
      toast.error(`Backend Error during assign driver!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setOpenDialogRouteName(false);
      setFormState({
        ...formState,
        detail_name: "",
      });
    }
  };

  const handleSplitRoute = async () => {
    setOpenDialogSplit(false);
    const routeOrderResponse = await getApiWithAuth(
      SPLIT_ROUTE + `${route.id}/`
    );
    if (routeOrderResponse.data.status === 201) {
      toast.success(`Route split successfully!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
    } else if (routeOrderResponse.data.status === 200) {
      toast.error(routeOrderResponse.data.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getRoutes();
    } else {
      toast.error(`Backend Error!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleUpdateDate = async () => {
    try {
      const response = await patchApiWithAuth(
        `${ALL_ROUTES}${route.id}/reschedule/`,
        formStateDate
      );
      if (response.data) {
        toast.success(`Route detail name updated successfully!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(`Backend Error during assign driver!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    getRoutes();
    setOpenDialogReschedule(false);
    setFormStateDate({
      ...formStateDate,
      createdAt: "",
    });
  };

  const handleDeleteRoute = async () => {
    try {
      const response = await deleteApiWithAuth(`${ALL_ROUTES}${route.id}/`);
      if (response.data) {
        toast.success(`Route deleted successfully!`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(`Backend Error during delete route!`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    getRoutes();
    setOpenDialogRouteDelete(false);
  };

  return (
    <>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: 1,
        p: 1,
        mb: 0.5,
        borderRadius: "10px",
        backgroundColor: "#fff",
        boxShadow: 1,
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f0f0f0",
          boxShadow: 1,
        },
      }}
    >
      {/* Left Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box sx={{ display: "flex", flex: 1, minWidth: "150px", gap: 1 }}>
          <Box
            onClick={handleOpenDialogRouteName}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              border: "1px solid #919eab",
              borderRadius: "10px",
              height: "38px",
              width: "250px",
              paddingX: "5px",
              overflow: "hidden",
            }}
          >
            <AddressIcon />
            <Tooltip title={route?.detail_name || ""} placement="top" arrow>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "11px",
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flex: 1,
                  cursor: "pointer",
                }}
              >
                {route?.detail_name}
              </Typography>
            </Tooltip>
            <IconButton size="small" sx={{ paddingLeft: 1 }}>
              <EditableIcon />
            </IconButton>
          </Box>

          <CustomDropdown
            customstyles={{
              width: "180px",
            }}
            label="Select Driver"
            value={routeDriver?.id}
            search={true}
            onChange={(selectedValue) => {
              setDriver(driverList.find((zone) => zone.id === selectedValue));
              setOpenDialogDriver(true);
            }}
            options={driverList?.map((drv) => ({
              value: drv.id,
              label: drv.name,
            }))}
            disabled={
              route?.routeStatus !== "Start" &&
              route?.routeStatus !== "Ready" &&
              routeDriver
            }
            iconSrc="/icons/Dropdown.svg"
            isMultiSelect={false}
          />
          <Button
            variant="contained"
            size="small"
            sx={{
              textTransform: "none",
              backgroundColor: `${
                route?.routeStatus === "Ready"
                  ? "#00b8d9"
                  : route?.routeStatus === "Done"
                  ? "#22C55E"
                  : "#FFAB00"
              }`,
              borderRadius: "10px",
              color: "#fff",
              height: "36px",
              fontSize: "13px",
              fontWeight: 500,
              boxShadow: "none",
            }}
          >
            {route?.routeStatus}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 1.5,
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              border: "1px solid #919eab",
              borderRadius: "10px",
              padding: 0.5,
              width: "106px",
              height: "36px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "11px", color: "#000", fontWeight: 700 }}
            >
              <span style={{ color: "#0094ff" }}>ETC:</span>{" "}
              {floatToTime(route.estimatedTimeCal)}
            </Typography>
            {route.routeStatus === "Done" && (
              <Typography
                variant="body2"
                sx={{ fontSize: "11px", color: "#000", fontWeight: 700 }}
              >
                <span style={{ color: "#0d4df2" }}>ATC:</span>{" "}
                {floatToTime(route.completedTimeCal)}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              border: "1px solid #919eab",
              borderRadius: "10px",
              padding: "0 0px",
              gap: 0.5,
              height: "32px",
              width: "157px",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "11px",
              }}
            >
              <StopsIcon />
              Stops {route?.totalOrderStop}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "11px",
              }}
            >
              <MilesIcon />
              {(parseFloat(route?.distanceCal) * 0.621371).toFixed(2)} mi
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              border: "1px solid #919eab",
              alignItems: "center",
              justifyContent: "center",
              height: "32px",
              width: "178px",
              borderRadius: "10px",
              padding: "0 4px",
            }}
          >
            <Typography sx={{ fontSize: "11px", color: "#05b321" }}>
              Done: {route?.totalDelivered}
            </Typography>
            <Typography sx={{ fontSize: "11px", color: "#fb1001" }}>
              Failed: {route?.totalFailed}
            </Typography>
            <Typography sx={{ fontSize: "11px", color: "#fe9604" }}>
              Pending: {route?.totalPending}
            </Typography>
          </Box>
          <Box>
            {/* Actions Button */}
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "none",
                backgroundColor: "#424242",
                color: "#fff",
                borderRadius: "8px",
                width: "82px",
                height: "32px",
                fontSize: "11px",
                padding: "5px 5px",
              }}
              endIcon={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    opacity: 0.8,
                  }}
                >
                  <DropDownArrowIcon />
                </Box>
              }
              onClick={handleActionsClick}
            >
              Actions
            </Button>

            {/* Actions Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleActionsClose}
              MenuListProps={{ disablePadding: true }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(0,0,0,0.1)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      if (item.label === "Route History") {
                        handleCreateHistory();
                      } else {
                        handleMenuItemClick(item);
                      }
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      width: "100%",
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.2)",
                      },
                    }}
                  >
                    {item.icon}
                    <Typography
                      variant="body2"
                      sx={{
                        flex: 1,
                      }}
                    >
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            gap: 1,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f2f3f5",
              borderRadius: "8px",
              p: 1,
              display: "flex",
              alignItems: "center",
              gap: 1,
              height: "40px",
              width: "250px",
            }}
          >
            <Tooltip title={route?.name || ""} placement="bottom" arrow>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "11px",
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flex: 1,
                  cursor: "pointer",
                }}
              >
                {route?.name}
              </Typography>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              minWidth: "200px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                borderRadius: "8px",
                height: "38px",
                width: "180px",
                padding: "4px 10px",
                border: "1px solid #919eab",
                color: "#000",
                gap: 1,
              }}
            >
              <PersonIcon />
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontSize: "11px" }}
              >
                {route?.createdby}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "38px",
                width: "180px",
                alignItems: "center",
                textTransform: "none",
                borderRadius: "8px",
                padding: "0px 10px",
                border: "1px solid #919eab",
                color: "#000",
                gap: 1,
              }}
            >
              <CalendarReportIcon />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 700, fontSize: "11px" }}
                >
                  Created At:
                </Typography>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 500 }}
                  >
                    {moment(route.createdAt).format("MM-DD-YYYY")}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "11px", fontWeight: 500 }}
                  >
                    {moment(route.createdAt).format("hh:mm:ss A")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          {route.totalRouteOrderTotalCopay !== 0 &&
            route.totalRouteOrderTotalCopay !== null && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "none",
                  width: "88px",
                  height: "32px",
                  borderRadius: "8px",
                  border: "1px solid #919eab",
                  color: "#000",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 500, fontSize: "11px" }}
                >
                  Copay ${route.totalRouteOrderTotalCopay.toFixed(2)}
                </Typography>
              </Box>
            )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              flexDirection: "column",
              textTransform: "none",
              padding: "4px 16px",
              color: "#000",
              height: "32px",
            }}
          >
            {route.totalCollectedTotalCopay !== 0 &&
              route.totalCollectedTotalCopay !== null && (
                <p className="route-copay-txt">
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 500, fontSize: "11px" }}
                  >
                    Collected: ${route.totalCollectedTotalCopay.toFixed(2)}
                  </Typography>
                </p>
              )}
            {route.totalPaidPhamracyTotalCopay !== 0 &&
              route.totalPaidPhamracyTotalCopay !== null && (
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 500, fontSize: "11px" }}
                >
                  Received: ${route.totalPaidPhamracyTotalCopay.toFixed(2)}
                </Typography>
              )}
          </Box>
          <Box
            sx={{
              color: "#fff",
              borderRadius: "8px",
              width: "300px",
              height: "34px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            {donePercentage > 0.0 && (
              <Box
                sx={{
                  backgroundColor: "#06B322",
                  width: `${donePercentage}%`, // Set width based on percentage
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                >
                  Done: {donePercentage.toFixed(2)}%
                </Typography>
              </Box>
            )}
            {failPercentage > 0.0 && (
              <Box
                sx={{
                  backgroundColor: "#fc1002",
                  width: `${failPercentage}%`, // Set width based on percentage
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                >
                  Failed: {failPercentage.toFixed(2)}%
                </Typography>
              </Box>
            )}
            {pendingPercentage > 0.0 && (
              <Box
                sx={{
                  backgroundColor: "#ff9800",
                  width: `${pendingPercentage}%`, // Set width based on percentage
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                >
                  Pending: {pendingPercentage.toFixed(2)}%
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <ConfirmationDialog
        open={openDialogDriver}
        title="Assign Driver"
        description={`Are you sure you want to assign Driver: ${driver?.name} to this route?`}
        onConfirm={handleDriverAssign}
        onCancel={handleCloseDialogDriver}
      />
      <ConfirmationDialog
        open={openDialogSplit}
        title="Split Route Confirmation"
        description={`Are you sure you want to split that route?`}
        onConfirm={handleSplitRoute}
        onCancel={handleCloseDialogSplit}
      />

      <ConfirmationDialog
        open={openDialogRouteDelete}
        title="Delete Route Confirmation"
        description={`Are you sure you want to delete that route?`}
        onConfirm={handleDeleteRoute}
        onCancel={handleCloseDialogRouteDelete}
      />

      <Dialog
        open={openDialogRouteName}
        onClose={handleCloseDialogRouteName}
        aria-labelledby="confirmation-dialog-title"
        PaperProps={{
          style: {
            width: "400px",
            height: "250px",
            borderRadius: "15px",
          },
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Typography variant="h6">Update Route Name</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            size="large"
            name="detail_name"
            autoComplete="off"
            value={formState.detail_name}
            onChange={handleInputChange}
            sx={{
              marginBottom: "8px",
              fontSize: "10px",
              "& .MuiInputBase-root": {
                fontSize: "10px",
              },
            }}
            placeholder="Route Name"
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginX: "30px",
            marginY: "15px",
          }}
        >
          <Button
            onClick={handleCloseDialogRouteName}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateDetailName}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogReschedule}
        onClose={handleCloseDialogReschedule}
        aria-labelledby="confirmation-dialog-title"
        PaperProps={{
          style: {
            width: "400px",
            height: "250px",
            borderRadius: "15px",
          },
        }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Typography variant="h6">Update Route Name</Typography>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <input
              type="date"
              autoComplete="off"
              className="facility-field"
              name="createdAt"
              placeholder="YYYY/MM/DD"
              value={formStateDate.createdAt}
              onChange={handleInputChangeDate}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginX: "30px",
            marginY: "15px",
          }}
        >
          <Button
            onClick={handleCloseDialogReschedule}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateDate}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.custom.layoutBgColor,
              color: theme.palette.custom.layoutFontColor,
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={historyModalOpen}
        onClose={handleHistoryClose}
        aria-labelledby="history-modal"
        aria-describedby="history-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 0, // Distance from the bottom of the screen
            right: 0,
            // transform: "translate(-50%, -50%)",
            width: 650,
            bgcolor: "background.paper",
            backgroundColor: "#f4f6f8",
            boxShadow: 24,
            overflowY: "auto",
            p: 2,
          }}
        >
          <HistoryComponent
            isRoutes="true"
            orderHistory={history}
            onClose={handleHistoryClose}
          />
        </Box>
      </Modal>
    </Box>
    {route.type === "Order" && (
      <div style={{ display: 'none' }}>
        {routeOrders && routeOrders.length > 0 &&
          <div ref={componentRef2}>
            <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', paddingTop: '10px', pageBreakBefore: 'always', pageBreakAfter: 'always', pageBreakInside: 'avoid' }} >
              <RouteSlip routeOrders={routeOrders} route={route} />
            </div>
          </div>
        }
      </div>
    )}
    {route.type === "Pharmacy" && (
      <div style={{ display: 'none' }}>
        {routeOrders && routeOrders.length > 0 &&
          <div ref={componentRef3}>
            <div>
              <RouteSlipReturn routeOrders={routeOrders} route={route} />
            </div>
          </div>
        }
      </div>
    )}
    </>
  );
};

export default RouteDetails;
