import { Box, TextField, IconButton, Popover, Button } from "@mui/material";
import React from "react";
import SingleFieldDateRangePicker from "../dateRangePicker/DateRangePicker";
import CustomDropdown from "../CustomDropdown";
import { SearchIcon, SettingsDarkIcon } from "../Icons";
import DefaultRouteSetting from "./DefaultRouteSetting";
import { ContainedButton } from "../buttonCompo/Buttons";

const TopBar = ({
  boroughList,
  zoneList,
  driverList,
  activeDriverList,
  dateRange,
  onBoroughChange,
  onZoneChange,
  onDriverChange,
  handleDateChange,
  handleSearchKeyDown,
  handleMergeRoute,
  mergeCheck,
  handleMergeCheck,
  handleSaveSettings,
  formData,
  setFormData,
}) => {
  const [city, setCity] = React.useState("");
  const [zones, setZones] = React.useState("");
  const [driver, setDriver] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSettingsChange = (field, value) => {
    setFormData((prevSettings) => ({
      ...prevSettings,
      [field]: value,
    }));
  };

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "5px",
      }}
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Search"
          autoComplete="off"
          onKeyDown={handleSearchKeyDown}
          InputProps={{
            sx: {
              fontSize: "11px",
              border: "1px solid #919eab",
            },
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={{
            minWidth: 250,
            backgroundColor: "#fff",
            "& .MuiOutlinedInput-root": {
              borderRadius: "7.21px",
              height: "36px",
              paddingLeft: 0,
              "& fieldset": {
                border: "none",
              },
            },
          }}
          size="small"
        />
        {/* Select City Dropdown */}
        <CustomDropdown
          customstyles={{
            width: "137px",
          }}
          label="Select City"
          value={city}
          onChange={(selectedValue) => {
            setCity(selectedValue);
            onBoroughChange(selectedValue);
          }}
          options={boroughList.map((boro) => ({
            value: boro.id,
            label: boro.name,
          }))}
          iconSrc="/icons/Dropdown.svg"
          isMultiSelect={false}
        />

        {/* Select Zone Dropdown */}
        <CustomDropdown
          customstyles={{
            width: "137px",
          }}
          label="Select Zone"
          value={zones}
          onChange={(selectedValue) => {
            setZones(selectedValue);
            onZoneChange(selectedValue);
          }}
          options={zoneList.map((zon) => ({
            value: zon.id,
            label: zon.name,
          }))}
          iconSrc="/icons/Dropdown.svg"
          isMultiSelect={false}
        />

        {/* Select Driver Dropdown */}
        <CustomDropdown
          customstyles={{
            width: "137px",
          }}
          label="Select Driver"
          value={driver}
          search={true}
          onChange={(selectedValue) => {
            setDriver(selectedValue);
            onDriverChange(selectedValue);
          }}
          options={driverList.map((drv) => ({
            value: drv.id,
            label: drv.name,
          }))}
          iconSrc="/icons/Dropdown.svg"
          isMultiSelect={false}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {mergeCheck && (
          <>
            {/* <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "none",
                backgroundColor: "#00b8d9",
                borderRadius: "10px",
                color: "#fff",
                height: "36px",
                fontSize: "13px",
                fontWeight: 500,
                boxShadow: "none",
              }}
            >
              
            </Button> */}

            <ContainedButton
              label="Cancel Merge"
              borderRadius="8px"
              onClick={handleMergeCheck}
              margin="0 10px 0 0"
              sx={{
                backgroundColor: "#fb1001",
                color: "white",
                borderRadius: "8px",
                padding: "6px 12px",
                minWidth: "auto",
                fontSize: "12px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#fb1001",
                },
              }}
            />
            <ContainedButton
              label="Merge Route"
              borderRadius="8px"
              onClick={handleMergeRoute}
              sx={{
                backgroundColor: "#0B6699",
                color: "white",
                borderRadius: "8px",
                padding: "6px 12px",
                minWidth: "auto",
                fontSize: "12px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#08517B",
                },
              }}
            />
          </>
        )}

        <SingleFieldDateRangePicker
          value={dateRange}
          onChange={handleDateChange}
        />
        <IconButton onClick={handleSettingsClick}>
          <SettingsDarkIcon />
        </IconButton>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleSettingsClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box p={2} sx={{ paddingRight: 4 }}>
            <DefaultRouteSetting
              onClose={handleSettingsClose}
              handleSaveSettings={handleSaveSettings}
              formData={formData}
              onChange={handleSettingsChange}
              isListPage={true}
            />
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default TopBar;
