import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  ContainedButton,
  ContainedButtonWithIcon,
} from "../../buttonCompo/Buttons";
import { ExcelRenderer } from "react-excel-renderer";
import { AddIcon } from "../../Icons";
import { postApiWithAuth, putApiWithAuth } from "../../../utils/api";
import { ALL_ZONES_LIST } from "../../../utils/apiUrl";
const EXTENSIONS = ["xlsx", "xls", "csv", "html", "sql"];
function getExtension(filename) {
  return EXTENSIONS.some((item) => filename.split(".").pop() === item);
}
const Topbar = ({ zone, getZips, zid }) => {
  const [edit, setEdit] = useState(false); // Edit mode
  const [open, setOpen] = useState(false); // Modal visibility
  const [formState, setFormState] = useState({
    zipcode: "",
    zone: zone?.id,
  });
  const UploadData = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      toast.error("No file selected!", {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    }

    if (getExtension(selectedFile.name)) {
      ExcelRenderer(selectedFile, async (err, resp) => {
        if (err) {
          console.error("Error reading file:", err);
          toast.error("Error reading file!", {
            position: "top-right",
            autoClose: 1000,
            theme: "colored",
          });
        } else {
          const data = resp.rows;
          const dataWithZipId = data.map((row) => ({ ...row, zip_id: zid }));

          try {
            const response = await postApiWithAuth(
              `${ALL_ZONES_LIST}process_data`,
              JSON.stringify({ dataWithZipId })
            );

            if (response.status === 404) {
              toast.error(response.data.message, {
                position: "top-right",
                autoClose: 1000,
                theme: "colored",
              });
            } else if (
              response.data.data &&
              response.data.data[0].status === "already exist"
            ) {
              toast.error("Zip Already Exist", {
                position: "top-right",
                autoClose: 1000,
                theme: "colored",
              });
            } else {
              toast.success("Zip Added Successfully!", {
                position: "top-right",
                autoClose: 1000,
                theme: "colored",
              });
            }

            getZips(); // Refresh the list of zip codes
          } catch (error) {
            console.error("Error uploading data:", error);
            toast.error("Error processing file!", {
              position: "top-right",
              autoClose: 1000,
              theme: "colored",
            });
          }
        }
      });
    } else {
      toast.error("Selected file type is incorrect!", {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
    }
  };
  const [editId, setEditId] = useState(null); // To track the ID being edited
  const handleClickOpen = () => {
    setOpen(true);
    setEdit(false); // Ensure add mode is set by default
    setFormState({
      zipcode: "",
      zone: zone?.id,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setFormState({
      zipcode: "",
      zone: zone?.id,
    });
    setEdit(false);
    setEditId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSaveZone = async () => {
    if (!formState.zipcode) {
      toast.error("Zip Code cannot be empty!", {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    }

    if (!edit) {
      // Add Mode
      if (!zone.zipcode.some((item) => item.zipcode === formState.zipcode)) {
        const response = await postApiWithAuth(
          `${ALL_ZONES_LIST}ZipmdList/`,
          formState
        );
        if (response.status !== 400) {
          toast.success("Zip Code Created Successfully!", {
            position: "top-right",
            autoClose: 1000,
            theme: "colored",
          });
        } else {
          toast.error(response.data[0] || "Error adding Zip Code!", {
            position: "top-right",
            autoClose: 1000,
            theme: "colored",
          });
        }
      } else {
        toast.error(
          `Zipcode already exists in zone ${zone.name} and city ${zone.borro.name}`,
          { position: "top-right", autoClose: 1000, theme: "colored" }
        );
      }
    } else {
      // Edit Mode
      const response = await putApiWithAuth(
        `${ALL_ZONES_LIST}ZipmdList/${editId}/update`,
        formState
      );
      if (response.status !== 400) {
        toast.success("Zip Code Edited Successfully!", {
          position: "top-right",
          autoClose: 1000,
          theme: "colored",
        });
      } else {
        toast.error(response.data[0] || "Error updating Zip Code!", {
          position: "top-right",
          autoClose: 1000,
          theme: "colored",
        });
      }
    }

    handleClose();
    getZips(); // Refresh the zip list
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", marginBottom: 1 }}>
        <Box sx={{ flex: 1 }}></Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "space-between" }}>
          {/* <ContainedButton
            label="Add Zip"
            border="none"
            width="72px"
            height="32px"
            borderRadius="8px"
          /> */}
          <button
            style={{
              backgroundColor: "#126599",
              color: "#fff",
              padding: "8px 12px",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              fontSize: "14px",
            }}
            onClick={() => document.getElementById("upload-csv").click()} // Trigger file input click
          >
            Upload CSV
          </button>

          <input
            id="upload-csv"
            type="file"
            accept=".xls,.xlsx"
            style={{ display: "none" }}
            onChange={UploadData}
          />
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
            height: "315px",
            padding: "13px",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
          {edit ? "Edit Zip Code" : "Add Zip Code"}
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 0,
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="zipcode"
            name="zipcode"
            label="Zip Code"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            value={formState.zipcode}
            onChange={handleInputChange}
            sx={{ width: "100px" }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <ContainedButton
            label="Save"
            border="none"
            borderRadius="8px"
            onClick={handleSaveZone}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Topbar;
