import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// 1. Regular Contained Button (Example: Save, Edit Route)
export const ContainedButton = ({
  label,
  onClick,
  fontWeight = 400,
  fontSize = "11px",
  width = "auto",
  height = "32px",
  borderRadius = "5px",
  border,
  bgColor, // bgColor will be set inside the function
  textColor, // textColor will be set inside the function
  disabled = false,
  margin,
}) => {
  const theme = useTheme(); // Access the theme

  return (
    <Button
      variant="contained"
      disabled={disabled}
      size="small"
      onClick={onClick}
      sx={{
        textTransform: "none",
        backgroundColor: bgColor || theme.palette.custom.layoutBgColor, // Default bgColor from theme
        color: textColor || theme.palette.custom.layoutFontColor, // Default textColor from theme
        borderRadius,
        border: border || theme.palette.custom.layoutBgColor,
        width,
        height,
        margin: margin,
        boxShadow: "none",
        "&:hover": {
          backgroundColor: theme.palette.custom.layoutHoverColor, // Hover background color
          color: "#fff", // Ensure hover text color is white
        },
      }}
    >
      <Typography
        sx={{
          fontSize,
          fontWeight: fontWeight.toString(),
          color: "inherit", // Inherit color from parent Button
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

// 2. Contained Button with Icon (Example: Edit Route with Icon)
export const ContainedButtonWithIcon = ({
  label,
  icon,
  onClick,
  iconPosition = "end",
  // bgColor = "#0b6699",
  // textColor = "#fff",
  width = "auto",
  height = "32px",
  borderRadius = "6px",
  padding = "15px 10px",
  fontSize = "11px",
  fontWeight = 400,
}) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      size="small"
      onClick={onClick}
      sx={{
        textTransform: "none",
        backgroundColor: theme.palette.custom.layoutBgColor,
        color: theme.palette.custom.layoutFontColor,
        borderRadius,
        width,
        height,
        padding,
        display: "flex",
        alignItems: "center",
        justifyContent: iconPosition === "start" ? "flex-start" : "center",

        boxShadow: "none",
        gap: 1,
        "&:hover": {
          backgroundColor: theme.palette.custom.layoutHoverColor,
          color: "#fff !important",
        },
      }}
    >
      {icon && iconPosition === "start" && (
        <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>{icon}</Box>
      )}
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize,
          fontWeight: fontWeight.toString(),
          color: "inherit",
        }}
      >
        {label}
      </Typography>
      {icon && iconPosition === "end" && (
        <Box sx={{ display: "flex", alignItems: "center" }}>{icon}</Box>
      )}
    </Button>
  );
};

// 3. Outlined Button with Icon (Example: Optimize by Time)
export const OutlinedButtonWithIcon = ({
  label,
  icon,
  onClick,
  padding = "15px 10px", // Default padding value
  iconPosition = "end",
  borderColor = "#d8dde1",
  textColor = "#000", // Default black text color
  width = "auto",
  height = "32px",
  borderRadius = "6px",
  disabled = false,
}) => (
  <Button
    variant="outlined"
    size="small"
    onClick={onClick}
    disabled={disabled}
    sx={{
      textTransform: "none",
      color: textColor, // Apply text color here
      borderColor,
      borderRadius,
      width,
      height,
      padding: padding, // Use the provided padding
      minHeight: 0, // Ensure minHeight doesn't override the height
      display: "flex",
      alignItems: "center",
      justifyContent: iconPosition === "start" ? "flex-start" : "center",
      gap: 1,
      "& .MuiButton-outlined": {
        borderColor,
      },
      "&:hover": {
        backgroundColor: "#f5f5f5",
        borderColor: "#00335b",
        color: textColor, // Ensure hover keeps the same text color
      },
    }}
  >
    {icon && iconPosition === "start" && (
      <Box sx={{ display: "flex", alignItems: "center" }}>{icon}</Box>
    )}
    <Typography
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        fontWeight: 400,
        color: "inherit", // Ensure it inherits color from the Button
      }}
    >
      {label}
    </Typography>
    {icon && iconPosition === "end" && (
      <Box sx={{ display: "flex", alignItems: "center" }}>{icon}</Box>
    )}
  </Button>
);
