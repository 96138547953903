import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
import {
  AttachFile,
  EmojiEmotions,
  Mic,
  PictureAsPdf,
  Close,
  CloseRounded,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import EmojiPicker from "emoji-picker-react";
import { postApiWithAuth, patchApiWithAuth } from "../../../utils/api";
import { CREATE_COMMENT, CREATE_TICKET } from "../../../utils/apiUrl";
import { toast } from "react-toastify";
const ChatComponent = ({
  ticketDetail,
  getTicketDetail,
  getTicketOrders,
  ticketId,
}) => {
  console.log("ticket details on chat componnetn page", ticketDetail, ticketId);
  const theme = useTheme();
  const [newMessage, setNewMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userType = localStorage.getItem("userType");
  const handleEmojiClick = (emojiObject) => {
    setNewMessage((prevMessage) => prevMessage + emojiObject.emoji);
    handleCloseEmojiPicker();
  };
  const handleEmojiIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseEmojiPicker = () => {
    setAnchorEl(null);
  };
  const handleSaveTicket = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const formDataComment = new FormData();
    formDataComment.append("comment", newMessage);
    formDataComment.append("ticket", ticketId);

    try {
      const responseComment = await postApiWithAuth(
        CREATE_COMMENT,
        formDataComment
      );
      if (responseComment.data !== undefined) {
        setNewMessage("");
        getTicketOrders();
        getTicketDetail(ticketId);
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.error("Error while saving ticket", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleCloseTicket = async (ticketId) => {
    // Send a PUT request to update the ticket status to 'Completed'
    const response = await patchApiWithAuth(CREATE_TICKET + `${ticketId}/`, {
      ticket_status: "Completed",
    });
    if (response.data !== undefined) {
      getTicketOrders();
      toast.success("Ticket Closed Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      console.log("Error");
      toast.error("Some Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getTicketOrders();
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSaveTicket();
    }
  };
  const HandleReopenTicket = async (id) => {
    const response = await patchApiWithAuth(CREATE_TICKET + `${id}/`, {
      ticket_status: "Pending",
    });
    console.log(response);
    if (response.data !== undefined) {
      getTicketOrders();
      toast.success("Ticket Re-Open Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      getTicketOrders();
    }
  };
  const isEmojiPickerOpen = Boolean(anchorEl);
  return (
    <Box>
      {ticketDetail.ticket_status === "Completed" ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              padding: "5px",
              borderRadius: "4px",
              backgroundColor: "#e8f5e9",
              border: "1px solid #05b320",
              maxWidth: "150px",
              marginBottom: "20px",
            }}
          >
            Ticket Closed
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={() => handleCloseTicket(ticketId)}
            style={{
              color: "gold",
              fontSize: "10px",
              fontWeight: 700,
              textAlign: "center",
              background: "none",
              border: "1px solid red",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            Issue Resolved <br /> Close Ticket
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => handleCloseTicket(ticketId)} // Optional: Handle close with icon
            >
              <CloseRounded
                sx={{
                  color: "red",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </button>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "72vh",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ flex: 1, overflowY: "auto", padding: "16px" }}>
          {ticketDetail.comments.map((msg) => (
            <Box
              key={msg.id}
              sx={{
                display: "flex",
                flexDirection: msg.sender === "user" ? "row-reverse" : "row",
                alignItems: "flex-start",
                marginBottom: "16px",
              }}
            >
              <Avatar
                src={msg.senderImage}
                alt={msg.sender}
                sx={{
                  width: 40,
                  height: 40,
                  marginRight: msg.sender === "user" ? 0 : "8px",
                  marginLeft: msg.sender === "user" ? "8px" : 0,
                }}
              />
              <Box>
                <Typography variant="caption" color="textSecondary">
                  {msg.comment_by.first_name} {msg.comment_by.last_name} •{" "}
                  {msg.created_at.split("T")[0]}{" "}
                  {msg.created_at.split("T")[1].split(".")[0]}
                </Typography>
                <Box
                  sx={{
                    backgroundColor:
                      msg.comment_by.type.type === "Admin" ||
                      msg.comment_by.type.type === "Staff"
                        ? theme.palette.custom.layoutBgColor
                        : "#F5F5F5",
                    color:
                      msg.comment_by.type.type === "Admin" ||
                      msg.comment_by.type.type === "Staff"
                        ? theme.palette.custom.layoutFontColor
                        : "#000",
                    padding: "8px 12px",
                    borderRadius: "12px",
                    marginTop: "4px",
                    whiteSpace: "pre-wrap",
                    maxWidth: "300px",
                  }}
                >
                  {msg.type === "file" ? (
                    <Box>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        {msg.file.name}
                      </Typography>
                      {msg.file.type.startsWith("image/") ? (
                        <img
                          src={URL.createObjectURL(msg.file)}
                          alt={msg.file.name}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "150px",
                            borderRadius: "8px",
                          }}
                        />
                      ) : msg.file.type === "application/pdf" ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            padding: "8px",
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <PictureAsPdf
                            sx={{ fontSize: 40, color: "#D32F2F" }}
                          />
                          <Typography variant="body2">
                            {msg.file.name}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography variant="body2">
                          Unsupported file
                        </Typography>
                      )}
                    </Box>
                  ) : (
                    msg.comment
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        {ticketDetail.ticket_status !== "Completed" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px",
              borderTop: "1px solid #ddd",
              gap: "8px",
            }}
          >
            {/* <IconButton component="label">
          <AttachFile />
          <input
            type="file"
            accept="image/*,application/pdf"
            hidden
            onChange={handleFileChange}
          />
        </IconButton> */}
            <IconButton onClick={handleEmojiIconClick}>
              <EmojiEmotions />
            </IconButton>
            <Popover
              open={isEmojiPickerOpen}
              anchorEl={anchorEl}
              onClose={handleCloseEmojiPicker}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </Popover>
            <Box sx={{ position: "relative", flex: 1 }}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                autoComplete="off"
                placeholder="Type a message"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              {/* {selectedFile && (
            <Box
              sx={{
                position: "absolute",
                top: "-50px",
                left: 0,
                display: "flex",
                alignItems: "center",
                gap: "8px",
                backgroundColor: "#f5f5f5",
                padding: "8px",
                borderRadius: "8px",
                border: "1px solid #ddd",
              }}
            >
              {selectedFile.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt={selectedFile.name}
                  style={{ width: "50px", height: "50px", borderRadius: "4px" }}
                />
              ) : (
                <PictureAsPdf sx={{ fontSize: 40, color: "#D32F2F" }} />
              )}
              <Typography variant="body2" noWrap>
                {selectedFile.name}
              </Typography>
              <IconButton
                size="small"
                onClick={handleRemoveFile}
                sx={{ position: "absolute", top: "-10px", right: "-10px" }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          )} */}
            </Box>
            {/* <IconButton>
          <Mic />
        </IconButton> */}
            <Button
              variant="contained"
              onClick={handleSaveTicket}
              sx={{
                color: theme.palette.custom.layoutFontColor,
                backgroundColor: theme.palette.custom.layoutBgColor,
                width: "160px",
                marginRight: "8%",
                paddingTop: "7.5px",
                paddingBottom: "7.5px",
                "&:hover": {
                  backgroundColor: theme.palette.custom.layoutBgColor,
                },
              }}
            >
              Send
            </Button>
          </Box>
        )}
        {ticketDetail.ticket_status === "Completed" &&
          (userType === "PharmacyEmployee" || userType === "PharmacyOwner") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => HandleReopenTicket(ticketId)}
                style={{
                  color: "red",
                  fontSize: "10px",
                  fontWeight: 700,
                  textAlign: "center",
                  background: "none",
                  border: "1px solid red",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 16px",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                Re-Open Ticket
              </button>
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default ChatComponent;
