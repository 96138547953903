import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ActionButtons = ({ onSaveAndPrint }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
      <Box sx={{ display: "flex", gap: 2 }}>
         <Button
          sx={{
            textTransform: "none",
            border: "1px solid #919eab",
            borderRadius: "5px",
            color: "black",
            fontWeight: "700",
            fontSize: "13px",
            display: "flex", 
            alignItems: "center", 
            padding: "5px 25px",
          }}
          onClick={() => navigate("/order-list")}
        >
          Back
        </Button>
        <Button
          onClick={onSaveAndPrint}
          sx={{
            textTransform: "none",
            border: "1px solid #919eab",
            borderRadius: "5px",
            color: "black",
            fontWeight: "700",
            fontSize: "13px",
            display: "flex", 
            alignItems: "center", 
            padding: "5px 10px", 
          }}
        >
          <img src="./icons/deliveryIcons/Printer.svg" alt="icon" />
          Save & Print
        </Button>
      </Box>
    </Box>
  );
};

export default ActionButtons;
