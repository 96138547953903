import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import moment from "moment";

const messages = [
  {
    id: 1,
    name: "Jack Raymonds",
    time: "10:30 AM",
    text: "I know, right? Weekend plans are the best. Any exciting plans on your end?",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 2,
    name: "Grace Miller",
    time: "10:31 AM",
    text: "Absolutely! I'm thinking of going for a hike on Saturday. How about you?",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 3,
    name: "Jack Raymonds",
    time: "10:32 AM",
    type: "audio",
    audioDuration: "00:16",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 4,
    name: "Grace Miller",
    time: "10:33 AM",
    text: "Sounds fun! Hope the weather stays nice for the hike.",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 5,
    name: "Jack Raymonds",
    time: "10:34 AM",
    type: "status",
    status: "Out for Delivery",
    statusDetails:
      "Message sent to (909) 552-4552 with ETA between 02:15pm to 04:15pm.",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 6,
    name: "Jack Raymonds",
    time: "10:35 AM",
    text: "I might go camping! Trying to find a good spot nearby.",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 7,
    name: "Grace Miller",
    time: "10:36 AM",
    text: "Camping sounds great! Hope you find a nice place.",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 8,
    name: "Grace Miller",
    time: "10:37 AM",
    text: "Did you hear about the new restaurant that opened downtown?",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 9,
    name: "Jack Raymonds",
    time: "10:38 AM",
    text: "No, I haven’t! What’s the vibe there?",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 10,
    name: "Grace Miller",
    time: "10:39 AM",
    text: "It’s supposed to be really cozy, with amazing pasta!",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 11,
    name: "Jack Raymonds",
    time: "10:40 AM",
    text: "Maybe I'll check it out this weekend.",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 12,
    name: "Grace Miller",
    time: "10:41 AM",
    text: "Let me know how it is if you do!",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 13,
    name: "Jack Raymonds",
    time: "10:42 AM",
    type: "status",
    status: "Delivered",
    statusDetails: "Package delivered to doorstep at 04:15pm.",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 14,
    name: "Jack Raymonds",
    time: "10:43 AM",
    text: "Sure thing! Any other recommendations?",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 15,
    name: "Grace Miller",
    time: "10:44 AM",
    text: "There's a rooftop bar nearby. Great place for sunsets!",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 16,
    name: "Jack Raymonds",
    time: "10:45 AM",
    type: "audio",
    audioDuration: "00:30",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 17,
    name: "Grace Miller",
    time: "10:46 AM",
    text: "I’ll send you the address!",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 18,
    name: "Jack Raymonds",
    time: "10:47 AM",
    text: "Perfect! I’m looking forward to it.",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 19,
    name: "Grace Miller",
    time: "10:48 AM",
    text: "Have a good day, Jack!",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 20,
    name: "Jack Raymonds",
    time: "10:49 AM",
    text: "You too, Grace! Talk later.",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 21,
    name: "Jack Raymonds",
    time: "10:50 AM",
    type: "status",
    status: "Attempted Delivery",
    statusDetails:
      "Attempted delivery at 03:00 PM. No one was available to receive the package.",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 22,
    name: "Grace Miller",
    time: "10:51 AM",
    text: "Hope you get your package soon!",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 23,
    name: "Jack Raymonds",
    time: "10:52 AM",
    text: "Thanks, just waiting on it now.",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
  {
    id: 24,
    name: "Grace Miller",
    time: "10:53 AM",
    text: "Alright, have a good day ahead!",
    avatar: "/icons/deliveryIcons/Grace.svg",
  },
  {
    id: 25,
    name: "Jack Raymonds",
    time: "10:54 AM",
    text: "You too! Take care.",
    avatar: "/icons/deliveryIcons/Jack.svg",
    isOwn: true,
  },
];


const ChatMessage = ({ message, order }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: message.type === "outgoing" ? "row-reverse" : "row",
        alignItems: "flex-start",
        gap: 1,
        marginBottom: "10px",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f0f0f0", // Change to a light gray on hover
        },
      }}
    >
      {/* <Avatar
        alt={order.name}
        src={message.avatar}
        sx={{ width: 57, height: 57 }}
      /> */}
      {message.type === "outgoing" ?
        message.agent_pic ?
          <Avatar
            src={message.agent_pic}
            alt="Profile Image"
            sx={{
              width: 38.71,
              height: 37.66,
              cursor: "pointer",
            }}
          />
          :
          <Avatar sx={{ width: 40, height: 40 }}>
            {message.agent_name ? message.agent_name.charAt(0) : 'A'}
          </Avatar>
        :
        <Avatar sx={{ width: 40, height: 40 }}>
          {order?.name ? order.name.charAt(0) : 'U'}
        </Avatar>
      }

      <Box
        sx={{
          maxWidth: "70%",
          textAlign: "left",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: message.type === "outgoing" ? "row-reverse" : "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: "13px", fontWeight: 500, color: "#515151" }}
          >
            {message.type === "outgoing" ? message.agent_name : order.name}
          </Typography>
          <Typography variant="body3" sx={{ fontSize: "13px", margin: '0 10px' }}>
            {moment(message.created_at).format("MMMM DD YYYY, h:mm:ss a")}
          </Typography>
        </Box>
        {message.sms_type === "sms" || !message.sms_type ? (
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: message.type === "outgoing"
                ? "15px 0 15px 15px"
                : "0 15px 15px 15px",
              padding: "10px",
              boxShadow: 2,
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 400 }}
            >
              {message.body}
            </Typography>
          </Box>
        ) : (message.sms_type === "audio" && message.recording_file) ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              padding: "10px",
              backgroundColor: "#0b6699",
              borderRadius: message.type === "outgoing"
                ? "15px 0 15px 15px"
                : "0 15px 15px 15px",
              color: "#fff",
            }}
          >
            <PlayCircleIcon />
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: 400 }}
            >
              {message.status}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", fontWeight: 400 }}
            >
              {message.duration} seconds
            </Typography>
          </Box>
        ) : (message.sms_type === "status") ? (
          <Box
            sx={{
              backgroundColor: "#0b6699",
              padding: "10px",
              borderRadius: message.type === "outgoing"
                ? "15px 0 15px 15px"
                : "0 15px 15px 15px",
            }}
          >
            <Typography
              variant="body2"
              color="#fff"
              sx={{ fontSize: "13px", fontWeight: 600 }}
            >
              {message.status}
            </Typography>
            <Typography
              variant="body2"
              color="#fff"
              sx={{ fontSize: "13px", fontWeight: 400 }}
            >
              {message.statusDetails}
            </Typography>
          </Box>
        ) :
          <Typography
            variant="body2"
            sx={{ fontSize: "12px", fontWeight: 400 }}
          >
            No Audio Recording
          </Typography>
        }
      </Box>
    </Box>
  );
};


const ChatWindow = ({ messages, order }) => {

  return (
    <Box sx={{ width: "100%" }}>
      {messages.map((message) => (
        <ChatMessage key={message.id} message={message} order={order} />
      ))}
    </Box>
  );

};

export default ChatWindow;
