import React from "react";
import { useDisplayMode } from "../context/DisplayModeContext";

const ResponsiveComponent = ({
  large: LargeComponent,
  small: SmallComponent,
  isEditable, // Accept isEditable prop
}) => {
  const { displayMode } = useDisplayMode();

  // Render the appropriate component with the isEditable prop
  return displayMode === "large" ? (
    <LargeComponent isEditable={isEditable} />
  ) : (
    <SmallComponent isEditable={isEditable} />
  );
};

export default ResponsiveComponent;
