import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  Checkbox,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  Input,
  styled,
  Modal,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import CustomDropdown from "../../CustomDropdown";
import { PhoneNumberField } from "../../Ui/PhoneNumberField";
import {
  getApiWithAuth,
  patchApiWithAuth,
  postApiWithAuth,
  deleteApiWithAuth,
} from "../../../utils/api";
import {
  PHARMACY_bank_URL,
  PHARMACY_detail_URL,
  ALL_DRIVERS,
  PHARMACY_URL,
  PHARAMCY_USER,
  PHARMACY_bankupdate_URL,
} from "../../../utils/apiUrl";
import { toast } from "react-toastify";
import { ContainedButton } from "../../buttonCompo/Buttons";
import Topbar from "./Topbar";
import { useSearchParams } from "react-router-dom";
import { ChooseImageIcon, DropdownIcon } from "../../Icons";
/* Muhammad Usman */
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const PharmacyInformationForm = () => {
  const [searchParams] = useSearchParams();
  const [isHippaEnabled, setIsHippaEnabled] = useState(false);
  const pharmacyId = searchParams.get("id");
  const location = useLocation();
  const navigate = useNavigate();
  const [driverList, setDriverList] = useState([]);
  const [driver, setDriver] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [isChecked2, setIsChecked2] = useState(false);
  const [backendimg, setbackendimg] = useState(
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
  );
  const [selectedOption, setSelectedOption] = useState("FACE TO FACE");
  const [selectedOption2, setSelectedOption2] = useState("Driver");
  const [selectedOptions4, setSelectedOptions4] = useState([]);

  const [bankInfo, setBankInfo] = useState({
    name: "",
    account_title: "",
    account_number: "",
    ach_routing: "",
    credit_crad: "",
  });

  const deliveryOptionMapping = {
    1: "FaceToFace",
    2: "LeaveWithReceptionist",
    3: "LeaveAtFloor",
    4: "LeaveAtDoor",
    5: "LeaveAtDoorIfPTE-Signs",
    6: "LeaveInTheMailBox",
    7: "LeaveInTheMailBoxIfPTE-Signs",
  };

  const [pharmacyDetails, setPharmacyDetails] = useState({
    name: "",
    description: "This is a test pharmacy",
    short_description: "Short description for the pharmacy",
    logo: null,
    location: "",
    contact_no: "",
    urgentPrice: "",
    sameDayPrice: "",
    timeWindowPrice: "",
    f_attemptPrice: "0",
    s_attemptPrice: "0",
    t_attemptPrice: "0",
    nextDayPrice: "",
    cancelPrice: "",
    isActive: true,
    fileRequired: false,
    approved: true,
    fax_number: "",
    emergency_contact: "012121212",
    email: "",
    designation: "",
    first_name: "",
    last_name: "lastname",
    employ_contact: "",
    employ_email: "",
    preferred_way_of_delivery: "",
    call_to_notify_failed_attempt: "1",
    firstattempt: false,
    secondattempt: false,
    thirdattempt: false,
    preferred_number: "",
    delivered_by: "",
    notes: "",
    pos_types: "PrimeRX",
    hippa_notes: "",
    createdBy: 1,
    PharmacyOwner: "pharmacyOwner",
    username: "",
    user_email: "",
    password: "",
    confirmPassword: "",
    cashCounter: null,
  });
  const [selectedOptionrx, setSelectedOptionrx] = useState(
    pharmacyDetails.pos_types || null
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleInputChange = (field, value) => {
    setPharmacyDetails((prev) => ({ ...prev, [field]: value }));
    setFieldErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const fetchDrivers = async () => {
    try {
      const response = await getApiWithAuth(ALL_DRIVERS);
      if (response.data !== undefined) {
        setDriverList(
          response.data.data.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  useEffect(() => {
    const fetchPharmacyData = async () => {
      try {
        const response = await getApiWithAuth(`${PHARMACY_URL}${pharmacyId}/`);

        if (response.data.status === 200) {
          const pharmacyData = response.data.data;
          console.log("response :", response.data.data);

          // Set Pharmacy Details
          setPharmacyDetails((prevState) => ({
            ...prevState,
            name: pharmacyData.name,
            description: pharmacyData.description,
            location: pharmacyData.location,
            contact_no: pharmacyData.contact_no,
            urgentPrice: parseFloat(pharmacyData?.urgentPrice).toFixed(2),
            sameDayPrice: parseFloat(pharmacyData?.sameDayPrice).toFixed(2),
            timeWindowPrice: parseFloat(pharmacyData?.timeWindowPrice).toFixed(
              2
            ),
            f_attemptPrice: parseFloat(pharmacyData?.f_attemptPrice).toFixed(2),
            s_attemptPrice: parseFloat(pharmacyData?.s_attemptPrice).toFixed(2),
            t_attemptPrice: parseFloat(pharmacyData?.t_attemptPrice).toFixed(2),
            nextDayPrice: parseFloat(pharmacyData?.nextDayPrice).toFixed(2),
            cancelPrice: parseFloat(pharmacyData?.cancelPrice).toFixed(2),
            fax_number: pharmacyData.fax_number,
            emergency_contact: pharmacyData.emergency_contact,
            email: pharmacyData.email,
            designation: pharmacyData.designation,
            first_name: pharmacyData.first_name,
            pos_types: pharmacyData.pos_types,
            last_name: pharmacyData.last_name,
            employ_contact: pharmacyData.employ_contact,
            employ_email: pharmacyData.employ_email,
            call_to_notify_failed_attempt:
              pharmacyData.call_to_notify_failed_attempt,
            preferred_number: pharmacyData.preferred_number,
            notes: pharmacyData.notes,
            hippa_notes: pharmacyData.hippa_notes,
            approved: pharmacyData.approved,
            delivered_by: pharmacyData.delivered_by,
            username: pharmacyData.user.username,
            user_email: pharmacyData.user.email,
            password: "",
            confirmPassword: "",
            isActive: pharmacyData?.isActive,
            cashCounter: pharmacyData.cashCounter,
          }));
          console.log("hippa notes", pharmacyData.hippa_notes);
          // Update IsActive Checkbox
          setIsChecked(pharmacyData.isActive === true);
          setIsChecked2(pharmacyData.isActive === false);
          if (pharmacyData?.hippa_notes) {
            setIsHippaEnabled(true);
          } else {
            setIsHippaEnabled(false);
          }
          // Set Bank Info
          const bankInfo = pharmacyData.bank_info?.[0];
          if (bankInfo) {
            setBankInfo({
              id: bankInfo.id,
              name: bankInfo.name,
              account_title: bankInfo.account_title,
              account_number: bankInfo.account_number,
              ach_routing: bankInfo.ach_routing,
              credit_crad: bankInfo.credit_crad,
            });
          }

          if (pharmacyData?.preferred_way_of_delivery) {
            const mappedValue =
              deliveryOptionMapping[pharmacyData.preferred_way_of_delivery] ||
              "FaceToFace"; // Default to "FaceToFace" if no match is found
            setSelectedOption(mappedValue);
            setPharmacyDetails((prev) => ({
              ...prev,
              preferred_way_of_delivery: mappedValue,
            }));
          }

          // Update Additional States
          // setSelectedImage(pharmacyData.logo);
          setFronts(pharmacyData.logo);
          console.log("pharmacy logo", pharmacyData.logo);

          setSelectedOption(pharmacyData.preferred_way_of_delivery);
          setSelectedOption2(pharmacyData.delivered_by);
          setSelectedOptionrx(pharmacyData.pos_types);
          setDriver(pharmacyData.cashCounter);
          console.log("CashChounter", driver);
          // setDriver(pharmacyData.cashCounter);

          // Handle Attempts
          const attemptsList = [];
          if (pharmacyData.firstattempt)
            attemptsList.push({ id: 1, name: "First Attempt" });
          if (pharmacyData.secondattempt)
            attemptsList.push({ id: 2, name: "Second Attempt" });
          if (pharmacyData.thirdattempt)
            attemptsList.push({ id: 3, name: "Third Attempt" });
          setSelectedOptions4(attemptsList);
        } else {
          console.error("Error fetching pharmacy details:", response);
          console.log("API Response:", response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (pharmacyId) fetchPharmacyData();
  }, [pharmacyId]);

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!pharmacyDetails.name) {
      errors.name = "Pharmacy Name is required";
      isValid = false;
    }
    if (!pharmacyDetails.fax_number) {
      errors.fax_number = "Fax Number is required";
      isValid = false;
    }
    if (!pharmacyDetails.location) {
      errors.location = "Address is required";
      isValid = false;
    }
    if (!pharmacyDetails.contact_no) {
      errors.contact_no = "Phone Number is required";
      isValid = false;
    }
    if (!pharmacyDetails.email) {
      errors.email = "Email is required";
      isValid = false;
    }

    if (!pharmacyDetails.last_name) {
      errors.last_name = "Last Name is required";
      isValid = false;
    }
    if (!pharmacyDetails.nextDayPrice) {
      errors.nextDayPrice = "Next Day Price is required";
      isValid = false;
    }
    if (!pharmacyDetails.urgentPrice) {
      errors.urgentPrice = "Urgent Price is required";
      isValid = false;
    }
    if (!pharmacyDetails.timeWindowPrice) {
      errors.timeWindowPrice = "Time Window Price is required";
      isValid = false;
    }
    if (!pharmacyDetails.sameDayPrice) {
      errors.sameDayPrice = "Same Day Price is required";
      isValid = false;
    }
    if (!pharmacyDetails.cancelPrice) {
      errors.cancelPrice = "Cancel Price is required";
      isValid = false;
    }

    if (!pharmacyDetails.s_attemptPrice) {
      errors.secondAttemptPrice = "Second Attempt Price is required";
      isValid = false;
    }
    if (!pharmacyDetails.t_attemptPrice) {
      errors.thirdAttemptPrice = "Third Attempt Price is required";
      isValid = false;
    }

    if (!pharmacyDetails.preferred_way_of_delivery) {
      errors.preferred_way_of_delivery = "Preffered Way is required";
      isValid = false;
    }
    if (!pharmacyDetails.preferred_number) {
      errors.preferred_number = "Preffered Number is required";
      isValid = false;
    }
    if (!pharmacyDetails.delivered_by) {
      errors.delivered_by = "Delivered by is required";
      isValid = false;
    }
    if (!pharmacyDetails.cashCounter) {
      errors.cashCollector = "Cash Collector is required";
      isValid = false;
    }
    // if (!driver) {
    //   errors.cashCounter = "Cash Collector is required";
    //   isValid = false;
    // }

    setFieldErrors(errors);
    return isValid;
  };
  const handleDriverChange = (e, value) => {
    if (value !== null) {
      setDriver(value);
      setPharmacyDetails((prevState) => ({
        ...prevState,
        cashCounter: value?.id || null,
        cashCounter: value?.id || null,
      }));
    } else {
      setDriver("");
    }
  };

  const handleBankInputChange = (event) => {
    const { name, value } = event.target;
    setBankInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Map selected options to pharmacy details

    if (pharmacyDetails.secondattempt || pharmacyDetails.thirdattempt) {
      // Add flags for failed attempts
      if (pharmacyDetails.secondattempt) {
        pharmacyDetails.secondattempt = true;
      }
      if (pharmacyDetails.thirdattempt) {
        pharmacyDetails.thirdattempt = true;
      }
    }

    if (pharmacyDetails.password !== pharmacyDetails.confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      console.log("error", fieldErrors);
      return;
    }

    setLoading(true);

    try {
      const payload = new FormData();
      payload.append("name", pharmacyDetails.name);
      payload.append("description", pharmacyDetails.description);
      payload.append("short_description", pharmacyDetails.short_description);
      payload.append("location", pharmacyDetails.location);
      payload.append("contact_no", pharmacyDetails.contact_no);
      payload.append("urgentPrice", pharmacyDetails.urgentPrice);
      payload.append("sameDayPrice", pharmacyDetails.sameDayPrice);
      payload.append("timeWindowPrice", pharmacyDetails.timeWindowPrice);
      payload.append("f_attemptPrice", pharmacyDetails.f_attemptPrice);
      payload.append("s_attemptPrice", pharmacyDetails.s_attemptPrice);
      payload.append("t_attemptPrice", pharmacyDetails.t_attemptPrice);
      payload.append("nextDayPrice", pharmacyDetails.nextDayPrice);
      payload.append("cancelPrice", pharmacyDetails.cancelPrice);
      payload.append("fax_number", pharmacyDetails.fax_number);
      payload.append("emergency_contact", pharmacyDetails.emergency_contact);
      payload.append("email", pharmacyDetails.email);
      payload.append("designation", pharmacyDetails.designation);
      payload.append("first_name", pharmacyDetails.first_name);
      payload.append("last_name", pharmacyDetails.last_name);
      payload.append("employ_contact", pharmacyDetails.employ_contact);
      payload.append("employ_email", pharmacyDetails.employ_email);
      payload.append(
        "preferred_way_of_delivery",
        pharmacyDetails.preferred_way_of_delivery
      );
      payload.append("pos_types", pharmacyDetails.pos_types);
      if (pharmacyDetails.logo instanceof File) {
        // New upload
        payload.append("logo", pharmacyDetails.logo);
      } else if (
        typeof pharmacyDetails.logo === "string" &&
        pharmacyDetails.logo.length > 0
      ) {
        // Existing logo from backend
        payload.append("logo", pharmacyDetails.logo);
      }
      payload.append("firstattempt", pharmacyDetails.firstattempt);
      payload.append("secondattempt", pharmacyDetails.secondattempt);
      payload.append("thirdattempt", pharmacyDetails.thirdattempt);
      payload.append("preferred_number", pharmacyDetails.preferred_number);
      payload.append("delivered_by", pharmacyDetails.delivered_by);
      payload.append("notes", pharmacyDetails.notes);
      payload.append("hippa_notes", pharmacyDetails.hippa_notes);
      payload.append("username", pharmacyDetails.username);
      payload.append("user_email", pharmacyDetails.user_email);
      payload.append(
        "call_to_notify_failed_attempt",
        pharmacyDetails.call_to_notify_failed_attempt
      );

      if (pharmacyDetails.password !== "") {
        payload.append("password", pharmacyDetails.password);
      }

      payload.append("createdBy", pharmacyDetails.createdBy);
      payload.append("PharmacyOwner", pharmacyDetails.PharmacyOwner);
      payload.append("approved", pharmacyDetails.approved);
      payload.append("isActive", pharmacyDetails.isActive);
      payload.append("fileRequired", pharmacyDetails.fileRequired);

      if (driver && driver.id) {
        payload.append("cashCounter", driver.id);
      }

      let response_pharmacy;

      if (pharmacyId) {
        response_pharmacy = await patchApiWithAuth(
          `${PHARMACY_detail_URL}${pharmacyId}/`,
          payload
        );
        toast.success("Pharmacy updated successfully!");
        console.log("hippaa", pharmacyDetails.hippa_notes);
      } else {
        // Create new pharmacy
        response_pharmacy = await postApiWithAuth(PHARMACY_detail_URL, payload);
        toast.success("Pharmacy created successfully!");
      }

      // Handle Bank Info
      if (bankInfo.name !== "") {
        let bankInfoPayload = new FormData();
        bankInfoPayload.append("name", bankInfo.name);
        bankInfoPayload.append("account_title", bankInfo.account_title);
        bankInfoPayload.append("account_number", bankInfo.account_number);
        bankInfoPayload.append("ach_routing", bankInfo.ach_routing);
        bankInfoPayload.append("credit_crad", bankInfo.credit_crad);

        if (bankInfo.id && bankInfo.id !== "") {
          // Update existing bank info
          await patchApiWithAuth(
            `${PHARMACY_bankupdate_URL}/${bankInfo.id}`,
            bankInfoPayload
          );
        } else {
          // Create new bank info
          bankInfoPayload.append("pharmacy", response_pharmacy.data.data.id);
          await postApiWithAuth(PHARMACY_bank_URL, bankInfoPayload);
        }
      }

      navigate("/addpharmacy");
    } catch (error) {
      console.error("Submission Error:", error);
      toast.error("An unexpected error occurred during form submission.");
    } finally {
      setLoading(false);
    }
  };
  const handleSelectChange = (selectedValues) => {
    const updatedOptions = selectedValues.map((value) => ({
      id: value === "Second Attempt" ? 2 : value === "Third Attempt" ? 3 : null,
      name: value,
    }));

    // Update the state for the dropdown
    setSelectedOptions4(updatedOptions);

    // Update pharmacyDetails with flags for second and third attempts
    setPharmacyDetails((prevDetails) => ({
      ...prevDetails,
      secondattempt: selectedValues.includes("Second Attempt"),
      thirdattempt: selectedValues.includes("Third Attempt"),
    }));
  };

  const handleDelete = async () => {
    if (!pharmacyId) {
      console.error("No pharmacy ID found for deletion.");
      toast.error("No pharmacy selected for deletion.");
      return;
    }

    try {
      const response = await deleteApiWithAuth(
        `${PHARMACY_detail_URL}${pharmacyId}/`
      );

      if (response.data?.status === 200 || response.data?.status === 204) {
        toast.success("Pharmacy deleted successfully!");
        navigate("/addpharmacy");
      } else {
        console.error("Failed to delete pharmacy:", response);
        toast.error("An error occurred while deleting the pharmacy.");
      }
    } catch (error) {
      console.error("Error during pharmacy deletion:", error);
      toast.error("An unexpected error occurred during deletion.");
    } finally {
      handleCloseDeleteModal();
    }
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  {
    /* Muhammad Usman Start */
  }
  const [fronts, setFronts] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const cropperRef = useRef(null);

  const handleImageUpload3 = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    setFileObject(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setFronts(reader.result);
      setOpenModal(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        croppedCanvas.toBlob((blob) => {
          const croppedFile = new File([blob], fileObject.name, {
            type: "image/png",
          });

          setPharmacyDetails((prevState) => ({
            ...prevState,
            logo: croppedFile,
          }));

          const reader = new FileReader();
          reader.onloadend = () => {
            setFronts(reader.result);
          };
          reader.readAsDataURL(blob);
        }, "image/png");

        setOpenModal(false);
      }
    }
  };
  {
    /* Muhammad Usman End */
  }

  const Input = styled("input")({
    display: "none", // Ensure the input element is completely hidden
  });

  const handleCheckboxChange = (event) => {
    setIsHippaEnabled(event.target.checked);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* Muhammad Usman Start */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageUpload3}
                  sx={{ display: "none" }}
                />
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    borderColor: "#05b320",
                    color: "#05b320",
                    width: "120px",
                    textTransform: "none",
                    fontSize: "13px",
                    height: "35px",
                  }}
                >
                  Choose File
                </Button>
              </label>

              <Typography variant="body2" sx={{ color: "#757575" }}>
                {fileObject ? fileObject.name : "No File Chosen"}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "80px",
                height: "80px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f4f4f4",
                border: "1px solid #ddd",
              }}
            >
              {fronts ? (
                <img
                  src={fronts}
                  alt="Cropped Preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Typography
                  variant="body2"
                  sx={{ fontSize: "48px", color: "#757575" }}
                >
                  📷
                </Typography>
              )}
            </Box>

            <Modal open={openModal} onClose={() => setOpenModal(false)}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 3,
                  borderRadius: 2,
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Crop & Adjust Image
                </Typography>

                <Cropper
                  src={fronts}
                  style={{ height: 250, width: "100%" }}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  guides={true}
                  ref={cropperRef}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCrop}
                  >
                    Save Cropped
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Box>
          {/* Muhammad Usman End */}

          <Box sx={{ display: "flex", gap: 1 }}>
            <ContainedButton
              label="Activate"
              border="none"
              bgColor={pharmacyDetails.isActive ? "#05b320" : "transparent"}
              borderRadius="4px"
              textColor={pharmacyDetails.isActive ? "#fff" : "#05b320"}
              onClick={() => handleInputChange("isActive", true)}
            />
            <ContainedButton
              label="Deactivate"
              border="1px solid #fc1002"
              bgColor={!pharmacyDetails.isActive ? "#fc1002" : "transparent"}
              borderRadius="4px"
              textColor={!pharmacyDetails.isActive ? "#fff" : "#fc1002"}
              onClick={() => handleInputChange("isActive", false)}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Pharmacy Detail
            <span style={{ color: "#fb1001" }}>(Mandatory Field)</span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: "1250px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              columnGap: 3.5,
              rowGap: 2,
              width: "100%",
              // maxWidth: "80%",
            }}
          >
            {/* First Row - Pharmacy Name and Address */}
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  marginBottom: "3px",
                }}
              >
                Pharmacy Name
              </Typography>
              <TextField
                value={pharmacyDetails.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                error={!!fieldErrors.name}
                helperText={fieldErrors.name}
                type="text"
                autoComplete="new-password"
                placeholder="Pharmacy Name"
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>

            {/* Address Field */}
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Address
              </Typography>
              <TextField
                value={pharmacyDetails.location}
                onChange={(e) => handleInputChange("location", e.target.value)}
                error={!!fieldErrors.location}
                helperText={fieldErrors.location}
                type="text"
                autoComplete="new-password"
                placeholder="Enter Address"
                sx={{
                  width: "396px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>

            {/* Phone Number Field */}
            <PhoneNumberField
              label="Phone Number"
              value={pharmacyDetails.contact_no}
              onChange={(value) => handleInputChange("contact_no", value)}
              placeholder="(675) 454-2724"
              height="32px"
              width="237px"
              error={!!fieldErrors.contact_no}
              helperText={fieldErrors.contact_no}
            />
            <PhoneNumberField
              label="Fax Number"
              value={pharmacyDetails.fax_number}
              onChange={(value) => handleInputChange("fax_number", value)}
              placeholder="(675) 454-2724"
              height="32px"
              width="237px"
              error={!!fieldErrors.fax_number}
              helperText={fieldErrors.fax_number}
            />
          </Box>
          {/* Email and POS Type */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  marginBottom: "3px",
                }}
              >
                Email
              </Typography>
              <TextField
                value={pharmacyDetails.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                error={!!fieldErrors.email}
                helperText={fieldErrors.email}
                type="email"
                autoComplete="new-password"
                placeholder="Email"
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  marginBottom: "3px",
                }}
              >
                POS Type
              </Typography>
              <CustomDropdown
                label="Select POS Type"
                value={selectedOptionrx || pharmacyDetails.pos_types || ""} // Use `selectedOptionrx` for editing, fallback to `pharmacyDetails.pos_types`
                onChange={(selectedValue) => {
                  setSelectedOptionrx(selectedValue); // Update `selectedOptionrx` for immediate visual feedback
                  setPharmacyDetails((prev) => ({
                    ...prev,
                    pos_types: selectedValue,
                    pos_types: selectedValue,
                  }));
                }}
                options={[
                  { value: "BestRx", label: "BestRx" },
                  { value: "PrimeRX", label: "PrimeRX" },
                  { value: "PioneerRx", label: "PioneerRx" },
                ]}
                customstyles={{
                  width: "237px",
                  height: "36px",
                  borderRadius: "4px",
                }}
                isMultiSelect={false}
                error={!!fieldErrors.pos_types}
                helperText={fieldErrors.pos_types || ""}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Emergency Contact
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Designation
            </Typography>
            <TextField
              value={pharmacyDetails.designation}
              onChange={(e) => handleInputChange("designation", e.target.value)}
              type="text"
              autoComplete="new-password"
              placeholder="Designation"
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Contact Name
            </Typography>
            <TextField
              value={pharmacyDetails.first_name}
              onChange={(e) => handleInputChange("first_name", e.target.value)}
              // error={!!fieldErrors.name}
              // helperText={fieldErrors.name}
              type="text"
              autoComplete="new-password"
              placeholder="Contact Name"
              sx={{
                width: "199px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Email
            </Typography>
            <TextField
              value={pharmacyDetails.email}
              onChange={(e) =>
                handleInputChange("employ_email", e.target.value)
              }
              type="email"
              autoComplete="new-password"
              placeholder="Email"
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <PhoneNumberField
            label="Phone Number"
            value={pharmacyDetails.employ_contact}
            onChange={(value) => handleInputChange("employ_contact", value)}
            placeholder="(675) 454-2724"
            height="32px"
            width="237px"
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Account Credentials
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Account Type
            </Typography>

            <CustomDropdown
              label="Select Account Type"
              value={pharmacyDetails.PharmacyOwner}
              onChange={(selectedValue) =>
                setPharmacyDetails((prev) => ({
                  ...prev,
                  PharmacyOwner: selectedValue,
                }))
              }
              options={[
                { value: "pharmacyOwner", label: "Pharmacy Owner" },
                { value: "pharmacyEmployee", label: "Pharmacy Employee" },
              ]}
              customstyles={{
                width: "237px",
                height: "36px",
                borderRadius: "4px",
              }}
              isMultiSelect={false}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              User Name
            </Typography>
            <TextField
              value={pharmacyDetails.username}
              onChange={(e) => handleInputChange("username", e.target.value)}
              type="text"
              autoComplete="new-password"
              placeholder="User Name"
              sx={{
                width: "199px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Email
            </Typography>
            <TextField
              value={pharmacyDetails.email}
              onChange={(e) => handleInputChange("user_email", e.target.value)}
              type="email"
              autoComplete="new-password"
              placeholder="Email"
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Password
            </Typography>
            <form autoComplete="off">
              <TextField
                value={pharmacyDetails.password}
                onChange={(e) => handleInputChange("password", e.target.value)}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="new-password" // Use an unconventional name to prevent browser interference
                autoComplete="new-password" // Explicitly disable autocomplete for this field
                sx={{
                  width: "237px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                }}
                InputProps={{
                  autoComplete: "new-password", // Explicitly set for InputProps
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                        sx={{
                          padding: "4px",
                          width: "24px",
                          height: "24px",
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOff
                            sx={{
                              fontSize: "16px",
                              width: "16px",
                              color: "#000",
                            }}
                          />
                        ) : (
                          <Visibility
                            sx={{
                              fontSize: "16px",
                              width: "16px",
                              color: "#000",
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Confirm Password
            </Typography>
            <TextField
              autoComplete="new-password"
              value={pharmacyDetails.confirmPassword}
              onChange={(e) =>
                handleInputChange("confirmPassword", e.target.value)
              }
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                  color: "#4b5563",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                      sx={{
                        padding: "4px",
                        width: "24px",
                        height: "24px",
                        padding: "4px",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOff
                          sx={{
                            fontSize: "16px",
                            width: "16px",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <Visibility
                          sx={{
                            fontSize: "16px",
                            width: "16px",
                            color: "#000",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Delivery Preferences
            <span style={{ color: "#fb1001" }}>(Mandatory Field)</span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                marginBottom: "3px",
              }}
            >
              Prefered Way Of Delivery
            </Typography>
            <CustomDropdown
              label="Select Preffered Way"
              search={true}
              value={selectedOption}
              onChange={(selectedValue) => {
                setSelectedOption(selectedValue);
                setPharmacyDetails((prev) => ({
                  ...prev,
                  preferred_way_of_delivery: selectedValue,
                }));
              }}
              options={[
                { value: "FaceToFace", label: "Face To Face" },
                {
                  value: "LeaveWithReceptionist",
                  label: "Leave With Receptionist",
                },
                { value: "LeaveAtFloor", label: "Leave At Floor" },
                { value: "LeaveAtDoor", label: "Leave At Door" },
                {
                  value: "LeaveAtDoorIfPTE-Signs",
                  label: "Leave At Door If PT E-Signs",
                },
                { value: "LeaveInTheMailBox", label: "Leave In The MailBox" },
                {
                  value: "LeaveInTheMailBoxIfPTE-Signs",
                  label: "Leave In The MailBox If PT E-Signs",
                },
              ]}
              customstyles={{
                width: "240px",
                height: "36px",
                borderRadius: "4px",
              }}
              isMultiSelect={false}
              error={!!fieldErrors.preferred_way_of_delivery}
              helperText={fieldErrors.preferred_way_of_delivery}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                marginBottom: "3px",
              }}
            >
              Call To Notify Failed Attempt
            </Typography>
            <CustomDropdown
              customstyles={{
                height: "32px",
                width: "240px",
              }}
              label="Notify Failed Attempt"
              value={selectedOptions4.map((option) => option.name)}
              onChange={handleSelectChange}
              options={[
                { value: "Second Attempt", label: "Second Attempt" },
                { value: "Third Attempt", label: "Third Attempt" },
              ]}
              isMultiSelect={true}
              error={!!fieldErrors.call_to_notify_failed_attempt}
              helperText={fieldErrors.call_to_notify_failed_attempt}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Preffered Number
            </Typography>
            <TextField
              value={pharmacyDetails.preferred_number}
              onChange={(e) =>
                handleInputChange("preferred_number", e.target.value)
              }
              error={!!fieldErrors.preferred_number}
              helperText={fieldErrors.preferred_number}
              type="text"
              autoComplete="new-password"
              placeholder="Preferred Number"
              sx={{
                width: "199px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                marginBottom: "3px",
              }}
            >
              Delivered By
            </Typography>
            <CustomDropdown
              value={selectedOption2 || pharmacyDetails.delivered_by}
              onChange={(selectedValue) => {
                setSelectedOption2(selectedValue);
                setSelectedOption2(selectedValue);
                setPharmacyDetails((prev) => ({
                  ...prev,
                  delivered_by: selectedValue,
                  delivered_by: selectedValue,
                }));
              }}
              options={[
                { value: "pd", label: "PD" },
                {
                  value: "driver",
                  label: "Driver",
                },
                { value: "none", label: "None" },
              ]}
              customstyles={{
                width: "240px",
                height: "36px",
                borderRadius: "4px",
              }}
              isMultiSelect={false}
              error={!!fieldErrors.delivered_by}
              helperText={fieldErrors.delivered_by}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                marginBottom: "3px",
              }}
            >
              Cash Collector
            </Typography>
            <Autocomplete
              disableClearable
              value={driver}
              onChange={handleDriverChange}
              options={driverList}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!fieldErrors.cashCollector}
                  helperText={fieldErrors.cashCollector}
                  fullWidth
                  placeholder="Select a driver"
                  autoComplete="new-password"
                  sx={{
                    width: "240px",
                    height: "36px",
                    "& .MuiOutlinedInput-root": {
                      height: "36px",
                      paddingLeft: "5px",
                    },
                    "& .MuiInputBase-input": {
                      height: "36px",
                      fontSize: "11px",
                    },
                  }}
                />
              )}
              popupIcon={<DropdownIcon />}
              filterOptions={(options, state) =>
                options.filter((option) =>
                  option.name
                    ?.toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                )
              }
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={`${option.id}-${option.name}`}
                  style={{ fontSize: "11px" }}
                >
                  {option.name}
                </li>
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Billing Rates
            <span style={{ color: "#fb1001" }}>(Mandatory Field)</span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Next Day
            </Typography>
            <TextField
              value={pharmacyDetails.nextDayPrice}
              onChange={(e) =>
                handleInputChange("nextDayPrice", e.target.value)
              }
              error={!!fieldErrors.nextDayPrice}
              helperText={fieldErrors.nextDayPrice}
              type="number"
              autoComplete="new-password"
              placeholder="20.00"
              sx={{
                width: "117px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ fontSize: "10px", paddingLeft: "5px" }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Next Day(Time Window)
            </Typography>
            <TextField
              value={pharmacyDetails.timeWindowPrice}
              onChange={(e) =>
                handleInputChange("timeWindowPrice", e.target.value)
              }
              error={!!fieldErrors.timeWindowPrice}
              helperText={fieldErrors.timeWindowPrice}
              type="number"
              autoComplete="new-password"
              placeholder="20.00"
              sx={{
                width: "145px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ fontSize: "10px", paddingLeft: "5px" }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Same Day
            </Typography>
            <TextField
              value={pharmacyDetails.sameDayPrice}
              onChange={(e) =>
                handleInputChange("sameDayPrice", e.target.value)
              }
              error={!!fieldErrors.sameDayPrice}
              helperText={fieldErrors.sameDayPrice}
              type="number"
              autoComplete="new-password"
              placeholder="20.00"
              sx={{
                width: "117px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ fontSize: "10px", paddingLeft: "5px" }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Urgent
            </Typography>
            <TextField
              value={pharmacyDetails.urgentPrice}
              onChange={(e) => handleInputChange("urgentPrice", e.target.value)}
              error={!!fieldErrors.urgentPrice}
              helperText={fieldErrors.urgentPrice}
              type="number"
              autoComplete="new-password"
              placeholder="20.00"
              sx={{
                width: "117px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ fontSize: "10px", paddingLeft: "5px" }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              1st Attempt
            </Typography>
            <TextField
              value={pharmacyDetails.f_attemptPrice}
              onChange={(e) =>
                handleInputChange("f_attemptPrice", e.target.value)
              }
              error={!!fieldErrors.f_attemptPrice}
              helperText={fieldErrors.f_attemptPrice}
              type="number"
              placeholder="20.00"
              sx={{
                width: "117px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                  MozAppearance: "textfield",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ fontSize: "10px", paddingLeft: "5px" }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              2nd Attempt
            </Typography>
            <TextField
              value={pharmacyDetails.s_attemptPrice}
              onChange={(e) =>
                handleInputChange("s_attemptPrice", e.target.value)
              }
              error={!!fieldErrors.secondAttemptPrice}
              helperText={fieldErrors.secondAttemptPrice}
              type="number"
              autoComplete="new-password"
              placeholder="20.00"
              sx={{
                width: "117px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ fontSize: "10px", paddingLeft: "5px" }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              3rd Attempt
            </Typography>
            <TextField
              value={pharmacyDetails.t_attemptPrice}
              onChange={(e) =>
                handleInputChange("t_attemptPrice", e.target.value)
              }
              error={!!fieldErrors.thirdAttemptPrice}
              helperText={fieldErrors.thirdAttemptPrice}
              type="number"
              autoComplete="new-password"
              placeholder="20.00"
              sx={{
                width: "117px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ fontSize: "10px", paddingLeft: "5px" }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Cancel/Return
            </Typography>
            <TextField
              value={pharmacyDetails.cancelPrice}
              onChange={(e) => handleInputChange("cancelPrice", e.target.value)}
              error={!!fieldErrors.cancelPrice}
              helperText={fieldErrors.cancelPrice}
              type="number"
              autoComplete="new-password"
              placeholder="20.00"
              sx={{
                width: "117px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ fontSize: "10px", paddingLeft: "5px" }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Pharmacy Billing Account Information
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Bank Name
            </Typography>
            <TextField
              name="name"
              value={bankInfo.name}
              onChange={handleBankInputChange}
              type="text"
              autoComplete="new-password"
              placeholder="Bank Name"
              sx={{
                width: "197px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Title on Account
            </Typography>
            <TextField
              name="account_title"
              value={bankInfo.account_title}
              onChange={handleBankInputChange}
              type="text"
              autoComplete="new-password"
              placeholder="Title on Account"
              sx={{
                width: "197px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Account Number
            </Typography>
            <TextField
              name="account_number"
              value={bankInfo.account_number}
              onChange={handleBankInputChange}
              type="number"
              autoComplete="new-password"
              placeholder="Account Number"
              sx={{
                width: "197px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              ACH Routing
            </Typography>
            <TextField
              name="ach_routing"
              value={bankInfo.ach_routing}
              onChange={handleBankInputChange}
              type="number"
              autoComplete="new-password"
              placeholder="ACH Routing"
              sx={{
                width: "197px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Credit Card
            </Typography>
            <TextField
              name="credit_crad"
              value={bankInfo.credit_crad}
              onChange={handleBankInputChange}
              type="number"
              autoComplete="new-password"
              placeholder="Credit Card"
              sx={{
                width: "197px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Additional Information
          </Typography>
        </Box>

        <TextField
          value={pharmacyDetails.notes}
          onChange={(e) => handleInputChange("notes", e.target.value)}
          type="text"
          autoComplete="new-password"
          placeholder="Text Here"
          multiline
          rows={4}
          sx={{
            width: "830px",
            "& .MuiInputBase-root": {
              padding: "5px 10px",
            },
            "& .MuiInputBase-input": {
              fontSize: "11px",
            },
            "& .MuiOutlinedInput-root": {
              "& textarea": {
                fontSize: "11px",
              },
            },
            "& .MuiInputLabel-root": {
              fontSize: "11px",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "#4b5563",
            },
          }}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Add Hippa Notes
            <Checkbox
              onChange={handleCheckboxChange}
              checked={isHippaEnabled}
              sx={{
                transform: "scale(0.8)",
                width: "10px",
                height: "10px",
                paddingLeft: 2,
              }}
            />
          </Typography>
        </Box>

        <TextField
          value={pharmacyDetails.hippa_notes}
          onChange={(e) => handleInputChange("hippa_notes", e.target.value)}
          type="text"
          autoComplete="new-password"
          placeholder="Hippa Notes"
          multiline
          rows={6}
          disabled={!isHippaEnabled}
          sx={{
            width: "full",
            "& .MuiInputBase-root": {
              padding: "5px 10px",
            },
            "& .MuiInputBase-input": {
              fontSize: "11px",
            },
            "& .MuiOutlinedInput-root": {
              "& textarea": {
                fontSize: "11px",
              },
            },
            "& .MuiInputLabel-root": {
              fontSize: "11px",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "#4b5563",
            },
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        {pharmacyId && (
          <ContainedButton
            label="Delete"
            onClick={handleOpenDeleteModal}
            borderRadius="5px"
            fontSize="13px"
            width="66px"
            height="32px"
            bgColor="#f44336"
          />
        )}
        <ContainedButton
          label={
            loading ? (
              <CircularProgress size={16} sx={{ color: "white" }} />
            ) : (
              "Save"
            )
          }
          onClick={handleSubmit}
          borderRadius="5px"
          fontSize="13px"
          width="66px"
          height="32px"
          loading={loading}
          disabled={loading}
        />
      </Box>
      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Pharmacy?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            sx={{
              backgroundColor: "#f44336",
              color: "white",
              backgroundColor: "#f44336",
              color: "white",
              "&:hover": {
                backgroundColor: "#d32f2f",
                backgroundColor: "#d32f2f",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PharmacyInformationForm;
