import { Box } from "@mui/material";
import React from "react";
import PharmacyDetails from "./PharmacyDetails";
import DeliveryTime from "./DeliveryTime";

const OrderOverview = () => {
  // Define the data for each section, with dynamic delivery times
  const orders = [
    {
      id: 1,
      deliveryTimes: [
        { time: "2PM TO 6PM", sameDay: true },
        { time: "5PM TO 9PM", sameDay: true },
        { time: "ANYTIME", sameDay: true },
        { time: "9AM TO 2PM", sameDay: false },
        { time: "2PM TO 6PM", sameDay: false },
        { time: "5PM TO 9PM", sameDay: false },
      ],
    },
    {
      id: 2,
      deliveryTimes: [
        { time: "8AM TO 12PM", sameDay: true },
        { time: "12PM TO 4PM", sameDay: true },
        { time: "4PM TO 8PM", sameDay: true },
        { time: "ANYTIME", sameDay: false },
        { time: "ANYTIME", sameDay: false },
        { time: "6AM TO 10AM", sameDay: false },
      ],
    },
    {
      id: 3,
      deliveryTimes: [
        { time: "10AM TO 2PM", sameDay: true },
        { time: "2PM TO 6PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "ANYTIME", sameDay: false },
      ],
    },
    {
      id: 4,
      deliveryTimes: [
        { time: "10AM TO 2PM", sameDay: true },
        { time: "2PM TO 6PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "ANYTIME", sameDay: false },
      ],
    },
    {
      id: 5,
      deliveryTimes: [
        { time: "8AM TO 12PM", sameDay: true },
        { time: "12PM TO 4PM", sameDay: true },
        { time: "4PM TO 8PM", sameDay: true },
        { time: "ANYTIME", sameDay: false },
        { time: "ANYTIME", sameDay: false },
        { time: "6AM TO 10AM", sameDay: false },
      ],
    },
    {
      id: 6,
      deliveryTimes: [
        { time: "8AM TO 12PM", sameDay: true },
        { time: "12PM TO 4PM", sameDay: true },
        { time: "4PM TO 8PM", sameDay: true },
        { time: "ANYTIME", sameDay: false },
        { time: "ANYTIME", sameDay: false },
        { time: "6AM TO 10AM", sameDay: false },
      ],
    },
    {
      id: 7,
      deliveryTimes: [
        { time: "10AM TO 2PM", sameDay: true },
        { time: "2PM TO 6PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "ANYTIME", sameDay: false },
      ],
    },
    {
      id: 8,
      deliveryTimes: [
        { time: "10AM TO 2PM", sameDay: true },
        { time: "2PM TO 6PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "6PM TO 10PM", sameDay: true },
        { time: "ANYTIME", sameDay: false },
      ],
    },
    {
      id: 9,
      deliveryTimes: [
        { time: "8AM TO 12PM", sameDay: true },
        { time: "12PM TO 4PM", sameDay: true },
        { time: "4PM TO 8PM", sameDay: true },
        { time: "ANYTIME", sameDay: false },
        { time: "ANYTIME", sameDay: false },
        { time: "6AM TO 10AM", sameDay: false },
      ],
    },
  ];

  return (
    <Box>
      {/* Render each order section */}
      {orders.map((order) => (
        <Box
          key={order.id}
          sx={{
            display: "flex",
            px: 1,
            py: 1,
            borderRadius: "8px",
            boxShadow: 1,
            transition: "background-color 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#f0f0f0",
              boxShadow: 3,
            },
            mb: 2, // Margin between sections
          }}
        >
          {/* Pharmacy Details Section */}
          <Box sx={{ flex: 1 }}>
            <PharmacyDetails />
          </Box>

          {/* Delivery Times Section */}
          <Box
            sx={{ display: "flex", flex: 5, justifyContent: "space-evenly" }}
          >
            {order.deliveryTimes.map((delivery, index) => (
              <DeliveryTime
                key={index}
                time={delivery.time}
                sameDay={delivery.sameDay}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default OrderOverview;
