import React, { createContext, useState, useEffect } from 'react';
export const OrderDataContext = createContext();
export const OrderDataProvider = ({ children }) => {
    const [orderData, setOrderData] = useState(() => {
        const savedData = localStorage.getItem("orderData");
        return savedData
            ? JSON.parse(savedData)
            : {
                  createdAt: new Date(),
                  deliveryDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000), // 1 day ahead
                  timeWindow: 'Anytime',
                  streetViewUrl: '',
                  name: '',
                  language: 'english',
                  address: '',
                  apart: '',
                  contact: '',
                  cell: '',
                  workNumber: '',
                  zip: '',
                  notes: '',
                  totalCopay: 0,
                  signRequired: true,
                  reqPhotoId: false,
                  registed: false,
                  collectCopay: false,
                  hippaForm: false,
                  lineForm: false,
                  nfForm: false,
                  addresstype: false,
                  business_start_time: "09:00",
                  business_end_time:"17:00",
                  parcelPlace: 'Face to face',
                  lat: '',
                  lng: '',
                  city_code: '',
                  orderdetail: [
                      {
                          quantity: '',
                          rf_number: '',
                          date: '',
                          pat_pay_amount: "0.00",
                          rx_number: 'M - ',
                          select: false,
                      },
                  ],
                  file: {},
              };
    });
    useEffect(() => {
        localStorage.setItem("orderData", JSON.stringify(orderData));
    }, [orderData]);
    return (
        <OrderDataContext.Provider value={{ orderData, setOrderData }}>
            {children}
        </OrderDataContext.Provider>
    );
};
