import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomDropdown from "../CustomDropdown";

const OrderStatus = () => {
  const [selectedDrivers, setSelectedDrivers] = useState([]); // For managing selected drivers
  const driverOptions = [
    { label: "All Drivers", value: "all" },
    { label: "Specific Driver", value: "specific" },
  ];
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 4 }}>
      <Box
        sx={{
          display: "flex",
          gap: 4,
          flex: 2,
        }}
      >
        <CustomDropdown
          label={
            selectedDrivers.length === 0
              ? "All/AnySpecific Driver"
              : selectedDrivers.join(", ")
          }
          options={driverOptions}
          value={selectedDrivers}
          onChange={setSelectedDrivers}
          customstyles={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            padding: "8px",
            width: "200px",
            border: "1px solid #dce0e4",
            fontSize: "11px",
            paddingX: "15px",
            color: "#000",
          }}
          isMultiSelect={false} // Change to true if you want multi-select
        />
        <Typography
          sx={{
            border: "1px solid #dce0e4",
            color: "#000",
            fontSize: "11px",
            borderRadius: "7px",
            padding: "8px",
            paddingX: "15px",
          }}
        >
          Total Stops <span style={{ color: "#146c1e" }}>2360</span>
        </Typography>
        <Typography
          sx={{
            border: "1px solid #dce0e4",
            color: "#000",
            fontSize: "11px",
            borderRadius: "7px",
            padding: "8px",
            paddingX: "15px",
          }}
        >
          Done Stops <span style={{ color: "#146c1e" }}>236</span>
        </Typography>
        <Typography
          sx={{
            border: "1px solid #dce0e4",
            color: "#000",
            fontSize: "11px",
            borderRadius: "7px",
            padding: "8px",
            paddingX: "15px",
          }}
        >
          Failed Stops <span style={{ color: "#fb1001" }}>236</span>
        </Typography>
        <Typography
          sx={{
            border: "1px solid #dce0e4",
            color: "#000",
            fontSize: "11px",
            borderRadius: "7px",
            padding: "8px",
            paddingX: "15px",
          }}
        >
          Pending Stops <span style={{ color: "#fe9604" }}>236</span>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1.3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            border: "1px solid #dce0e4",
            borderRadius: "7px",
            padding: "8px",
          }}
        >
          <Typography sx={{ fontSize: "11px", color: "#05b320" }}>
            Done: 10
          </Typography>
          <Typography sx={{ fontSize: "11px", color: "#fc1002" }}>
            Failed: 93
          </Typography>
          <Typography sx={{ fontSize: "11px", color: "#fe9604" }}>
            Pending: 5
          </Typography>
        </Box>
        <Typography
          sx={{
            border: "1px solid #dce0e4",
            color: "#000",
            fontSize: "11px",
            borderRadius: "7px",
            padding: "8px",
            paddingX: "15px",
          }}
        >
          Done <span style={{ color: "#146c1e", fontWeight: 700 }}>236</span>
        </Typography>
        <Typography
          sx={{
            border: "1px solid #dce0e4",
            color: "#000",
            fontSize: "11px",
            borderRadius: "7px",
            padding: "8px",
            paddingX: "15px",
          }}
        >
          Failed <span style={{ color: "#fb1001", fontWeight: 700 }}>236</span>
        </Typography>
        <Typography
          sx={{
            border: "1px solid #dce0e4",
            color: "#000",
            fontSize: "11px",
            borderRadius: "7px",
            padding: "8px",
            paddingX: "15px",
          }}
        >
          Pending <span style={{ color: "#fe9604", fontWeight: 700 }}>236</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderStatus;
