import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import DateRange from "../components/reportPageCompo/DateRange";
import DateOverview from "../components/reportPageCompo/DateOverview.jsx";
import { styled } from "@mui/system";
import SingleFieldDateRangePicker from "../components/dateRangePicker/DateRangePicker.jsx";
import moment from "moment";
import CustomDropdown from "../components/CustomDropdown.jsx";
import { PHARMACY_REPORT_DETAIL, ALL_PHARMACY_LIST } from "../utils/apiUrl";
import { putApiWithAuth, getApiWithAuth } from "../utils/api";
const isCurrentWeek = (dateRange) => {
  const [startDate, endDate] = dateRange.split(" - ");
  const currentDate = new Date();
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  return false;
};
const ReportsPage = ({ extraContent }) => {
  const [filter, setFilter] = useState([]);
  const [dateRange, setDateRange] = useState(() => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 14);
    return [startDate, endDate];
  });
  const [pharmacyOrderList, setPharmacyOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const pid = localStorage.getItem("pharmacyId");
  const userType = localStorage.getItem("userType");
  const [pharmacy, setPharmacy] = useState("");
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };
  const formControlStyle = {
    minWidth: 120,
    height: 36,
    "& .MuiInputBase-root": {
      height: 36,
      display: "flex",
      alignItems: "center",
    },
  };
  const handlePharmacyChange = (value) => {
    if (value) {
      setPharmacy(value);
    } else {
      setPharmacy("");
    }
  };
  const renderMenuItem = (value, label, selectedValues) => (
    <MenuItem key={value} value={value} sx={{ fontSize: "11px" }}>
      <Checkbox size="small" checked={selectedValues.indexOf(value) > -1} />
      {label}
    </MenuItem>
  );
  const handleChange = (setter) => (event) => {
    const {
      target: { value },
    } = event;
    setter(typeof value === "string" ? value.split(",") : value);
  };
  const fetchPharmacies = async () => {
    try {
      const response = await getApiWithAuth(ALL_PHARMACY_LIST, {
        justList: true,
      });
      if (response.data !== undefined) {
        setUniquePharmacy(
          response.data.data.map((item) => ({ id: item.id, name: item.name }))
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const filteredPharmacyOrderList = pharmacyOrderList?.filter(
    (pharmacyOrder) => !isCurrentWeek(pharmacyOrder.dateRange)
  );
  const getPharmacyOrderList = async () => {
    setLoading(true);

    if (userType === "PharmacyOwner" || userType === "PharmacyEmployee") {
      const validEndDate =
        dateRange[1] && !isNaN(dateRange[1].getTime())
          ? dateRange[1]
          : new Date();
      const formData = {
        data: "pharmacy",
        pharmacy: pid,
        from: moment(dateRange[0]).format("YYYY-MM-DD"),
        to: moment(validEndDate).format("YYYY-MM-DD"),
      };
      const response = await putApiWithAuth(PHARMACY_REPORT_DETAIL, formData);
      if (response.data !== undefined) {
        setPharmacyOrderList(response.data);
      }
    } else if (userType === "Admin" || userType === "SubAdmin") {
      const validEndDate =
        dateRange[1] && !isNaN(dateRange[1].getTime())
          ? dateRange[1]
          : new Date();
      const formData = {
        data: pharmacy.length === 0 ? "all" : "specific",
        from: moment(dateRange[0]).format("YYYY-MM-DD"),
        to: moment(validEndDate).format("YYYY-MM-DD"),
      };

      if (pharmacy.length > 0) {
        formData.pharmacy = pharmacy;
      }
      const response = await putApiWithAuth(PHARMACY_REPORT_DETAIL, formData);
      if (response.data !== undefined) {
        setPharmacyOrderList(response.data);
      }
    }

    setLoading(false);
  };
  useEffect(() => {
    getPharmacyOrderList();
  }, [dateRange, pharmacy]);
  useEffect(() => {
    fetchPharmacies();
  }, []);
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        paddingTop: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {userType !== "PharmacyOwner" && userType !== "PharmacyEmployee" && (
            <div style={{ marginRight: "10px" }}>
              <CustomDropdown
                customstyles={{
                  height: "36px",
                  width: "137px",
                }}
                label="Filter Pharmacy"
                value={pharmacy}
                onChange={handlePharmacyChange}
                options={uniquePharmacy.map((pharm) => ({
                  value: pharm.id,
                  label: pharm.name,
                }))}
                isMultiSelect={true}
              />
            </div>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SingleFieldDateRangePicker
            value={dateRange}
            onChange={handleDateChange}
          />
        </Box>
      </Box>
      {(userType === "PharmacyOwner" || userType === "PharmacyEmployee") &&
        pharmacyOrderList.reverse().map((pharmacy, index) => {
          return (
            <Box key={index} sx={{ marginBottom: "24px" }}>
              <DateRange pharmacyDetails={pharmacy} />
              <DateOverview pharmaDetails={pharmacy} />
            </Box>
          );
        })}
      {(userType === "Admin" || userType === "SubAdmin") &&
        filteredPharmacyOrderList?.length > 0 &&
        filteredPharmacyOrderList
          .slice()
          .sort((a, b) => {
            const dateA = new Date(a.dateRange.split(" - ")[0]);
            const dateB = new Date(b.dateRange.split(" - ")[0]);
            return dateB - dateA;
          })
          .map((pharmacyOrder, i) => (
            <>
              <DateRange pharmacyDetails={pharmacyOrder} />
              <div key={i}>
                {pharmacyOrder.data?.length > 0 &&
                  pharmacyOrder.data.map((orderReport, index) => (
                    <Box key={index} sx={{ marginBottom: "24px" }}>
                      <DateOverview pharmaDetails={orderReport} />
                    </Box>
                  ))}
              </div>
            </>
          ))}
    </Box>
  );
};

export default ReportsPage;
