import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Checkbox,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Input,
  Modal,
} from "@mui/material";
import { ChooseImageIcon, UploadIcon } from "../Icons";
import CustomDropdown from "../CustomDropdown";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PhoneNumberField } from "../Ui/PhoneNumberField";
import Topbar from "./Topbar";
import { ContainedButton } from "../buttonCompo/Buttons";
import {
  postApiWithAuth,
  getApiWithAuth,
  postFormDataAPI,
  patchApiWithAuth,
  putApiWithAuth,
  deleteApiWithAuth,
} from "../../utils/api";
import {
  ADD_DRIVER,
  ADD_BANK,
  ADD_VEHICLE,
  USER_TYPE_URL,
  FACILITY_TEAM_URL,
  FACILITY_Team_api,
  FACILITY_Update_api,
  FACILITY_Team_bank,
  FacilityTeam_updatebank,
  FACILITY_Team_add,
  FACILITY_Team_document_update,
  FACILITY_Team_document,
} from "../../utils/apiUrl";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
/* Muhammad Usman */
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const UserForm = () => {
  const location = useLocation();
  const [idImage, setIdImage] = useState(
    "https://cdn2.vectorstock.com/i/1000x1000/37/61/id-card-icon-male-user-person-profile-avatar-vector-23703761.jpg"
  );
  const [idImage1, setIdImage1] = useState(
    "https://cdn2.vectorstock.com/i/1000x1000/37/61/id-card-icon-male-user-person-profile-avatar-vector-23703761.jpg"
  );
  const [backendimg, setbackendimg] = useState(
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
  );
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [payType, setPayType] = useState("Hourly");
  const [personalDetail, setPersonalDetail] = useState({
    firstName: "",
    lastName: "",
    show_dashboard: false,
    show_rx: false,
    show_pharm: false,
    show_driver: false,
    show_routes: false,
    show_cities: false,
    show_facuser: false,
    show_reports: false,
    show_noti: false,
    show_scanning: false,
    show_finance: false,
    calling_allowed: false,
    sms_allowed: false,
    address: "",
    dateOfBirth: "",
    phoneNumber: "",
    cellNumber: "",
    isActive: true,
    logo: "",
    email: "",
    socialSecurityNo: "",
    idIssued: "",
    idExpired: "",
    hourPrice: 0,
    overTimePrice: 0,
    weekPrice: 0,
    type: "Staff",
    username: "",
    password: "",
    addtionalNote: "",
    pictureIdNo: "",
    pay_type: payType,
    regularHourPrice: 0,
    id1card: "",
    id2card: "",
    user: 1,
  });
  const [userTypes, setUserTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    id: "",
    bankName: "",
    accountTitle: "",
    accountNo: "",
    achRouting: "",
  });
  const [facility_team_work, setfacility_team_work] = useState({
    Monday: { checked: false, start_time: "", end_time: "" },
    Tuesday: { checked: false, start_time: "", end_time: "" },
    Wednesday: { checked: false, start_time: "", end_time: "" },
    Thursday: { checked: false, start_time: "", end_time: "" },
    Friday: { checked: false, start_time: "", end_time: "" },
    Saturday: { checked: false, start_time: "", end_time: "" },
    Sunday: { checked: false, start_time: "", end_time: "" },
  });

  const [facility_team_documents, setfacility_team_documents] = useState({
    id: "",
    description: "",
    document: null,
  });
  const [errors, setErrors] = useState({});
  const [front, setFront] = useState("");
  const [back, setBack] = useState("");
  const navigate = useNavigate();

  // const handleImageUpload = (event, setPreview) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => setPreview(reader.result);
  //     reader.readAsDataURL(file);
  //   }
  // };
  const handleImageUpload = (event) => {
    const fileObject = event.target.files[0];
    setPersonalDetail({
      ...personalDetail,
      id1card: fileObject,
    });
    const { name, size, type, lastModified } = fileObject;
    const fileBlob = new Blob([fileObject], { type });
    const file = new File([fileBlob], name, { lastModified });
    // setFrontImage(file);
    if (file) {
      const reader = new FileReader();
      //

      reader.onloadend = () => {
        // Set the image URL in the state
        setFront(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageUpload2 = (event) => {
    const fileObject = event.target.files[0];
    setPersonalDetail({
      ...personalDetail,
      id2card: fileObject,
    });
    const { name, size, type, lastModified } = fileObject;
    const fileBlob = new Blob([fileObject], { type });
    const file = new File([fileBlob], name, { lastModified });
    // setBackImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the image URL in the state
        setBack(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const validateForm = () => {
    const tempErrors = {};

    // Validate personal details
    if (!personalDetail.firstName.trim())
      tempErrors.firstName = "First Name is required.";
    if (!personalDetail.lastName.trim())
      tempErrors.lastName = "Last Name is required.";
    if (!personalDetail.address.trim())
      tempErrors.address = "Address is required.";
    if (!personalDetail.email.trim()) tempErrors.email = "Email is required.";
    if (!personalDetail.phoneNumber.trim())
      tempErrors.phoneNumber = "Phone Number is required.";
    if (!personalDetail.dateOfBirth.trim())
      tempErrors.dateOfBirth = "Date of Birth is required.";
    if (!personalDetail.socialSecurityNo.trim())
      tempErrors.socialSecurityNo = "SSN is required.";
    if (!personalDetail.pictureIdNo.trim())
      tempErrors.pictureIdNo = "PictureId is required.";
    if (!personalDetail.idIssued.trim())
      tempErrors.idIssued = "Id Issued is required.";
    if (!personalDetail.idExpired.trim())
      tempErrors.idExpired = "Id Expired is required.";

    if (
      personalDetail.password &&
      personalDetail.password !== personalDetail.confirmPassword
    )
      tempErrors.confirmPassword = "Passwords do not match.";

    //Account Credentials

    // if (!personalDetail.email.trim()) tempErrors.email = "Email is required.";
    // if (!personalDetail.password.trim())
    //   tempErrors.password = "Password is required.";
    // if (!personalDetail.confirmPassword.trim())
    //   tempErrors.confirmPassword = "Password is required.";

    // Validate bank details
    // if (bankDetails.accountNo && !bankDetails.bankName.trim())
    //   tempErrors.bankName =
    //     "Bank Name is required if Account Number is provided.";
    // if (bankDetails.accountNo && !bankDetails.accountTitle.trim())
    //   tempErrors.accountTitle =
    //     "Account Title is required if Account Number is provided.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const getUserType = async () => {
    setLoading(true);

    const response = await getApiWithAuth(USER_TYPE_URL);

    if (response.data !== undefined) {
      const filteredData = response.data.data.filter(
        (item) =>
          item.type === "Admin" ||
          item.type === "Staff" ||
          item.type === "SubAdmin"
      );
      setUserTypes(filteredData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    console.log("userId:", userId);
    if (userId) {
      setLoading(true);
      try {
        const response = await getApiWithAuth(`${FACILITY_TEAM_URL}${userId}/`);
        console.log(response.data.data);
        if (response.data && response.data.data) {
          const userData = response.data.data;
          setPayType(userData.pay_type);
          setPersonalDetail({
            firstName: userData.firstName || "",
            lastName: userData.lastName || "",
            email: userData.email || "",
            phoneNumber: userData.phoneNumber || "",
            cellNumber: userData.cellNumber || "",
            address: userData.address || "",
            dateOfBirth: userData.dateOfBirth || "",
            isActive: userData.isActive || true,
            socialSecurityNo: userData.socialSecurityNo || "",
            idIssued: userData.idIssued || "",
            idExpired: userData.idExpired || "",
            hourPrice: userData.hourPrice || 0,
            overTimePrice: userData.overTimePrice || 0,
            weekPrice: userData.weekPrice || 0,
            type: userData.type || "Staff",
            username: userData.user.username || "",
            password: "",
            show_dashboard: userData.show_dashboard || false,
            show_rx: userData.show_rx || false,
            show_pharm: userData.show_pharm || false,
            show_driver: userData.show_driver || false,
            show_routes: userData.show_routes || false,
            show_cities: userData.show_cities || false,
            show_facuser: userData.show_facuser || false,
            show_reports: userData.show_reports || false,
            show_noti: userData.show_noti || false,
            show_scanning: userData.show_scanning || false,
            show_finance: userData.show_finance || false,
            calling_allowed: userData.calling_allowed || false,
            sms_allowed: userData.sms_allowed || false,
            addtionalNote: userData.addtionalNote || "",
            pictureIdNo: userData.pictureIdNo || "",
            pay_type: userData.pay_type || "",
            regularHourPrice:
              userData.regularHourPrice === null
                ? 0
                : userData.regularHourPrice || 0,
            id1card: userData.id1card || "",
            id2card: userData.id2card || "",
            user: userData.user.id || 1,
          });
          setFronts(userData?.logo);
          setFront(response.data.data?.id2card);
          setBack(response.data.data?.id1card);
          setBankDetails({
            id: userData.acility_team_bank[0]?.id || "",
            bankName: userData.acility_team_bank[0]?.bankName,
            accountTitle: userData.acility_team_bank[0]?.accountTitle,
            accountNo: userData.acility_team_bank[0]?.accountNo,
            achRouting: userData.acility_team_bank[0]?.achRouting,
          });

          setfacility_team_documents((prevState) => ({
            ...prevState,
            id: userData?.facility_team_documents[0]?.id,
            description: userData?.facility_team_documents[0]?.description,
          }));
          if (userData.working_schedules) {
            const updatedFacilityTeamWork = Object.keys(
              facility_team_work
            ).reduce((acc, day) => {
              const schedule = userData.working_schedules.find(
                (item) => item.day === day
              );
              acc[day] = schedule
                ? {
                    checked: true,
                    start_time: schedule.start_time,
                    end_time: schedule.end_time,
                  }
                : { checked: false, start_time: "", end_time: "" };
              return acc;
            }, {});
            setfacility_team_work(updatedFacilityTeamWork);
          }
        } else {
          toast.error("User data not found.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to fetch user data.");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getUserType();
    fetchUserData();
    console.log(personalDetail.show_cities);
  }, [userId]);

  const handleWeekdayChange = (day) => (event) => {
    setfacility_team_work((prevState) => ({
      ...prevState,
      [day]: { ...prevState[day], checked: event.target.checked },
    }));
  };

  const handleStartTimeChange = (day, value) => {
    const formattedTime = convertTo24HourFormat(value); // Convert to 24-hour format
    setfacility_team_work((prevState) => ({
      ...prevState,
      [day]: { ...prevState[day], start_time: formattedTime }, // Store the 24-hour time
    }));
  };

  const handleEndTimeChange = (day, value) => {
    const formattedTime = convertTo24HourFormat(value); // Convert to 24-hour format
    setfacility_team_work((prevState) => ({
      ...prevState,
      [day]: { ...prevState[day], end_time: formattedTime }, // Store the 24-hour time
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill all required fields.");
      return;
    }

    setLoading(true);

    const working_schedule = Object.entries(facility_team_work)
      .filter(
        ([day, { checked, start_time, end_time }]) =>
          checked && start_time && end_time
      )
      .map(([day, { start_time, end_time }]) => ({
        day,
        start_time,
        end_time,
        is_working_day: true,
      }));

    try {
      // Prepare the personal details payload, including working schedule
      const updatedPersonalDetail = new FormData();
      Object.entries(personalDetail).forEach(([key, value]) => {
        updatedPersonalDetail.append(key, value);
      });
      if (personalDetail.id1card) {
        updatedPersonalDetail.append("id1card", personalDetail.id1card);
      }
      if (personalDetail.id2card) {
        updatedPersonalDetail.append("id2card", personalDetail.id2card);
      }
      if (personalDetail.logo instanceof File) {
        updatedPersonalDetail.append("logo", personalDetail.logo);
      } else if (
        typeof personalDetail.logo === "string" &&
        personalDetail.logo.length > 0
      ) {
        updatedPersonalDetail.append("logo", personalDetail.logo);
      }
      if (front && front.length > 0) {
        updatedPersonalDetail.append("id1card", personalDetail.id1card);
      }
      if (back && back.length > 1) {
        updatedPersonalDetail.append("id2card", personalDetail.id2card);
      }
      updatedPersonalDetail.append(
        "working_schedule",
        JSON.stringify(working_schedule)
      );

      // Determine if we are in Edit Mode (check if fid exists, which means we are editing an existing user)
      const updatePersonalUrl = userId
        ? `${FACILITY_Update_api}/${userId}`
        : FACILITY_Team_api;
      const methodPersonal = userId ? "patch" : "post";

      let responsePersonal;
      if (methodPersonal === "post") {
        responsePersonal = await postApiWithAuth(
          FACILITY_Team_api,
          updatedPersonalDetail
        );
      } else if (userId) {
        console.log("id", userId);
        responsePersonal = await patchApiWithAuth(
          `${FACILITY_Update_api}/${userId}`,
          updatedPersonalDetail
        );
      }

      if (responsePersonal && responsePersonal.data) {
        // Handle bank details if available and update the bank information
        if (bankDetails.accountNo) {
          const bankdatatoUpdate = new FormData();
          Object.entries(bankDetails).forEach(([key, value]) => {
            bankdatatoUpdate.append(key, value);
          });

          const bankUpdateUrl = userId
            ? await patchApiWithAuth(
                `${FacilityTeam_updatebank}/${bankDetails.id}`,
                bankdatatoUpdate
              )
            : await postApiWithAuth(FACILITY_Team_bank, bankdatatoUpdate);
        }

        // Handle documents if available and update document data
        if (facility_team_documents && facility_team_documents.description) {
          const doctdata = new FormData();
          doctdata.append("description", facility_team_documents.description);
          doctdata.append("facilityTeam", responsePersonal.data.data.id);
          const responseDocument = await postApiWithAuth(
            FACILITY_Team_document,
            doctdata
          );
        }

        toast.success("User form submitted successfully!");
        navigate("/facilityuser");
      } else {
        throw new Error("Failed to submit personal details.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred during submission.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!userId) {
      console.error("No User ID found for deletion.");
      toast.error("No User selected for deletion.");
      return;
    }

    try {
      const response = await deleteApiWithAuth(
        `${FACILITY_Team_api}${userId}/`
      );
      if (response.data?.status === 200 || response.data?.status === 204) {
        toast.success("User deleted successfully!");
        navigate("/facilityuser"); // Redirect after successful deletion
      } else {
        console.error("Failed to delete user:", response);
        toast.error("An error occurred while deleting the User.");
      }
    } catch (error) {
      console.error("Error during user deletion:", error);
      toast.error("An unexpected error occurred during deletion.");
    } finally {
      handleCloseDeleteModal();
    }
  };

  const handleInputChange = (setter, field, value) => {
    setter((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const payrateOptions = [
    { value: "Hourly", label: "Hourly" },
    { value: "Weekly", label: "Weekly" },
  ];

  const handlePayTypeChange = (value) => {
    setPayType(value);
    handleInputChange(setPersonalDetail, "pay_type", value);
  };

  const convertTo24HourFormat = (time) => {
    const [hours, minutes] = time.split(":");
    let hoursInt = parseInt(hours);
    const ampm = time.slice(-2).toUpperCase();

    if (ampm === "PM" && hoursInt < 12) {
      hoursInt += 12; // Convert PM time to 24-hour format
    } else if (ampm === "AM" && hoursInt === 12) {
      hoursInt = 0; // Convert 12 AM to 00:00
    }

    const hours24 = hoursInt.toString().padStart(2, "0");
    return `${hours24}:${minutes}`;
  };

  /* Muhammad Usman Start */
  const [fronts, setFronts] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const cropperRef = useRef(null);

  const handleImageUpload3 = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    console.log("File selected:", file);
    setFileObject(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setFronts(reader.result);
      setOpenModal(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        croppedCanvas.toBlob((blob) => {
          const croppedFile = new File([blob], fileObject.name, {
            type: "image/png",
          });

          setPersonalDetail({
            ...personalDetail,
            logo: croppedFile,
          });

          const reader = new FileReader();
          reader.onloadend = () => {
            setFronts(reader.result);
          };
          reader.readAsDataURL(blob);
        }, "image/png");

        setOpenModal(false);
      }
    }
  };
  /* Muhammad Usman End */
  const theme = useTheme();

  return (
    <Box>
      {/* <Topbar /> */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* Muhammad Usman Start */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageUpload3}
                  sx={{ display: "none" }}
                />
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    borderColor: "#05b320",
                    color: "#05b320",
                    width: "120px",
                    textTransform: "none",
                    fontSize: "13px",
                    height: "35px",
                  }}
                >
                  Choose File
                </Button>
              </label>
              <Typography variant="body2" sx={{ color: "#757575" }}>
                {fileObject ? fileObject.name : "No File Chosen"}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "80px",
                height: "80px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f4f4f4",
                border: "1px solid #ddd",
                borderRadius: "5px",
              }}
            >
              {fronts ? (
                <img
                  src={fronts}
                  alt="Cropped Preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Typography
                  variant="body2"
                  sx={{ fontSize: "48px", color: "#757575" }}
                >
                  📷
                </Typography>
              )}
            </Box>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 3,
                  borderRadius: 2,
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Crop & Adjust Image
                </Typography>

                <Cropper
                  src={fronts}
                  style={{ height: 250, width: "100%" }}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  guides={true}
                  ref={cropperRef}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCrop}
                  >
                    Save Cropped
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Box>
          {/* Muhammad Usman End */}

          <Box sx={{ display: "flex", gap: 1 }}>
            <ContainedButton
              label="Activate"
              border="none"
              bgColor={personalDetail.isActive ? "#05b320" : "transparent"}
              borderRadius="4px"
              textColor={personalDetail.isActive ? "#fff" : "#05b320"}
              onClick={() =>
                handleInputChange(setPersonalDetail, "isActive", true)
              }
            />
            <ContainedButton
              label="Deactivate"
              border="1px solid #fc1002"
              bgColor={!personalDetail.isActive ? "#fc1002" : "transparent"}
              borderRadius="4px"
              textColor={!personalDetail.isActive ? "#fff" : "#fc1002"}
              onClick={() =>
                handleInputChange(setPersonalDetail, "isActive", false)
              }
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            PD Staff Information{"  "}
            <span style={{ color: "#fb1001" }}>(Mandatory Field)</span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              First Name
            </Typography>
            <TextField
              value={personalDetail.firstName}
              autoComplete="new-password"
              onChange={(e) =>
                handleInputChange(
                  setPersonalDetail,
                  "firstName",
                  e.target.value
                )
              }
              type="text"
              placeholder="Asad Arshad"
              error={!!errors.firstName}
              helperText={errors.firstName}
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Last Name
            </Typography>
            <TextField
              value={personalDetail.lastName}
              onChange={(e) =>
                handleInputChange(setPersonalDetail, "lastName", e.target.value)
              }
              type="text"
              autoComplete="new-password"
              placeholder="Arshad Sh"
              error={!!errors.lastName}
              helperText={errors.lastName}
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Address
            </Typography>
            <TextField
              value={personalDetail.address}
              onChange={(e) =>
                handleInputChange(setPersonalDetail, "address", e.target.value)
              }
              type="text"
              autoComplete="new-password"
              placeholder="Address"
              error={!!errors.address}
              helperText={errors.address}
              sx={{
                width: "396px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Email
            </Typography>
            <TextField
              value={personalDetail.email}
              onChange={(e) =>
                handleInputChange(setPersonalDetail, "email", e.target.value)
              }
              type="email"
              autoComplete="new-password"
              placeholder="email"
              error={!!errors.email}
              helperText={errors.email}
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <PhoneNumberField
            value={personalDetail.phoneNumber}
            onChange={(value) =>
              handleInputChange(setPersonalDetail, "phoneNumber", value)
            }
            label="Mobile Number"
            placeholder="(675) 454-2724"
            height="32px"
            width="237px"
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Date Of Birth
            </Typography>
            <TextField
              value={personalDetail.dateOfBirth}
              onChange={(e) =>
                handleInputChange(
                  setPersonalDetail,
                  "dateOfBirth",
                  e.target.value
                )
              }
              type="date"
              autoComplete="new-password"
              placeholder="mm/dd/yyyy"
              error={!!errors.dateOfBirth}
              helperText={errors.dateOfBirth}
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              SSN Number
            </Typography>
            <TextField
              value={personalDetail.socialSecurityNo}
              onChange={(e) =>
                handleInputChange(
                  setPersonalDetail,
                  "socialSecurityNo",
                  e.target.value
                )
              }
              type="number"
              autoComplete="new-password"
              placeholder="Number"
              error={!!errors.socialSecurityNo}
              helperText={errors.socialSecurityNo}
              sx={{
                width: "137px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Picture ID Number
            </Typography>
            <TextField
              value={personalDetail.pictureIdNo}
              onChange={(e) =>
                handleInputChange(
                  setPersonalDetail,
                  "pictureIdNo",
                  e.target.value
                )
              }
              type="number"
              autoComplete="new-password"
              placeholder="Number"
              error={!!errors.pictureIdNo}
              helperText={errors.pictureIdNo}
              sx={{
                width: "137px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              ID Issued
            </Typography>
            <TextField
              value={personalDetail.idIssued}
              onChange={(e) =>
                handleInputChange(setPersonalDetail, "idIssued", e.target.value)
              }
              type="date"
              autoComplete="new-password"
              placeholder="mm/dd/yyyy"
              error={!!errors.idIssued}
              helperText={errors.idIssued}
              sx={{
                width: "137px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              ID Expired
            </Typography>
            <TextField
              value={personalDetail.idExpired}
              onChange={(e) =>
                handleInputChange(
                  setPersonalDetail,
                  "idExpired",
                  e.target.value
                )
              }
              type="date"
              autoComplete="new-password"
              placeholder="mm/dd/yyyy"
              error={!!errors.idExpired}
              helperText={errors.idExpired}
              sx={{
                width: "137px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2, paddingTop: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Picture ID Front
            </Typography>
            <Box
              sx={{
                width: "237.47px",
                height: "120px",
                border: "1px dashed #d0d0d0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                borderRadius: 1,
                paddingTop: 1.5,
                position: "relative",
              }}
            >
              {front ? (
                <img
                  src={front}
                  alt="License Front"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <>
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    Drop here to attach or upload
                  </Typography>
                  <Typography variant="caption" sx={{ fontSize: "10px" }}>
                    Max size: 1MB
                  </Typography>
                </>
              )}
              <IconButton
                color="primary"
                component="label"
                sx={{ position: "absolute", bottom: "5px" }}
              >
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleImageUpload(e, setFront)}
                />
                <UploadIcon />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Picture ID Back
            </Typography>
            <Box
              sx={{
                width: "237.47px",
                height: "120px",
                border: "1px dashed #d0d0d0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                borderRadius: 1,
                paddingTop: 1.5,
                position: "relative",
              }}
            >
              {back ? (
                <img
                  src={back}
                  alt="License back"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <>
                  <Typography variant="body2" sx={{ fontSize: "11px" }}>
                    Drop here to attach or upload
                  </Typography>
                  <Typography variant="caption" sx={{ fontSize: "10px" }}>
                    Max size: 1MB
                  </Typography>
                </>
              )}
              <IconButton
                color="primary"
                component="label"
                sx={{ position: "absolute", bottom: "5px" }}
              >
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleImageUpload2(e, setBack)}
                />
                <UploadIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Account Credentials{"  "}
            <span style={{ color: "#fb1001" }}>(Mandatory Field)</span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              User Name
            </Typography>
            <TextField
              value={personalDetail.username}
              onChange={(e) =>
                handleInputChange(setPersonalDetail, "username", e.target.value)
              }
              type="text"
              autoComplete="new-password"
              placeholder="User Name"
              error={!!errors.username}
              helperText={errors.username}
              sx={{
                width: "199px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Email
            </Typography>
            <TextField
              value={personalDetail.email}
              onChange={(e) =>
                handleInputChange(setPersonalDetail, "email", e.target.value)
              }
              type="email"
              autoComplete="new-password"
              placeholder="email"
              error={!!errors.email}
              helperText={errors.email}
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Password
            </Typography>
            <TextField
              value={personalDetail.password}
              onChange={(e) =>
                handleInputChange(setPersonalDetail, "password", e.target.value)
              }
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              autoComplete="new-password"
              error={!!errors.password}
              helperText={errors.password}
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                      sx={{
                        padding: "4px",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOff
                          sx={{
                            fontSize: "16px",
                            width: "16px",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <Visibility
                          sx={{
                            fontSize: "16px",
                            width: "16px",
                            color: "#000",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Confirm Password
            </Typography>
            <TextField
              value={personalDetail.confirmPassword}
              onChange={(e) =>
                handleInputChange(
                  setPersonalDetail,
                  "confirmPassword",
                  e.target.value
                )
              }
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Password"
              autoComplete="new-password"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                      sx={{
                        padding: "4px",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      {showConfirmPassword ? (
                        <VisibilityOff
                          sx={{
                            fontSize: "16px",
                            width: "16px",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <Visibility
                          sx={{
                            fontSize: "16px",
                            width: "16px",
                            color: "#000",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Banking Details
            {/* <span style={{ color: "#fb1001" }}>(Mandatory Field)</span> */}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Bank Name
            </Typography>
            <TextField
              value={bankDetails.bankName}
              onChange={(e) =>
                handleInputChange(setBankDetails, "bankName", e.target.value)
              }
              type="text"
              placeholder="Bank Name"
              autoComplete="new-password"
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Title on Account
            </Typography>
            <TextField
              value={bankDetails.accountTitle}
              onChange={(e) =>
                handleInputChange(
                  setBankDetails,
                  "accountTitle",
                  e.target.value
                )
              }
              type="text"
              autoComplete="new-password"
              placeholder="Title on Account"
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Account Number
            </Typography>
            <TextField
              value={bankDetails.accountNo}
              onChange={(e) =>
                handleInputChange(setBankDetails, "accountNo", e.target.value)
              }
              type="Number"
              autoComplete="new-password"
              placeholder="Account Number"
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              ACH routing
            </Typography>
            <TextField
              value={bankDetails.achRouting}
              onChange={(e) =>
                handleInputChange(setBankDetails, "achRouting", e.target.value)
              }
              type="number"
              autoComplete="new-password"
              placeholder="ACH Routing"
              sx={{
                width: "237px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Work Schedule
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexWrap: "wrap", columnGap: 5, rowGap: 0.7 }}
        >
          {[
            { label: "Monday", day: "Monday" },
            { label: "Tuesday", day: "Tuesday" },
            { label: "Wednesday", day: "Wednesday" },
            { label: "Thursday", day: "Thursday" },
            { label: "Friday", day: "Friday" },
            { label: "Saturday", day: "Saturday" },
            { label: "Sunday", day: "Sunday" },
          ].map(({ label, day }) => (
            <Box key={day}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    marginBottom: "3px",
                  }}
                >
                  {label}
                </Typography>
                <Checkbox
                  checked={facility_team_work[day].checked}
                  onChange={handleWeekdayChange(day)}
                  sx={{
                    width: "10px",
                    height: "10px",
                    transform: "scale(0.6)",
                    color: "#d2d5da",
                    "&.Mui-checked": { color: "#000" },
                  }}
                />
              </Box>
              {facility_team_work[day].checked && (
                <Box>
                  <TextField
                    value={facility_team_work[day].start_time}
                    onChange={(e) => handleStartTimeChange(day, e.target.value)}
                    type="time"
                    sx={{
                      width: "136px",
                      "& .MuiInputBase-input": {
                        fontSize: "11px",
                        padding: "8px 12px",
                      },
                    }}
                  />

                  <TextField
                    value={facility_team_work[day].end_time}
                    onChange={(e) => handleEndTimeChange(day, e.target.value)}
                    type="time"
                    autoComplete="new-password"
                    sx={{
                      width: "136px",
                      "& .MuiInputBase-input": {
                        fontSize: "11px",
                        padding: "8px 12px",
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Pay Rate
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Type
            </Typography>
            <CustomDropdown
              customstyles={{
                height: "32px",
                width: "137px",
              }}
              label=""
              value={payType}
              onChange={(value) => handlePayTypeChange(value)}
              options={payrateOptions}
              isMultiSelect={false}
              error={!!errors.type}
            />
          </Box>

          {/* Conditionally render Hourly Pay and Over Time fields based on payType */}
          {payType !== "Weekly" ? (
            <>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    marginBottom: "3px",
                  }}
                >
                  Hourly Pay
                </Typography>
                <TextField
                  value={personalDetail.hourPrice}
                  onChange={(e) =>
                    handleInputChange(
                      setPersonalDetail,
                      "hourPrice",
                      e.target.value
                    )
                  }
                  type="number"
                  autoComplete="new-password"
                  placeholder="0.00"
                  sx={{
                    width: "136px",
                    "& .MuiInputBase-input": {
                      fontSize: "11px",
                      padding: "8px 12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "32px",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "11px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#4b5563",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ fontSize: "10px", paddingLeft: "5px" }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    marginBottom: "3px",
                  }}
                >
                  Over Time
                </Typography>
                <TextField
                  value={personalDetail.overTimePrice}
                  onChange={(e) =>
                    handleInputChange(
                      setPersonalDetail,
                      "overTimePrice",
                      e.target.value
                    )
                  }
                  type="number"
                  autoComplete="new-password"
                  placeholder="0.00"
                  sx={{
                    width: "136px",
                    "& .MuiInputBase-input": {
                      fontSize: "11px",
                      padding: "8px 12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "32px",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "11px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#4b5563",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ fontSize: "10px", paddingLeft: "5px" }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    marginBottom: "3px",
                  }}
                >
                  Regular Hours/Week
                </Typography>
                <TextField
                  value={personalDetail.regularHourPrice}
                  onChange={(e) =>
                    handleInputChange(
                      setPersonalDetail,
                      "regularHourPrice",
                      e.target.value
                    )
                  }
                  type="number"
                  placeholder="0.00"
                  sx={{
                    width: "136px",
                    "& .MuiInputBase-input": {
                      fontSize: "11px",
                      padding: "8px 12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "32px",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "11px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#4b5563",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ fontSize: "10px", paddingLeft: "5px" }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </>
          ) : (
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
              >
                Regular Hours/Week
              </Typography>
              <TextField
                value={personalDetail.weekPrice}
                onChange={(e) =>
                  handleInputChange(
                    setPersonalDetail,
                    "weekPrice",
                    e.target.value
                  )
                }
                type="number"
                placeholder="0.00"
                sx={{
                  width: "136px",
                  "& .MuiInputBase-input": {
                    fontSize: "11px",
                    padding: "8px 12px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "11px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4b5563",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ fontSize: "10px", paddingLeft: "5px" }}
                    >
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Additional Information
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Additional Document
            </Typography>

            <TextField
              value={facility_team_documents.description}
              onChange={(e) =>
                handleInputChange(
                  setfacility_team_documents,
                  "description",
                  e.target.value
                )
              }
              type="text"
              autoComplete="new-password"
              placeholder="Field text goes here"
              sx={{
                width: "405px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, marginBottom: "3px" }}
            >
              Additional Information
            </Typography>

            <TextField
              value={personalDetail.addtionalNote}
              onChange={(e) =>
                handleInputChange(
                  setPersonalDetail,
                  "addtionalNote",
                  e.target.value
                )
              }
              type="text"
              autoComplete="new-password"
              placeholder="Field text goes here"
              sx={{
                width: "405px",
                "& .MuiInputBase-input": {
                  fontSize: "11px",
                  padding: "8px 12px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "11px",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#4b5563",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ backgroundColor: "#f4f6f8", py: 1, px: 1, my: 0.5 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: 600, color: "#637681" }}
          >
            Permissions
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 5,
            rowGap: 0.7,
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_dashboard}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_dashboard: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Show Dashboard
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_rx}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_rx: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Show RX
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_pharm}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_pharm: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Show Pharmacies
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_facuser}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_facuser: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Show Facility User
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_driver}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_driver: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Show Drivers
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_routes}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_routes: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Show Routes
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_cities}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_cities: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Show Cities
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_reports}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_reports: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Show Report
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_noti}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_noti: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Show Notification
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_scanning}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_scanning: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Scanning Bar
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.2,
              }}
            >
              <Checkbox
                checked={personalDetail.show_finance}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    show_finance: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Show Finance
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
              marginTop: 3,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={personalDetail.calling_allowed}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    calling_allowed: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Allow Calling
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={personalDetail.sms_allowed}
                onChange={(e) =>
                  setPersonalDetail({
                    ...personalDetail,
                    sms_allowed: e.target.checked,
                  })
                }
                sx={{
                  width: "10px",
                  height: "10px",
                  transform: "scale(0.6)",
                  color: "#d2d5da",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 400 }}
              >
                Allow SMS
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          marginTop: 5,
        }}
      >
        {userId && (
          <ContainedButton
            label="Delete"
            onClick={handleOpenDeleteModal}
            borderRadius="5px"
            fontSize="13px"
            width="66px"
            height="32px"
            bgColor="#f44336"
          />
        )}
        <ContainedButton
          label={
            loading ? (
              <CircularProgress size={16} sx={{ color: "white" }} />
            ) : (
              "Save"
            )
          }
          onClick={handleSubmit}
          borderRadius="5px"
          fontSize="13px"
          width="66px"
          height="32px"
          loading={loading}
          disabled={loading}
        />
      </Box>
      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this user?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            sx={{
              backgroundColor: "#f44336",
              color: "white",
              "&:hover": {
                backgroundColor: "#d32f2f",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserForm;
