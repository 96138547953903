import { Box } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import OrderInfo from "../../homePageCompo/OrderDetails/orderInfo/OrderInfo";
import StatusInfo from "../../homePageCompo/OrderDetails/statusInfo/StatusInfo";
import { OrderDataContext } from "../../../context/OrderDataContext";
import { PERSON_ORDERS } from "../../../utils/apiUrl";
import { getApiWithAuth } from "../../../utils/api";
const PreviousOrder = () => {
  const { orderData } = useContext(OrderDataContext);
  const [loading, setLoading] = useState(null);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);

  const handleNameClick = async () => {
    setLoading(true);
    const response = await getApiWithAuth(
      PERSON_ORDERS + `?orderId=${orderData?.order?.id}&name=${orderData?.order?.name}&phone=${orderData?.order?.contact}&cell=${orderData?.order?.cell}&work=${orderData?.order?.workNumber}`
    );
    if (response.data !== undefined) {
      setSelectedOrderDetails(response.data.data);
      setLoading(false);
    } else {
      console.log("Error");
    }
  };
  useEffect(() => {
    handleNameClick()
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      {selectedOrderDetails?.map((order, index) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            padding: "10px",
            borderRadius: 1,
            border: "1px solid rgba(0,0,0,0.2)",
            transition: "background-color 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#f0f0f0", // Change to a light gray on hover
            },
          }}
        >
          <OrderInfo
            previousOrder={true}
            // showTicket={order.last_orderticket !== null}
            showUrgent={order.deliverydate === "urgent"}
            showRefrigerated={order.refrigrated}
            order={order}
          />
          <StatusInfo previousOrder={true} order={order} />
        </Box>
      ))}
    </Box>
  );
};

export default PreviousOrder;
