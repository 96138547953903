import { Box } from "@mui/material";
import React from "react";
import { ContainedButton } from "../buttonCompo/Buttons";

const Topbar = () => {
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <ContainedButton
        label={
          <>
            Today Orders <span style={{ color: "#fe9604" }}>(400)</span>
          </>
        }
        bgColor="transparent"
        border="1px solid #dce0e4"
        borderRadius="5px"
        textColor="#000"
        fontSize="12px"
        fontWeight="600"
      />
      <ContainedButton
        label={
          <>
            Today 2pm to 6pm <span style={{ color: "#fe9604" }}>(67)</span>
          </>
        }
        bgColor="transparent"
        border="1px solid #dce0e4"
        borderRadius="5px"
        textColor="#000"
        fontSize="12px"
        fontWeight="600"
      />
      <ContainedButton
        label={
          <>
            Today 5pm to 9pm <span style={{ color: "#fe9604" }}>(88)</span>
          </>
        }
        bgColor="transparent"
        border="1px solid #dce0e4"
        borderRadius="5px"
        textColor="#000"
        fontSize="12px"
        fontWeight="600"
      />
      <ContainedButton
        label={
          <>
            Next Day Anytime <span style={{ color: "#fe9604" }}>(250)</span>
          </>
        }
        bgColor="transparent"
        border="1px solid #dce0e4"
        borderRadius="5px"
        textColor="#000"
        fontSize="12px"
        fontWeight="600"
      />
      <ContainedButton
        label={
          <>
            Next Day 9am to 2pm <span style={{ color: "#fe9604" }}>(88)</span>
          </>
        }
        bgColor="transparent"
        border="1px solid #dce0e4"
        borderRadius="5px"
        textColor="#000"
        fontSize="12px"
        fontWeight="600"
      />
      <ContainedButton
        label={
          <>
            Next Day 2pm to 6pm <span style={{ color: "#fe9604" }}>(88)</span>
          </>
        }
        bgColor="transparent"
        border="1px solid #dce0e4"
        borderRadius="5px"
        textColor="#000"
        fontSize="12px"
        fontWeight="600"
      />
      <ContainedButton
        label={
          <>
            Next Day 5pm to 9pm <span style={{ color: "#fe9604" }}>(88)</span>
          </>
        }
        bgColor="transparent"
        border="1px solid #dce0e4"
        borderRadius="5px"
        textColor="#000"
        fontSize="12px"
        fontWeight="600"
      />
    </Box>
  );
};

export default Topbar;
