import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { CallIcon, MailIcon, PersonIconDriver, SettingsIcon } from "../Icons";
import {
  ContainedButton,
  ContainedButtonWithIcon,
} from "../buttonCompo/Buttons";
import { useNavigate } from "react-router-dom";

const DriverOverview = ({ driver, showActive }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const currentDate = new Date().toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const handleSettingsClick = (driver) => {
    navigate(`add-info?id=${driver.id}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 20px",
        boxShadow: 1,
        borderRadius: "8px",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f0f0f0",
          boxShadow: 1,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: "700px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            width: "207px",
            padding: "7px",
            border: "none",
            backgroundColor: "#f1f3f4",
            color: "#000",
            gap: 1,
          }}
        >
          <PersonIconDriver />
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, fontSize: "11.71px" }}
          >
            {driver.firstName + " " + driver.lastName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            minWidth: "129px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <CallIcon />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {driver.cellNumber !== "" && driver.cellNumber !== null
              ? driver.cellNumber
              : driver.phoneNumber}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            maxWidth: "237px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <MailIcon />
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {driver.email}
          </Typography>
        </Box>
        {isLargeScreen && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textTransform: "none",
              padding: "11px",
              borderRadius: "8px",
              border: "1px solid #dce0e4", // Border styling like 'outlined'
              color: "#000",
              height: "32px",
              width: "87px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, fontSize: "10.74px" }}
            >
              Routes: {driver.routeCount}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, fontSize: "10.74px" }}
            >
              Stops: {driver.totalStops}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        {!showActive && (
          <ContainedButton
            label="Deactivated"
            bgColor="#fc1002"
            width="91px"
            height="31.23px"
            border="none"
          />
        )}
        {showActive && (
          <ContainedButton
            label="Active"
            bgColor="#05b320"
            width="60.51px"
            height="31.23px"
            border="none"
          />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textTransform: "none",
            borderRadius: "8px",
            height: "31px",
            width: "124px",
            padding: "7px",
            border: "1px solid #dce0e4", // Border styling like 'outlined'
            color: "#000",
            gap: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, fontSize: "10.74px" }}
          >
            {new Date(driver.createdAt).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </Typography>
        </Box>
        <ContainedButtonWithIcon
          label="Account Setting"
          icon={<SettingsIcon />}
          bgColor="#000"
          borderRadius="8px"
          width="124.94px"
          height="31.23px"
          padding="4.19px"
          onClick={() => handleSettingsClick(driver)}
        />
        {driver?.profilefile ? (
          <img
            src={driver?.profilefile}
            alt="Preview"
            style={{
              width: 40,
              height: 40,
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        ) : (
          <Avatar
            src="/icons/Profile.png"
            alt="Profile Image"
            sx={{
              width: 40,
              height: 40,
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default DriverOverview;
