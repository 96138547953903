import React from "react";
import { Box, Typography, Chip, Avatar } from "@mui/material";
import { Andi, Bye, Drew, Natali, Orlando } from "../Icons";
const statusColors = {
  "Payment In": "#baedbd", // Light green
  "Payment Out": "#f8d7da", // Light red (background for Payment Out)
};
const statusTextColors = {
  "Payment In": "rgba(80, 200, 80, 0.9)", // Darker green text
  "Payment Out": "#721c24", // Dark red text for Payment Out
};
const FinanceHistory = ({expenses}) => {
  return (
    <Box
      sx={{
        padding: "16px",
        borderRadius: "24px",
        backgroundColor: "#f7f9fb",
        width: "100%",
      }}
    >
      {/* Title */}
      <Typography
        variant="body2"
        sx={{
          fontSize: "18px",
          fontWeight: 600,
          marginBottom: "22px",
          marginTop: "18px",
          lineHeight: "28px",
        }}
      >
        Finance History
      </Typography>
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box sx={{ flex: 1 }}>
          {/* Table Header */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              padding: "8px 16px",
              borderRadius: "12px",
              fontWeight: 400,
              fontSize: "12px",
              marginLeft:"5px"
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Name
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Date
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Amount
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Type
            </Typography>
          </Box>

          {/* Table Rows */}
          {expenses.map((row, index) => (
            <Box
              key={index}
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                alignItems: "center",
                padding: "8px",
                backgroundColor: index % 2 === 0 ? "#e3f5ff" : "#e5ecf6", // Alternate row colors
                borderRadius: "16px",
                marginTop: "4px",
              }}
            >
              {/* Manager with Avatar */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {row.avatar}
                <Box
                  sx={{
                    cursor: "pointer",
                    transition: "transform 0.3s ease", // Smooth transition
                    "&:hover": {
                      transform: "translateX(10px)", // Move to the right on hover
                    },
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{ fontSize: "14px", fontWeight: 400 }}
                  >
                    {row.name}
                  </Typography>
                </Box>
              </Box>

              {/* Date */}
              <Typography
                variant="h1"
                sx={{ fontSize: "14px", fontWeight: 400 }}
              >
                {row.date}
              </Typography>

              {/* Amount */}
              <Typography
                variant="h1"
                sx={{ fontSize: "14px", fontWeight: 400 }}
              >
                {row.amount}
              </Typography>

              {/* Status */}
              <Chip
                label={row.payment_type}
                sx={{
                  backgroundColor: statusColors[row.payment_type],
                  color: statusTextColors[row.payment_type],
                  fontWeight: 500,
                  width: "150px",
                  padding: "8px",
                  borderRadius: "12px",
                  textTransform: "capitalize",
                }}
              />
            </Box>
          ))}
        </Box>
        <Box sx={{ flex: 1 }}>
          {/* Table Header */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              padding: "8px 16px",
              borderRadius: "12px",
              fontWeight: 400,
              fontSize: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Manager
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Date
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Amount
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Status
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FinanceHistory;
