import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  TextField,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import { OrderDataContext } from "../../context/OrderDataContext";

const getFormattedDate = (date) => {
  if (date) {
    const dateObj = new Date(date);
    return dateObj.toISOString().split('T')[0]; // Format to "yyyy-MM-dd"
  }
  return '';
};

const PatientOrderDetails = ({ onOrderDetailChange }) => {
  const { orderData, setOrderData } = useContext(OrderDataContext);
  const [localData, setLocalData] = useState(orderData);
  useEffect(() => {
    setLocalData(orderData);
  }, [orderData]);
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      onOrderDetailChange(localData);
    }, 300);
    return () => clearTimeout(debounceTimer);
  }, [localData, onOrderDetailChange]);

  const handleOrderChange = (event, index) => {
    const { name, value, type, checked } = event.target;
    let updatedValue = value;

    console.log("Wwww=============", name, updatedValue)

    if (type === "checkbox") {
      updatedValue = checked;
    }
    if (name.includes(".")) {
      const [list, rxIndex, fieldName] = name.split(".");
      const updatedRxList = [...localData?.orderdetail];
      updatedRxList[index][fieldName] = updatedValue;  
      // Update collectCopay based on selected items
      const anySelected = updatedRxList.some((rx) => rx.select);
  
      // Calculate total copay
      const totalCopay = updatedRxList.reduce((total, rx) => {
        return rx.select ? total + parseFloat(rx.pat_pay_amount) : total;
      }, 0);
      setLocalData({
        ...localData,
        orderdetail: updatedRxList,
        collectCopay: anySelected,
        totalCopay: totalCopay,
      });
    } else if (name === "collectCopay") {
      const updatedRxList = localData?.orderdetail.map((rx) => ({
        ...rx,
        select: checked,
      }));

      let totalCopay = 0;

      if (checked) {
        localData?.orderdetail.forEach((rx) => {
          totalCopay += parseFloat(rx.pat_pay_amount);
        });
      }

      setLocalData({
        ...localData,
        [name]: updatedValue,
        orderdetail: updatedRxList,
        totalCopay: totalCopay,
      });
    } else if (name === "zip") {
      const trimmedValue = value.replace(/\s/g, "");
      if (trimmedValue.length <= 5) {
        setLocalData((prevState) => ({
          ...prevState,
          [name]: trimmedValue,
        }));
      }
    } else {
      console.log("Wwww", name, updatedValue)
      setLocalData({
        ...localData,
        [name]: updatedValue,
      });
    }
  };

  const handleAddRow = () => {
    setLocalData((prev) => ({
      ...prev,
      orderdetail: [
        ...(Array.isArray(prev.orderdetail) ? prev.orderdetail : []), // Ensure it's an array
        {
          quantity: "1",
          rf_number: "0",
          date: "",
          pat_pay_amount: "0.00",
          rx_number: "M - ",
          select: false,
        },
      ],
    }));
  };

  const handleDeleteRow = (index) => {
    const updatedRxList = [...localData?.orderdetail];
    updatedRxList.splice(index, 1);
    setLocalData((prev) => ({
      ...prev,
      orderdetail: updatedRxList,
    }));
  };


  return (
    <Box sx={{ mb: 1 }}>
      <Box
        sx={{
          width: "full",
          height: "38px",
          marginBottom: "3px",
          backgroundColor: "#f4f6f8",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            opacity: "0.7",
            paddingLeft: "15px",
          }}
        >
          Order Details
        </Typography>
      </Box>
      <Box sx={{ maxWidth: "750px" }}>
        <TableContainer>
          <Table
            sx={{ borderCollapse: "collapse" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="start"
                  sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                >
                  Count
                </TableCell>
                <TableCell
                  align="start"
                  sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                >
                  RX Number
                </TableCell>
                <TableCell
                  align="start"
                  sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                >
                  Date
                </TableCell>
                <TableCell
                  align="start"
                  sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                >
                  Copay
                </TableCell>
                <TableCell
                  align="start"
                  sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "12px", fontWeight: 600, padding: 0 }}
                >
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {localData?.orderdetail?.map((rx, i) => (
                <TableRow key={i}>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "11px", padding: 0, width: "50px" }}
                  >
                    <Box
                      name="i"
                      sx={{
                        fontSize: "11px",
                        border: "1px solid #d2d5da",
                        height: "33px",
                        width: "50%",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {i + 1}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="start"
                    sx={{ fontSize: "11px", padding: 0, width: "100px" }}
                  >
                    <TextField
                      name={`orderdetail.${i}.rx_number`}
                      value={rx.rx_number}
                      onChange={(e) => handleOrderChange(e, i, "rx_number")}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      sx={{
                        width: "80%",
                        "& .MuiOutlinedInput-root": {
                          fontSize: "11px",
                          borderRadius: "6px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="start"
                    sx={{ fontSize: "11px", padding: 0, width: "100px" }}
                  >
                    <TextField
                      name={`orderdetail.${i}.date`}
                      value={getFormattedDate(rx.date)}  // Convert to YYYY-MM-DD for input
                      onChange={(e) => handleOrderChange(e, i, "date")}
                      type="date"
                      autoComplete="off"
                      variant="outlined"
                      size="small"
                      sx={{
                        width: "85%",
                        "& .MuiOutlinedInput-root": {
                          fontSize: "11px",
                          borderRadius: "6px",
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </TableCell>
                  <TableCell
                    align="start"
                    sx={{ fontSize: "11px", padding: 0, width: "50px" }}
                  >
                    <TextField
                      value={rx.pat_pay_amount}
                      name={`orderdetail.${i}.pat_pay_amount`}
                      onChange={(e) =>
                        handleOrderChange(e, i, "pat_pay_amount")
                      }
                      variant="outlined"
                      autoComplete="off"
                      size="small"
                      sx={{
                        width: "80%",
                        "& .MuiOutlinedInput-root": {
                          fontSize: "11px",
                          borderRadius: "6px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: "0", width: "50px" }}>
                    <Checkbox
                      name={`orderdetail.${i}.select`}
                      checked={rx.select}
                      onChange={(e) => handleOrderChange(e, i, "select")}
                      sx={{ transform: "scale(0.8)" }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "11px", padding: "0", width: "10px" }}
                  >
                    <IconButton
                      onClick={() => handleDeleteRow(i)}
                      sx={{ padding: 0 }}
                    >
                      <img
                        src="./icons/deliveryIcons/Delete.svg"
                        alt="Delete-Icon"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          onClick={handleAddRow}
          sx={{
            mt: 2,
            backgroundColor: "#000",
            color: "#fff",
            borderRadius: "10px",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <img src="./icons/deliveryIcons/Add.svg" alt="add" /> Add Item
        </Button>
      </Box>
    </Box>
  );
};

export default PatientOrderDetails;
