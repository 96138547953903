import React from "react";
import { Box, Typography } from "@mui/material";

const ProgressBar = ({ done, pending }) => {
  const isZero = done === 0 && pending === 0;
  console.log(done);

  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "8px",
        overflow: "hidden",
        width: "330px", // Adjust as needed
        height: "32px", // Adjust height as per your design
        border: "1px solid #dce0e4", // Optional border for clarity
      }}
    >
      {/* If Done is 100%, show only Done */}
      {done === "100.00" && (
        <Box
          sx={{
            flexBasis: "100%",
            backgroundColor: "#05b320",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#fff",
              fontWeight: 500,
              fontSize: "13px",
            }}
          >
            Done: 100%
          </Typography>
        </Box>
      )}

      {/* If Pending is 100%, show only Pending */}
      {pending === "100.00" && (
        <Box
          sx={{
            flexBasis: "100%",
            backgroundColor: "#fe9604",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#fff",
              fontWeight: 500,
              fontSize: "13px",
            }}
          >
            Pending: 100%
          </Typography>
        </Box>
      )}

      {/* If both are 0%, divide equally */}
      {isZero && (
        <>
          <Box
            sx={{
              flexBasis: "50%",
              backgroundColor: "#05b320",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#fff",
                fontWeight: 500,
                fontSize: "13px",
              }}
            >
              Done: 0%
            </Typography>
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              backgroundColor: "#fe9604",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#fff",
                fontWeight: 500,
                fontSize: "13px",
              }}
            >
              Pending: 0%
            </Typography>
          </Box>
        </>
      )}

      {/* Regular case: dynamically show Done and Pending */}
      {done !== "100.00" && pending !== "100.00" && !isZero && (
        <>
          <Box
            sx={{
              flexBasis: `${done}%`,
              backgroundColor: "#05b320",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#fff",
                fontWeight: 500,
                fontSize: "13px",
              }}
            >
              Done: {done}%
            </Typography>
          </Box>
          <Box
            sx={{
              flexBasis: `${pending}%`,
              backgroundColor: "#fe9604",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#fff",
                fontWeight: 500,
                fontSize: "13px",
              }}
            >
              Pending: {pending}%
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProgressBar;
