import {
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Link,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { setToken } from "../utils/localStorage";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { ContainedButton } from "../components/buttonCompo/Buttons";
import { postApiWithoutAuth } from "../utils/api";
import { LOGIN, TOKEN_VALIDITY } from "../utils/apiUrl";
import { fetchUserDetail } from "../slices/userSlice";
const Login = ({ setIsAuthenticated, isAuthenticated, userType }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [pid, setPid] = useState(0);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const colorOptions = [
    {
      label: "Default Blue",
      backgroundColor: "#0B6699",
      hoverColor: "#044367",
      fontColor: "#FFFFFF",
      iconColor: "#fff",
    },
    {
      label: "Light Gray",
      backgroundColor: "#E6E6E6",
      hoverColor: "#C7C6C6",
      fontColor: "#000000",
      iconColor: "#000",
    },
    {
      label: "Dark Blue",
      backgroundColor: "#091235",
      hoverColor: "#4A5275",
      fontColor: "#FFFFFF",
      iconColor: "#fff",
    },
    {
      label: "Dark Gray",
      backgroundColor: "#2B4257",
      hoverColor: "#19334C",
      fontColor: "#FFFFFF",
      iconColor: "#fff",
    },
    {
      label: "Greenish Gray",
      backgroundColor: "#53655C",
      hoverColor: "#415E4F",
      fontColor: "#FFFFFF",
      iconColor: "#fff",
    },
    {
      label: "Deep Purple",
      backgroundColor: "#341f31",
      hoverColor: "#492D45",
      fontColor: "#FFFFFF",
      iconColor: "#fff",
    },
  ];

  useEffect(() => {
    console.log("Component mounted. Calling checkTokenValidity.");
    const checkTokenValidity = async () => {
      console.log("Checking token validity...");
      const token = localStorage.getItem("access_token");

      // Check if the token exists
      if (token) {
        const isValid = await validateToken(token);
        console.log("isValid", isValid);
        if (!isValid) {
          console.log("Invalid token, clearing local storage.");
          localStorage.clear(); // Clear local storage if the token is not valid
        } else {
          const storedUserType = localStorage.getItem("userType");
          const pharmacyId = localStorage.getItem("pharmacyId");
          // Navigate based on user type and pharmacy ID
          
          if (
            storedUserType === "PharmacyEmployee" ||
            storedUserType === "PharmacyOwner"
          ) {
            if (pharmacyId) {
              console.log("Navigating to pharmacy orders...");
              navigate(`/order-list`);
            }
          }
        }
      } else {
        console.log("No token found, redirecting to login.");
      }
    };

    checkTokenValidity();
    return () => { };
  }, []);

  // Helper function to validate token and return validity
  const validateToken = async (token) => {
    try {
      console.log("validate api is called");
      const response = await postApiWithoutAuth(TOKEN_VALIDITY, { token });
      console.log("responseee", response.data, response.data.valid);
      return response.data.valid; // Return the validity status
    } catch (error) {
      console.error("Error validating token:", error);
      return false; // Return false in case of error
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onChangeHandle = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setError("");
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();

    // Prevent multiple clicks while loading
    if (loading) return;

    setLoading(true); // Disable the button and show the spinner

    try {
      const response = await postApiWithoutAuth(LOGIN, data);
      console.log(response);

      if (response.status === 200) {
        // Successful response, handle token and session storage
        const { data } = response;
        setToken(data.access);
        localStorage.setItem("userType", data.type.type);
        localStorage.setItem("superuser", data.is_superuser);
        localStorage.setItem("userDetail", data.first_name + " " + data.last_name);
        localStorage.setItem("profilePic", data.profile_pic);
        localStorage.setItem("sessionId", data.session_id);
        localStorage.setItem("route_startaddress", data.route_startaddress);
        const color = colorOptions.find((c) => c.label === data.selected_color);
        if (color) {
          localStorage.setItem("selectedColor", color.backgroundColor);
          localStorage.setItem("selectedFontColor", color.fontColor);
          localStorage.setItem("selectedIconColor", color.iconColor);
          localStorage.setItem("selectedHoverColor", color.hoverColor);
        }
        localStorage.setItem("UID", data.id);

        // Check user type and navigate accordingly
        if (data.type.type === "Driver") {
          setError("Driver can't access this web panel");
          setLoading(false);
        } else {
          dispatch(fetchUserDetail())
          // Handle pharmacy employees or owners
          if (
            data.type.type === "PharmacyEmployee" ||
            data.type.type === "PharmacyOwner"
          ) {
            setPid(data.pharmacy_id);
            localStorage.setItem("pharmacyId", data.pharmacy_id);
            localStorage.setItem("show_report", data.show_report);
            localStorage.setItem("show_team", data.show_team);

            // Handle device ID logic
            let deviceId = localStorage.getItem("deviceId");
            if (!deviceId) {
              deviceId = uuidv4();
              localStorage.setItem("deviceId", deviceId);
            }

            // After success, navigate to order list
            navigate(`/order-list`);
          } else {
            // For non-pharmacy users, navigate to the dashboard
            navigate("/order-list");
          }
        }
      } else {
        // Handle failed login
        setLoading(false);
        setError(response.data.non_field_errors[0] || "Login failed.");
      }
    } catch (error) {
      console.error(error);
      setLoading(false); // Reset loading in case of error
      setError("An error occurred while logging in.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handlerSubmit(e); // Call the submit handler when Enter is pressed
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${process.env.PUBLIC_URL}/icons/login/loginBg.svg)`,
        backgroundSize: "cover",
        backgroundPosition: "center", // Center the image
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "300px",
        }}
      >
        <img src="/icons/LogoBlack.svg" alt="Logo" />
        <Typography
          variant="body2"
          color="#1560BD"
          sx={{
            fontSize: "22px",
            fontWeight: 700,
            mb: 2, // Margin bottom
          }}
        >
          Log in
        </Typography>
        <h3 style={{ color: "rgb(235, 11, 11)", fontSize: "20px" }}>{error}</h3>
        <Typography
          variant="subtitle1"
          fontWeight={700}
          align="left"
          sx={{ width: "100%" }}
        >
          Email
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Enter your email"
          name="email"
          autoComplete="off"
          fullWidth
          onChange={onChangeHandle}
          sx={{
            mb: 2,
          }}
        />

        {/* Password Field */}
        <Typography
          variant="subtitle1"
          fontWeight={700}
          align="left"
          sx={{ width: "100%" }}
        >
          Password
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Enter your Password"
          fullWidth
          name="password"
          autoComplete="off"
          type={showPassword ? "text" : "password"}
          onChange={onChangeHandle}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyDown={handleKeyDown}
          sx={{
            mb: 1,
          }}
        />

        {/* Remember Me and Forgot Password */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mb: 1,
          }}
        >
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label={
              <Typography color="#1560BD" fontSize="14px">
                Remember Me
              </Typography>
            }
          />
          <Link href="#" underline="none" color="#1560BD" fontSize="14px">
            Forget Password?
          </Link>
        </Box>
        <Box>
          <ContainedButton
            label={
              loading ? (
                <CircularProgress size={16} sx={{ color: "white" }} />
              ) : (
                "Log in"
              )
            }
            textColor="white"
            onClick={handlerSubmit}
            disabled={loading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
